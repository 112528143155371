<template>
  <div class="pt-[48px] w-full px-[19px]">
    <!-- 제목 -->
    <div class="">
      <h1 class="mb-3 text-[24px] font-medium">1:1 문의</h1>
    </div>

    <!-- 설명 -->
    <div class="text-[14px] text-noto-300 mb-[50px]">
      <p class="leading-6">보내주신 문의 내용을 검토한 후 가입할 때 작성하신 회원님의 메일로 회신드리겠습니다.</p>
      <p class="leading-6">메일이 오지 않으면 스팸 메일함을 확인해주세요.</p>
    </div>

    <!-- 문의 제목 -->
    <div class="mb-[10px]">
      <input class="w-full sub_border1 h-[40px] pa-3" 
      v-model="qnaTitle"
      placeholder="제목을 입력해주세요"/>
    </div>

    <!-- 문의 내용 -->
    <div class="mb-[50px]">
      <textarea class="w-full sub_border1 h-[300px] px-[12px] py-[8px]" 
      v-model="qnaContent"
      placeholder="내용을 입력해주세요">
      </textarea>
    </div>

    <!-- 제출 버튼 -->
    <div 
    class="w-full h-[50px] bg-main_color text-white flex justify-center items-center mb-[100px] clickable"
    @click="prepareSubmitQNA"
    >
      제출하기
    </div>
  </div>

  <v-dialog v-model="alert_dialog" persistent width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn v-if="qnaStatus" :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="()=>{
                  changeAlertDialogFalse();
                  $router.go(0)
                }">
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn v-else :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="changeAlertDialogFalse">
                    {{ modal_btn_msg }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { requestMemberInquiry } from '../../api/user';
import UtilSessionStorage from '../../utils/session';

const qnaTitle = ref('');
const qnaContent = ref('');
const qnaStatus = ref(false);
const isSubmited = ref(false);

const sessionStorage = new UtilSessionStorage();

async function prepareSubmitQNA(){
  if( isSubmited.value === true ){
    return;
  }
  isSubmited.value = true;
  
  const requestData = {
    id : sessionStorage.getUserId(),
    title : qnaTitle.value,
    content : qnaContent.value.replaceAll(/(\n|\r\n)/g,'<br>'),
  }

  try {
    const response = await requestMemberInquiry( requestData );
    if( response.data.status === 'success' ){
      qnaStatus.value = true;
      onSubmitSuccess();
      isSubmited.value = false;
    } else {
      onSubmitError();
      isSubmited.value = false;
    }
  } catch (error) {
    onSubmitError();
    console.error(error);
    isSubmited.value = false;
  }
  
}

// 모달창 컨트롤용 변수
const alert_dialog = ref(false);
const modal_title = ref('안내');
const nested_msg = ref(null);
const modal_btn_msg = ref('확인');
const modal_btn_width = ref(117);
function changeAlertDialogTrue () { alert_dialog.value = true; }
function changeAlertDialogFalse () { alert_dialog.value = false; }

// 발송 완료 얼럿
function onSubmitSuccess(){
  modal_title.value = `발송 안내`
  nested_msg.value = `1:1 문의가 정상적으로 발송되었습니다. 가입할 때 작성하신 이메일 주소의 메일함을 확인해주세요. 메일이 오지 않는다면 스팸메일함을 확인해주세요.`
  changeAlertDialogTrue();
}

// 문의 발송 오류 안내 얼럿
function onSubmitError(){
  modal_title.value = `승인오류 안내`
  nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
  changeAlertDialogTrue();
}

</script>