import UtilSessionStorage from '@/utils/session'


/**
 * 현재 로그인 상태를 반환합니다.
 * @returns
 */
export function checkLoginStatus() {
    const sessionStorage = new UtilSessionStorage();
    // const user = window.sessionStorage.getItem('loginStatus');
    const user = sessionStorage.getItemWithCrypto('loginStatus');
    try {
        if( user ){
            const userData = JSON.parse(user)
            if ( userData?.status || userData?.status === 'success' ) {
                return true
            }
        }
    } catch (error) {
        console.error(error)
        return false
    }
    return false
}
