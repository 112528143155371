<!-- 마이페이지 > 나의 정보 > 회원탈퇴 -->
<template>
  <div>
    <article class="mb-5">
      <div class="mpmi1 w-[1030px] bg-[#fff] basicBoxShadow">
        <!-- title 영역 -->
        <div class="tm1 border-b px-4 py-2">
          <p class="subTitle text-noto-700 flex">
              나의정보
          </p>
        </div>

        <section class="myinfo_tab1 text-[14px]">
          <div class="flex flex-col justify-center items-center" @submit.prevent>
            <!-- title -->
            <h1 class="__lang subTitle mt-[50px] mb-[10px] text-[#222421] text-noto-700">회원탈퇴</h1>
            <!-- underbar -->
            <div class="w-[500px] border-b mb-[50px]"></div>
            <p class="__lang text-[14px] text-noto-300">회원탈퇴 전, 아래 내용을 확인해주세요.</p>
            <article class="text-market_rise my-[50px]">
              <p class="__lang mb-2">1. 원화 포인트가 5,000 KRW를 초과하는 경우</p>
              <p class="__lang mb-2">2. 보유한 작품이 있을 경우</p>
              <p class="__lang mb-2">3. 미체결 내역이 있을 경우</p>
              <p class="__lang mb-2">4. 입금 진행 중인 건이 있을 경우</p>
              <p class="__lang mb-2">5. 출금 진행 중인 건이 있을 경우</p>
            </article>
            <div class="flex flex-col justify-center items-center text-noto-300 mb-[30px]">
              <p class="__lang">회원탈퇴와 동시에 등록된 회원님의 개인정보는 모두 삭제되며 복구되지 않습니다.</p>
              <p class="__lang">단, 관계 법령의 규정에 따라 회사가 보관하여야 하는 정보는 일정 기간 보관됩니다.</p>
            </div>
            <div class="flex flex-col justify-center items-center text-noto-300 mb-[70px]">
              <p class="__lang">회원탈퇴는 5,000 KRW 이하의 원화 포인트에 대한 권리를 포기하셔야 가능합니다.</p>
              <p class="__lang">탈퇴하시기 전, 출금 가능한 자산 또는 작품이 남아있는지 반드시 확인해주세요.</p>
              <p class="__lang">출금하지 않은 자산이나 작품은 거래소가 처분할 수 있습니다.</p>
              <p class="__lang">직접 탈퇴하실 수 없으면 1:1 문의 부탁드립니다.</p>
            </div>
            <div class="mb-[120px]">
              <button v-if="isitPossibleToWithdrawMember" class="w-[366px] h-[50px]" id="buy_btn" @click.prevent="prepareWithdrawMember">회원탈퇴</button>
              <div v-else class="flex justify-center items-center w-[366px] h-[50px] bg-gray text-white" @click.prevent>회원탈퇴</div>
            </div>
          </div>
        </section>
      </div>
    </article>
  </div>

  <!-- alert용 모달 -->
  <v-dialog v-model="nested_dialog" persistent width="470px">
      <v-card class="pa-4">
          <v-card-text class="flex flex-col">
              <div class="text-noto-700 text-[18px] mb-3">
                  {{ modal_title }}
              </div><br />
              <div class="text-[15px]" v-html="nested_msg">
              </div>
          </v-card-text>
          <v-card-actions class="justify-end mt-12">
              <v-btn v-if="step==='success'" :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="successWithdrawMember">
                  {{ modal_btn_msg }}
              </v-btn>
              <v-btn v-else :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click.prevent="changeNestedDialogFalse">
                  확인
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
// api import
import { logOut, requestWithdrawMember, requestCheckWithdrawCondition } from '../../api/user';
// utils import
import UtilSessionStorage from '../../utils/session';

const router = useRouter();

const sessionStorage = new UtilSessionStorage();

const step = ref('ready')

// 회원탈퇴가 가능한 상황인지 계산해주는 변수
const isitPossibleToWithdrawMember = computed(()=>{
  let result = false;
  try {
    if( ITEM.value.isLessThanKrw && ITEM.value.isNoHaveNft && ITEM.value.isNoPendingOrder && ITEM.value.isNoInOut ){
      result = true;
    }
  } catch (error) {
    console.error(error)
  }
  return result;
})
const ITEM = ref(null);

// 모달창 컨트롤용 변수
const nested_dialog = ref(false);
const modal_title = ref('안내');
const nested_msg = ref('');
const modal_btn_msg = ref('변경');
const modal_btn_width = ref(77);
// 모달창 컨트롤 함수
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }

onMounted(async ()=>{
  try {
    const requestData = {
      id : sessionStorage.getUserId(),
    }
    const response = await requestCheckWithdrawCondition( requestData );
    if( response.data.status === 'success' ){
      ITEM.value = response.data.result;
    }
  } catch (error) {
    console.error(error)
  }
  
})

async function prepareWithdrawMember(){
  const id = sessionStorage.getUserId();
  try {
    const response = await requestWithdrawMember({ id });
    if( response?.data?.status === 'success' ){
      step.value = 'success'
      makeSuccessAlert();
    } else {
      step.value = 'error'
      makeGetErrorAlert();
    }
  } catch (error) {
    console.error(error)
    step.value = 'error'
    makeGetErrorAlert();
  }
  changeNestedDialogTrue();
}

function successWithdrawMember(){
  changeNestedDialogFalse();
  logOut();
  router.push({ path : '/' });
}

function makeSuccessAlert(){
  modal_title.value = '안내';
  nested_msg.value =`성공적으로 회원탈퇴되었습니다.`
}
function makeGetErrorAlert(){
  modal_title.value = '승인오류 안내';
  nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
  일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
  잠시 후 다시 시도해주세요.<br/><br/>
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
}

</script>

<style scoped>
.myinfo_tab1 label{
    width: 370px;
    font-size: 14px;
    margin: 10px 0 5px 0;
}
.myinfo_tab1 input{
    /* background-color: var(--ex_RightA_label); */
    background-color: #FFF;
    width: 366px;
    height: 50px;
    border: 0.3px solid var(--stroke);
    padding: 16px
}
.myinfo_tab1 #name{
    background-color: var(--ex_RightA_label);
}
.myinfo_tab1 .small_tab{
    width: 282px;
}
</style>