/* 양방향 암호화용 메서드 모음 */
import CryptoJS from 'crypto-js'

class Crypto {

  static encodeByAES256( key, data ){
    let result = '';
    result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key),{
      iv: CryptoJS.enc.Utf8.parse(""),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return result.toString();
  }

  static decodeByAES256( key, data ){
    if( data === null || data === undefined || !data ){
      return '';
    }
    let result = ''
    result = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(""),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return result.toString(CryptoJS.enc.Utf8);
  }

  static makeCryptoKey( key ){
    return key.padEnd(32, " ");
  }


  /**
   * 특정 문자를 단방향 인코딩
   * ? 암호화 알고리즘 방식 : SHA521
   * ? 인코딩 방식 : base64
   * @param {string} str 인코딩 할 문자
   * @returns {string}
   */
  static encodingStringByHash(str) {
    try {
      const hash = CryptoJS.HmacSHA512(str, process.env.VUE_APP_ENC_SECRET_KEY);
      return hash.toString(CryptoJS.enc.Base64);  
    } catch (error) {
      console.error(error)
    }
  }

}

export default Crypto