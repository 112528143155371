// api 들이 공통으로 사용할 변수나 함수 등의 로직을 저장합니다.
import Crypto from "../utils/crypto";

// http request용 공통 요청 헤더
export function requestHeader(){
  const item = window.sessionStorage.getItem('_x_access_token')
  const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
  let header = null;
  if( item ){
    header = { 
      headers:
      {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'x-access-token' : `${Crypto.decodeByAES256( secretKey, item )}`,
      }
    }
  } else {
    header = { 
      headers:
      {
        'Content-Type' : 'application/x-www-form-urlencoded',
      }
    }
  }
  return header;
}

// 로그인 시간 갱신용 refresh 요청용 헤더
export function requestRefreshHeader(){
  const accessToken = window.sessionStorage.getItem('_x_access_token')
  const refreshToken = window.sessionStorage.getItem('_x_refresh_token')
  const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
  let header = null;
  if( accessToken && refreshToken ){
    header = { 
      headers:
      {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'x-access-token' : `${Crypto.decodeByAES256( secretKey, accessToken )}`,
        'x-refresh-token' : `${Crypto.decodeByAES256( secretKey, refreshToken )}`,
      }
    }
  } else {
    header = { 
      headers:
      {
        'Content-Type' : 'application/x-www-form-urlencoded',
      }
    }
  }
  return header;
}

export class configForm {

  headers

}