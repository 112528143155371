<template>
  <main>
    <div class="h-[700px] flex items-center justify-center">
      <h1>페이지 준비 중 입니다.</h1>
    </div>
  </main>
</template>

<script setup>

</script>

<style scoped>

</style>