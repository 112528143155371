<!-- 로그인하지 않은 유저도 사용할 수 있는 비밀번호 재설정 페이지 -->
<template>
    <main class="bg-bg2 pa-4 mt-[60px]">
        <article
            v-if="pages === 0"
            class="text-center bg-[#fff] md:container mx-auto md:w-[1240px] md:h-[880px] pt-32"
        >
            <div class="__lang subTitle text-title md:loginbar">비밀번호 재설정</div>
            <div></div>
            <section class="text-login_join_page_text pa-10">
                <div class="__lang">
                    가입하신 이메일 주소를 입력해주세요.<br />
                    해당 이메일 주소로 인증번호를 발송합니다.
                </div>
            </section>
            <section>
                <div class="flex justify-center">
                    <!-- input ----------------------------------------------->
                    <input
                        :value="form.email.value"
                        @input="form.email.value = $event.target.value"
                        @focus="form.email.isFocused = true"
                        @keydown="preventSpace"
                        @blur="form.email.isFocused = false"
                        :class="
                            emailValidationResult.status === false && form.email.isFocused === true
                                ? 'error'
                                : ''
                        "
                        class="border pa-4 w-[289px] mb-2 h-[50px]"
                        type="text"
                        placeholder="이메일 계정을 입력해주세요."
                        id="phone"
                        name="phone"
                        :disabled="emailVerifyState !== 'ready'"
                    />
                    <div
                        v-if="emailVerifyState === 'ready'"
                        class="w-[77px] h-[50px] clickable flex justify-center items-center bg-[#fff] sub_border1 contents_primary text-main_color"
                        @click="prepareSendEmailAuth"
                    >
                        전송하기
                    </div>
                    <div
                        v-else
                        class="mypage_btn_type1 text-gray sub_border1 bg-[#F2F2F2] flex justify-center items-center"
                    >
                        전송하기
                    </div>
                </div>
                <div
                    class="w-[366px] mx-auto text-left text-[14px] mt-2"
                    :class="emailValidationResult?.status ? 'text-sub_color1' : 'text-red'"
                >
                    {{ emailValidationResult?.msg || '' }}
                </div>
                <div
                    class="w-[360px] mx-auto text-left text-[14px] mt-2"
                    :class="emailAuthVerifyTime > 0 ? 'text-sub_color1' : 'text-red'"
                    v-show="emailResponseMsg"
                >
                    {{ emailResponseMsg }}
                </div>

                <!-- 이메일 주소가 없는 것을 확인했고 이메일로 인증 메일을 보냈을 때 나타날 영역 -->
                <div
                    v-if="emailFormCheck"
                    class="flex items-center justify-center mt-5 translate-y-[37px]"
                >
                    <input
                        v-model="userVerifyNumber"
                        class="w-[289px] h-[50px] sub_border1 pa-4"
                        type="text"
                        placeholder="인증번호를 입력해주세요"
                        :disabled="emailVerifyState === 'ready' || isEmailAuthCompleted"
                        :class="isEmailAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                    />
                    <button
                        v-if="emailVerifyState === 'send'"
                        class="mypage_btn_type1"
                        @click.prevent="prepareVerifyEmailAuth"
                    >
                        인증하기
                    </button>
                    <button
                        v-else
                        class="mypage_btn_type1 text-gray sub_border1 bg-[#F4F4F4]"
                        @click.prevent
                    >
                        인증하기
                    </button>
                </div>

                <!-- next btn 영역 -->
                <div class="nextBtnArea mt-[231px]">
                    <button
                        v-if="isEmailAuthCompleted === true"
                        class="__lang default_btn btn_main_green default_btn md:mt-[128px]"
                        @click.prevent="moveToResetPwdPage()"
                    >
                        다음
                    </button>
                    <button
                        v-else
                        class="__lang default_btn j_next_btn default_btn md:mt-[128px]"
                        @click.prevent
                    >
                        다음
                    </button>
                </div>
            </section>
        </article>

        <article
            v-if="pages === 1"
            class="text-center bg-[#fff] container mx-auto w-[1240px] h-[880px] pt-32"
        >
            <div class="__lang subTitle text-title loginbar">비밀번호 재설정</div>
            <div></div>
            <section class="text-login_join_page_text pa-10">
                <div class="__lang">
                    새로운 비밀번호를 입력해주세요.<br />
                    이전과 동일한 비밀번호는 사용할 수 없습니다.
                </div>
            </section>
            <section>
                <div class="flex flex-col justify-center items-center mb-[30px]">
                    <div class="relative w-[368px]">
                        <input
                            @focus="
                                () => {
                                    isPassword1Focused = true
                                    isPassword1FirstChecked = true
                                }
                            "
                            @blur="
                                () => {
                                    isPassword1Focused = false
                                }
                            "
                            @keydown="preventSpace"
                            :class="
                                validationForPwd1.length > 0 && isPassword1FirstChecked === true
                                    ? 'error'
                                    : ''
                            "
                            class="default_btn sub_border1 py-2 px-4"
                            placeholder="비밀번호를 입력해주세요."
                            :type="passwordORText ? 'text' : 'password'"
                            v-model="password1"
                        />
                        <div
                            class="absolute right-4 bottom-3 clickable"
                            @click.prevent="togglePasswordORText"
                        >
                            <img v-if="passwordORText" src="/asset/img/eye-gray.svg" />
                            <img v-else src="/asset/img/eye-gray-hide.svg" />
                        </div>
                    </div>
                    <div class="mb-[48px] w-[368px] flex">
                        <span class="text-left text-[14px] text-market_rise mt-2">{{
                            validationForPwd1 || ''
                        }}</span>
                    </div>

                    <div class="relative w-[368px]">
                        <input
                            @focus="
                                () => {
                                    isPassword2Focused = true
                                    isPassword2FirstChecked = true
                                }
                            "
                            @keydown="preventSpace"
                            :class="
                                validationForPwd2.length > 0 && isPassword2Focused === true
                                    ? 'error'
                                    : ''
                            "
                            class="default_btn sub_border1 py-2 px-4"
                            placeholder="비밀번호를 한 번 더 입력해주세요."
                            :type="passwordORTextConfirm ? 'text' : 'password'"
                            v-model="password2"
                        />
                        <div
                            class="absolute right-4 bottom-3 clickable"
                            @click.prevent="togglepasswordORTextConfirm"
                        >
                            <img v-if="passwordORTextConfirm" src="/asset/img/eye-gray.svg" />
                            <img v-else src="/asset/img/eye-gray-hide.svg" />
                        </div>
                    </div>
                    <div class="mb-[48px] w-[368px] flex">
                        <span class="text-left text-[14px] text-market_rise mt-2">{{
                            validationForPwd2 || ''
                        }}</span>
                    </div>
                </div>

                <div class="w-[360px] text-[14px] text-gray text-left tracking-tighter mx-auto">
                    <div class="flex">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>최소 8자리 이상이어야 합니다.</p>
                    </div>
                    <div class="flex translate-y-1">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>영문, 숫자, 특수문자를 모두 조합해주세요.</p>
                    </div>
                    <div class="flex translate-y-2">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>특수문자 ~!@#$^%&amp;*()_+={}|;:&lt;,&gt;.?`-[] 만 허용합니다.</p>
                    </div>
                </div>

                <!-- next btn 영역 -->
                <div class="nextBtnArea">
                    <button
                        v-if="isPasswordValidationCompleted === true"
                        class="__lang default_btn btn_main_green default_btn md:mt-[128px]"
                        :disabled="isSendRequest"
                        @click.prevent="prepareResetPwd()"
                    >
                        비밀번호 재설정
                    </button>
                    <button
                        v-else
                        class="__lang default_btn j_next_btn default_btn md:mt-[128px]"
                        @click.prevent
                    >
                        비밀번호 재설정
                    </button>
                </div>
            </section>
        </article>
    </main>

    <!-- alert용 중첩 가능한 모달, 이메일 인증 완료 -->
    <v-dialog v-model="nested_dialog" width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ nested_title || '' }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script></script>

<script setup>
import { computed, nextTick, reactive, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { sendVerifyEmail } from '@/api/auth/email'
import {
    findPassword,
    verifyFindPassword,
    findPwd,
    verifyPwd,
    updatePwd
} from '../api/user/index.js'
import { preventSpace } from '../utils/utilsFactory'
import UtilSessionStorage from '../utils/session'

const router = useRouter()

const phonePattern = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/
const emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const pages = ref(0)

const form = reactive({
    email: { value: '', isFocused: false }
})

// 이메일 인증 관련
const userId = ref('')
const user_email = ref('')
const emailFormCheck = ref(false) // 이메일 형식 체크에 성공했을 때 true로 변경
const emailVerifyCheck = ref(false) // 이메일 인증이 성공적일 때 true로 변경
const emailVerifyState = ref('ready') // 이메일 인증이 ready일 때만 전송하기 활성화하기 위함.
const emailAuthLimitTime = ref(900)
const emailAuthStartTime = ref(null)
const emailAuthVerifyTime = ref(0)
let emailAuthVerifyIntervalId = null
const emailAuthRemainTime = ref('00:00')
const userVerifyNumber = ref('')
const emailResponseMsg = ref('')

// 비밀번호 - 텍스트 전환, password to text, pwd to text 관련
const passwordORText = ref(false)
const passwordORTextConfirm = ref(false)
const password1 = ref('')
const password2 = ref('')
const isPassword1Focused = ref(false)
const isPassword2Focused = ref(false)
const isPassword1FirstChecked = ref(false)
const isPassword2FirstChecked = ref(false)
const validationForPwd1 = ref('')
const validationForPwd2 = ref('')
const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~!@#$^%&*()_+={}|;:<,>.?`\[\]-]).{1,}$/
const isPasswordValidationCompleted = computed(() => {
    let result = false
    if (
        passwordPattern.test(password1.value) &&
        passwordPattern.test(password2.value) &&
        password1.value.length > 7 &&
        password2.value.length > 7
    ) {
        result = true
    }
    if (password1.value !== password2.value) result = false
    return result
})

// 모달창 컨트롤용 변수
const nested_dialog = ref(false) // 중첩용으로 나오는 작은 모달창
const nested_title = ref('안내')
const nested_msg = ref('') // alert용 모달창 메세지
const dialog_status = ref('ready')
const terms_dialog = ref(false)
const html_msg = ref('')
const isSendRequest = ref(false)

// 발송 완료 얼럿
function onSubmitSuccess() {
    nested_title.value = `발송 안내`
    nested_msg.value = `인증번호가 정상적으로 발송되었습니다.<br/>
메일이 오지 않는다면 스팸메일함을 확인하거나.<br/>
1:1 문의를 통해 확인해주세요.`
    changeNestedDialogTrue()
}

/**
 * 모달창의 상태 관리
 * 트루로 변하게 하거나 false로 변하게 하는 함수를 아예 분리했습니다.
 */
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}
function changeNestedMsg(newMsg) {
    nested_msg.value = newMsg
}

// 이메일 유효성 Validation
const emailValidationResult = computed(() => {
    const result = {
        status: false,
        msg: ''
    }

    if (form.email.value) {
        const [localPart, domainPart] = form.email.value.split('@')

        if (localPart && localPart.length > 64) {
            result.msg = '이메일 주소를 확인해주세요.'
        } else if (domainPart && domainPart.length > 254) {
            result.msg = '이메일 주소를 확인해주세요.'
        } else if (!emailPattern.test(form.email.value)) {
            result.status = false
            result.msg = '이메일 주소를 확인해주세요.'
        }
    } else if (form.email.isFocused === true && user_email.value === '') {
        result.msg = '이메일을 입력해주세요'
    } else {
        result.msg = ''
    }

    if (emailPattern.test(form.email.value)) {
        result.status = true
        result.msg = ''
    }

    return result
})

/**
 * 2개의 input의 validation이 완료되면 다음 버튼을 활성화시킵니다.
 */
const isEmailAuthCompleted = computed(() => {
    let result = false
    if (emailFormCheck.value === true && emailVerifyCheck.value === true) {
        result = true
    }
    return result
})

/**
 * 이메일 인증을 위해 이메일 계정에 인증번호를 전송합니다.
 */
async function prepareSendEmailAuth() {
    if (!form.email.value) {
        return
    }
    if (emailValidationResult.value.status === true) {
        // 백엔드에 요청합니다.
        emailVerifyState.value = 'send'
        const response = await findPwd(form.email.value)
        if (response.status === `success`) {
            emailFormCheck.value = true
            emailAuthStartTime.value = Number(new Date().getTime())
            onSubmitSuccess()
        } else {
            emailVerifyState.value = 'ready'
        }
    }
}

/**
 * 이메일로 받은 인증번호를 확인합니다.
 */
async function prepareVerifyEmailAuth() {
    if (!userVerifyNumber.value) {
        return
    }
    clearInterval(emailAuthVerifyIntervalId)
    emailAuthVerifyIntervalId = null
    const key = userVerifyNumber.value
    const response = await sendVerifyEmail(form.email.value, key)
    nested_title.value = '안내'
    if (response.data.status === `success`) {
        nested_msg.value = `정상적으로 인증되었습니다.`
        emailVerifyCheck.value = true
        emailResponseMsg.value = ''
    } else {
        userVerifyNumber.value = null
        emailVerifyState.value = 'ready'
        emailAuthStartTime.value = 0
        nested_msg.value = `올바르지 않은 인증번호입니다.<br/>전송하기 버튼을 다시 눌러주세요.`
        emailResponseMsg.value = ''
    }
    changeNestedDialogTrue()
}

/**
 * 비밀번호 재설정 페이지로 이동합니다.
 */
function moveToResetPwdPage() {
    pages.value = 1
}

/**
 * 비밀번호 재설정 버튼 클릭 이벤트
 * 비밀번호 validation이 끝났다면 비밀번호 변경 요청을 보냅니다.
 */
async function prepareResetPwd() {
    if (isSendRequest.value === true) {
        return
    }
    isSendRequest.value = true
    try {
        const response = await verifyFindPassword({
            email: form.email.value,
            password: password1.value
        })
        nested_title.value = '안내'
        if (response.status === `success`) {
            const response2 = await excuteResetPwd()
            if (response2.status === `success`) {
                nested_msg.value = `입력하신 비밀번호가 정상적으로 등록되었습니다.`
                dialog_status.value = 'done'
            } else if (response2) {
                nested_msg.value = `${JSON.stringify(response2)}`
            } else {
                nested_msg.value = `알 수 없는 이유로 비밀번호 변경에 실패하였습니다.<br/>
        문제가 계속 된다면 관리자에게 1:1 문의를 요청해주세요 ${JSON.stringify(response)}`
            }
        } else if (response.title === `부적합비밀번호`) {
            nested_msg.value = `올바르지 않은 비밀번호 형식입니다.<br/>글자 수, 조합 형식, 입력할 수 있는 특수문자를 확인한 후 다시 입력해주세요.`
        } else if (response.descriptKor.includes(`동일`)) {
            // 전과 동일한 비밀번호일때
            nested_msg.value = `최근에 사용한 비밀번호입니다. 다른 비밀번호를 입력해주세요.`
        } else if (response.descriptKor) {
            nested_msg.value = response.descriptKor
        } else {
            nested_msg.value = `알 수 없는 이유로 비밀번호 변경에 실패하였습니다.<br/>
    문제가 계속 된다면 관리자에게 1:1 문의를 요청해주세요 ${JSON.stringify(response)}`
        }
    } catch (error) {
        nested_msg.value = `알 수 없는 이유로 비밀번호 변경에 실패하였습니다.<br/>
    문제가 계속 된다면 관리자에게 1:1 문의를 요청해주세요`
        console.error(error)
    } finally {
        isSendRequest.value = false
        changeNestedDialogTrue()
    }
}

/**
 *
 */
async function excuteResetPwd() {
    return findPassword({ email: form.email.value, password: password1.value })
}

// 비밀번호, password validation
/**
 * 비밀번호 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglePasswordORText() {
    passwordORText.value = !passwordORText.value
}
/**
 * 비밀번호 확인 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglepasswordORTextConfirm() {
    passwordORTextConfirm.value = !passwordORTextConfirm.value
}

onMounted(async () => {
    watch(nested_dialog, (newValue, oldValue) => {
        if (oldValue === true && newValue === false && dialog_status.value === 'done') {
            router.push({ path: '/login' })
        }
    })

    watch(emailVerifyState, () => {
        if (emailVerifyState.value === 'send') {
            emailAuthVerifyIntervalId = setInterval(() => {
                if (emailAuthStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(emailAuthStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    emailAuthVerifyTime.value = emailAuthLimitTime.value - tempValue
                    if (emailAuthVerifyTime.value < 1) {
                        clearInterval(emailAuthVerifyIntervalId)
                        emailResponseMsg.value = `인증번호를 다시 발급받아주세요.(00:00)`
                        emailVerifyState.value = 'ready'
                        return
                    }
                    emailAuthRemainTime.value = `${`0${Math.floor(
                        emailAuthVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(emailAuthVerifyTime.value % 60)}`.slice(-2)}`
                    emailResponseMsg.value = `인증번호를 작성해주세요.(${emailAuthRemainTime.value})`
                }
            }, 1000)
        }
    })
    watch(isEmailAuthCompleted, (newVal) => {
        emailVerifyState.value = 'done'
    })
    watch(password1, () => {
        if (password1.value === '') {
            validationForPwd1.value = ''
        } else if (!passwordPattern.test(password1.value)) {
            validationForPwd1.value = '비밀번호는 영문, 숫자, 특수문자를 모두 포함해야 합니다.'
        } else {
            validationForPwd1.value = ''
        }
        if (!password1.value && isPassword1FirstChecked.value === true) {
            validationForPwd1.value = '비밀번호를 입력해주세요.'
        } else if (password1.value.length < 8) {
            validationForPwd1.value = '비밀번호는 8자리 이상이어야 합니다.'
        }
    })

    watch(password2, () => {
        nextTick(() => {
            if (password2.value === '') {
                validationForPwd2.value = ''
            } else if (password1.value === password2.value) {
                validationForPwd2.value = ''
            } else {
                validationForPwd2.value = '비밀번호가 일치하지 않습니다.'
            }
        })
    })
})
</script>

<style scoped>
.error {
    border: 1px solid var(--market_rise) !important;
}
input:focus {
    outline: none !important;
    border: 1px solid black !important;
}
.error:focus {
    border: 1px solid var(--market_rise) !important;
}
.modal_confirm_btn {
    background-color: var(--sub_color1) !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 90px;
    min-height: 43px;
}
</style>
