/* eslint-disable class-methods-use-this */
// https://docs.metamask.io/guide/

import Web3 from "web3";


class MetaMaskWallet {
  static state = {
      address: 'address', // 메타마스크 지갑 주소
      notInstallMetaMask: 'notInstallMetaMask', // 설치되지 않은 상태
      connectFail: 'connectFail', // 연결 실패 상태
      name: 'name',
  };

  /** @type {Object} 메타마스크 객체 */
  ethereum = null;

  // web3 = new Web3(new Web3.providers.WebsocketProvider("wss://mainnet.infura.io/ws"))

  /** @type {string} 현재 상태 값 */
  currentState = null;

  /** @type {string} 연결된 지갑 주소 */
  connectedAddress = null;

  /** @type {HTMLSpanElement} 네트워크 정보 */
  networkSpan = null;
  
  /** @type {HTMLSpanElement} 연결된 주소 정보 */
  addressSpan = null;

  /**
   * 초기화
   * @param {{networkSpan:HTMLSpanElement, addressSpan:HTMLSpanElement}} params
   */
  initialize({ networkSpan, addressSpan } = {}) {
      this.networkSpan = networkSpan;
      this.addressSpan = addressSpan;

      this.ethereum = window.ethereum;
      // 메타마스크가 설치되어 있으면 이벤트 등록
      if (this.ethereum) {
          this.ethereum.on('chainChanged', this.handleChainChanged.bind(this));
          this.ethereum.on('connect', this.connect.bind(this));
      }
  }

  isConnected() {
      if (this.ethereum) {
          return this.ethereum.isConnected();
      }
      return false;
  }

  /**
   * 메타마스크 연결, 상태 값 또는 상태 값 + 주소를 리턴
   * @returns {{state:MetaMaskWallet.state, value?:string}}
   */
  async connect() {
      try {
          const { ethereum } = window;
          // 메타 마스크 설치가 되어 있지 않는 경우
          if (!ethereum) {
              this.currentState = MetaMaskWallet.state.notInstallMetaMask;
              return { state: this.currentState };
          }
          this.ethereum = ethereum;
          // 연결 요청
          const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

          // 정상적으로 연결된 경우, 주소 및 상태 저장
          const account = accounts[0];
          this.connectedAddress = account;
          this.currentState = MetaMaskWallet.state.address;

          // 정보 갱신
          this.setAddressSpan(account);
          this.setNetworkSpan(ethereum.eth_chainId);

          return { state: this.currentState, value: account };
      } catch (error) {
          // 연결을 취소하거나 연결이 되지 않는 경우
          console.error(error);
          this.setAddressSpan(''); // 정보 갱신
          this.currentState = MetaMaskWallet.state.connectFail;
          return { state: this.currentState };
      }
  }

  /**
   * 네트워크 hex 값으로 체인 확인하여 한국어로 변환하여 반환
   * @returns {string} 네트워크 한글로 변환하여 반환
   *
   * https://docs.metamask.io/guide/ethereum-provider.html#chain-ids
   */
  getChainNetworkStringToKR(chainId) {
      const chainIdDecimal = Number.parseInt(Number(chainId), 10);
      let result = '';
      switch (chainIdDecimal) {
          case 1:
              result = '이더리움 메인넷';
              break;
          case 3:
              result = '이더리움 Ropsten 테스트넷';
              break;
          case 4:
              result = '이더리움 Rinkeby 테스트넷';
              break;
          case 5:
              result = '이더리움 Goerli 테스트넷';
              break;
          case 42:
              result = '이더리움 Kovan 테스트넷';
              break;
          case 11155111:
              result = '이더리움 Sepolia 테스트넷';
              break;
          default:
              result = '-';
              break;
      }

      return result;
  }

  /**
   * 네트워크 정보 표시 설정
   * @param {number} chainId hex
   */
  setNetworkSpan(chainId) {
      const network = this.getChainNetworkStringToKR(chainId);
      if (this.networkSpan) this.networkSpan.textContent = network;
  }

  /**
   * 주소 정보 표시 설정
   * @param {string} address
   */
  setAddressSpan(address) {
      if (this.addressSpan) this.addressSpan.textContent = address;
  }

  /**
   * 메타 마스크 체인 변경 이벤트 핸들러
   * @param {number} chainId hex
   */
  handleChainChanged(chainId) {
      const chainKR = this.getChainNetworkStringToKR(chainId);
      if (this.networkSpan) this.networkSpan.textContent = chainKR;
  }

  async sign( message ){
    try {
        const { ethereum } = window;
        const from = ethereum.selectedAddress;
        const params = [message, from];
        const method = 'personal_sign';

        // web3 요청
        const result = await web3.currentProvider.request({
            method,
            params,
            from,
        });

        // 서버에 서명 결과 전송
        // await fetch(`http://localhost:3000/sign/${encodeURIComponent(JSON.stringify(result.result))}`);
        return result;
    } catch (error) {
        console.error("서명 요청 중 오류 발생:", error);
        return null;
    }
  }

}

export default MetaMaskWallet;