import TradingMonitor from '../../pages/TradingMonitor.vue'

import DepositWithdrawalHistory from '../../components/tradingMonitor/DepositWithdrawalHistory.vue'
import MyAssets from '../../components/tradingMonitor/MyAssets.vue'
import PendingTransactions from '../../components/tradingMonitor/PendingTransactions.vue'
import TradingHistory from '../../components/tradingMonitor/TradingHistory.vue'

export default [
    {
        path: '/trading',
        name: 'trading',
        component: TradingMonitor,
        children: [
            {
                path: 'withdrawalHistory',
                name: 'withdrawalHistory',
                component: DepositWithdrawalHistory
            },
            {
                path: 'myAssets',
                name: 'myAssets',
                component: MyAssets
            },
            {
                path: 'pendingTransactions',
                name: 'pendingTransactions',
                component: PendingTransactions
            },
            {
                path: 'tradingHistory',
                name: 'tradingHistory',
                component: TradingHistory
            },
        ]
    }
]
