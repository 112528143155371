<template>
    <div class="w-full bg-exchanger_bg pt-[18px] pb-[18px]">
        <div class="w-[1202px] mx-auto bg-[#fff] basicBoxShadow px-[19px] py-[50px]">
            <h1 class="text-[24px] mb-[12px]">{{ serviceName }} 이용약관</h1>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
            >
                제1조 (목적)<br />이 약관은 {{ companyName }} 주식회사(이하 “회사”라 합니다)가 제공하는 {{ serviceName }}
                및 {{ serviceName }} 관련 제반 서비스의 이용에 대해 회사와 회원의 권리, 의무 및 책임 사항, 기타
                필요한 사항을 규정함을 목적으로 합니다. <br /><br />
                제2조 (정의)
                <br />
                본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <br />
            </span>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
            >
                1. “서비스”라 함은 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함)에 상관없이,
                회원이 이용할 수 있는 {{ serviceName }}의 디지털 창작물 거래 서비스 및 이와 관련된 제반 서비스를
                의미합니다.
                <br />
                2. “회원”이라 함은 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고, 회사가
                제공하는 서비스를 이용하는 고객을 말합니다.<br />
                3. “아이디(ID 또는 계정)”라 함은
                회원이 본 약관 및 회사의 개인정보 수집·이용에 동의한 후 회사가 회원을 식별하고
                제공하기 위해 회원에게 부여한 문자 또는 숫자의 조합을 말합니다.<br />
                4. “디지털 자산”이라 함은 블록체인 상의 데이터로서 서비스를 통해 전자적인 방법으로 가치의 저장
                수단이나 교환의 매개가 되는 코인이나 대체성 있는 토큰을 의미합니다.<br />
                5. “디지털 저작물”이라 함은 서비스에서 디지털 자산으로 구매 또는 판매할 수 있는 서비스의
                대상물을 말합니다. 회원은 회사에 대하여 계정 내 “디지털 저작물”의 수량만큼의 출금
                청구권을 보유합니다.<br />
                6. “NFT”라 함은 블록체인 기반의 디지털 저작물로서, 이미지,
                동영상, 음악, 서적 등의 “디지털 저작물”에 대한 메타데이터 정보를 포함한 상태로
                발행되는 대체 불가능한 토큰(Non-Fungible Token)을 의미합니다.<br />
                7. “NFT 서비스”란 회사에서 제공하는 NFT 관련 서비스를 의미합니다.<br />
                8. “발행단체”란 다음 각 목의 행위를 하는데 필요한 일체의 적법한 권한과 이 권한을 취득한 자를 의미합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 디지털 저작물 제작<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 디지털 저작물 자체에 대한 지식재산권을 취득<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 원저작물 저작권자와의 디지털
                저작물 제작 위탁계약 등을 통하여 디지털 저작물 제작에 필요한 지식재산권 또는 이에
                관한 라이선스를 취득<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;b. 디지털 저작물과 연계된 NFT 발행 및 유통<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 발행 및 유통된 NFT를 소유한 자에게소유권 보장<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >9. “소유자”라 함은 {{ serviceName }}에서 디지털 저작물 구매를 통하여 디지털 저작물에 대한 소유권을
                취득한 자이며, “소유자”는 디지털 저작물 자체에 대한 소유권, 지식재산권, 기타 권리를
                취득하지 않습니다.<br />
                10. “KRW”라 함은 회원이 서비스 내에서 디지털 저작물 거래 등에
                사용할 수 있는 포인트를 말합니다. 디지털 자산을 입∙출금 시점의 원화와 1:1 비율로
                표현한 지표이며 현금으로 교환할 수 없습니다. 실제 원화가 아니므로 디지털 자산으로
                분류합니다.<br />
                11. “외부 디지털 자산 주소”라 함은 서비스 외부에서, 회원 혹은 다른
                사람이 소유하거나 사용하는 모든 디지털 자산 주소 또는 계정을 의미합니다. 외부 디지털
                자산 주소는 회사에서 소유, 통제 또는 운영하지 않습니다.<br />
                12. “콘텐츠”라 함은 회사가
                회원에게 제공하는 부호, 문자, 도형, 색채, 음성, 음향, 이미지, 영상 등(이들의
                복합체를 포함한다.) 온라인 디지털 형태의 정보나 자료를 말합니다.<br />
                13. “오픈마켓”이라
                함은 오픈마켓 사업자가 운영하는 애플리케이션 거래 사이트를 의미합니다.<br />
                14. “오픈마켓 사업자”라 함은 서비스의 설치, 결제 기능을 제공하는 전자상거래 업체를 의미합니다.<br />
                15. “유료 결제”라 함은 서비스 내에서 유료로 콘텐츠 등을 구매하기 위한 결제 행위를 말합니다.<br />
                16. “유료 콘텐츠”라 함은 유료 결제를 통해 유료로 구매할 수 있는 콘텐츠를 의미합니다.<br />
                17. "외부 입∙출금"이란, 회사가 운영하는 디지털 자산 지갑과, 회사 외의 제삼자가 운영하는 디지털
                자산 지갑 또는 외부의 개인 지갑 사이에 NFT를 포함한 디지털 자산을 입∙출금하는 행위를
                의미합니다.<br />
                18. “중요 제휴사”라 함은 회사와 제휴 계약 등을 체결하여 서비스의 중요한
                일부 기능(로그인 기능 등 이에 한정하지 않습니다)을 제공하는 회사를 말합니다.<br />
                19. “고객센터”라 함은 회사가 서비스와 관련된 고객 응대를 위해 운영하는 온라인, 유선 또는 오프라인
                응대 서비스를 의미합니다.<br />
                20. “이용자 가이드”라 함은 회사가 서비스 내에 게시하는
                서비스 운영 및 이용 관련 사항을 의미합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제3조 (약관의 게시와 개정)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 내 화면 또는 연결화면을
                통하여 게시합니다.<br />
                2. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 본
                약관을 개정할 수 있습니다.<br />
                3. 회사가 본 약관을 개정할 경우에는 개정내용과
                적용일자를 명시하여 적용일자 30일 이전부터 적용일자 전날까지 회원에게 공지(서비스 내
                초기화면, 게시판에 게시하거나 팝업화면 등을 제시하여 모든 회원들에게 안내하는 행위,
                이하 같음) 또는 통지(제20조 제1항에 따라 회원이 제공한 전자우편주소, 서비스 내
                메시지, 문자메시지(LMS/SMS) 등으로 개별 회원에게 안내하는 행위, 이하 같음)합니다.<br />
                4. 회사가 본조 제3항에 따라 공지 또는 통지하면서 회원이 적용일자 전날까지 의사표시를 하지
                않으면 의사표시가 표명된 것으로 본다는 뜻을 명시한 경우, 회원이 명시적으로 거부의
                의사표시를 하지 아니하였다면 회원이 개정약관에 동의한 것으로 봅니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >5. 회원은 개정 약관에 동의하지 않는 경우 적용일자 전날까지 회사에 거부 의사를
                표시하고 서비스 이용계약을 해지할 수 있습니다.<br /><br />제4조 (약관의 해석)<br />1.
                회사는 서비스 이용과 관련하여 본 약관에서 정한 범위 내에서 이용자 가이드에 세부적인
                사항을 명시할 수 있습니다.<br />2. 본 약관에서 정하지 아니한 사항이나 해석에
                대해서는 관련 법령에 따릅니다.<br /><br />제5조 (이용계약 체결)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 이용계약은 회원이 되고자 하는 자(이하 “가입 신청자”라 합니다)가 회사가 제공하는
                약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 완료하고,
                회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br />2. 회사는 가입 신청자의
                신청에 대하여 아래 각호에 해당하는 사유가 있는 경우에는 승낙을 거절할 수
                있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 가입 신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 실명이 아닌 명의 또는 타인의 명의를 이용한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 가입 신청자의 귀책 사유로 인하여 승인할 수 없거나 기타 본 약관에서 규정한 제반 사항을 위반하여 신청하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 관련 법령에서 어긋나거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;f. 가입 신청자가 국내 비거주자, 외국법인 혹은 미성년자인 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;g. 가입 신청자가 특정 금융거래정보의 보고 및 이용 등에 관한 법률(이하
                “특정금융정보법”이라고 합니다) 및 관련 법정, 자금세탁방지 및 공중협박자금조달금지에
                관한 업무규정(이하 “FIU고시 업무규정”이라고 합니다)등에 따라 국가위험을 평가할 때
                공신력 있는 자료에서 열거한 국가의 국적자 또는 거주자에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp; h. 가입 신청자가 특정금융정보법 및 관련 법령, FIU고시 업무규정, 정부지침 등에 따라 자금세탁
                등과 관련하여 추가정보확인이 필요한 자 또는 요주의 인물에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp; i. 가입 신청자가 이미 회원으로 가입되어 있는 것이 확인되어 중복 가입 신청에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp; j. 회사가 서비스를 지원하지 않는 기기 환경에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp; k. 가입 신청자가 휴대폰 본인인증 절차를 완료하지 않은 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >3. 이용계약의 성립 시기는 회사가 계정 생성 완료를 신청 절차상에서 표시하거나 제20조의
                방식에 따른 통지가 가입 신청자에게 도달한 시점으로 합니다.<br />
                4. 회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서
                전문기관을 통해 실명확인 또는 본인인증을 요청할 수 있습니다.<br />
                5. 회사는 회원 1인 1계정 정책을 실시하고 있습니다. 회원은 본인 명의로 1개의 계정만을 사용할 수
                있습니다. 다만 회사가 정책상 예외적으로 허용하거나 회사와 별도로 합의한 경우에만 2개
                이상의 계정을 사용할 수 있습니다. 회원이 회사의 예외적 허용 또는 회사와의 별도 합의
                없이 2개 이상의 계정을 사용하는 경우, 회사는 회원의 계정에 대하여 본 약관 제18조 및
                제19조에 따른 조치를 취할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제6조 (회원 정보의 변경)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회원은 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
                다만, 서비스 관리를 위해 필요한 아이디는 수정할 수 없습니다.<br />
                2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나 고객센터를
                통하여 회사에 변경 사항을 통지하여야 합니다.<br />
                3. 회원이 귀책사유로 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익은 회원이 부담합니다. 다만, 회사의 고의
                또는 과실로 인하여 불이익이 발생한 경우에는 그러하지 아니합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제7조 (회원 정보의 관리)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회사는 회원이 등록한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우에는 별도의
                확인절차 없이 이용자를 회원으로 간주합니다.<br />
                2. 회원은 본인의 허가를 받지 않은 제삼자가 회원의 계정에 무단으로 접근하지 않도록, 비밀번호를 포함하여 계정 접근을
                위해 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.<br />
                3. 회원은 계정 접근정보를 분실했거나, 도용당했거나, 제삼자에게 공개되었음을 인지한 경우 이를 즉시
                회사에 통지해야 합니다. 회사는 즉시 계정 이용중단 등의 조치를 할 수 있습니다.<br />
                4. 회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여
                회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는 과실에 의한 경우에는
                그러하지 아니합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제8조 (개인정보의 보호)<br />회사는 개인정보 보호법 등 관련 법령이 정하는
                바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에
                대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여
                제공한 화면 이외의 화면(외부로 링크된 화면 등)에서는 회사의 개인정보처리방침이
                적용되지 않습니다.<br /><br />제9조 (회사의 의무)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회사는 관련 법령과 본 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기
                위하여 최선을 다하여 노력합니다.<br />
                2. 회사는 회원이 안전하게 서비스를 이용할 수
                있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖출 수 있으며,
                개인정보처리방침을 공시하고 준수합니다.<br />
                3. 회사는 서비스 이용과 관련하여
                회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며,
                서비스 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수
                있습니다.<br />
                4. 회사는 관련 법령상 허용되는 범위 내에서 이를 준수하기 위한 적절한
                방법을 선택할 수 있으며, 이용자 가이드에 회사의 조치를 위해 회원이 협력해야 하는
                내용 및 절차 등에 관한 세부사항을 정할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제10조 (회원의 의무)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회원은 아래의 행위를 하여서는 안 됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                &nbsp;&nbsp;&nbsp;&nbsp;a. 타인의 정보도용<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 회사가 게시한 정보의 변경<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 회사 및 기타 제삼자의 저작권 등 지식 재산권에 대한 침해<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;f. 외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;g. 회사의 사전 승낙 없이 에이전트(Agent), 스크립트(Script), 스파이더(Spider), 스파이웨어(Spyware), 툴바(Toolbar) 등의 자동화된 수단, 기타 부정한 방법을 통하여 서비스에 접속하는 행위, 노출 횟수 및 클릭 횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위 또는 회사의 서버에 부하를 일으키는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;h. 다른 회원의 개인정보 및 계정정보를 수집하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;i. 디지털 저작물의 시세에 부당한 영향을 주는 등의 방법으로 건전한 거래질서를 교란하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;j. 제5조 제5항에 반하여 2개 이상의 계정을 사용하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;k. 회사가 공지한 입·출금한도를 직·간접적인 방법으로 초과하여 입·출금을 시도하는 행위<br />
                &nbsp;&nbsp;&nbsp;&nbsp;l. 기타 관련 법령에 위반되는 불법적인 행위<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >2. 회원은 관계 법령, 이용약관, 이용자 가이드 및 기타 서비스와 관련하여 회사가
                공지하거나 통지한 사항 등을 준수하여야 하며, 회사의 업무에 방해되는 행위를 하여서는
                안 됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제11조 (소유자의 권리와 의무)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 소유자는 디지털 저작물을 다음 각 호의 한도 내에서 이용할 수 있는 권한을 의미할 뿐
                디지털 저작물 자체에 대한 소유권, 저작권을 포함한 지식재산권, 기타 권리를 취득하는
                것을 의미하지 않습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                &nbsp;&nbsp;&nbsp;&nbsp;a. 디지털 저작물을 비상업적인 용도로 사용(개인 SNS 내 업로드하는 행위를 포함하며, 디지털 저작물 원본 그대로 개인적 용도로 사용하는 행위를 포함한다)할 수 있는 권리<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 본조 제1항에 따라 디지털 저작물을 사용하면서 해당 디지털 저작물이 진본이라고 외부 공표할 권리<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 디지털 저작물 입출고, 거래 등이 지원되는 경우, 지원되는 방식에 따라 디지털 저작물의 소유권을 타인에게 이전할 수 있는 권리<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                2. 소유자는 적법하게 보유한 디지털 저작물에 대하여 이를 재판매하고 복제 및 전송할 수
                있지만, 디지털 저작물에 대한 지식재산권이나 초상권, 상품화 권리 등 특정 권리를 양도
                받는 것이 아닙니다.<br />
                3. 회사는 회원에 대하여 완전한 소유권의 이전, 저작권의 실현
                등의 권리를 보장하지 않습니다.<br />
                4. 소유자는 권리를 행사하는 시점에 해당 디지털
                저작물을 적법하게 보유한 자에게 인정되며 디지털 저작물을 판매한 회원은 소유자의
                권리를 더 이상 행사할 수 없습니다.<br />
                5. 회원이 보유한 디지털 저작물을 다른 지갑에
                전송하거나 외부 마켓플레이스에서 거래하는 데 대하여 회사는 어떠한 책임을 지지
                않습니다.<br />
                6. 디지털 저작물 별로 소유권의 내용이 다를 수 있으며, 회사는 소유권에
                관한 사항을 서비스 내에 게시합니다. 소유자는 자신이 보유한 디지털 저작물의 소유권
                내용을 확인하여 이를 준수하여야 합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제12조 (권리의 귀속)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 서비스와 관련된 저작권 및 지식 재산권은 회사에 귀속됩니다.<br />
                2. 회사는 회원에게 서비스와 관련하여 회사가 정한 이용조건에 따라 서비스 등을 이용할 수 있는 이용권만을
                부여하며, 회원은 이를 양도, 판매 등의 처분행위를 할 수 없습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제13조 (서비스의 제공 등)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 다만, 회사는 서비스를 일정
                범위로 나누어 범위별 이용 가능 시간을 별도로 지정할 수 있으며, 그 내용을 사전에
                공지합니다,<br />
                2. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
                또는 운영상 합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
                이 경우 회사는 사전에 서비스 초기 화면이나 공지사항 게시판을 통하여 회원에게
                공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우(예컨대,
                갑작스러운 서버 장애 등 회사가 예측하기 통제할 수 없는 사유 및 이에 준하는 사유)
                사후에 공지합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제14조 (서비스의 변경)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회사는 안정적인 서비스 제공을 위하여 서비스의 내용 및 운영정책, 기술상 사항 들을
                변경할 수 있습니다.<br />
                2. 회사는 회원의 권리와 의무에 영향이 있는 서비스의 변경이
                있을 경우 변경 내용과 적용 일자를 명시하여 변경일로부터 7일 이전에 공시합니다. 다만,
                회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.<br />
                3. 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제15조 (서비스의 이용)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                1. 회원이 사전에 발급받은 입금 주소로 디지털 자산을 입금(회원의 동의 하에 회사에
                추심한 경우를 포함합니다.)하면 회사는 입금한 시점을 기준으로 원화 환산금액 1원당
                1KRW의 비율로 계산한 수량에 해당하는 KRW를 회원의 {{ serviceName }} 계정에 표시합니다. 회원은
                사전에 등록한 외부 디지털 자산 주소로 회사가 공지한 입·출금한도 내에서 디지털 자산과
                "KRW"를 교환(입출금)할 수 있습니다.<br />
                2. 회원은 회사가 정하는 방법에 따라 디지털 저작물 매매 주문을 회사에 제출하여야 합니다.<br />
                3. 회원이 디지털 저작물 매매주문을 제출할 경우, 매매주문 체결을 위해 필요한 수량 이상의 디지털 자산과 디지털 저작물
                출금 청구권을 보유하고 있어야 합니다.<br />
                4. 회사는 회원의 주문을 제출하기 전에 회원이
                매매하려고 하는 디지털 저작물의 수량, 가격 및 수수료 등을 요약한 주문확인 정보를
                제공합니다. 회원은 회사가 이러한 주문확인 정보를 제공하지 못하는 경우에도 회원이
                제출한 주문에는 영향을 미치지 않음에 동의합니다.<br />
                5. 회원은 디지털 자산 및 디지털
                저작물의 가격 변동에 대해서 회사가 책임지지 않는 것에 동의합니다. 디지털 자산 및
                디지털 저작물 시장의 중단 또는 제21조 2항의 사유가 발생한 경우 회사는 다음 중 하나
                이상의 조치를 수행할 수 있으며 이러한 조치로 인해서 회원에게 발생한 손실에 대해서
                회사는 고의 또는 과실이 없는 한 책임을 지지 않습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 서비스에 대한 접근 중단<br />&nbsp;&nbsp;&nbsp;&nbsp;b. 서비스 안에서의 모든 활동 중단<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >6. 회원이 보유한 KRW에 대해서 회사에 출금을 요청하면, 회사는 1 KRW 당 지정한 디지털
                자산의 환산금액 비율로 계산한 디지털 자산을 회원이 사전에 등록한 외부 디지털 자산
                주소로 지급합니다.<br />
                7. 회사는 디지털 자산, 디지털 저작물 입출금 시 회원이 제공한
                정보가 사실과 일치하는지 여부를 확인하기 위하여 법령에 의하여 허용된 범위 내에서
                직접 또는 전문기관을 통해 실명확인 또는 본인인증을 요청할 수 있습니다.<br />
                8. 회원은 {{ serviceName }} 계정 내 디지털 자산 잔액이 미체결 주문에 해당하는 금액보다 큰 경우 해당
                금액에서 수수료를 제외한 금액을 외부 디지털 자산 주소로 보낼 수 있습니다. 회원이
                제공하는 외부 디지털 자산 주소의 정확성에 대한 책임은 회원에게 있으며, 회사는 고의
                또는 과실이 없는 한 회원이 잘못 기재한 외부 디지털 자산 주소로 디지털 자산을 보내는
                것에 대하여 책임을 지지 않습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제16조 (서비스 이용 관련 유의사항)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 디지털 자산 및 디지털 저작물의 가치는 주관적입니다. 디지털 저작물 가격은 변동성을
                띠며 디지털 자산 가격 변동도 디지털 저작물 가격에 실질적이고 악영향을 미칠 수
                있습니다. 회원은 이러한 주관성과 변동성을 완전히 이해하고 있으며 손해를 볼 수도
                있음을 인정합니다.<br />
                2. 회원은 서비스에 제출한 주문이 체결되기 전에만 주문을 취소할
                수 있습니다. 부분적으로 체결된 주문에 대해서는 체결되지 않은 나머지 주문을 취소할 수
                있습니다. 회사는 회원이 시장가로 주문을 제출한 경우 이에 대한 취소 요청을 거부할 수
                있습니다.<br />
                3. 회원의 {{ serviceName }} 계정 내 디지털 자산이 회원이 제출한 주문을 체결시키기
                위한 금액보다 부족한 경우 회사는 전체 주문을 취소하거나 회원의 {{ serviceName }} 계정 내 이용
                가능한 디지털 자산에 해당하는 부분의 주문만을 이행할 수 있습니다.<br />
                4. 회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 중요 제휴사와의 계약 만료, 당해 서비스의
                현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는
                서비스 전부를 중단할 수 있습니다. 이 경우 다른 불가피한 사유가 없는 한 중단 일자
                30일 이전까지 중단 일자, 중단 사유, 이용자의 보유 디지털 자산 및 디지털 저작물 이전
                방법 등을 제20조 제1항의 방법으로 회원에게 통지합니다. 단, 회사는 거래지원을
                중단하는 경우라도 회원이 자신의 자산인 디지털 자산 및 디지털 저작물을 안전하게
                이전(출금)할 수 있도록 본조 제4항에 따라 회원의 디지털 자산 및 디지털 저작물
                이전(출금)을 지원합니다.<br />
                5. 회사는 디지털 자산 및 디지털 저작물 자체의 하자, 중요
                제휴사의 파산∙해산, "거래지원 종료 정책"에 따른 디지털 자산 및 디지털 저작물
                거래지원 종료 사유 발생 등 불가피한 서비스 제공 중단 사유가 있거나 관련 법령, 감독
                당국 등 정부의 방침 또는 이에 준하는 사유가 있으면 서비스에서 회원이 이용할 수 있는
                개별 디지털 자산 및 디지털 저작물의 거래지원을 종료할 수 있습니다. 회사는 디지털
                자산 및 디지털 저작물 거래지원 종료 여부를 사전에 회원에게 알리기 위해 합리적인
                노력을 기울일 것입니다. 회사가 특정 디지털 자산 및 디지털 저작물의 거래지원을 종료할
                경우 회사는 디지털 자산 및 디지털 저작물 거래지원 종료 공지일로부터 적어도 30일의
                기간 동안 {{ serviceName }} 이외 타 서비스에서 발급된 개인 지갑 등에 디지털 자산 및 디지털
                저작물을 이전할 수 있도록 이전(출금)을 지원합니다. 회사는 구체적인 디지털 자산 및
                디지털 저작물 거래지원 종료 사유를 서비스 내에 게시된 거래지원 종료 정책에
                명시합니다.<br />
                6. 서비스에서 제공하는 콘텐츠는 서비스 이용을 위한 보조 자료이며, 투자
                또는 거래의 권유 및 암시가 아닙니다. 회사는 콘텐츠 및 타 정보제공자가 제공하는
                정보가 정확히 이루어지도록 노력합니다.<br />
                7. 회사는 거래지원 정책에 따라 새로운 디지털 자산 및 디지털 저작물의 거래를 지원할 수 있습니다.<br />
                8. 회원은 디지털 자산 및 디지털 저작물 입금 주소 발급 시 발급되는 주소의 디지털 자산 및 디지털 저작물 종류를
                정확하게 확인하여야 합니다. 발급된 주소로 해당 디지털 자산 및 디지털 저작물과 다른
                종류의 디지털 자산 및 디지털 저작물이 입금될 경우 정상 입금 처리되지 않습니다.
                이러한 오입금에 대하여 회사의 고의, 과실이 없는 한 회사는 손해배상, 복구지원 등
                일체의 책임을 부담하지 않습니다. 단, 회사는 보안상 위험 요소가 없거나 현저히
                저감되었고 해당 오입금 복구지원이 기술적, 경제적으로 가능하다는 회사의 합리적인
                판단이 있을 경우 해당 오입금 건을 복구지원할 수 있으며, 이 경우 복구를 요청한
                회원에게 사전에 안내하고 합리적인 수준의 복구비용을 청구할 수 있습니다.<br />
                9. 디지털 자산 및 디지털 저작물 입출금 메뉴 상단 "(디지털 자산명)입출금" 표기 부분의 우측에
                해당 디지털 자산 및 디지털 저작물이 사용하는 네트워크의 종류 및 수량 등이
                표시됩니다. 회원은 이용 대상 디지털 자산 및 디지털 저작물의 네트워크의 종류를 정확히
                확인해야 하며, 네트워크 선택 또는 기재 오류로 오입금이 발생하는 경우 회사는 회사의
                고의, 과실이 없는 한 손해배상, 복구지원 등 일체의 책임을 부담하지 않습니다.<br />
                10. 자체 메인넷이 구성되지 않은 디지털 자산 및 디지털 저작물의 입금 주소 발급 시, 입금 주소는
                컨트랙트 주소로 발급될 수 있습니다. 일반 지갑 주소와 달리 컨트랙트 주소 그 자체에는
                개인키가 존재하지 않습니다. 따라서 컨트랙트 주소로 오입금할 경우 개인키를 이용한
                일반적인 디지털 자산 오입금 복구 방식으로 복구할 수 없으며, 회사에서 다른 방식으로
                복구해 드리는 것 또한 불가능합니다. 단, 회사의 고의 및 과실로 인해 오입금이 발생한
                경우는 예외로 합니다.<br />
                11. 회사는 디지털 저작물의 중개자이며, 디지털 저작물의 거래
                당사자가 아닙니다. 회사는 소유자 및 판매자를 대리하거나 디지털 저작물의 권리 등에
                대하여 어떠한 보증도 하지 않습니다.<br />
                12. 디지털 자신 및 디지털 저작물과 관련하여
                발생한 제세공과금은 관련 법령에서 납세 의무자로 정하는 자가 부담합니다. 다만, 어느
                디지털 자산 및 디지털 저작물과 관련하여 납세 의무자가 아닌 자가 특정 거래에 대한
                제세공과금을 부담하기로 정한 경우에는 그러하지 않습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제17조 (서비스 수수료)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회원은 NFT 서비스, 디지털 자산 및 디지털 저작물 입출금 서비스 등 회사가 제공하는
                서비스를 이용하는 경우 이에 따른 수수료를 지급하여야 합니다. 회사는 홈페이지 또는
                서비스 내에 서비스와 관련된 수수료의 산정 기준을 명시합니다.<br />
                2. 회사는 특정 조건에 해당하는 경우에는 서비스 수수료를 회원과의 협의에 의하여 정할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제18조 (이용제한 등)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회사는 다음 각호에 해당하면 회원의 서비스 로그인을 제한할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 회원의 외부 입∙출금 관련하여 이에 이용된 회원 소유의 외부 디지털 자산 주소가 해킹
                및 사기 사고가 발생하였거나 발생한 것으로 합리적으로 의심되는 경우(원격앱을 통해
                서비스 이용이 이루어지는 경우 등을 포함하며 이에 한정하지 않음)<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 회원의 계정이 명의도용 등 사기행위에 연루되었거나 연루된 것으로 합리적으로 의심되는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 수사기관이 거래 및 입출금 제한을 요청하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 회원으로 가입된 이용자가 미성년자, 국내 비거주자 및 해외 법인
                또는 제5조 제2항 제7호, 제8호, 제9호, 제10호, 제11호에 해당함이 확인된 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;f. 계정 명의인이 아닌 사람 또는 법인이 해당 계정을 이용하고 있는 것으로 확인된 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;g. 회원과 회원의 계정 내 디지털 자산을 각 채무자 등 대상자, 집행 대상 자산으로 하여 (가)압류
                결정, (가)처분 결정, 추징보전명령, 기타 이와 유사한 법원 또는 수사기관의 결정이나
                명령, 처분 등이 있는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;h. 서비스 오류, 전산장애 등의 사유로 회원의 계정 내 타인 소유의 디지털 자산이 표시된 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;i. 회원 또는 회원의 계정이 시세조종, 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;j. 회원이 특정 디지털 자산 및 디지털 저작물 발행주체(자연인, 법인 기타
                조직체를 모두 포괄하며 이에 한정되지 않음)의 임직원, 상법상 특수관계인, 대리인 등에
                해당하거나, 위 발행주체와 계약을 체결하는 등 위 디지털 저작물과 관련하여
                미공개중요정보(해당 디지털 자산 투자자의 투자판단에 중대한 영향을 미칠 수 있는
                정보로서 불특정 다수인이 알 수 있도록 공개되기 전의 것)를 이용할 수 있는 지위에 있어
                불공정거래를 방지하기 위하여 해당 디지털 저작물의 거래를 제한하는 조치가 필요한
                경우<br />
                k. 관련 법령, 금융감독당국, 과세당국 기타 정부기관의 법률상 또는 사실상
                행정처분, 명령, 권고 또는 지도를 준수할 필요가 있는 경우<br />
                l. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >2. 회사는 다음 각호에 해당하면 회원의 거래, 입금 및 출금 이용을 보류하거나 제한할 수
                있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 본조 제1항 각호에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 구매대행 등 타인을 대신하여 디지털 저작물을 거래하기 위하여 입금한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. VPN을 활용하여 접속한 경우 등 회사가 회원이 실제로 접속한 IP를 확인하기 어려운 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >3. 회사는 다음 각호의 사유가 있거나 그와 같은 사유로 인한 회원의 피해 발생을 방지하기
                위해 필요한 경우 회원에게 경고 또는 주의 조치를 취하거나, 서비스를 통해 제출된
                주문을 거부하거나, 거래 금액 및 다른 거래 조건에 제한을 둘 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 제5조 제2항 각호에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 제10조 제1항 각호에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 본조 제1항, 제2항 각호에 해당하는 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >4. 회사는 이용자 가이드에 본조의 이용제한 범위 내에서 그 구체적인 제한의 조건 및
                세부내용을 정할 수 있습니다.<br />
                5. 본조에 따라 서비스 이용을 제한하는 경우에는 회사는
                제20조에 따른 방법으로 회원에게 통지합니다.<br />
                6. 회원은 본조에 따른 이용제한 등에
                대해 고객센터를 통하여 이의신청을 할 수 있습니다. 이때 객관적인 자료에 의하여
                이의사유가 정당함이 명백히 확인되거나 객관적인 자료가 없더라도 이의사유가 타당한
                경우 회사는 즉시 서비스의 이용 재개 등 필요한 조치를 합니다.<br />
                7. 회사는 이용제한 사유 해당 여부를 판단하기 위하여 회원에게 자금 출처에 대한 소명을 요청할 수
                있습니다.<br />
                8. 서비스 오류, 전산장애 등의 사유로 회원의 계정 내 타인 소유의 디지털
                자산 및 디지털 저작물이 표시되는 등 잘못된 정보가 표시되는 경우, 회사는 해당 디지털
                자산 및 디지털 저작물의 회수, 원상회복, 표시 정정 등 필요한 조치를 취할 수 있습니다.
                회사는 필요한 조치를 취한 후 5영업일 이내에 조치의 사유 및 내용을 회원에게 제20조
                제1항의 방법으로 통지합니다.<br />
                9. 본조에 따라 서비스 이용이 제한될 경우 회원의 자산
                보호를 위하여 미체결주문은 취소될 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제19조 (이용계약 해지)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회원은 언제든지 서비스 내 정보 관리 메뉴 또는 고객센터 등을 통하여 이용계약 해지를
                신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야
                합니다.<br />
                2. 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스
                이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간
                내에 시정되지 않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는 이용계약을
                해지할 수 있습니다. 다만, 본항 제5호에 해당하는 경우 또는 회원에게 발생한 사유가
                객관적으로 시정이 불가능한 경우에는 회원에게 사전에 통지하여 이용계약을 해지할 수
                있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 본 약관 제10조에 규정된 회원의 의무를 위반하거나 제18조에 규정된 이용제한 사유에 해당하는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망
                이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성 프로그램의
                배포, 접속 권한 초과행위 등과 같이 관련 법령을 위반한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 회원 가입 이후 본 약관 제5조 제2항 각호의 회원 가입 신청 승낙 거절 사유가 확인되는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 그 밖에 각호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >3. 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸하며, 회사는
                이에 대해 별도로 보상하지 않습니다.<br />
                4. 본조에 따라 서비스 이용계약을 해지하는
                경우에는 회사는 제20조에 따른 방법으로 회원에게 통지합니다.<br />
                5. 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를
                제외한 회원의 모든 정보가 삭제됩니다.<br />
                6. 전항에도 불구하고 본조 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정
                기간 회원의 정보를 보관할 수 있으며, 해당 기간이 지난 후에 회원의 정보(단, 디지털
                자산 및 디지털 저작물 매매내역은 제외)를 삭제합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제20조 (회원에 대한 통지)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                1. 회사가 회원에게 통지를 하는 경우 본 약관에 별도의 규정이 없는 한 회원이 회사에
                제공한 전자우편주소, 서비스 내 메시지, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.<br />
                2. 회사는 회원 전체에 대하여 통지를 하는 경우 본 약관에 별도의 규정이 없는 한 30일 이상 서비스
                내 게시판에 게시하거나 팝업 화면 등을 제시하는 방법으로 공지함으로써 전항의 통지에
                갈음할 수 있습니다.<br />
                3. 회원 전체에 대한 통지의 내용이 다음 각호에 해당하는
                경우에는 본조 제2항의 방법에 의한 공지와 함께 회원이 그 내용을 충분히 알 수 있도록
                명확하게 통지의 내용을 표시하여 본조 제1항의 방법으로 개별 통지합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 회원에게 불리한 내용<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 회원의 권리 또는 의무에 영향을 미치는 중요한 내용<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >4. 회사는 제9조 제4항에서 정하는 의무의 수행을 위해 불가피한 경우, 회원이 등록한
                전자우편 주소, 연락처 등이 수신 불가 상태에 있는 경우 등 기타 사전에 통지할 수 없는
                부득이한 사유가 있는 경우, 본조 제1항 내지 제3항에도 불구하고 기간을 단축하거나
                사후적으로 통지할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제21조 (책임 제한)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                1. 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를
                배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지
                아니합니다.<br />
                2. 다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사가 회원의
                손해 발생을 방지하기 위하여 필요한 관리자의 주의를 다하였음을 입증한 때에는 그
                손해에 대하여 책임을 지지 아니합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                &nbsp;&nbsp;&nbsp;&nbsp;a. 전시, 사변, 천재지변, 또는 이에 준하는 국가 비상사태 등 불가항력이 발생한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 정부기관의 사실상 또는 법률상 행정처분 및 명령 등을 준수하기 위한 행위로 인한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 전기통신사업법상 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 회사가 관리할 수 없는 외주 시스템의 하자 또는 이용자 측 귀책 사유로 인하여 서비스에 장애가 발생한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 순간적인 홈페이지 접속 증가, 일부 종목의 주문 폭주 등으로 인한 서버의 장애가 발생한 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;f. 서비스 내 거래시스템, 입출금 시스템, 각 디지털 자산의 네트워크(예: 비트코인 네트워크) 장애 또는 오류가 발생한 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >3. 본조 제2항의 "필요한 관리자의 주의"란 다음 사항을 포함합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                &nbsp;&nbsp;&nbsp;&nbsp;a. 정보보호 책임자 선임 및 관리<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 정보보호 교육<br />
                &nbsp;&nbsp;&nbsp;&nbsp;c. 전산실(회사의 본질적인 업무인
                디지털 자산 및 디지털 저작물 거래에 필요하거나 중요 정보를 저장하고 있는 전산장비,
                통신 및 보안장비가 설치된 장소), 전산실이 위치한 건물 내 시설, 정보 보호
                시스템(정보처리시스템 내 정보를 유출, 위·변조, 훼손하거나 정보처리시스템의 정상적인
                서비스를 방해하는 행위로부터 정보 등을 보호하기 위한 장비 및 프로그램) 및
                정보처리시스템(디지털 자산 및 디지털 저작물 거래를 포함하여 정보기술 부문에 사용되는
                하드웨어, 소프트웨어 및 관련 장비)에 대한 보호 대책<br />
                &nbsp;&nbsp;&nbsp;&nbsp;d. 암호키(디지털 자산 및 디지털 저작물에 대한 처분 권한을 증명할 때 필요한 서명을 하기 위한 개인키(private key)) 관리 방안<br />
                &nbsp;&nbsp;&nbsp;&nbsp;e. 정보보안사고 대응 방안 수립<br />
                &nbsp;&nbsp;&nbsp;&nbsp;f. 정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제22조 (대금결제)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                &nbsp;&nbsp;&nbsp;&nbsp;1. 회원은 오픈마켓 사업자 또는 결제업체 등이 정하는 정책, 방법 등에 따라 결제금액을 내야 합니다.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;2. 결제 한도는 회사 및 오픈마켓 사업자, 결제업체의 정책, 정부의 방침 등에 따라 조정될 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제23조 (청약철회)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                1. 회원이 구매하는 유료 콘텐츠는 청약철회가 가능한 콘텐츠와 청약철회가 불가능한
                콘텐츠로 구분됩니다. 청약철회가 가능한 콘텐츠는 구매 후 7일 이내에 청약철회를 할 수
                있으며, 이 기간이 지난 청약철회가 가능한 콘텐츠, 사용한 콘텐츠, 재화 등의 가치가
                현저히 감소한 경우 또는 전자상거래 등에서의 소비자 보호에 관한 법률 등 기타 관련
                법령에서 정한 청약철회 제한 사유에 해당하는 콘텐츠는 청약철회가 제한됩니다. 회사는
                청약철회가 제한되는 콘텐츠에 대하여 청약철회가 제한된다는 사실을 표시하거나 동
                콘텐츠를 시험 사용할 수 있는 콘텐츠를 제공합니다.<br />
                2. 회사가 회원에게 무료로 지급한 콘텐츠(유, 무료 콘텐츠 포함), 선물 받은 유료 콘텐츠 등 회원이 직접 구매하지 않은
                콘텐츠는 청약철회의 대상에서 제외됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제24조 (청약철회 등의 효과)<br />회사는 회원으로부터 청약철회의 신청을 받은
                경우, 제23조 제1항에서 정하는 청약철회가 불가능한 콘텐츠가 아닌 경우에 해당한다면
                신청일의 다음날부터 3영업일 이내(수납확인이 필요한 경우 수납확인일로부터 3영업일
                이내)에 유료 콘텐츠의 구매 대금을 환불합니다. 회사가 환불을 지연한 때에는 그 지연
                기간에 대하여 전자상거래 등에서의 소비자 보호에 관한 법률에서 정한 이율을 곱하여
                산정한 지연이자를 지급합니다.<br /><br />제25조 (과오납금의 환불)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 회원이 과오납한 금액이 발생한 경우 환불이 이루어집니다. 결제과정에서 과오납금이
                발생하는 경우 원칙적으로는 오픈마켓 사업자에게 환불을 요청하여야 하나, 오픈마켓
                사업자의 정책∙시스템 상 환불 절차의 처리 지원이 가능한 경우, 회사가 오픈마켓
                사업자에게 필요한 환불절차의 이행을 요청할 수도 있습니다.<br />
                2. 환불은 회원이 결제한 방법과 같은 방법으로 환불하는 것을 원칙으로 하되, 같은 방법으로 환불이 불가능할
                때에는 다른 방법으로 환불할 수 있습니다.<br />
                3. 회원의 책임 있는 사유로 과오납금이 발생한 경우, 환불에 소요되는 수수료 등은 회원이 부담합니다.<br />
                4. 애플리케이션 다운로드 또는 네트워크 서비스를 이용하여 발생하는 요금(통화료, 데이터 통화료 등)은
                환불대상에서 제외됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제26조 (유료 콘텐츠의 환불)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >1. 유료 콘텐츠와 관련하여 다음의 각호와 같은 상황에 해당된다면, 고객센터를 통한 환불을
                받을 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >&nbsp;&nbsp;&nbsp;&nbsp;a. 유료 콘텐츠를 구매하였으나, 구매한 콘텐츠를 이용할 수 있는 서비스가 없어 그에 대한
                책임이 전적으로 회사에 있는 경우<br />
                &nbsp;&nbsp;&nbsp;&nbsp;b. 기타 소비자 보호를 위해 회사에서 따로 정하는 경우<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                2. 유료 콘텐츠의 환불을 원하는 회원은 회사가 정한 절차를 통해 환불을 신청해야 하며,
                회사는 환불 신청이 적정한지 여부를 심사합니다.<br />
                3. 회사가 회원의 환불 신청에 대하여 환불하는 경우, 회사는 해당 회원이 구매한 유료 콘텐츠의 잔액을 환불합니다.<br />
                4. 회사가 회원에게 무료로 지급한 콘텐츠나 제삼자가 무료로 지급받아 회원에게 선물한 콘텐츠와
                같이 최초 취득 시 회사에 대가가 지급되지 아니한 콘텐츠는 환불대상에서 제외됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제27조 (준거법 및 재판관할)<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >
                1. 회사와 회원간 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.<br />
                2. 회사와 회원간 발생한 분쟁에 관한 소송의 관할법원은 당사자들이 합의하여 결정하는 법원으로
                하며 합의가 이루어지지 않을 때에는 민사소송법에 의한 법원으로 합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />{{ '<부칙>' }}<br />본 약관은 2023년 11월 15일부터 적용됩니다.</span
            >
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'

const serviceName = process.env.VUE_APP_SERVICE_NAME_KOR
const companyName = process.env.VUE_APP_COMPANY_NAME

onMounted(() => {
    scrollToTop()
})

function scrollToTop() {
    window.scrollTo(0, 0)
}
</script>

<style scoped>
ul.root {
    list-style-type: none;
    counter-reset: li-counter;
}
.root > li {
    counter-increment: li-counter;
}
.root > li::before {
    content: counter(li-counter) '. ';
}

ul.root > li > ul {
    counter-reset: branch-first-counter;
    padding-left: 25px;
}

li.branch_first::before {
    counter-increment: branch-first-counter;
    content: counter(branch-first-counter, lower-alpha) '. ';
}

li.branch_first > ul {
    counter-reset: branch-scd-counter;
    padding-left: 25px;
}
li.branch_scd::before {
    counter-increment: branch-scd-counter;
    content: counter(branch-scd-counter, lower-roman) '. ';
}
</style>
