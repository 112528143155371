<!-- 마이페이지 등에서 넘어온 비밀번호 재설정 페이지 -->
<template>
    <main class="bg-bg2 pa-4 mt-[60px]">
        <article class="text-center bg-[#fff] container mx-auto w-[1240px] h-[880px] pt-32">
            <div class="__lang subTitle text-title loginbar">비밀번호 재설정</div>
            <div></div>
            <section class="text-login_join_page_text pa-10">
                <div class="__lang">
                    새로운 비밀번호를 입력해주세요.<br />
                    이전과 동일한 비밀번호는 사용할 수 없습니다.
                </div>
            </section>
            <section>
                <div class="flex flex-col justify-center items-center mb-[30px]">
                    <div class="relative w-[368px]">
                        <input
                            @focus="()=>{ isPassword1Focused = true; isPassword1FirstChecked = true; }"
                            @blur="()=>{ isPassword1Focused = false; }"
                            @keydown="preventSpace"
                            :class="validationForPwd1.length > 0 && isPassword1FirstChecked === true ? 'error': ''"
                            class="default_btn sub_border1 py-2 px-4"
                            placeholder="비밀번호를 입력해주세요."
                            :type="passwordORText ? 'text' : 'password'"
                            v-model="password1"
                        />
                        <div
                            class="absolute right-4 bottom-3 clickable"
                            @click.prevent="togglePasswordORText"
                        >
                            <img v-if="passwordORText" src="/asset/img/eye-gray.svg" />
                            <img v-else src="/asset/img/eye-gray-hide.svg" />
                        </div>
                    </div>
                    <div class="mb-[48px] w-[368px] flex">
                        <span class="text-left text-[14px] text-market_rise mt-2">{{
                            validationForPwd1 || ''
                        }}</span>
                    </div>

                    <div class="relative w-[368px]">
                        <input
                            @focus="()=>{ isPassword2Focused = true; isPassword2FirstChecked = true; }"
                            @keydown="preventSpace"
                            :class="validationForPwd2.length > 0 && isPassword2Focused === true ? 'error': ''"
                            class="default_btn sub_border1 py-2 px-4"
                            placeholder="비밀번호를 한 번 더 입력해주세요."
                            :type="passwordORTextConfirm ? 'text' : 'password'"
                            v-model="password2"
                        />
                        <div
                            class="absolute right-4 bottom-3 clickable"
                            @click.prevent="togglepasswordORTextConfirm"
                        >
                            <img v-if="passwordORTextConfirm" src="/asset/img/eye-gray.svg" />
                            <img v-else src="/asset/img/eye-gray-hide.svg" />
                        </div>
                    </div>
                    <div class="mb-[48px] w-[368px] flex">
                        <span 
                            class="text-left text-[14px] text-[#0A8A1D] mt-2"
                            :class="emailAuthVerifyTime > 0 ? 'text-sub_color1' : 'text-red'"
                        >{{
                            validationForPwd2 || ''
                        }}</span>
                    </div>
                </div>

                <div class="w-[360px] text-[14px] text-gray text-left tracking-tighter mx-auto">
                    <div class="flex">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>최소 8자리 이상이어야 합니다.</p>
                    </div>
                    <div class="flex translate-y-1">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>영문, 숫자, 특수문자를 모두 조합해주세요.</p>
                    </div>
                    <div class="flex translate-y-2">
                        <img src="/asset/img/register_notice_dot.svg" />
                        <p>특수문자 ~!@#$^%&amp;*()_+={}|;:&lt;,&gt;.?`-[] 만 허용합니다.</p>
                    </div>
                </div>

                <!-- next btn 영역 -->
                <div class="nextBtnArea">
                    <button
                        v-if="isPasswordValidationCompleted === true"
                        class="__lang default_btn btn_main_green default_btn md:mt-[128px]"
                        @click.prevent="prepareResetPwd()"
                    >
                        비밀번호 재설정
                    </button>
                    <button
                        v-else
                        class="__lang default_btn j_next_btn default_btn md:mt-[128px]"
                        @click.prevent
                    >
                        비밀번호 재설정
                    </button>
                </div>
            </section>
        </article>
    </main>
    <!-- alert용 중첩 가능한 모달, 이메일 인증 완료 -->
    <v-dialog v-model="nested_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ nested_title || '' }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { computed, nextTick, reactive, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router';
import { findPassword, verifyFindPassword, verifyUpdatePassword, findPwd, verifyPwd , updatePwd } from '../api/user/index.js';
import { preventSpace } from '../utils/utilsFactory';
import UtilSessionStorage from '../utils/session';


const router = useRouter();

const sessionStorage = new UtilSessionStorage();

const phonePattern = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/
const emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const pages = ref(0)

// 이메일 인증 관련
const userId = ref('')
const user_email = ref('')
const emailFormCheck = ref(false) // 이메일 형식 체크에 성공했을 때 true로 변경
const emailVerifyCheck = ref(false) // 이메일 인증이 성공적일 때 true로 변경
const emailVerifyState = ref('ready') // 이메일 인증이 ready일 때만 전송하기 활성화하기 위함.
const emailAuthLimitTime = ref(900)
const emailAuthStartTime = ref(null)
const emailAuthVerifyTime = ref(0)
let emailAuthVerifyIntervalId = null
const emailAuthRemainTime = ref('00:00')
const userVerifyNumber = ref('')
const emailResponseMsg = ref('')

// 비밀번호 - 텍스트 전환, password to text, pwd to text 관련
const passwordORText = ref(false);
const passwordORTextConfirm = ref(false);
const password1 = ref('');
const password2 = ref('');
const isPassword1Focused = ref(false);
const isPassword2Focused = ref(false);
const isPassword1FirstChecked = ref(false);
const isPassword2FirstChecked = ref(false);
const validationForPwd1 = ref('');
const validationForPwd2 = ref('');
const allowedSpecialChars = "~!@#$^%&*()_+={}|;:<,>.?`[]-";
const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~!@#$^%&*()_+={}|;:<,>.?`\[\]-]).{1,}$/
// const passwordPattern = new RegExp(`^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[${allowedSpecialChars}]).{8,}$`);
const passwordPattern2 = new RegExp(`^[a-zA-Z0-9${allowedSpecialChars}]{8,}$`);
const isPasswordValidationCompleted = computed(()=>{
    let result = false;
    if( passwordPattern.test( password1.value ) && passwordPattern.test( password2.value ) && password1.value.length > 7 && password2.value.length > 7 ){
        result = true;
    }
    return result;
})


// 모달창 컨트롤용 변수
const nested_dialog = ref(false) // 중첩용으로 나오는 작은 모달창
const nested_title = ref('안내')
const nested_msg = ref('') // alert용 모달창 메세지
const dialog_status = ref('ready')
const terms_dialog = ref(false)
const html_msg = ref('')

/**
 * 모달창의 상태 관리
 * 트루로 변하게 하거나 false로 변하게 하는 함수를 아예 분리했습니다.
 */
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}
function changeNestedMsg(newMsg) {
    nested_msg.value = newMsg
}


/**
 * 2개의 input의 validation이 완료되면 다음 버튼을 활성화시킵니다.
 */
const isEmailAuthCompleted = computed(() => {
    let result = false
    if (emailFormCheck.value === true && emailVerifyCheck.value === true) {
        result = true
    }
    return result
})


/**
 * 비밀번호 재설정 버튼 클릭 이벤트
 * 비밀번호 validation이 끝났다면 비밀번호 변경 요청을 보냅니다.
 */
async function prepareResetPwd() {
    try {
        const id = sessionStorage.getUserId();
        const response = await verifyUpdatePassword({ id : id, password : password1.value })
        nested_title.value = '안내';
        if (response.status === `success`) {
            const response2 = await excuteResetPwd( id );
            if( response2.status === `success` ){
                nested_msg.value = `입력하신 비밀번호가 정상적으로 등록되었습니다.`
                dialog_status.value = 'done';
            } else {
                nested_title.value = '승인오류 안내';
                nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
            }
        } else if( response?.title === `부적합비밀번호`){
            nested_msg.value = `올바르지 않은 비밀번호 형식입니다.<br/>글자 수, 조합 형식, 입력할 수 있는 특수문자를 확인한 후 다시 입력해주세요.`
        } else if( response?.descriptKor.includes(`동일`) ){
            // 전과 동일한 비밀번호일때
            nested_msg.value = `최근에 사용한 비밀번호입니다. 다른 비밀번호를 입력해주세요.`
        } else if( response?.descriptKor ){
            nested_msg.value = response.descriptKor;
        } else {
            nested_title.value = '승인오류 안내';
            nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
        }
    } catch (error) {
        nested_title.value = '승인오류 안내';
        nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
        console.error(error)
    } finally {
        changeNestedDialogTrue()
    }
}

/**
 * 
 */
async function excuteResetPwd( id ){
    return updatePwd({ id : id, password : password1.value });
}

// 비밀번호, password validation
/**
 * 비밀번호 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglePasswordORText(){
    passwordORText.value = !passwordORText.value
}
/**
 * 비밀번호 확인 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglepasswordORTextConfirm(){
    passwordORTextConfirm.value = !passwordORTextConfirm.value
}

onMounted(async () => {
    watch(nested_dialog, (newValue, oldValue) => {
        if (oldValue === true && newValue === false && dialog_status.value === 'done') {
            router.push({ path : '/mypage/myinfo' });
        }
    });

    watch(emailVerifyState, () => {
        if (emailVerifyState.value === 'send') {
            emailAuthVerifyIntervalId = setInterval(() => {
                if (emailAuthStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(emailAuthStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    emailAuthVerifyTime.value = emailAuthLimitTime.value - tempValue
                    if (emailAuthVerifyTime.value < 1) {
                        clearInterval(emailAuthVerifyIntervalId)
                        emailResponseMsg.value = `인증번호를 다시 발급받아주세요.(00:00)`
                        emailVerifyState.value = 'ready'
                        return
                    }
                    emailAuthRemainTime.value = `${`0${Math.floor(
                        emailAuthVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(emailAuthVerifyTime.value % 60)}`.slice(-2)}`
                    emailResponseMsg.value = `인증번호를 작성해주세요.(${emailAuthRemainTime.value})`
                }
            }, 1000)
        }
    })
    watch(isEmailAuthCompleted, (newVal) => {
        emailVerifyState.value = 'done'
    })
    watch(password1, () => {
        if (password1.value === '') {
            validationForPwd1.value = ''
        } else if ( !passwordPattern.test(password1.value) ) {
            validationForPwd1.value = '비밀번호는 영문, 숫자, 특수문자를 모두 포함해야 합니다.'
        } else {
            validationForPwd1.value = '';
        }
        if (password1.value.length < 8) {
        validationForPwd1.value = '비밀번호는 8자리 이상이어야 합니다.'
        }
        if( !password1.value && isPassword1FirstChecked.value === true ){
            validationForPwd1.value = '비밀번호를 입력해주세요.'
        } else if( password1.value.length < 8 ) {
            validationForPwd1.value = '비밀번호는 8자리 이상이어야 합니다.'
        }
    })

    watch(password2, () => {
        if (password2.value === '') {
            validationForPwd2.value = ''
        } else if (password1.value === password2.value) {
            validationForPwd2.value = ''
        } else {
            validationForPwd2.value = '비밀번호가 일치하지 않습니다.'
        }
    })

})
</script>

<style scoped>
.error {
    border: 1px solid var(--market_rise) !important;
}
input:focus {
    outline: none !important;
    border: 1px solid black !important;
}
.error:focus {
    border: 1px solid var(--market_rise) !important;
}
.modal_confirm_btn {
    background-color: var(--sub_color1) !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 90px;
    min-height: 43px;
}
</style>
