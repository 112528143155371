import { ref } from 'vue'
import { defineStore } from 'pinia'

/**
 * UserStore, 유저의 개인적인 정보 혹은 즐겨찾기에 관한 정보를 관리
 */
export const useUserStore = defineStore('userData', () => {
    // 나의 자산 관련
    // 유저의 사용할 수 있는 주문 가능 잔액
    const userWallet = ref(0);
    // 유저의 거래 가능 자산 데이터 (아이템별)
    const availableAsset = ref(0);
    // 평가 손익 데이터 상태 관리
    const memberAssetEval = ref([])
    // 보유 토큰 목록 상태 관리
    const memberTokenList = ref([])
    // 보유 거래 코인 상태 관리
    const memberCoinList = ref([])
    // 입출금 내역 관리
    const memberInoutList = ref([])
    // 전체 토큰 대비 보유 관련 데이터
    const memberAllMarketToken = ref([])

    // 유저 로그인 관련
    // 유저의 로그인 상태 관리
    const loginStatus = ref({ status : 'ready'})
    // 유저의 로그인 정보
    const loginedUser = ref({});
    // 유저의 로그인 실패 정보
    const loginFailedUserData = ref(null);
    // 유저의 마지막 로그인 시간
    const latestUserLogin = ref('');
    // 유저의 로그인 로그
    const userLoginLog = ref([]);

    // 마켓 관련
    // 서버로 받아온 유저의 관심 마켓 리스트(idx 보관용)
    const responseFavoriteMarketList = ref([]);
    // 유저의 관심 마켓 리스트
    const favoriteMarketList = ref([]);
    // 관심 마켓 리스트의 로딩 상태
    const favoriteLoading = ref(false);

    // 유저의 보안등급 데이터
    const userSecurityData = ref({});
    // 유저의 보안등급 레벨
    const userSecurityLevel = ref(1);
    // 유저의 알림 설정 데이터, 언제나 기본값으로 초기화하고 로그인하고 함수로 설정했을 경우에만 값을 할당합니다.
    const userAllowSetting = ref(false);
    // 유저의 인증 상태
    const userCertificationFullStatus = ref({});

    /**
     * 유저의 보안등급 레벨을 반환합니다.
     */
    function getUserSecurityLevel(){
        return userSecurityLevel.value;
    }

    // ------------------------ setter 영역 ------------------
    /**
     * 멤버의 입출금 내역을 새로 세팅합니다.
     * @param {*} newData 
     */
    function setMemberInoutList( newData ){
        memberInoutList.value = newData
    }

    /**
     * 멤버의 구매가능 자산 데이터(구매)를 새로 세팅합니다.
     * @param {*} newData 
     */
    function setAvailableAsset( newData ){
        availableAsset.value = newData
    }

    /**
     * 멤버의 거래가능 자산 데이터(판매)를 새로 세팅합니다.
     * @param {*} newData 
     */
    function setAvailableUserWallet( newData ){
        userWallet.value = Math.floor( Number(newData) )
    }


    /**
     * 유저의 보안등급 레벨을 새로 세팅합니다.
     */
    function setUserSecurityLevel( newValue ){
        if( newValue ){
            userSecurityLevel.value = newValue;
        }
    }

    /**
     * 로그인 스테이터스를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setLoginStatus( newValue ){
        if( newValue ){
            loginStatus.value = newValue
        }
    }

    /**
     * 로그인 유저 정보를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setLoginedUser( newValue ){
        if( newValue ){
            loginedUser.value = newValue
        }
    }

    /**
     * 평가 손익 데이터를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setMemberAssetEval( newValue ){
        if( newValue ){
            memberAssetEval.value = newValue
        }
    }

    /**
     * NFT 리스트를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setMemberTokenList( newValue ){
        if( newValue ){
            memberTokenList.value = newValue
        }
    }

    /**
     * 유저의 자산 리스트를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setMemberCoinList( newValue ){
        if( newValue ){
            memberCoinList.value = newValue
        }
    }

    /**
     * 유저의 모든 자산, 작품 데이터를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setAllMarketToken( newValue ){
        if( newValue ){
            memberAllMarketToken.value = newValue
        }
    }

    /**
     * 응답 받은 즐겨찾기 데이터를 보관합니다.
     * @param {*} newValue 
     */
    function setResponseFavoriteMarketList( newValue ){
        if( newValue ){
            responseFavoriteMarketList.value = newValue
        }
    }

    /**
     * 관심 마켓 리스트를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setFavoriteMarketList( newValue ){
        if( newValue ){
            favoriteMarketList.value = newValue
        }
    }

    /**
     * 유저의 최근 로그인 정보를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setLatestUserLogin( newValue ){
        if( newValue ){
            latestUserLogin.value = newValue
        }
    }

    /**
     * 유저의 전체 로그인 로그를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setUserLoginLog( newValue ){
        if( newValue ){
            userLoginLog.value = newValue
        }
    }


    function setLoginFailedUserData( newValue ){
        loginFailedUserData.value = newValue
    }


    return {
        availableAsset,
        latestUserLogin,
        loginStatus,
        loginedUser,
        loginFailedUserData,
        memberAllMarketToken,
        memberAssetEval,
        memberTokenList,
        memberCoinList,
        memberInoutList,
        favoriteMarketList,
        favoriteLoading,
        userLoginLog,
        userWallet,
        getUserSecurityLevel,
        setAvailableAsset,
        setAvailableUserWallet,
        setMemberInoutList,
        setUserSecurityLevel,
        setLoginStatus,
        setLoginedUser,
        setLoginFailedUserData,
        setMemberAssetEval,
        setMemberTokenList,
        setMemberCoinList,
        setAllMarketToken,
        setResponseFavoriteMarketList,
        setFavoriteMarketList,
        setLatestUserLogin,
        setUserLoginLog,
    }
})
