/* eslint-disable consistent-return */
import axios from 'axios';
import { useOrderStore } from '../../stores/order/index'
import { requestHeader } from '../config.js'


/**
 * 지정가 주문 생성
 * @param {*} command     명령어
 * @param {*} id          사용자 id
 * @param {*} market      시장
 * @param {*} type        1 = 매도, 2 = 매수
 * @param {*} volume      수량
 * @param {*} price       가격
 * @param {*} market1   매수시장
 * @param {*} market2   매도시장
 */
export async function initOrder(command, id, market, type, volume, price, bidMarket, askMarket){
  const requestData = {
      command, id, market, type,
      volume, price, bidMarket, askMarket
  }
  try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/order_put_limit`, requestData, requestHeader() );
        return response;
  } catch (error) {
      console.error(error);
  }
  return null;
}


/**
 * 시장가 주문 생성
 * @param {*} command     명령어
 * @param {*} id          사용자 id
 * @param {*} market      시장
 * @param {*} type        1 = 매도, 2 = 매수
 * @param {*} volume      가격
 * @param {*} market1   매수시장
 * @param {*} market2   매도시장
 */
export async function initOrderMarketPrice(command, id, market, type, volume, bidMarket, askMarket){
    try {
        const requestData = {
            command, id, market, type,
            volume, bidMarket, askMarket
        }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/order_put_market`, requestData, requestHeader() )
        return response;
    } catch (error) {
        console.error(error)
    }
}


/**
 * 지정가 예약 주문 생성
 * @param {*} command     명령어
 * @param {*} id          사용자 id
 * @param {*} market      시장
 * @param {*} type        1 = 매도, 2 = 매수
 * @param {*} volume      수량
 * @param {*} price      가격
 * @param {*} market1   매수시장
 * @param {*} market2   매도시장
 * @param {*} monitorPrice 감시가
 */
export async function initOrderStopPutPrice(command, id, market, type, volume, price, bidMarket, askMarket, monitorPrice){
    try {
        
        const requestData = {
            command, id, market, type,
            volume, price, bidMarket, askMarket, monitorPrice
        }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/order_put_stop_limit`, requestData, requestHeader() );
        return response;
    } catch (error) {
        console.error(error)
    }
}
  

/**
 * 주문 취소
 * @param {*} command 
 * @param {*} id 
 * @param {*} idx 
 */
export async function makeCancelOrder({ command, id, idx, type, market }){
    try {
        let result = false;
        const requestData = {}
        if( command ) requestData.command = command;
        if( id ) requestData.id = id;
        if( idx ) requestData.idx = idx;
        if( type ) requestData.type = type;
        if( market ) requestData.market = market;
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/order_cancel`, requestData, requestHeader() );
        if( response.data.result.message === 'successfully canceled' ){
            result = true;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}


/**
 * 호가 작품 목록을 서버에 요청합니다.
 * @param {*} price 
 * @param {*} type 
 * @param {*} market 
 */
export async function getOrderImageGallery({ price, market }){
    try {
        const requestData = {}
        if( price && Number(price) !== 0 ) requestData.price = price;
        if( market ) requestData.market = market;
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/order_image`, requestData, requestHeader() );
        return response.data.result;
    } catch (error) {
        console.error(error);
    }
}


/**
 * 체결 주문 데이터 요청 생성
 * @param {*} id
 * @returns {Object} response
 */
export async function initOrderDealList ( {id, page, type, start, end, symbol, } ) {
    try {
        const requestData = {}
        if( id ) requestData.id = id;
        if( Number(page) ) requestData.page = Number(page);
        if( Number( type ) === 1 || Number( type ) === 2 ) requestData.type = type;
        if( start ) requestData.start = start;
        if( end ) requestData.end = end;
        if( symbol ) requestData.symbol = symbol

        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/deal_list`, requestData, requestHeader() );
        if( response.data.status === 'success' && response.data.result.data ){
            const orderStore = useOrderStore();
            orderStore.setDealList( response?.data.result.data );
            return response.data.result.page_num;
        }
    } catch (error) {
        console.error(error)
    }
}


/**
 * 미체결 주문 데이터 요청 생성
 * @param {*} id
 * @returns {Object} response
 */
export async function initOrderPendingList( {id, page, start, end, type, symbol, stopLimit} ){
    try {
        const requestData = {}
        if( id ) requestData.id = id;
        if( page ) requestData.page = page;
        if( type ) requestData.type = type;
        if( stopLimit ) requestData.stopLimit = stopLimit;
        if( start ) requestData.start = start;
        if( end ) requestData.end = end;
        if( symbol ) requestData.symbol = symbol
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/order/pending_list`, requestData, requestHeader() );
        if( response.data.status === 'success' && response.data.result.data ){
            const orderStore = useOrderStore();
            orderStore.setPendingList( response?.data.result.data );
            orderStore.setTotalAskBidCount( { total_ask_count : response?.data.result.total_ask_count || 0, total_bid_count : response?.data.result.total_bid_count || 0, } );
            return response.data.result.page_num;
        }
    } catch (error) {
        console.error(error)
    }
}