<template>
  <main class="bg-bg2 pa-4">
      <article v-if="pages === 0 || !pages" class="text-center bg-[#fff] container mx-auto w-[1240px] h-[778px] pt-32">
          <div class="__lang subTitle text-title loginbar">아이디 찾기</div>
          <div></div>
          <section class="text-login_join_page_text pa-10">
              <div class="__lang">가입할 때 등록하신 휴대폰 번호를 입력해주세요.<br/>
(번호 변경, 기기 분실 시 1:1 문의를 통해 확인해주세요.)
              </div>

          </section>
          <section>
            <div>
                <!-- input ----------------------------------------------->
                <input
                    @focus="form.phone.isFocused = true"
                    :class="phoneValidationResult.status === false && form.phone.isFocused === true ? 'error' : ''"
                    class="border pa-4 default_btn mb-2"
                    type="text"
                    placeholder="휴대폰 번호를 입력해주세요."
                    id="phone"
                    name="phone"
                    :value="form.phone.value"
                    @input="form.phone.value = $event.target.value"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
                    @keydown="handleKeydown"
                />
            </div>
            <div class="w-[367px] mx-auto text-left text-[14px] mt-2" :class="phoneValidationResult?.status ? 'text-sub_color1' : 'text-red'">{{ phoneValidationResult?.msg || '' }}</div>
            <!-- 로그인 버튼 영역 -->
            <div class="md:mt-[223px]">
              <div>
                <form name="form_auth" target="auth_popup" :action="`${backEndUrl}/phone_auth/req`">
                    <!-- <button class="btn_main_green" @click.prevent="auth_type_check()">핸드폰 본인 인증하기</button> -->
                    <button v-if="phoneValidationResult.status === true" class="__lang default_btn btn_main_green default_btn" @click="auth_type_check">
                        아이디 찾기
                    </button>
                    <button v-else class="__lang default_btn j_next_btn default_btn cursor-default" @click.prevent>
                        아이디 찾기
                    </button>
                    <!-- 주문번호(ordr_idxx) -->
                    <input type="hidden" id="ordr_idxx" name="ordr_idxx" value="" readonly="readonly"/>
                    <!-- 요청종류 -->
                    <input type="hidden" name="req_tx"       value="cert"/>
                    <!-- 요청구분 -->
                    <input type="hidden" name="cert_method"  value="01"/>
                    <!-- 웹사이트아이디 -->
                    <input type="hidden" name="web_siteid"   value=""/>
                    <!-- 노출 통신사 default 처리시 아래의 주석을 해제하고 사용하십시요
                        SKT : SKT , KT : KTF , LGU+ : LGT
                    <input type="hidden" name="fix_commid"      value="KTF"/>
                    -->
                    <!-- 사이트코드 -->
                    <input type="hidden" name="site_cd"      value="S6186" />
                    <!-- Ret_URL : 인증결과 리턴 페이지 ( 가맹점 URL 로 설정해 주셔야 합니다. ) -->
                    <input type="hidden" name="Ret_URL"      v-model="retUrl" id="ret_url"/>
                    <!-- cert_otp_use 필수 ( 메뉴얼 참고)
                        Y : 실명 확인 + OTP 점유 확인 , N : 실명 확인 only
                    -->
                    <input type="hidden" name="cert_otp_use" value="Y"/>
                    <!-- cert_enc_use 필수 (고정값 : 메뉴얼 참고) -->
                    <input type="hidden" name="cert_enc_use" value="Y"/>
                    <!-- cert_enc_use_ext 필수 (고정값 : 메뉴얼 참고) -->
                    <input type="hidden" name="cert_enc_use_ext" value="Y"/>
                    <input type="hidden" name="res_cd"       value=""/>
                    <input type="hidden" name="res_msg"      value=""/>

                    <!-- up_hash 검증 을 위한 필드 -->
                    <input type="hidden" name="veri_up_hash" value=""/>

                    <!-- 본인확인 input 비활성화 -->
                    <input type="hidden" name="cert_able_yn" value="Y"/>

                    <!-- web_siteid 검증 을 위한 필드 -->
                    <input type="hidden" name="web_siteid_hashYN" value=""/>

                    <!-- 가맹점 사용 필드 (인증완료시 리턴)-->
                    <input type="hidden" name="param_opt_1"  value="opt1"/>
                    <input type="hidden" name="param_opt_2"  value="opt2"/>
                    <input type="hidden" name="param_opt_3"  value="opt3"/>
                </form>
            </div>
              
            </div>
          </section>
      </article>

      <article v-else-if="pages === 1" class="text-center bg-[#fff] container mx-auto w-[1240px] h-[778px] pt-32">
          <div class="__lang subTitle text-title loginbar">아이디 찾기</div>
          <div></div>
          <section class="text-login_join_page_text pa-10 mb-[223px]">
              <div class="__lang mb-[50px]">
                <p>회원님의 이메일 주소는 아래와 같습니다.</p>
                <p>해당 주소로 다시 로그인해주세요.</p>
              </div>
              <div>
                <input 
                  class="default_btn bg-[#f2f2f2] px-5 py-3" 
                  type="text" 
                  v-model="responseResult" 
                  readonly
                />
              </div>
          </section>
          <div>
            <button class="btn_main_green default_btn ">
              <RouterLink to="/login">로그인</RouterLink>
            </button>
          </div>
      </article>


      <!-- alert용 중첩 가능한 모달 -->
      <v-dialog v-model="nested_dialog" width="470px">
          <v-card class="pa-4">
              <v-card-text class="flex flex-col">
                  <div class="text-noto-700 text-[18px] mb-3">
                      {{ modal_title }}
                  </div><br />
                  <div class="text-[15px]" v-html="nested_msg">
                  </div>
              </v-card-text>
              <v-card-actions class="justify-end mt-12">
                  <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="changeNestedDialogFalse">
                      확인
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </main>
</template>

<script setup>
import { computed, reactive, ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import UtilLocalStorage from '@/utils/storage/localStorage';
import UtilSessionStorage from '@/utils/session/index.js';
import { findIdPwd } from '../api/user/index.js';
import { handleKeydown } from '../utils/utilsFactory';

const localStorage = new UtilLocalStorage();

const successUserData = ref({});

const backEndUrl = ref(process.env.VUE_APP_BACKEND);

const router = useRouter();
const pages = ref(0);
const responseResult = ref('');

const phonePattern = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;

const form = reactive({
    phone : { value: '', isFocused: false },
})

// 모달창 컨트롤용 변수
const nested_dialog = ref(false)
const modal_title = ref('안내')
const nested_msg = ref('')
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }

// 모바일 인증용 변수
const retUrl = ref('');
const BACKEND_URL = ref('');

/**
 * 인증 오류 얼럿
 */
function makeAlertAuthErrorAlarm(){
  modal_title.value = `인증오류 안내`
  nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/><br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요`;
  changeNestedDialogTrue();
}

/**
 * 회원 정보 부재 알림 얼럿
 */
function cantFindUserInfoAlert(){
  modal_title.value = `안내`
  nested_msg.value = `일치하는 회원정보를 찾을 수 없습니다. 휴대폰 번호를 다시 확인해주세요.`;
  changeNestedDialogTrue();
}

// 핸드폰 유효성 Validation
const phoneValidationResult = computed(()=>{
    const result = {
        status : false,
        msg : ''
    };

    if( form.phone.isFocused === true || form.phone.value === '' ){
      result.msg = '휴대폰 번호를 입력해주세요.';
    }

    if (form.phone.value) {
        if (phonePattern.test(form.phone.value) && form.phone.value.length < 12 ) {
          result.msg = '';
          result.status = true;
        } else {
          result.msg = '휴대폰 번호를 확인해주세요.';
        }
    }

    if( form.phone.isFocused === false ){
      result.msg = '';
    }

    return result;
});

/**
 * 모바일 인증을 위한 함수입니다.
 */
 async function mobileCertification() {
  
  const successData = successUserData.value;
  const {
        phone = (successData.phone && successData.phone !== "null") ? successData.phone : '01012345678',
        birth = (successData.birth && successData.birth !== "null") ? successData.birth : '19901225',
        name = (successData.name && successData.name !== "null") ? successData.name : '홍길동',
        sex = (successData.sex && successData.sex !== "null") ? successData.sex : 'female',
        local = (successData.local && successData.local !== "null") ? successData.local : 'domestic',
    } = successData;
  try {
    const response = await findIdPwd( phone, birth, name, sex )
    // 0번인 join, main에서 다음 과정으로 넘어갑니다.
    if ( response?.status === "success" ) {
      responseResult.value = response?.result?.email
      modal_title.value = `안내`
      nested_msg.value = `핸드폰 본인 인증이 완료되었습니다.`
      changeNestedDialogTrue();
      pages.value = 1;
    } else if( response?.descript.includes('Do not have member info') ){
      cantFindUserInfoAlert();
    } else {
      makeAlertAuthErrorAlarm();
    }
  } catch (error) {
    console.error(error);
    makeAlertAuthErrorAlarm();
  }
  
}

onMounted(()=>{
  /**
   * 핸드폰 본인 인증이 완료되었을 때 실행될 함수
   */
  BACKEND_URL.value = process.env.VUE_APP_BACKEND;
  document.getElementById( "ordr_idxx"  ).value = Date.now() ;
  retUrl.value = `${BACKEND_URL.value}/phone_auth/res`;
  window.addEventListener("message",receivePostMsg);
})

// 모바일 인증에 필요한 함수들

function receivePostMsg(event)
{
    if (event.origin === `${BACKEND_URL.value}`) {
      const temp = {
          phone: (event.data.phone && event.data.phone !== "null") ? event.data.phone : '01012345678',
          birth: (event.data.birth && event.data.birth !== "null") ? event.data.birth : '19901225',
          name: (event.data.name && event.data.name !== "null") ? event.data.name : '홍길동',
          sex: (event.data.sex && event.data.sex !== "null") ? event.data.sex : 'female',
          local: (event.data.local && event.data.local !== "null") ? event.data.local : 'domestic',
        }
        successUserData.value = temp;
        mobileCertification();
    }
}



function auth_type_check() {
    retUrl.value = `${BACKEND_URL.value}/phone_auth/res`;
    /* eslint-disable no-else-return */
    var auth_form = document.form_auth;

    if( auth_form.ordr_idxx.value === "" )
    {
        alert( "주문번호는 필수 입니다." );
        return false;
    }
    else
    {
        var AUTH_POP;
        if( ( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 ) == false ) // 스마트폰이 아닌경우
        {
            var return_gubun;
            var width  = 410;
            var height = 500;
            var leftpos = screen.width  / 2 - ( width  / 2 );
            var toppos  = screen.height / 2 - ( height / 2 );
            var winopts  = "width=" + width   + ", height=" + height + ", toolbar=yes,status=no,statusbar=no,menubar=no,scrollbars=no,resizable=no";
            var position = ",left=" + leftpos + ", top="    + toppos;
            AUTH_POP = window.open('','auth_popup', winopts + position);
        }

        auth_form.target = "auth_popup"; // !!주의 고정값 ( 리턴받을때 사용되는 타겟명입니다.)
        auth_form.action = `${process.env.VUE_APP_BACKEND}/phone_auth/req`; // 인증창 호출 및 결과값 리턴 페이지 주소

        return true;
    }
}

</script>

<style scoped>
.error {
  border: 1px solid var(--market_rise) !important;
}
input:focus {
  outline: none !important;
  border: 2px solid black !important;
}
.error:focus {
  border: 2px solid var(--market_rise) !important;
}
</style>