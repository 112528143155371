// 에러 상태를 관리합니다.
import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useErrorStore = defineStore('error', () => {

  const message = ref('');

  const status = ref('');

  function updateErrorMessage( newMsg ){
    message.value = newMsg;
  }

  function updateErrorStatus( newStatus ){
    status.value = newStatus;
  }

  return {
    message,
    status,
    updateErrorMessage,
    updateErrorStatus
  }
})