/**
 * nice 본인 인증 서비스에 대한 spa 라우터 입니다.
 */

export default [
  {
      path: '/checkplus_pop',
      name: 'CheckPlusPop',
      component: () => import('@/pages/PopUp/AuthPopUp.vue')
  },
  {
    path: '/checkplus_success',
    name: 'CheckPlusSuccess',
    component: () => import('@/pages/PopUp/AuthPopUp.vue')
  },
  {
    path: '/checkplus_fail',
    name: 'CheckPlusFail',
    component: () => import('@/pages/PopUp/AuthPopUp.vue')
  },
]