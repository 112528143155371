<!-- 입출금 -> 입금/출금 -->
<template>
    <main class="flex mb-5">
        <!-- 데이터 로딩 -->
        <div
            v-if="!dataLoading"
            class="w-[1050px] h-[800px] bg-[#fff] flex justify-center items-center"
        >
            <v-progress-circular
                width="10"
                size="80"
                indeterminate
                color="#305C3A"
            ></v-progress-circular>
        </div>
        <div v-else-if="dataLoading" class="flex">
            <article class="bg-[#fff] min-w-[600px] basicBoxShadow">
                <!-- 총 보유자산 영역 -->
                <div class="flex justify-between h-[100px] items-center">
                    <div class="'h-full flex items-center border-r px-5">총 보유자산</div>
                    <div class="flex items-end w-[460px] justify-end pr-3">
                        <h1 class="text-[24px]">
                            {{
                                Math.floor(
                                    userStore?.memberAssetEval?.total_exchanged_current_balance
                                ).toLocaleString() || 0
                            }}
                        </h1>
                        <small class="ml-1 pb-1">{{ defaultMarket }}</small>
                    </div>
                </div>

                <!-- 가진 포인트 영역 (원화 포인트, 이더리움, 비트스타 등등) -->
                <!-- 법정화폐, 원화마켓을 사용하지 않을 때 렌더될 영역 -->
                <ul v-if="dataLoading && isUseFiatCurrency === 0" class="w-[610px] border-t">
                    <li
                        v-for="(item, index) in tokenList"
                        v-bind:key="index"
                        :class="!$route.params.symbol ? 'active' : ''"
                        class="asset_item flex justify-between clickable h-[50px] border-b leading-4"
                        @click.prevent="selectThisSymbol(item, 0)"
                    >
                        <span class="flex min-w-[500px] justify-between items-center">
                            <img
                                v-if="tokenList"
                                :src="item?.image"
                                class="w-[30px] h-[30px] ml-4"
                            />
                            <img v-else src="/asset/img/text1.svg" class="w-[30px] h-[30px] ml-4" />
                            <div class="flex flex-col flex-start">
                                <p
                                    :class="
                                        !$route.params.symbol ? 'activeTitle text-noto-700' : ''
                                    "
                                    class="asset_name text-[13px]"
                                >
                                    {{ item?.name }}
                                </p>
                                <p
                                    :class="!$route.params.symbol ? 'text-main_color' : ''"
                                    class="contents_secondary"
                                >
                                    {{ item?.symbol }}
                                </p>
                            </div>
                            <!-- 보유량, 환산가 영역 -->
                            <span class="w-[200px] flex flex-col justify-end items-end pr-5">
                                <div class="text-[14px]">
                                    {{
                                        Math.floor(item?.current_balance)?.toLocaleString('ko-KR', {
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0
                                        }) || 0
                                    }}
                                    <span class="contents_secondary">{{ item?.symbol }}</span>
                                </div>
                            </span>
                            <!-- 보유비중 -->
                            <span
                                class="contents_secondary text-noto-700 w-[90px] pl-5 pr-5 text-right"
                            >
                                {{ item?.percent?.toFixed(2) }}%
                            </span>
                        </span>
                        <div
                            v-if="$route.params.type === 'deposit' || !$route.params.type"
                            class="flex justify-center items-center w-[98px] border-l 'text-noto-700"
                        >
                            <p
                                v-if="isDepositPossibleItem(item.state, $route.params.type)"
                                class="contents_secondary text-main_color"
                                @click.prevent
                            >
                                입금
                            </p>
                            <p v-else class="contents_secondary text-market_rise" @click.prevent>
                                입금중지
                            </p>

                            <span class="translate-x-3">
                                <svg
                                    width="6"
                                    height="8"
                                    viewBox="0 0 6 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 1L5 4L1 7" stroke="#305C3A" stroke-width="0.65" />
                                </svg>
                            </span>
                        </div>
                        <div
                            v-else
                            class="flex justify-center items-center w-[98px] border-l 'text-noto-700"
                        >
                            <p
                                v-if="isDepositPossibleItem(item.state, $route.params.type)"
                                class="contents_secondary text-main_color"
                                @click.prevent
                            >
                                출금
                            </p>
                            <p v-else class="contents_secondary text-market_rise" @click.prevent>
                                출금중지
                            </p>
                            <span class="translate-x-3">
                                <svg
                                    width="6"
                                    height="8"
                                    viewBox="0 0 6 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 1L5 4L1 7" stroke="#305C3A" stroke-width="0.65" />
                                </svg>
                            </span>
                        </div>
                    </li>
                </ul>
                <!-- 법정화폐, 원화마켓을 사용하지 않을 때 렌더될 영역 끝 -->

                <!-- 법정화폐, 원화마켓을 사용할 때 렌더될 영역 -->
                <ul v-else-if="dataLoading && isUseFiatCurrency === 1" class="w-[610px] border-t">
                    <li
                        v-for="(item, index) in tokenList"
                        v-bind:key="item"
                        :class="$route.params.symbol === item.symbol ? 'active' : ''"
                        class="asset_item flex justify-between clickable h-[50px] border-b leading-4"
                        @click.prevent="selectThisSymbol(item)"
                    >
                        <span class="flex min-w-[500px] justify-between items-center">
                            <img
                                v-if="tokenList"
                                :src="item?.image"
                                class="w-[30px] h-[30px] ml-4"
                            />
                            <img v-else src="/asset/img/text1.svg" class="w-[30px] h-[30px] ml-4" />
                            <div class="flex flex-col flex-start w-[120px]">
                                <p
                                    :class="
                                        $route.params.symbol === item.symbol
                                            ? 'activeTitle text-noto-700'
                                            : ''
                                    "
                                    class="text-[13px]"
                                >
                                    {{ item?.name }}
                                </p>
                                <!-- 환산 금액 영역 -->
                                <p
                                    :class="
                                        $route.params.symbol === item.symbol
                                            ? 'text-main_color'
                                            : ''
                                    "
                                    class="contents_secondary"
                                >
                                    {{ item?.symbol }}
                                </p>
                            </div>
                            <!-- 보유량, 환산가 영역 -->
                            <span class="w-[200px] flex flex-col justify-end items-end pr-5">
                                <div class="text-[14px]">
                                    {{
                                        Math.floor(item?.current_balance)?.toLocaleString('ko-KR', {
                                            maximumFractionDigits: 8,
                                            minimumFractionDigits: 0
                                        }) || 0
                                    }}
                                    <span class="contents_secondary">{{ item?.symbol }}</span>
                                </div>
                                <!-- 환산 금액 영역 -->
                                <!-- TODO exchangedCurrentBalance 로 교체 예정 -->
                                <p v-if="item.symbol !== 'KRW'" class="text-[10px] text-gray">
                                    {{
                                        `≈ ${
                                            Number(item?.exchanged_current_balance)?.toLocaleString(
                                                'ko-KR',
                                                {
                                                    maximumFractionDigits: 0,
                                                    minimumFractionDigits: 0
                                                }
                                            ) || 0
                                        }`
                                    }}
                                    {{ 'KRW' }}
                                </p>
                            </span>
                            <!-- 보유비중 -->
                            <span
                                class="contents_secondary text-noto-700 w-[90px] pl-5 pr-5 text-right"
                            >
                                {{ item?.percent?.toFixed(2) }}%
                            </span>
                        </span>
                        <div
                            v-if="$route.params.type === 'deposit' || !$route.params.type"
                            class="flex justify-center items-center w-[98px] border-l 'text-noto-700"
                        >
                            <p
                                v-if="isDepositPossibleItem(item.state, $route.params.type)"
                                class="contents_secondary text-main_color"
                                @click.prevent
                            >
                                입금
                            </p>
                            <p v-else class="contents_secondary text-market_rise" @click.prevent>
                                입금중지
                            </p>

                            <span class="translate-x-3">
                                <svg
                                    width="6"
                                    height="8"
                                    viewBox="0 0 6 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 1L5 4L1 7" stroke="#305C3A" stroke-width="0.65" />
                                </svg>
                            </span>
                        </div>
                        <div
                            v-else
                            class="flex justify-center items-center w-[98px] border-l 'text-noto-700"
                        >
                            <p
                                v-if="isDepositPossibleItem(item.state, $route.params.type)"
                                class="contents_secondary text-main_color"
                                @click.prevent
                            >
                                출금
                            </p>
                            <p v-else class="contents_secondary text-market_rise" @click.prevent>
                                출금중지
                            </p>
                            <span class="translate-x-3">
                                <svg
                                    width="6"
                                    height="8"
                                    viewBox="0 0 6 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 1L5 4L1 7" stroke="#305C3A" stroke-width="0.65" />
                                </svg>
                            </span>
                        </div>
                    </li>
                </ul>
                <!-- 법정화폐, 원화마켓을 사용할 때 렌더될 영역 끝 -->

                <!-- 작품 검색 영역 -->
                <div class="flex h-[50px] border">
                    <div
                        class="w-[400px] flex items-center justify-between px-5 border-r searchContainer"
                        :class="{ 'focused-style': isFocused }"
                    >
                        <input
                            :value="searchNft"
                            @input="searchNft = $event.target.value"
                            placeholder="작품명/심볼 검색"
                            class="searchBox w-[330px]"
                            @focus="onFocus"
                            @blur="onBlur"
                        />
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <rect width="22" height="22" fill="url(#pattern0)" fill-opacity="0.3" />
                            <defs>
                                <pattern
                                    id="pattern0"
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                    height="1"
                                >
                                    <use xlink:href="#image0_737_33616" transform="scale(0.01)" />
                                </pattern>
                                <image
                                    id="image0_737_33616"
                                    width="100"
                                    height="100"
                                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFOUlEQVR4nO2dW4iVVRTHfzPTzZAedI5TZEQy3WRMkHoQEsLngtSkmzg+lNEdldQiekiinu2CUg8lBUVUQ/aSY6VEEGQITdl0YzIq7GJSWZYaJzasoWE6e+3vnDlzzl7fWT/YjzOz1vf/9rf32mutPeA4juM4juM4juM4juM4zlQ4HVgAXAusBdYBm2TcBqwClgAVf8zTw2zgemA7MAr8A1QLjsPAThFtoQvUOKcCy4Ah4HgdAlQT4wCwGTjXxSnGacBq4IsmilCtMYLIO4CLXZg4NwPfT7MQ1UnjJLANmOXC/Ec/8HaLhahOGj/KzOx4wmL9a4MP8S/gK2AfsBf4APgE+GkKwjwPzOxEVXqArXU+rM+Ax4HlwDz5HTHCJ+hK4H5guM6NwajM2o6KI14u+HCOAk8AV0zxb/YCd8sMKvJ3DwGL6BAxdhV4IH8Cj0xDcNcNrCgozG/AYkpMT8GZ8QZwQQvinPtkBmq2/AzMp6Sk1oxjwB0ttulSYCRh1zfA2ZRwN5Xadk51nWiUM4HXEvbtls9dKehPbG0PAhdl8Dl9OiHKQ5SEtxQnQ8xwCXnQBTyr2HoCuAzj3JRYM9r1mdLO0vYoNu8V4UwyA/hOca7VC3hRzgF+UOwO66FJ7kxsbXPmGsX2jyzOkrDP/1oJ+qY7zmgGQ4ooIVtpiuWKMyECt8A8Wchr+RDOx0wRe7v+MJbrfi7iR0gjn4cRepXT1XBQaIkBZaZvxAg3KE5cjj0+jPgSkmom2K7kMyyyXkmQha199oxGHAjJJYsMKDN+KZlzhhQO1DI+5CEs0qUEiveQOQuUt8lC7BHjzYhPT2I0/jiWyIFbzeUMkzm3RAwPhW+WWR/xK+zAsmadVcMT3Brx63My58GI4e9imxsjfoVKy6zZGDH8fWwzGPErHKBmze0Rw0PZjWXusurXKiVVa5ktEb/eI3OWKHGI5Qrzl5Ra4KypKIKEWlurjER8CjMnew5HjA+FzxbpVdroVmKAnVaj2gjXKbN+Loaj2uPGsoWp9WMMIyxU3qjQEmCJs6Qoo5Yv4XzLDAeUfbulGtkNlnMhE9msOLLCUC/Lt0qEbur0OvSB/x1x5mOp27L8Uj2AQZ5RHArNMrm/UL9HbA9NPnMwyIVKOveoNMvkSHei7e4xDLNNcWxEmmUsfap+kbtXzDJLuqNiDr4OnEJeRdYnFXvD7UPmWa04WJXOpRy2wlcpMcd4ki0HO5vCjoQor0gJUbtYJnXHMfuOGK+a+R8zlQK68bFHmmVaSbesGak7uNZQQvrlhgTN8VCQdnULt7a7EvZMjJ1MbnVTLCp42cyQ9GdMVwS+SYkzOk6UxUrOZOI4If0Zoba2WQeFGxJ9jx0ryny5IaHog9gnx/oDdfb3zZZ8xouJHZSLItdV7G7ggRySWtutItJaydwNSqPpFslhjNR5YeZ44LdGZkJHzpQeuSGhmRddVhscIc44X+ya08miIFdrDLdJiCPAvTWCvoq0QGs/+2kbtuoto0ua8lMPodqkEQ44H02cTRWdKX2UmC7pAx9uYA2oFhgHJZ9R9HPjokxgrtQLvyM9fY2KMCabgKUNZvpclBrMkAca2siekig7bIe/lJ3XmOTyQ3nnC7LbWtnEUh0XJUMqBda40TIv9DlScVHyo+Ki5ClK6gJN/3y1mLDQuyiZ4aJkiIuSIS5KhrgoGdJX4EByf5urbDqOvgKi+H/0aYMo2r/FeLjVBjmoooTiPKdNouyfJMarZSpLtZo6GJTPVJgZLobjOI7jOI7jOI7jOI5DmfkX7DDiNxG+tpEAAAAASUVORK5CYII="
                                />
                            </defs>
                        </svg>
                    </div>
                    <div class="w-[200px] flex items-center justify-center">
                        <span v-if="onlyPossessedAsset === true">
                            <img src="/asset/img/Eye.svg" />
                        </span>
                        <span v-else>
                            <img src="/asset/img/Hide.svg" />
                        </span>
                        <span class="ml-2" @click.prevent="changePossessedOnly">
                            <p
                                class="clickable underline"
                                :class="
                                    onlyPossessedAsset === true ? 'text-sub_color1' : 'text-gray'
                                "
                            >
                                보유작품만 보기
                            </p>
                        </span>
                    </div>
                </div>

                <!-- 보유 작품 List -->
                <div v-if="dataLoading" class="ms3 w-full">
                    <table class="w-full">
                        <colgroup>
                            <col style="width: 30%" />
                            <col style="width: 30%" />
                            <col style="width: 23%" />
                            <col style="width: 17%" />
                        </colgroup>
                        <thead
                            class="h-[26px] bg-ex_RightA_label text-[#848484] contents_secondary"
                        >
                            <tr>
                                <th class="text-left pl-5">작품명</th>
                                <th class="text-right pr-5">보유량</th>
                                <th class="text-left pl-16">보유비중</th>
                                <th class="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in reArangedNftList"
                                v-bind:key="index"
                                class="asset_item border-b max-h-[50px] leading-4"
                                :class="$route.params.symbol === item.symbol ? 'active' : ''"
                                @click.prevent="selectThisSymbol(item, 1)"
                            >
                                <!-- 작품명 영역 -->
                                <td class="pl-3 flex h-[48px] pt-2">
                                    <!-- 이미지 미리보기 -->
                                    <div class="pt-1 mr-1">
                                        <img
                                            v-if="userStore.memberAllMarketToken !== null"
                                            :src="item?.image"
                                            class="w-[30px] h-[30px]"
                                        />
                                        <img
                                            v-else
                                            src="/asset/img/text1.svg"
                                            class="w-[30px] h-[30px]"
                                        />
                                    </div>
                                    <!-- 작품명 및 마켓 -->
                                    <div>
                                        <p
                                            class="asset_name text-[13px]"
                                            :class="
                                                $route.params.symbol === item.symbol
                                                    ? 'activeTitle text-noto-700'
                                                    : ''
                                            "
                                        >
                                            {{ item?.name }}
                                        </p>
                                        <p
                                            class="contents_secondary"
                                            :class="
                                                $route.params.symbol === item.symbol
                                                    ? 'text-main_color'
                                                    : 'text-black'
                                            "
                                        >
                                            {{ item?.symbol }}
                                        </p>
                                    </div>
                                </td>
                                <!-- 보유량 영역 -->
                                <td class="text-right pr-5 max-h-[46px]">
                                    <div class="flex flex-col">
                                        <span class="leading-3 mb-1">
                                            <span class="text-[14px] pr-1 pa-0"
                                                >{{
                                                    Math.floor(
                                                        item?.current_balance
                                                    )?.toLocaleString() || 0
                                                }}
                                            </span>
                                            <span class="contents_secondary text-gray">
                                                {{ item?.symbol }}
                                            </span>
                                        </span>
                                        <!-- 환산 금액 영역 -->
                                        <!-- TODO exchangedCurrentBalance 로 교체 예정 -->
                                        <span class="text-[10px] text-gray pa-0 leading-3">
                                            {{
                                                `≈ ${
                                                    item?.exchanged_current_balance?.toLocaleString(
                                                        'ko-KR',
                                                        {
                                                            maximumFractionDigits: 0,
                                                            minimumFractionDigits: 0
                                                        }
                                                    ) || 0
                                                }`
                                            }}
                                            {{ defaultMarket }}
                                        </span>
                                    </div>
                                </td>
                                <!-- 보유비중 영역 -->
                                <td>
                                    <span class="contents_secondary flex justify-end pr-8">
                                        {{ item?.percent?.toFixed(2) || 0 }}%
                                    </span>
                                </td>
                                <td class="w-[90px] border-l">
                                    <span
                                        v-if="
                                            $route.params.type === 'deposit' || !$route.params.type
                                        "
                                        @click.prevent
                                    >
                                        <span
                                            v-if="
                                                isDepositPossibleItem(
                                                    item.state,
                                                    $route.params.type
                                                )
                                            "
                                            class="'clickable flex justify-center contents_secondary items-center text-main_color"
                                        >
                                            입금
                                            <span class="translate-x-3">
                                                <svg
                                                    width="6"
                                                    height="8"
                                                    viewBox="0 0 6 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L5 4L1 7"
                                                        stroke="#305C3A"
                                                        stroke-width="0.65"
                                                    />
                                                </svg>
                                            </span>
                                        </span>
                                        <span
                                            v-else
                                            class="'clickable flex justify-center contents_secondary items-center text-market_rise"
                                        >
                                            입금 중지
                                            <span class="translate-x-3">
                                                <svg
                                                    width="6"
                                                    height="8"
                                                    viewBox="0 0 6 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L5 4L1 7"
                                                        stroke="#CE271D"
                                                        stroke-width="0.65"
                                                    />
                                                </svg>
                                            </span>
                                        </span>
                                    </span>
                                    <span
                                        v-else-if="$route.params.type === 'withdraw'"
                                        @click.prevent
                                    >
                                        <span
                                            v-if="
                                                isDepositPossibleItem(
                                                    item.state,
                                                    $route.params.type
                                                )
                                            "
                                            class="clickable flex justify-center contents_secondary items-center text-main_color"
                                        >
                                            출금
                                            <span class="translate-x-3">
                                                <svg
                                                    width="6"
                                                    height="8"
                                                    viewBox="0 0 6 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L5 4L1 7"
                                                        stroke="#305C3A"
                                                        stroke-width="0.65"
                                                    />
                                                </svg>
                                            </span>
                                        </span>
                                        <span
                                            v-else
                                            class="'clickable flex justify-center contents_secondary items-center text-market_rise"
                                        >
                                            출금 중지
                                            <span class="translate-x-3">
                                                <svg
                                                    width="6"
                                                    height="8"
                                                    viewBox="0 0 6 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L5 4L1 7"
                                                        stroke="#CE271D"
                                                        stroke-width="0.65"
                                                    />
                                                </svg>
                                            </span>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </article>

            <!-- 우측 영역 -->
            <article class="ml-5 w-[400px]">
                <section v-if="dataLoading" class="bg-[#fff] basicBoxShadow">
                    <div class="flex justify-between h-[66px] items-center px-4 border-b">
                        <span class="flex items-center">
                            <img
                                v-if="dataLoading"
                                :src="activeItem?.image || tokenList[0]?.image"
                                class="w-[30px] h-[30px]"
                            />
                            <img v-else src="/asset/img/text1.svg" class="w-[30px] h-[30px]" />
                            <div class="ml-3">
                                <p class="text-[13px]">
                                    {{ activeItem?.name || tokenList[0]?.name }}
                                </p>
                                <p class="contents_secondary">
                                    {{ activeItem?.symbol || defaultMarket }}
                                </p>
                            </div>
                        </span>
                        <div
                            v-if="$route.params.type === 'deposit' || !$route.params.type"
                            class="__lang text-[13px] text-noto-700"
                        >
                            입금
                        </div>
                        <div
                            v-else-if="$route.params.type === 'withdraw'"
                            class="__lang text-[13px] text-noto-700"
                        >
                            출금
                        </div>
                        <div v-else>입금</div>
                    </div>
                    <!-- default 보유량 영역 -->
                    <div
                        v-if="isDepositPossibleItem(activeItem.state, $route.params.type)"
                        class="flex justify-between items-center border-b h-[69px]"
                    >
                        <div
                            class="__lang w-[82px] h-[69px] border-r flex items-center justify-center font-semibold"
                        >
                            보유량
                        </div>
                        <div class="flex flex-col items-end pr-4">
                            <div class="w-full flex items-center h-[32px] justify-end">
                                <h1 class="text-[22px]">
                                    {{
                                        Math.floor(activeItem?.current_balance)?.toLocaleString(
                                            'ko-KR',
                                            { maximumFractionDigits: 0, minimumFractionDigits: 0 }
                                        ) ||
                                        tokenList[0]?.current_balance?.toLocaleString('ko-KR', {
                                            maximumFractionDigits: 0,
                                            minimumFractionDigits: 0
                                        })
                                    }}
                                </h1>
                                <small class="flex items-end h-[50px] pb-3 ml-1 text-gray">
                                    {{ activeItem?.symbol || defaultMarket }}
                                </small>
                            </div>
                            <!-- 환산 금액 영역 -->
                            <span
                                v-if="activeItem.symbol === defaultMarket"
                                class="contents_secondary pa-0 leading-3"
                            >
                            </span>
                            <span v-else class="contents_secondary pa-0 leading-3">
                                {{
                                    `≈ ${
                                        activeItem.exchanged_current_balance
                                            ? Math.floor(
                                                  Number(activeItem?.exchanged_current_balance)
                                              ).toLocaleString('ko-KR', {
                                                  maximumFractionDigits: 0,
                                                  minimumFractionDigits: 0
                                              })
                                            : 0
                                    }`
                                }}
                                {{ defaultMarket }}
                            </span>
                        </div>
                    </div>

                    <!-- ======================================================= 입금탭 ================================================ -->
                    <!-- 입금탭, 자산일 때 default로 나타날 영역 -->
                    <div
                        v-if="
                            (Number($route.params.isAsset) === 0 &&
                                $route.params.type === 'deposit' &&
                                isDepositPossibleItem(activeItem.state, $route.params.type)) ||
                            (!$route.params.isAsset &&
                                $route.params.type === 'deposit' &&
                                isDepositPossibleItem(activeItem.state, $route.params.type))
                        "
                    >
                        <div
                            v-if="
                                isUserHaveWalletAddress === true && isUserDid2ChannelAuth === true
                            "
                        >
                            <!-- 입금 step 1 -->
                            <div v-if="depositProcess === 'ready'" class="pa-4">
                                <p class="contents_primary mb-[10px]">
                                    외부 지갑에 보유중인 {{ whatAssetCanDepositWithdraw }}를 입금할
                                    수 있습니다. 아래의 버튼을 눌러 입금 주소를 확인해주세요.
                                </p>
                                <div class="mb-[40px]">
                                    <button
                                        class="default_btn btn_main_green"
                                        @click.prevent="changeAssetDialogTrue"
                                    >
                                        입금 자산 선택
                                    </button>
                                </div>
                            </div>
                            <!-- 입급 주소 체크 -->
                            <div
                                v-else-if="depositProcess !== 'ready' && !isThisItemHaveAddress"
                                class="pa-4"
                            >
                                <div class="mb-[35px]">
                                    <p class="contents_primary mb-[10px]">
                                        외부 지갑에 보유중인
                                        {{
                                            `${unitName || tokenList[0]?.name}(${
                                                unitSymbol || defaultMarket
                                            })`
                                        }}을(를) 입금하기 위해서는 주소가 필요합니다. 아래의 버튼을
                                        눌러 주소를 발급받아주세요.
                                    </p>
                                    <button
                                        class="btn_main_green default_btn"
                                        @click.prevent="prepareUserJigapAddressModalForVirtual()"
                                    >
                                        입금 주소 발급
                                    </button>
                                </div>
                            </div>
                            <!-- 입금 step 2 -->
                            <div v-else-if="depositProcess === 'prepare'">
                                <p class="text-red contents_primary pa-3">
                                    입금 주소를 정확하게 입력해야 정상적으로 입금됩니다.
                                </p>
                                <br />
                                <div
                                    class="flex justify-between items-start px-4 mb-3 contents_primary overflow-visible"
                                >
                                    <div class="flex items-center relative">
                                        <label class="pt-1">네트워크</label>
                                        <img
                                            class="ml-1 mt-[3px]"
                                            src="/asset/img/exclamationIcon.svg"
                                            @mouseover="showNetworkTooltip = true"
                                            @mouseleave="showNetworkTooltip = false"
                                        />
                                        <div
                                            v-if="showNetworkTooltip"
                                            class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px]"
                                        >
                                            <p>작품별 입금할 수 있는 네트워크를 표시합니다.</p>
                                            <p>
                                                입금할 때 발급받은 주소의 네트워크와 동일한
                                                네트워크를 선택한 후 출금해주세요.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <input
                                            class="ml-3 w-[278px] h-[33px] bg-ex_RightA_label px-4"
                                            :value="n_network"
                                            readonly
                                        />
                                        <!-- random qr generator -->
                                        <div id="qrcode">
                                            <qrcode-vue :value="n_address" :size="size" level="H" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex justify-between items-center px-4 contents_primary"
                                >
                                    <label>나의주소</label>
                                    <div class="w-[290px] flex">
                                        <input
                                            class="ml-3 w-[222px] h-[33px] bg-[#fff] border px-4"
                                            :value="n_address"
                                            readonly
                                        />
                                        <div
                                            class="clickable w-[55px] h-[33px] sub_border1 contents_primary flex items-center justify-center"
                                            @click="copyBackUpCodeToClipboard(unitName)"
                                        >
                                            복사
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="text-right contents_primary text-market_rise mt-2 mb-4 pr-4"
                                >
                                    <p>
                                        최소 입금금액은 {{ inMinLimitForItem || 0 }}
                                        <span v-if="isUseFiatCurrency === 1">{{
                                            activeItem?.symbol || defaultMarket || 'NFT'
                                        }}</span>
                                        <span v-else>{{
                                            route.name === 'BalanceTypeWithSymbol'
                                                ? activeItem?.symbol
                                                : unitSymbol || 'NFT'
                                        }}</span>
                                        입니다.
                                    </p>
                                </div>
                                <div class="flex justify-between items-center px-4 pb-[35px]">
                                    <button
                                        class="default_btn sub_border1"
                                        @click.prevent="prepareReSelectAsset"
                                    >
                                        다시 선택
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 -->
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === true && isUserDid2ChannelAuth === false
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                안전한 출금 신청을 위해 2채널 인증을 사용합니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 2채널 인증에서 연결 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="
                                        $router.push({ path: '/mypage/securitySetting' })
                                    "
                                >
                                    2채널 인증 연결
                                </button>
                            </div>
                        </div>
                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 끝 -->

                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 -->
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === false && isUserDid2ChannelAuth === false
                            "
                        >
                            <div class="pa-4">
                                <p class="contents_primary">
                                    입금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                                </p>
                                <p class="contents_primary mb-[10px]">
                                    마이페이지의 나의지갑에서 등록 후 진행해주세요.
                                </p>
                                <div class="mb-[40px]">
                                    <button
                                        class="default_btn btn_main_green"
                                        @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                    >
                                        메타마스크 등록
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 끝 -->

                        <!-- 지갑이 등록되지 않았을 때 나타날 영역 -->
                        <div v-else>
                            <div class="pa-4">
                                <p class="contents_primary">
                                    입금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                                </p>
                                <p class="contents_primary mb-[10px]">
                                    마이페이지의 나의지갑에서 등록 후 진행해주세요.
                                </p>
                                <div class="mb-[40px]">
                                    <button
                                        class="default_btn btn_main_green"
                                        @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                    >
                                        메타마스크 등록
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 입금탭, 자산일 때 default로 나타날 영역 끝 -->

                    <!-- 입금탭, 작품일 때 default로 나타날 영역 -->
                    <div
                        v-else-if="
                            $route.params.type === 'deposit' &&
                            Number($route.params.isAsset) === 1 &&
                            isDepositPossibleItem(activeItem.state, $route.params.type)
                        "
                    >
                        <div
                            v-if="
                                isUserHaveWalletAddress === false && isUserDid2ChannelAuth === false
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                입금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 나의지갑에서 등록 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                >
                                    메타마스크 등록
                                </button>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === true && isUserDid2ChannelAuth === false
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                안전한 출금 신청을 위해 2채널 인증을 사용합니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 2채널 인증에서 연결 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="
                                        $router.push({ path: '/mypage/securitySetting' })
                                    "
                                >
                                    2채널 인증 연결
                                </button>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === false && isUserDid2ChannelAuth === true
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                입금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 나의지갑에서 등록 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                >
                                    메타마스크 등록
                                </button>
                            </div>
                        </div>
                        <div v-else-if="!isThisItemHaveAddress" class="pa-4">
                            <div class="mb-[35px]">
                                <p class="contents_primary mb-[10px]">
                                    외부 지갑에 보유중인
                                    {{
                                        `${activeItem?.name || tokenList[0]?.name}(${
                                            activeItem?.symbol || defaultMarket
                                        })`
                                    }}을(를) 입금하기 위해서는 주소가 필요합니다. 아래의 버튼을 눌러
                                    주소를 발급받아주세요.
                                </p>
                                <button
                                    class="btn_main_green default_btn"
                                    @click.prevent="prepareUserJigapAddressModal()"
                                >
                                    입금 주소 발급
                                </button>
                            </div>
                        </div>
                        <div v-else class="pb-5">
                            <p class="text-red contents_primary pa-3">
                                입금 주소를 정확하게 입력해야 정상적으로 입금됩니다.
                            </p>
                            <br />
                            <div
                                class="flex justify-between items-start px-4 mb-3 contents_primary"
                            >
                                <div class="flex items-center">
                                    <label class="pt-1">네트워크</label>
                                    <img
                                        class="ml-1 mt-[3px]"
                                        src="/asset/img/exclamationIcon.svg"
                                    />
                                </div>
                                <div>
                                    <input
                                        class="ml-3 w-[278px] h-[33px] bg-ex_RightA_label px-4"
                                        :value="n_network"
                                        readonly
                                    />
                                    <!-- random qr generator -->
                                    <div id="qrcode">
                                        <qrcode-vue :value="n_address" :size="size" level="H" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between items-center px-4 contents_primary">
                                <label>나의주소</label>
                                <div class="w-[290px] flex">
                                    <input
                                        class="ml-3 w-[222px] h-[33px] bg-[#fff] border px-4"
                                        :value="n_address"
                                        readonly
                                    />
                                    <div
                                        class="clickable w-[55px] h-[33px] sub_border1 contents_primary flex items-center justify-center"
                                        @click="copyBackUpCodeToClipboard(activeItem.name)"
                                    >
                                        복사
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-right contents_primary text-market_rise mt-2 mb-4 pr-4"
                            >
                                <p>
                                    최소 입금금액은 {{ inMinLimitForItem || 0 }}
                                    {{ activeItem?.symbol || defaultMarket || 'NFT' }} 입니다.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 입금탭, 작품일 때 default로 나타날 영역 끝 -->

                    <!-- ======================================================= 출금탭 ================================================ -->
                    <!-- 출금탭, 자산일 때 default로 나타날 영역 -->
                    <div
                        v-else-if="
                            (Number($route.params.isAsset) === 0 &&
                                $route.params.type === 'withdraw' &&
                                isDepositPossibleItem(activeItem.state, $route.params.type)) ||
                            (!$route.params.isAsset &&
                                $route.params.type === 'withdraw' &&
                                isDepositPossibleItem(activeItem.state, $route.params.type))
                        "
                    >
                        <!-- 유저의 지갑이 존재하며 2채널 인증을 완료하였을 때 나타나는 영역 -->
                        <div
                            v-if="
                                isUserHaveWalletAddress === true && isUserDid2ChannelAuth === true
                            "
                        >
                            <div v-if="withdrawProcess === 'ready'" class="pa-4">
                                <p class="contents_primary mb-[10px]">
                                    보유하신 원화 포인트를 {{ whatAssetCanDepositWithdraw }}로
                                    출금할 수 있습니다. 아래의 버튼을 눌러 출금 자산을 선택해주세요.
                                </p>
                                <div class="mb-[40px]">
                                    <button
                                        class="default_btn btn_main_green"
                                        @click.prevent="changeAssetDialogTrue"
                                    >
                                        출금 자산 선택
                                    </button>
                                </div>
                            </div>
                            <!-- 출금 관련 버튼 누르기 전에 default 영역 끝-->

                            <!-- 자산의 출금 데이터 관련 컴포넌트 -->
                            <div v-else-if="withdrawProcess === 'prepare'">
                                <div class="flex justify-between items-center pa-4">
                                    <button
                                        class="default_btn sub_border1"
                                        @click.prevent="prepareReSelectAsset"
                                    >
                                        다시 선택
                                    </button>
                                </div>
                                <div class="contents_primary">
                                    <ul class="">
                                        <li
                                            class="flex justify-between items-center px-3 mb-2 pt-1"
                                        >
                                            <label class="__lang">출금량</label>
                                            <span>
                                                <!-- input, 가상자산 출금액 바인딩 ------------------------------------------------------------------>
                                                <input
                                                    :value="displayCryptoAmount"
                                                    @input="handleOnCryptoAmountInput"
                                                    class="sub_border1 w-[278px] h-[33px] text-right px-3"
                                                    type="text"
                                                    :placeholder="`최소 ${outMinLimitForItem.toLocaleString()} ${
                                                        activeItem?.symbol || 'NFT'
                                                    }`"
                                                    @keydown="handleKeydown"
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                    <div class="text-right pr-4 text-market_rise min-h-[16px]">
                                        {{ validationMessageForOutAmountCrypto }}
                                    </div>
                                    <br />
                                </div>

                                <!-- 가운데 구분 선 -->
                                <div class="border-t px-3 w-[366px] mx-auto"></div>

                                <!-- 입금, 출금 한도 영역 -->
                                <div>
                                    <br />
                                    <ul class="contents_primary">
                                        <!-- <li class="flex justify-between px-4 mb-2">
                                            <div class="relative flex items-center">
                                                <label class="__lang">출금방식</label>
                                                <img
                                                    class="ml-1 mt-[2px]"
                                                    src="/asset/img/exclamationIcon.svg"
                                                    @mouseover="showOutMethodExplainTooltip = true"
                                                    @mouseleave="
                                                        showOutMethodExplainTooltip = false
                                                    "
                                                />
                                                <div
                                                    v-if="showOutMethodExplainTooltip"
                                                    class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                                >
                                                    <p>
                                                        외부출금은 블록체인을 이용하여 회원님의
                                                        개인지갑으로 출금하는 방식이며 송금 내역이
                                                        블록체인에 기록됩니다.
                                                    </p>
                                                    <br />
                                                    <p>
                                                        내부출금은 블록체인을 이용하지 않는
                                                        출금방식이며 송금 내역이 블록체인에 기록되지
                                                        않습니다.
                                                    </p>
                                                    <br />
                                                    <p>
                                                        내부출금은 블록체인을 이용하지 않아 수수료가
                                                        무료입니다. 또한 카키 계정 간 송금 시에만
                                                        이용할 수 있습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="flex items-center text-[14px] text-noto-300">
                                                <input type="radio" value="0" v-model="selectedOption"/>
                                                <span class="ml-[6px] mr-[16px]">외부출금</span>
                                                <input type="radio" value="1" v-model="selectedOption"/>
                                                <span class="ml-[6px]">내부출금</span>
                                            </div>
                                        </li> -->
                                        <li class="flex justify-between px-4 mb-2">
                                            <div class="relative flex items-center">
                                                <label class="__lang">출금한도</label>
                                                <img
                                                    class="ml-1 mt-[2px]"
                                                    src="/asset/img/exclamationIcon.svg"
                                                    @mouseover="showOutLimitTooltip = true"
                                                    @mouseleave="showOutLimitTooltip = false"
                                                />
                                                <div
                                                    v-if="showOutLimitTooltip"
                                                    class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                                >
                                                    <p>
                                                        출금한도는 마지막 출금시점을 기준으로 24시간
                                                        뒤에 초기화됩니다.
                                                    </p>
                                                    <p>
                                                        현재 출금한도 = 24시간 출금한도 - (출금 대기
                                                        금액의 합 + 최근 24시간 내 출금한 자산의
                                                        원화 환산금액)
                                                    </p>
                                                </div>
                                            </div>
                                            <span>
                                                {{
                                                    outMaxLimitForItem.toLocaleString('ko-KR', {
                                                        maximumFractionDigits: 8,
                                                        minimumFractionDigits: 0
                                                    }) || 0
                                                }}
                                                <small class="ml-1 text-gray">{{
                                                    defaultMarket || ''
                                                }}</small>
                                            </span>
                                        </li>
                                        <li class="flex justify-between px-4 mb-1">
                                            <div class="relative flex items-center">
                                                <label class="__lang">출금가능</label>
                                                <img
                                                    class="ml-1 mt-[2px]"
                                                    src="/asset/img/exclamationIcon.svg"
                                                    @mouseover="showOutAvailableTooltip = true"
                                                    @mouseleave="showOutAvailableTooltip = false"
                                                />
                                                <div
                                                    v-if="showOutAvailableTooltip"
                                                    class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                                >
                                                    <p>
                                                        출금가능 금액은 미체결 주문과 출금 대기 금액
                                                        및 출금 수수료를 제외한 금액입니다.
                                                    </p>
                                                    <p>
                                                        출금 수수료는 선택된 네트워크에 따라 변동될
                                                        수 있습니다.
                                                    </p>
                                                </div>
                                            </div>
                                            <span>
                                                {{ availableOutData || 0 }}
                                                <small
                                                    v-if="availableOutData"
                                                    class="ml-1 text-gray"
                                                    >{{ defaultMarket || '' }}</small
                                                >
                                            </span>
                                        </li>
                                    </ul>
                                    <br />
                                </div>

                                <!-- 가운데 구분 선 -->
                                <div class="border-t px-3 w-[366px] mx-auto"></div>

                                <!-- 수수료 총출금금액 -->
                                <div class="pb-3">
                                    <br />
                                    <ul class="contents_primary">
                                        <li class="flex justify-between px-4 mb-2">
                                            <label class="__lang">수수료</label>
                                            <span
                                                :class="
                                                    validationMessageForOutFee !== ''
                                                        ? 'text-market_rise'
                                                        : ''
                                                "
                                            >
                                                {{ computedFeeData || 0 }}
                                                {{ validationMessageForOutFee }}
                                                <small
                                                    v-if="isUseFiatCurrency === 1"
                                                    class="ml-1 text-gray"
                                                    >{{ defaultMarket || '' }}</small
                                                >
                                                <small v-else class="ml-1 text-gray">{{
                                                    defaultMarket || ''
                                                }}</small>
                                            </span>
                                        </li>
                                        <li class="flex justify-between px-4 mb-1">
                                            <label class="__lang">총 출금금액</label>
                                            <span class="text-main_color text-noto-500">
                                                {{
                                                    resultCryptoOut?.toLocaleString('ko-KR', {
                                                        maximumFractionDigits: 8,
                                                        minimumFractionDigits: 0
                                                    }) || 0
                                                }}
                                                <small class="ml-1 text-gray">{{
                                                    defaultMarket || ''
                                                }}</small>
                                            </span>
                                        </li>
                                        <li
                                            class="flex justify-end px-3 text-gray contents_secondary mb-2"
                                        >
                                            ≈
                                            {{
                                                resultExchangedCryptoOutAmount.toLocaleString(
                                                    'ko-KR',
                                                    {
                                                        maximumFractionDigits: 8,
                                                        minimumFractionDigits: 0
                                                    }
                                                )
                                            }}{{ ` ${unitSymbol}` }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- 자산의 출금 데이터 관련 컴포넌트 끝 -->

                            <!-- 출금 > 자산에서 "출금 자산 선택" 버튼 클릭시 보여질 영역 -->
                            <div v-else-if="withdrawProcess === 'before2ChannelAuth'">
                                <!-- 출금, 환급 신청 버튼 -->
                                <div
                                    class="flex items-center w-full px-4 mb-1 justify-between h-[83px] combobox-area"
                                >
                                    <label class="contents_primary">출금주소</label>
                                    <!-- ComboBox------------------------------------------------------------------------------>
                                    <div>
                                        <div
                                            class="sub_border1 w-[278px] h-[50px] contents_primary px-3 py-2"
                                        >
                                            <div class="whitespace-break-spaces w-[248px]">
                                                {{ userWallet || '' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="!isUserHaveWalletAddress === false"
                                    class="contents_primary text-left w-full pb-3"
                                >
                                    <div class="flex px-4 mb-1" @click="toggleImgSelected(0)">
                                        <img
                                            :src="
                                                checkedState[0]
                                                    ? `/asset/img/OkDoneType1.svg`
                                                    : `/asset/img/OkType1.svg`
                                            "
                                            style="width: 16px; height: 16px"
                                            class="clickable mr-2"
                                        />
                                        <span>출금주소를 확인하였습니다.</span>
                                    </div>
                                    <div class="flex px-4" @click="toggleImgSelected(1)">
                                        <img
                                            :src="
                                                checkedState[1]
                                                    ? `/asset/img/OkDoneType1.svg`
                                                    : `/asset/img/OkType1.svg`
                                            "
                                            style="width: 16px; height: 16px"
                                            class="clickable mr-2"
                                        />
                                        <span
                                            >아래 {{ unitSymbol }} 출금 안내 및 주의사항을 숙지하고
                                            출금에 동의합니다.</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- 출금 > 자산에서 "출금 자산 선택" 버튼 클릭시 보여질 영역 끝 -->
                        </div>
                        <!-- 유저의 지갑이 존재하며 2채널 인증을 완료하였을 때 나타나는 영역 끝-->

                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 -->
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === true && isUserDid2ChannelAuth === false
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                안전한 출금 신청을 위해 2채널 인증을 사용합니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 2채널 인증에서 연결 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="
                                        $router.push({ path: '/mypage/securitySetting' })
                                    "
                                >
                                    2채널 인증 연결
                                </button>
                            </div>
                        </div>
                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 끝 -->

                        <!-- 유저의 지갑이 존재하지 않으며 2채널 인증을 완료하였을 때 나타나는 영역 -->
                        <div
                            v-else-if="
                                isUserHaveWalletAddress === false && isUserDid2ChannelAuth === true
                            "
                            class="pa-4"
                        >
                            <p class="contents_primary">
                                출금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 나의지갑에서 등록 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                >
                                    메타마스크 등록
                                </button>
                            </div>
                        </div>
                        <!-- 유저의 지갑이 존재하지 않으며 2채널 인증을 완료하였을 때 나타나는 영역 끝 -->

                        <!-- 2채널 인증, 메타마스크 등록이 전부 완료되지 않았을 때 나타나는 영역 -->
                        <div v-else class="pa-4">
                            <p class="contents_primary">
                                출금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다.
                            </p>
                            <p class="contents_primary mb-[10px]">
                                마이페이지의 나의지갑에서 등록 후 진행해주세요.
                            </p>
                            <div class="mb-[40px]">
                                <button
                                    class="default_btn btn_main_green"
                                    @click.prevent="$router.push({ path: '/mypage/wallet' })"
                                >
                                    메타마스크 등록
                                </button>
                            </div>
                        </div>
                        <!-- 2채널 인증이 완료되지 않았을 때 나타나는 영역 끝 -->
                    </div>
                    <!-- 출금탭, 자산일 때 default로 나타날 영역 끝 -->

                    <!-- 출금탭, 작품일 때 default로 나타날 영역 -->
                    <div
                        v-else-if="
                            $route.params.type === 'withdraw' &&
                            Number($route.params.isAsset) === 1 &&
                            isUserHaveWalletAddress === false &&
                            isDepositPossibleItem(activeItem.state, $route.params.type)
                        "
                        class="pa-4"
                    >
                        <div class="mb-[44px]">
                            <p class="contents_primary mb-[10px]">
                                출금은 회원님이 등록하신 메타마스크에서만 할 수 있습니다. 마이페이지
                                나의지갑에서 등록 후 진행해주세요.
                            </p>
                            <button
                                class="btn_main_green default_btn"
                                @click.prevent="$router.push({ path: '/mypage/wallet' })"
                            >
                                메타마스크 등록
                            </button>
                        </div>
                    </div>

                    <div
                        v-else-if="
                            $route.params.type === 'withdraw' &&
                            Number($route.params.isAsset) === 1 &&
                            isUserDid2ChannelAuth === false &&
                            isDepositPossibleItem(activeItem.state, $route.params.type)
                        "
                        class="pa-4"
                    >
                        <p class="contents_primary">
                            안전한 출금 신청을 위해 2채널 인증을 사용합니다.
                        </p>
                        <p class="contents_primary mb-[10px]">
                            마이페이지의 2채널 인증에서 연결 후 진행해주세요.
                        </p>
                        <div class="mb-[40px]">
                            <button
                                class="default_btn btn_main_green"
                                @click.prevent="$router.push({ path: '/mypage/securitySetting' })"
                            >
                                2채널 인증 연결
                            </button>
                        </div>
                    </div>

                    <div
                        v-else-if="
                            $route.params.type === 'withdraw' &&
                            Number($route.params.isAsset) === 1 &&
                            withdrawProcess === 'prepare' &&
                            isDepositPossibleItem(activeItem.state, $route.params.type)
                        "
                    >
                        <div class="contents_primary">
                            <ul class="mt-5">
                                <li class="flex justify-between items-center px-3 mb-2 pt-2">
                                    <label class="__lang">출금량</label>
                                    <span>
                                        <!-- input, KRW 출금액 바인딩 ------------------------------------------------------------------>
                                        <input
                                            :value="displayNftAmount"
                                            @input="handleOnNftAmountInput"
                                            class="sub_border1 w-[278px] h-[33px] text-right px-3"
                                            type="text"
                                            :placeholder="`최소 ${outMinLimitForItem.toLocaleString()} ${
                                                activeItem?.symbol || 'NFT'
                                            }`"
                                            @keydown="handleKeydown"
                                        />
                                    </span>
                                </li>
                            </ul>
                            <div class="text-right pr-4 text-market_rise min-h-[18px]">
                                {{ validationMessageForOutAmountNFT }}
                            </div>
                            <br />
                        </div>

                        <!-- 가운데 구분 선 -->
                        <div class="border-t px-3 w-[366px] mx-auto"></div>

                        <!-- 입금, 출금 한도 영역 -->
                        <div>
                            <br />
                            <ul class="contents_primary">
                                <li class="flex justify-between px-4 mb-2">
                                    <div class="relative flex items-center">
                                        <label class="__lang">출금방식</label>
                                        <img
                                            class="ml-1 mt-[2px]"
                                            src="/asset/img/exclamationIcon.svg"
                                            @mouseover="showOutMethodExplainTooltip = true"
                                            @mouseleave="showOutMethodExplainTooltip = false"
                                        />
                                        <div
                                            v-if="showOutMethodExplainTooltip"
                                            class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                        >
                                            <p>
                                                외부출금은 블록체인을 이용하여 회원님의 개인지갑으로
                                                출금하는 방식이며 송금 내역이 블록체인에 기록됩니다.
                                            </p>
                                            <br />
                                            <p>
                                                내부출금은 블록체인을 이용하지 않는 출금방식이며
                                                송금 내역이 블록체인에 기록되지 않습니다.
                                            </p>
                                            <br />
                                            <p>
                                                내부출금은 블록체인을 이용하지 않아 수수료가
                                                무료입니다. 또한 카키 계정 간 송금 시에만 이용할 수
                                                있습니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex items-center text-[14px] text-noto-300">
                                        <input type="radio" value="0" v-model="selectedOption" />
                                        <span class="ml-[6px] mr-[16px]">외부출금</span>
                                        <input type="radio" value="1" v-model="selectedOption" />
                                        <span class="ml-[6px]">내부출금</span>
                                    </div>
                                </li>
                                <li class="flex justify-between px-4 mb-2">
                                    <div class="relative flex items-center">
                                        <label class="__lang">출금한도</label>
                                        <img
                                            class="ml-1 mt-[2px]"
                                            src="/asset/img/exclamationIcon.svg"
                                            @mouseover="showOutLimitTooltip = true"
                                            @mouseleave="showOutLimitTooltip = false"
                                        />
                                        <div
                                            v-if="showOutLimitTooltip"
                                            class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                        >
                                            <p>
                                                출금한도는 마지막 출금시점을 기준으로 24시간 뒤에
                                                초기화됩니다.
                                            </p>
                                            <p>
                                                현재 출금한도 = 24시간 출금한도 - (출금 대기 금액의
                                                합 + 최근 24시간 내 출금한 작품의 원화 환산금액)
                                            </p>
                                        </div>
                                    </div>
                                    <span>
                                        {{
                                            outMaxLimitForItem?.toLocaleString('ko-KR', {
                                                maximumFractionDigits: 8,
                                                minimumFractionDigits: 0
                                            }) || 0
                                        }}
                                        <small class="ml-1 text-gray">{{
                                            defaultMarket || ''
                                        }}</small>
                                    </span>
                                </li>
                                <li class="flex justify-between px-4 mb-1">
                                    <div class="relative flex items-center">
                                        <label class="__lang">출금가능</label>
                                        <img
                                            class="ml-1 mt-[2px]"
                                            src="/asset/img/exclamationIcon.svg"
                                            @mouseover="showOutAvailableTooltip = true"
                                            @mouseleave="showOutAvailableTooltip = false"
                                        />
                                        <div
                                            v-if="showOutAvailableTooltip"
                                            class="absolute bg-[#fff] basicBoxShadow w-[252px] left-[30px] top-6 px-[10px] py-[7px] z-10"
                                        >
                                            <p>
                                                출금가능 수량은 미체결 주문과 출금 대기 수량을
                                                제외한 금액입니다.
                                            </p>
                                            <p>
                                                출금 수수료는 선택된 네트워크에 따라 변동될 수
                                                있습니다.
                                            </p>
                                        </div>
                                    </div>
                                    <span>
                                        {{ availableOutData || 0 }}
                                        <small class="ml-1 text-gray">{{
                                            activeItem?.symbol || ''
                                        }}</small>
                                    </span>
                                </li>
                            </ul>
                            <br />
                        </div>

                        <!-- 가운데 구분 선 -->
                        <div class="border-t px-3 w-[366px] mx-auto"></div>

                        <!-- 수수료 총출금금액 -->
                        <div class="pb-3">
                            <br />
                            <ul class="contents_primary">
                                <li
                                    v-if="selectedOption === '0'"
                                    class="flex justify-between px-4 mb-2"
                                >
                                    <label class="__lang">수수료</label>
                                    <span
                                        :class="
                                            validationMessageForOutFee !== ''
                                                ? 'text-market_rise'
                                                : ''
                                        "
                                    >
                                        {{ computedFeeData || 0 }}
                                        {{ validationMessageForOutFeeNFT }}
                                        <small
                                            v-if="isUseFiatCurrency !== 1"
                                            class="ml-1 text-gray"
                                            >{{ defaultMarket || 'KRW' }}</small
                                        >
                                        <small v-else class="ml-1 text-gray">{{
                                            activeItem?.symbol
                                        }}</small>
                                    </span>
                                </li>
                                <li class="flex justify-between px-4 mb-1">
                                    <label class="__lang">총 출금금액</label>
                                    <span class="text-main_color text-noto-500">
                                        {{ resultNFTOut?.toLocaleString() || 0 }}
                                        <small class="ml-1 text-gray">{{
                                            activeItem?.symbol || ''
                                        }}</small>
                                        <span v-if="selectedOption === '0'">
                                            + {{ computedFeeData?.toLocaleString() || 0 }}
                                            <small class="ml-1 text-gray">{{
                                                selectedFeeType || 'KRW'
                                            }}</small>
                                        </span>
                                    </span>
                                </li>
                                <li class="flex justify-end px-3 text-gray contents_secondary mb-2">
                                    ≈
                                    {{
                                        resultExchangedNFTOutAmount.toLocaleString('ko-KR', {
                                            maximumFractionDigits: 8,
                                            minimumFractionDigits: 0
                                        })
                                    }}{{
                                        selectedOption === '0'
                                            ? ` ${defaultMarket}(수수료 포함)`
                                            : ` ${defaultMarket}`
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        v-else-if="
                            withdrawProcess === 'before2ChannelAuth' &&
                            isDepositPossibleItem(activeItem.state, $route.params.type)
                        "
                    >
                        <!-- 출금, 환급 신청 버튼 -->
                        <div
                            class="flex items-center w-full px-4 mb-1 justify-between h-[83px] combobox-area"
                        >
                            <label class="contents_primary">출금주소</label>
                            <!-- ComboBox------------------------------------------------------------------------------>
                            <div v-if="selectedOption === '0'">
                                <div
                                    class="sub_border1 w-[278px] h-[50px] contents_primary px-3 py-2"
                                >
                                    <div class="w-[248px] h-[50px] whitespace-break-spaces">
                                        {{ userWallet || '' }}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <textarea
                                    class="sub_border1 w-[278px] h-[50px] contents_primary px-3 py-2"
                                    v-model="innerWithdrawAddress"
                                >
                                </textarea>
                            </div>
                        </div>

                        <div
                            v-if="!isUserHaveWalletAddress === false"
                            class="contents_primary text-left w-full pb-3"
                        >
                            <div class="flex px-4 mb-1" @click="toggleImgSelected(0)">
                                <img
                                    :src="
                                        checkedState[0]
                                            ? `/asset/img/OkDoneType1.svg`
                                            : `/asset/img/OkType1.svg`
                                    "
                                    style="width: 16px; height: 16px"
                                    class="clickable mr-2"
                                />
                                <span>출금주소를 확인하였습니다.</span>
                            </div>
                            <div class="flex px-4" @click="toggleImgSelected(1)">
                                <img
                                    :src="
                                        checkedState[1]
                                            ? `/asset/img/OkDoneType1.svg`
                                            : `/asset/img/OkType1.svg`
                                    "
                                    style="width: 16px; height: 16px"
                                    class="clickable mr-2"
                                />
                                <span
                                    >아래 {{ activeItem.symbol }} 출금 안내 및 주의사항을 숙지하고
                                    출금에 동의합니다.</span
                                >
                            </div>
                        </div>
                    </div>
                    <!-- 출금탭, 작품일 때 default로 나타날 영역 끝 -->

                    <section class="">
                        <div
                            v-if="
                                route.params.type === 'withdraw' &&
                                withdrawProcess === 'prepare' &&
                                isUserHaveWalletAddress &&
                                isDepositPossibleItem(activeItem.state, $route.params.type)
                            "
                            class="flex flex-col items-center bg-[#fff] w-[400px]"
                        >
                            <div
                                v-if="
                                    !isUserHaveWalletAddress === false &&
                                    !isUserDid2ChannelAuth === false
                                "
                                class="mb-[35px]"
                            >
                                <!-- 가상자산의 출금 버튼 -->
                                <button
                                    v-if="!route.params.isAsset"
                                    class="default_btn"
                                    :class="[
                                        isAssetCanWithdraw
                                            ? 'btn_main_green'
                                            : 'bg-stroke text-white'
                                    ]"
                                    @click="prepareValidationFiatInfo"
                                    :disabled="!isAssetCanWithdraw"
                                >
                                    출금 신청
                                </button>
                                <!-- 작품의 출금 버튼 -->
                                <button
                                    v-else-if="route.params.isAsset === '1'"
                                    class="default_btn"
                                    :class="[
                                        isVirtualCanWithdraw
                                            ? 'btn_main_green'
                                            : 'bg-stroke text-white'
                                    ]"
                                    @click="prepareValidationVirtualInfo"
                                    :disabled="!isVirtualCanWithdraw"
                                >
                                    출금 신청
                                </button>
                                <button v-else class="default_btn btn_main_green" @click.prevent>
                                    출금 신청
                                </button>
                            </div>
                            <div v-if="isUserHaveWalletAddress === false" class="mt-5">
                                <RouterLink :to="`/mypage/wallet`" class="__lang">
                                    <button class="default_btn btn_main_green">
                                        메타마스크 등록
                                    </button>
                                </RouterLink>
                            </div>
                        </div>
                    </section>
                </section>

                <!-- 유의사항 영역, 상황에 따라서 붙었다가 떨어질 수 있습니다. -->
                <!-- 입금페이지 기본 유의사항 -->
                <section
                    v-if="
                        $route.params.type === 'deposit' &&
                        !$route.params.isAsset &&
                        !$route.params.symbol &&
                        depositProcess !== 'prepare' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            {{ whatAssetCanDepositWithdraw }}를 입금 시점의 원화 포인트 금액으로
                            환산하여 자산에 반영합니다. 원화 포인트는 원화와 1:1 비율을 가집니다.
                            거래의 편의성을 위해 보여주는 지표이며
                            <span class="text-market_rise">실제 원화를 입출금하지 않습니다.</span>
                        </li>
                        <li class="__lang">
                            잘못된 네트워크의 가상자산을 입금할 때 발생할 수 있는 오류/손실은 복구할
                            수 없습니다.
                        </li>
                        <li class="__lang">
                            자산별 최소 입금 금액과 confirmation 횟수가 다릅니다. 입금 자산을 선택한
                            후 해당 자산의 유의사항을 확인해주세요.
                        </li>
                        <li class="__lang">위 주소는 입금 전용 주소입니다.</li>
                    </ul>
                </section>

                <!-- 가상 자산의 입금 신청 유의사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'deposit' &&
                        $route.name === 'BalanceType' &&
                        depositProcess === 'prepare' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            위 주소는 {{ n_network || '' }} 네트워크의 가상자산만 입금할 수
                            있습니다. 해당 주소로 다른 네트워크의 가상자산을 입금할 때 발생할 수
                            있는 오류/손실은 복구할 수 없습니다.
                        </li>
                        <li class="__lang">
                            {{ confirmationCount || 0 }}번의 confirmation이 발생한 후, 입금
                            프로세스를 거쳐 잔고에 반영됩니다. 입금 프로세스는 자동으로 진행되며
                            네트워크 상황에 따라 소요 시간이 달라질 수 있습니다.
                        </li>
                        <li class="__lang">
                            {{ inMinLimitForItem || 0 }} {{ unitSymbol }} 이상 입금해야만 자산
                            목록에 반영됩니다. 최소 입금금액 미만 입금하면 자산 목록 반영 및 입금
                            취소할 수 없습니다.
                        </li>
                        <li class="__lang">위 주소는 입금 전용 주소입니다.</li>
                    </ul>
                </section>

                <!-- 작품의 입금 신청 유의사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'deposit' &&
                        $route.name === 'BalanceTypeWithSymbol' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            위 주소는 {{ n_network || '' }} 네트워크의 가상자산만 입금할 수
                            있습니다. 해당 주소로 다른 네트워크의 가상자산을 입금할 때 발생할 수
                            있는 오류/손실은 복구할 수 없습니다.
                        </li>
                        <li class="__lang">
                            {{ confirmationCount || 0 }}번의 confirmation이 발생한 후, 입금
                            프로세스를 거쳐 잔고에 반영됩니다. 입금 프로세스는 자동으로 진행되며
                            네트워크 상황에 따라 소요 시간이 달라질 수 있습니다.
                        </li>
                        <li class="__lang">
                            {{ inMinLimitForItem || 0 }} {{ activeItem.symbol }} 이상 입금해야만
                            자산 목록에 반영됩니다. 최소 입금금액 미만 입금하면 자산 목록 반영 및
                            입금 취소할 수 없습니다.
                        </li>
                        <li class="__lang">위 주소는 입금 전용 주소입니다.</li>
                        <li>
                            작품이 입금된 후 특정 작품을 선택하여 출금할 수 없습니다. 출금할 때는
                            수량만 지정할 수 있습니다. 특별히 소장하는 작품이 오입금되지 않게 입금
                            전 한 번 더 확인해주세요.
                        </li>
                    </ul>
                </section>
                <!-- 입급 유의사항 끝 -->

                <!-- 출금페이지 기본 유의사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'withdraw' &&
                        !$route.params.isAsset &&
                        !$route.params.symbol &&
                        withdrawProcess === 'ready' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            원화 포인트를 출금 시점의 {{ whatAssetCanDepositWithdraw }} 금액으로
                            환산하여 회원님의 개인 메타마스크 주소로 송금합니다. 원화 포인트는
                            원화와 1:1 비율을 가집니다. 거래의 편의성을 위해 보여주는 지표이며
                            <span class="text-market_rise">실제 원화를 입출금하지 않습니다.</span>
                        </li>
                        <li class="__lang">
                            등록된 메타마스크가 없으면 출금할 수 없습니다. 메타마스크 등록 후 출금을
                            진행해주세요.
                        </li>
                        <li class="__lang">
                            자산별 최소 출금 금액과 confirmation 횟수가 다릅니다. 출금 자산을 선택한
                            후 해당 자산의 유의사항을 확인해주세요.
                        </li>
                    </ul>
                </section>
                <!-- 출금페이지 기본 유의사항 유의사항 끝 -->

                <!-- 가상 자산의 출금 신청 유의사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'withdraw' &&
                        $route.name === 'BalanceType' &&
                        withdrawProcess === 'prepare' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            원화 포인트를 출금 시점의 {{ whatAssetCanDepositWithdraw }} 금액으로
                            환산하여 회원님의 개인 메타마스크 주소로 송금합니다. 원화 포인트는
                            원화와 1:1 비율을 가집니다. 거래의 편의성을 위해 보여주는 지표이며
                            <span class="text-market_rise">실제 원화를 입출금하지 않습니다.</span>
                        </li>
                        <li class="__lang">
                            등록된 메타마스크가 없으면 출금할 수 없습니다. 메타마스크 등록 후 출금을
                            진행해주세요.
                        </li>
                        <li class="__lang">
                            {{ confirmationCount || 0 }}번의 confirmation이 발생한 후, 출금
                            프로세스를 거쳐 잔고에 반영됩니다. 출금 프로세스는 자동으로 진행되며
                            네트워크 상황에 따라 소요 시간이 달라질 수 있습니다.
                        </li>
                    </ul>
                </section>

                <!-- 가상자산의 출금 신청 > 2채널 인증 전 유의 사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'withdraw' &&
                        $route.name === 'BalanceType' &&
                        withdrawProcess === 'before2ChannelAuth' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary"
                >
                    <ul class="list-disc px-4">
                        <li class="__lang">
                            가상자산의 특성상 출금 신청이 완료되면 취소할 수 없습니다. 보내기 전
                            주소와 수량을 꼭 확인해주세요.
                        </li>
                        <li class="__lang">
                            출금 신청 완료 후 송금 과정은 블록체인 네트워크에서 처리됩니다. 이
                            과정에서 발생할 수 있는 송금 지연 등의 문제는 자사 거래소에서 처리할 수
                            없습니다.
                        </li>
                        <li class="__lang">
                            출금은 자사 거래소의 핫월렛에서 진행되기 때문에 출금이 이루어지는 주소는
                            회원님의 출금 주소와 동일하지 않습니다.
                        </li>
                        <li class="__lang">
                            타인의 지시나 요청 등으로 본인 명의의 거래소 계정을 타인에게 대여하면
                            법적 처벌 대상이 될 수 있습니다.
                        </li>
                        <li class="__lang">
                            원화 포인트를 출금 시점의 {{ whatAssetCanDepositWithdraw }} 금액으로
                            환산하여 회원님의 개인 메타마스크 주소로 송금합니다. 원화 포인트는
                            원화와 1:1 비율을 가집니다. 거래의 편의성을 위해 보여주는 지표이며
                            <span class="text-market_rise">실제 원화를 입출금하지 않습니다.</span>
                        </li>
                        <li class="__lang">
                            {{ confirmationCount || 0 }}번의 confirmation이 발생한 후, 출금
                            프로세스를 거쳐 잔고에 반영됩니다. 출금 프로세스는 자동으로 진행되며
                            네트워크 상황에 따라 소요 시간이 달라질 수 있습니다.
                        </li>
                    </ul>
                </section>

                <!-- 작품의 출금 신청 유의사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'withdraw' &&
                        $route.name === 'BalanceTypeWithSymbol' &&
                        withdrawProcess === 'prepare' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary pb_override"
                >
                    <h1>유의사항</h1>
                    <ul class="list-disc pa-4">
                        <li class="__lang">
                            출금은 자사 거래소의 핫월렛에서 진행되기 때문에 출금이 이루어지는 주소는
                            회원님의 입금 주소와 동일하지 않습니다.
                        </li>
                        <li class="__lang">
                            등록된 메타마스크가 없으면 출금할 수 없습니다. 메타마스크 등록 후 출금을
                            진행해주세요.
                        </li>
                        <li class="__lang">
                            작품의 특정 이미지만 선택하여 출금할 수 없습니다. 출금할 때는 수량만
                            지정할 수 있습니다. 특정 이미지가 소진될 때까지 출금되며 특정 이미지가
                            소진되면 다음 순서의 이미지가 소진될 때까지 출금됩니다.
                        </li>
                    </ul>
                </section>
                <!-- 유의 사항 영역 끝 -->

                <!-- 작품의 출금 신청 > 2채널 인증 전 유의 사항 -->
                <section
                    v-else-if="
                        $route.params.type === 'withdraw' &&
                        $route.name === 'BalanceTypeWithSymbol' &&
                        withdrawProcess === 'before2ChannelAuth' &&
                        isDepositPossibleItem(activeItem.state, $route.params.type)
                    "
                    class="bg-ex_RightA_label pa-4 basicBoxShadow contents_primary"
                >
                    <ul class="list-disc px-4">
                        <li class="__lang">
                            가상자산의 특성상 출금 신청이 완료되면 취소할 수 없습니다. 보내기 전
                            주소와 수량을 꼭 확인해주세요.
                        </li>
                        <li class="__lang">
                            출금 신청 완료 후 송금 과정은 블록체인 네트워크에서 처리됩니다. 이
                            과정에서 발생할 수 있는 송금 지연 등의 문제는 자사 거래소에서 처리할 수
                            없습니다.
                        </li>
                        <li class="__lang">
                            출금은 자사 거래소의 핫월렛에서 진행되기 때문에 출금이 이루어지는 주소는
                            회원님의 입금 주소와 동일하지 않습니다.
                        </li>
                        <li class="__lang">
                            타인의 지시나 요청 등으로 본인 명의의 거래소 계정을 타인에게 대여하면
                            법적 처벌 대상이 될 수 있습니다.
                        </li>
                        <li class="__lang">
                            작품의 특정 이미지만 선택하여 출금할 수 없습니다. 출금할 때는 수량만
                            지정할 수 있습니다. 특정 이미지가 소진될 때까지 출금되며 특정 이미지가
                            소진되면 다음 순서의 이미지가 소진될 때까지 출금됩니다.
                        </li>
                    </ul>
                </section>

                <!-- 2채널 인증 버튼 영역 -->
                <div
                    v-if="withdrawProcess === 'before2ChannelAuth'"
                    class="basicBoxShadow bg-[#fff] w-[400px] h-[120px] flex justify-center items-center"
                >
                    <!-- 가상자산의 출금 버튼 -->
                    <button
                        v-if="allAgree && !route.params.isAsset"
                        class="default_btn btn_main_green"
                        @click.prevent="prepareFiatOutVirtual"
                    >
                        2채널 인증
                    </button>
                    <!-- 작품의 출금 버튼 -->
                    <button
                        v-else-if="allAgree && route.params.isAsset === '1'"
                        class="default_btn btn_main_green"
                        @click.prevent="prepareFiatOutNFT"
                    >
                        2채널 인증
                    </button>
                    <div
                        v-else
                        class="default_btn bg-stroke text-white flex justify-center items-center"
                    >
                        2채널 인증
                    </div>
                </div>
                <!--  -->

                <section
                    v-if="
                        !isDepositPossibleItem(activeItem.state, $route.params.type) &&
                        $route.name === 'BalanceTypeWithSymbol'
                    "
                >
                    <div class="w-full h-[575px] bg-[#fff] py-[17px] px-[17px]">
                        <ul class="list-disc text-market_rise text-[12px] px-[17px]">
                            <li>
                                {{ unitName || activeItem.name || '' }}의
                                {{ $route.params.type === 'withdraw' ? '출금' : '입금' }} 지원을
                                일시 중단합니다.
                            </li>
                            <li>서비스 재개 시 공지사항을 통해 안내 드리겠습니다.</li>
                        </ul>
                    </div>
                </section>

                <section
                    v-else-if="
                        !isDepositPossibleItem(activeItem.state, $route.params.type) &&
                        $route.name === 'BalanceType'
                    "
                >
                    <div class="w-full h-[575px] bg-[#fff] py-[17px] px-[17px]">
                        <ul class="list-disc text-market_rise text-[12px] px-[17px]">
                            <li>
                                {{ unitName || activeItem.name || '' }}의
                                {{ $route.params.type === 'withdraw' ? '출금' : '입금' }} 지원을
                                일시 중단합니다.
                            </li>
                            <li>서비스 재개 시 공지사항을 통해 안내 드리겠습니다.</li>
                        </ul>
                    </div>
                </section>

                <!-- 입출금내역 라우터 링크 -->
                <RouterLink to="/trading/withdrawalHistory">
                    <div
                        class="__lang mt-3 w-full h-[50px] bg-[#fff] flex justify-center items-center text-green text-noto-700 clickable basicBoxShadow"
                    >
                        입출금내역 보러가기
                    </div>
                </RouterLink>
            </article>
        </div>

        <!-- 보안인증 모달 -->
        <v-dialog v-model="security_auth_dialog" persistent width="486px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">2채널 인증</div>
                    <br />
                    <div v-if="security_auth_process === 0" class="text-[15px] flex flex-col">
                        <div
                            v-if="Number(userAuthKakao) === 1"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToKakaoAuth()"
                        >
                            카카오페이 인증
                        </div>
                        <div
                            v-else
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#BEBEBE]"
                            @click.prevent
                        >
                            카카오페이 인증
                        </div>
                        <div
                            v-if="Number(userAuthOTP) === 1"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToGoogleOTPAuth()"
                        >
                            구글 어센티케이터 인증
                        </div>
                        <div
                            v-else
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#BEBEBE]"
                            @click.prevent
                        >
                            구글 어센티케이터 인증
                        </div>
                    </div>
                    <!-- 카카오 페이 인증을 클릭했을 때 -->
                    <div v-else-if="security_auth_process === 1" class="text-[15px] flex flex-col">
                        <div
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#f2f2f2]"
                            @click.prevent
                        >
                            카카오페이 인증
                        </div>
                        <div
                            class="w-[400px] h-[49px] flex justify-center items-center text-white bg-main_color clickable rounded mb-[11px]"
                            @click="prepareWithdrawOut({ authType: 1 })"
                            :class="[kakaoVerifyState === 'request_out' ? 'bg-[#f5f5f5]' : '']"
                            :disabled="kakaoVerifyState === 'request_out'"
                        >
                            인증완료
                        </div>
                        <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                            <p>등록된 휴대폰 번호로 카카오페이 인증 요청이 발송되었습니다.</p>
                            <p>
                                카카오톡 안내에 따라 인증 절차를 완료해주세요.({{
                                    authRemainTime || '0:00'
                                }})
                            </p>
                        </span>
                    </div>

                    <!-- 구글 어센티케이터를 클릭했을 때 -->
                    <div
                        v-else-if="security_auth_process === 2"
                        class="text-[15px] flex flex-col w-[400px]"
                    >
                        <div class="w-[400px] h-[49px] pa-3 border bg-[#f2f2f2]" @click.prevent>
                            구글 어센티케이터 인증
                        </div>
                        <div class="flex items-center justify-center mt-[10px] mb-[11px]">
                            <input
                                @keydown="handleKeydown"
                                v-model="userOtpVerifyNumber"
                                class="w-[321px] h-[50px] sub_border1 pa-4"
                                type="text"
                                placeholder="인증번호를 입력해주세요"
                                :disabled="otpVerifyState === 'ready' || otpVerifyState === 'done'"
                                :class="isOtpAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                                maxlength="6"
                            />
                            <button
                                class="mypage_btn_type1"
                                :class="[otpVerifyState === 'request_out' ? 'bg-[#f5f5f5]' : '']"
                                @click.prevent="
                                    prepareWithdrawOut({ authType: 2, code: userOtpVerifyNumber })
                                "
                                :disabled="otpVerifyState === 'request_out'"
                            >
                                인증하기
                            </button>
                        </div>

                        <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                            <p>인증번호 6자리를 작성해주세요.({{ authRemainTime || '0:00' }})</p>
                        </span>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        class="w-[90px] h-[43px] border"
                        color="black"
                        @click="cancelWithdrawProcess"
                    >
                        취소
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 자산 선택용 모달 -->
        <v-dialog v-model="asset_dialog" persistent width="486px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ $route.params.type === 'withdraw' ? '출금' : '입금' }} 자산
                    </div>
                    <br />
                    <div v-if="$route.params.type === 'withdraw'" class="text-[15px] flex flex-col">
                        <div
                            v-for="(item, index) in assetList || []"
                            :key="index"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToWithdrawItem(item.symbol, item.name)"
                        >
                            {{ `${item.name}(${item.symbol})` }}
                        </div>
                    </div>
                    <div v-else class="text-[15px] flex flex-col">
                        <div
                            v-for="(item, index) in assetList || []"
                            :key="index"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToDepositItem(item.symbol, item.name)"
                        >
                            {{ `${item.name}(${item.symbol})` }}
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        class="w-[90px] h-[43px] border"
                        color="black"
                        @click="changeAssetDialogFalse"
                    >
                        취소
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- alert용 중첩 가능한 모달, 확인 전용 -->
        <v-dialog v-model="nested_dialog" width="486px" persistent>
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ modal_title }}
                    </div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        v-if="
                            step === 'withdraw_done' ||
                            step === 'withdraw_fail' ||
                            step === 'withdraw_error'
                        "
                        class="modal_confirm_btn w-[90px]"
                        color="white"
                        @click="
                            () => {
                                changeSecurityAuthDialogFalse()
                                changeNestedDialogFalse()
                                $router.go(0)
                            }
                        "
                    >
                        확인
                    </v-btn>
                    <v-btn
                        v-else
                        class="modal_confirm_btn w-[90px]"
                        color="white"
                        @click="
                            () => {
                                changeSecurityAuthDialogFalse()
                                changeNestedDialogFalse()
                            }
                        "
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- alert용 중첩 가능한 모달, 취소 및 확인 버튼 -->
        <v-dialog v-model="confirm_dialog" persistent width="486px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ modal_title }}
                    </div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        class="modal_cancel_btn w-[90px]"
                        color="black"
                        @click="changeConfirmDialogFalse"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        class="modal_confirm_btn w-[90px]"
                        color="white"
                        @click="whatShouldModalDo(commandForModal)"
                    >
                        {{ confirm_btn || '' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 복사 완료시 표시하기 위한 토스트 알림 -->
        <div class="text-center ma-2">
            <v-snackbar v-model="toastBarStatus" :timeout="3000" color="#767676">
                {{ toastMessage }}
            </v-snackbar>
        </div>
    </main>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import QrcodeVue from 'qrcode.vue'

// pinia store import
import { useUserStore } from '../../stores/user'
import { useExchangerStore } from '../../stores/exchanger'

// utils import
import UtilSessionStorage from '../../utils/session'
import { handleKeydown, copy, changeToUpperCase, formatNumberString } from '@/utils/utilsFactory'

// api import
import {
    // requestBlockChainIn,
    checkInternalOutAddress,
    hasBlockChainAddress,
    makeBlockChainAddress,
    requestConfirmationAsset,
    requestCompleteAuthBlockChainOut,
    requestOutAuth
} from '../../api/blockChain/index.js'
import ExchangerApi, { requestSymbolTicker } from '../../api/exchanger'
import {
    getSelectedWalletList,
    getAllMarketToken,
    getMemberAssetEval,
    initMemberInfomation,
    prepareMemberSecurityLevel,
    setUserAvailableInout,
    logOut,
    requestLimitMaxOut
} from '../../api/user/index.js'
import { selectFiatMarketFlag, selectInAccount, selectOutAccount } from '../../api/fiat/index.js'

// !컴포넌트 컨트롤용 변수 모음
const sessionStorage = new UtilSessionStorage()

// 라우터 인스턴스 주입
const router = useRouter()
const route = useRoute()

const exchangerApi = new ExchangerApi()

// 실제 법정화폐(예 : 원화)를 사용하는지 판단하는 변수
const isUseFiatCurrency = ref(0)

// 출금 지원하는 자산의 종류를 보여주는 변수
const whatAssetCanDepositWithdraw = ref('')

// 유저의 아이디를 임시 서장
const id = ref('')

// pinia instance 주입
const userStore = useUserStore()
const exchanger = useExchangerStore()

const pages = ref(0)
const n_address = ref('')
const n_network = ref('')

const depositProcess = ref('ready')
const withdrawProcess = ref('ready')

// 외부출금 / 내부출금 관련 데이터
/**
 * value = 0, 외부출금
 * value = 1, 내부출금
 */
const selectedOption = ref('0')
const innerWithdrawAddress = ref(null)

// 로딩 스테이터스들
const dataLoading = ref(false) // 로딩 스테이터스
const qrDataLoading = ref(false)
const dataWaiting = ref(true)

// List 형 데이터들
const dealMarketList = ref([])
const tokenList = ref([]) // 상단의 코인들 데이터
const assetList = ref([]) // 가상자산에 대한 데이터
const nftList = ref([]) // 하단의 작품들 리스트 데이터
const walletList = ref(null) // 출금 주소 콤보 박스에서 보여줄 배열
const userWallet = computed(() => {
    let result = ''
    try {
        if (walletList.value) {
            result = `${process.env.VUE_APP_WALLET_NAME}(${walletList.value.address})`
        }
        if (!walletList.value.walletName || !walletList.value.address) {
            result = ''
        }
    } catch (error) {
        console.error(error)
    }
    return result
})

// 모달 컨트롤을 위한 변수
const commandForModal = ref('')
const modal_title = ref('')
const step = ref(null)
// 보안인증용 모달
const security_auth_dialog = ref(false)
const security_auth_process = ref(0)
function changeSecurityAuthDialogTrue() {
    security_auth_dialog.value = true
}
function changeSecurityAuthDialogFalse() {
    security_auth_dialog.value = false
}
function closeModalAndMakeCanceledAlert() {
    changeSecurityAuthDialogFalse()
    modal_title.value = `안내`
    nested_msg.value = `보안인증이 취소되었습니다.`
    changeNestedDialogTrue()
}
// 단순 확인용 모달창
const nested_dialog = ref(false) // 중첩용으로 나오는 작은 모달창
const nested_msg = ref('') // alert용 모달창 메세지
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}

// 자산 선택용 모달창
const asset_dialog = ref(false)
function changeAssetDialogTrue() {
    asset_dialog.value = true
}
function changeAssetDialogFalse() {
    asset_dialog.value = false
}

// 확인, 취소 가능한 모달창
const confirm_dialog = ref(false)
const confirm_btn = ref('확인')
function changeConfirmDialogTrue() {
    confirm_dialog.value = true
}
function changeConfirmDialogFalse() {
    confirm_dialog.value = false
}

// 2채널 인증 모달에 들어가는 정보들을 전부 초기값으로 세팅합니다.
function initSecurityModal() {
    // 인증 단계 초기화
    security_auth_process.value = 0
    // 카카오 인증 상태 초기화
    kakaoVerifyState.value = null
    // OTP 인증 상태 초기화
    otpVerifyState.value = null
    // user가 입력한 verify code 초기화
    userOtpVerifyNumber.value = null
}

function whatShouldModalDo(command) {
    if (command === '지갑등록') {
        // 입금 주소 발급을 위한 함수
        prepareUserJigapAddress(activeItem.value.symbol)
    } else if (command === '가상자산지갑등록') {
        // 가상자산의 입금 주소 발급을 위한 함수
        prepareUserJigapAddress(unitSymbol.value)
    } else if (command === '계좌등록알림') {
        changeConfirmDialogFalse()
        router.push({ path: '/mypage/securitySetting' })
    } else if (command === '가상자산출금신청') {
        // 보안인증 모달창
        changeConfirmDialogFalse()
        changeSecurityAuthDialogTrue()
    } else if (command === 'NFT작품출금신청') {
        // requestBlockChainIn()
    }
}

// 인증 컨트롤용 변수
// 확인까지 남은 인증시간 interval 저장용 변수
let authVerifyIntervalId = null
// 유저에게 보여줄 남은 시간
const authRemainTime = ref('0:00')
// 인증 제한시간 (초 단위)
const authLimitTime = ref(180)
// 인증버튼 눌렀을 때 저장될 인증 시작 시간
const authStartTime = ref(null)
// 인증 잔여시간 (단위 : 밀리초)
const authVerifyTime = ref(0)

// 카카오페이 인증 관리용 변수
// 카카오페이 인증 진행 상태
const kakaoVerifyState = ref(null)

// OTP 인증 관리용 변수
// OTP 인증 번호
const userOtpVerifyNumber = ref(null)
// OTP 인증 진행 상태
const otpVerifyState = ref(null)
// OTP 인증 완료 여부
const isOtpAuthCompleted = ref(false)

// 토스트 메세지 컨트롤용 변수
const toastBarStatus = ref(false)
const toastMessage = ref('')

// input Data 양방향 바인딩용 변수들
// 입출금======================================================
// 입력 받은 input area의 값들 (rawValue, 숫자형 데이터)
const krwAmount = ref(null)
const cryptoAmount = ref(null)
const nftAmount = ref(null)
// xxx,xxx,xxx 형식으로 변환된 값이 임시 저장될 변수들
const formattedKrwAmount = ref(0) // 가격
const formattedCryptoAmount = ref(0) // 감시가격
const formattedNftAmount = ref(0) // 주문가격
// 사용자에게 보여지게 될 input area의 값들
const displayKrwAmount = ref(null) // 가격
const displayCryptoAmount = ref(null) // 감시가격
const displayNftAmount = ref(null) // 주문가격
// 각각의 input area에 대한 handleEvent
const handleOnKrwAmountInput = (event) => {
    const numbersOnlyValue = event.target.value.replace(/\D/g, '').replace(/^0+(?=\d)/, '')
    krwAmount.value = numbersOnlyValue
    formattedKrwAmount.value = formatNumberString(numbersOnlyValue)
    event.target.value = formattedKrwAmount.value
}
const handleOnCryptoAmountInput = (event) => {
    const numbersOnlyValue = event.target.value.replace(/\D/g, '').replace(/^0+(?=\d)/, '')
    cryptoAmount.value = numbersOnlyValue
    formattedCryptoAmount.value = formatNumberString(numbersOnlyValue)
    event.target.value = formattedCryptoAmount.value
}
const handleOnNftAmountInput = (event) => {
    const numbersOnlyValue = event.target.value.replace(/\D/g, '').replace(/^0+(?=\d)/, '')
    nftAmount.value = numbersOnlyValue
    formattedNftAmount.value = formatNumberString(numbersOnlyValue)
    event.target.value = formattedNftAmount.value
}

const rateForChange = ref(1)
// 입금 한도
// 최소 입금 한도
const inMinLimitForItem = computed(() => {
    let result = 0
    try {
        if (inoutLimitData.value) {
            if (Number(route.params.isAsset) === 1) {
                result = inoutLimitData.value.in_limit.min.find(
                    (item) => item.symbol === route.params.symbol
                )?.limit
            } else {
                result = inoutLimitData.value.in_limit.min.find(
                    (item) => item.symbol === unitSymbol.value
                )?.limit
            }
        }
        if (!result) {
            result = 0
        }
    } catch (error) {
        console.error(error)
    }
    return result
})
// 최대 입금 한도
// 최대 입금 한도는 존재하지 않습니다.

// 출금 한도
// 최대 출금 한도
const outMaxLimitForItem = computed(() => {
    let result = 0
    try {
        if (outOnlyLimitData.value) {
            result = outOnlyLimitData.value?.data?.result?.limitMaxOut
        }
        if (!result) {
            result = 0
        }
    } catch (error) {
        console.error(error)
    }
    return result
})
// 최소 출금 한도 for 가상자산 및 작품
const outMinLimitForItem = computed(() => {
    let result = 0
    try {
        if (inoutLimitData.value) {
            if (Number(route.params.isAsset) === 1) {
                result =
                    inoutLimitData.value.out_limit.min.find(
                        (item) => item.symbol === route.params.symbol
                    )?.limit || 0
            } else {
                result =
                    inoutLimitData.value.out_limit.min.find(
                        (item) => item.symbol === defaultMarket.value
                    )?.limit || 0
            }
        }
        if (!result) {
            result = 0
        }
    } catch (error) {
        console.error(error)
    }
    return result
})
// 총 출금 금액 KRW, Crypto, NFT 별로 계산
const resultKRWOut = computed(() => {
    let result = 0
    if (krwAmount.value > 0) result = krwAmount.value
    if (inoutLimitData.value && inoutLimitData.value?.in_limit?.max[0]?.limit <= krwAmount.value) {
        result = inoutLimitData.value?.in_limit?.max[0]?.limit
    }
    result = Number(result)
    if (!result || Number.isNaN(result)) {
        result = 0
    }
    return result
})

// 해당하는 자산, 작품이 입금, 출금 가능한 상태인지 판단해주는 함수
function isDepositPossibleItem(index, type) {
    const depositPossible = [1, 3, 5, 6]
    const withdrawPossible = [1, 2, 6, 7]
    const item = Number(index)
    if (type === 'deposit') {
        if (depositPossible.includes(item)) {
            return true
        }
    } else if (type === 'withdraw') {
        if (withdrawPossible.includes(item)) {
            return true
        }
    }
    return false
}

/**
 * 작품의 출금에 대한 validation 메세지
 */
const validationMessageForOutAmountNFT = computed(() => {
    let result = ''
    if (Number(nftAmount.value) > Number(outMaxLimitForItem.value)) {
        result = '출금할 수 있는 금액을 초과하였습니다.'
    } else if (Number(nftAmount.value) > Number(availableOut.value)) {
        result = '출금할 수 있는 수량을 초과하였습니다.'
    } else if (Number(outMinLimitForItem.value) > Number(nftAmount.value)) {
        result = `최소 ${Number(outMinLimitForItem.value).toLocaleString()} ${
            activeItem.value.symbol
        } 이상 출금 가능합니다.`
    }

    if (!nftAmount.value || nftAmount.value === 0 || nftAmount.value === '') {
        result = ''
    }
    return result
})

/**
 * 가상자산의 출금에 대한 validation 메세지
 */
const validationMessageForOutAmountCrypto = computed(() => {
    let result = ''
    if (Number(cryptoAmount.value) > Number(availableOut.value)) {
        result = '출금할 수 있는 금액을 초과하였습니다.'
    } else if (Number(cryptoAmount.value) > Number(outMaxLimitForItem.value)) {
        result = '출금할 수 있는 금액을 초과하였습니다.'
    } else if (Number(cryptoAmount.value) < Number(outMinLimitForItem.value)) {
        result = `최소 ${Number(outMinLimitForItem.value).toLocaleString()} ${
            defaultMarket.value
        } 이상 출금 가능합니다.`
    }

    if (!cryptoAmount.value || cryptoAmount.value === 0 || cryptoAmount.value === '') {
        result = ''
    }
    return result
})

/**
 * 원화와 가상자산에 대한 수수료 validation 메세지
 */
const validationMessageForOutFee = computed(() => {
    let result = ''
    if (tokenList.value[0]?.current_balance < fee.value) {
        result = `(${Math.round(
            Number(fee.value) - Number(tokenList.value[0].current_balance)
        ).toLocaleString('ko-KR', { minimumFractionDigits: 0 })} ${
            selectedFeeType.value
        } 부족합니다.)`
    }
    return result
})

/**
 * 작품에 대한 수수료 validation 메세지
 */
const validationMessageForOutFeeNFT = computed(() => {
    let result = ''
    if (tokenList.value[0]?.current_balance < fee.value) {
        result = `(${Math.round(
            Number(fee.value) - Number(tokenList.value[0].current_balance)
        ).toLocaleString('ko-KR', { minimumFractionDigits: 0 })} ${
            selectedFeeType.value
        } 부족합니다.)`
    }
    return result
})

/**
 * 가상자산 총 출금금액
 */
const resultCryptoOut = computed(() => {
    let result = 0
    if (cryptoAmount.value > 0) result = cryptoAmount.value

    result = Number(result)
    if (!result || Number.isNaN(result)) {
        result = 0
    }
    if (activeItem.value.current_balance === 0) {
        result = 0
    }
    if (outMaxLimitForItem.value && outMaxLimitForItem.value <= result) {
        result = outMaxLimitForItem.value
    }
    if (fee.value) {
        result += fee.value
    }

    return result
})

/**
 * 작품 총 출금금액
 */
const resultNFTOut = computed(() => {
    let result = 0
    if (nftAmount.value > 0) result = nftAmount.value
    if (outMaxLimitForItem.value && outMaxLimitForItem.value <= nftAmount.value) {
        result = outMaxLimitForItem.value
    }
    result = Number(result)
    if (!result || Number.isNaN(result)) {
        result = 0
    }
    if (activeItem.value.current_balance === 0) {
        result = 0
    }
    return result
})

/**
 * 출금창에서 보여줄 수수료 입니다.
 */
const feeOfCoin = ref(0)
// 계산에 사용될 수수료 데이터
const fee = ref(0)
const computedFeeData = computed(() => {
    let result = 0
    try {
        if (fee.value) {
            const temp = Math.round(Number(fee.value))
            result = temp.toLocaleString('ko-KR', {
                maximumFractionDigits: 8,
                minimumFractionDigits: 0
            })
        }
    } catch (error) {
        console.error(error)
    }
    return result
})

const selectedFeeData = computed(() => {
    let result = 0
    try {
        const whatSymbol = activeItem.value?.symbol || tokenList.value[0]?.symbol
        const temp = feeData.value.filter((item) => item.asset === whatSymbol)
        if (temp) result = temp.fee
        else result = feeData.value[0].fee
    } catch (error) {
        result = 0
        console.error(error)
    }
    return result
})

// 기타 지역변수들
const defaultMarket = ref(null) // 디폴트 마켓에 대한 데이터
const unitSymbol = ref(null) // 출금용 심볼에 대한 데이터
const unitName = ref(null)
const selectedEvalAmount = ref(0) // 각 보유량에 대한 환산 금액 데이터.
const confirmationCount = ref(0) // 현재 페이지에 표시할 가상자산 / 작품에 대한 confirmation 횟수에 대한 데이터.
const inoutLimitData = ref(null) // 가상자산, 작품에 대한 입출금 한도 데이터.
const outOnlyLimitData = ref(null) // 출금 한도에 대한 데이터

// 네트워크 아이콘 호버시 툴팁 창 visible을 컨트롤하기 위한 변수
const showNetworkTooltip = ref(false)
// 출금창에서 출금한도, 출금가능 옆의 툴팁창을 컨트롤하기 위한 변수
const showOutMethodExplainTooltip = ref(false)
const showOutLimitTooltip = ref(false)
const showOutAvailableTooltip = ref(false)

// 가상자산 page에서 환산된 총 출금금액
const resultExchangedCryptoOutAmount = computed(() => {
    let result = 0

    if (activeItem.value && selectedEvalAmount.value && isUseFiatCurrency.value === 0) {
        try {
            result += resultCryptoOut.value * rateForChange.value.price
            // result = resultCryptoOut.value;
        } catch (error) {
            console.error(error)
        }
    } else if (selectedEvalAmount.value && isUseFiatCurrency.value === 1) {
        try {
            const lateForChange =
                selectedEvalAmount.value.member_hold /
                    selectedEvalAmount.value.exchange_member_hold || 1
            let tempVal = cryptoAmount.value
            result += tempVal * rateForChange.value.price
        } catch (error) {
            console.error(error)
        }
    }
    if (!result || Number.isNaN(result)) {
        result = 0
    }
    return result
})
// 작품 page에서 환산된 총 출금금액
const resultExchangedNFTOutAmount = computed(() => {
    let result = 0
    try {
        const temp = selectedOption.value === '0' ? Math.floor(Number(fee.value)) : 0
        if (activeItem.value && selectedEvalAmount.value) {
            result = resultNFTOut.value * rateForChange.value.price
            result += temp
        }
        if (!result || Number.isNaN(result)) {
            result = 0
        }
    } catch (error) {
        console.error(error)
    }
    return result
})
const activeItem = ref([]) // 우측에 들어갈 active된 item의 데이터
const isThisItemHaveAddress = ref(false) // 현재 선택된 activeItem이 입금 주소를 가지고 있는지
const onlyPossessedAsset = ref(false) // 보유자산만 볼지의 여부를 저장하는 data
const feeData = ref([]) // 수수료 데이터들의 모음
const selectedFeeType = ref('KRW') // select 창에서 선택한 Fee의 값

// 유저의 임시 정보 저장용 변수
// 유저가 지갑을 가지고 있는지 아닌지 판단한 결과가 들어가는 변수
const isUserHaveWalletAddress = ref(false)
// 유저의 지갑 주소
const userWalletAddress = ref('')
// 유저가 2채널 인증을 했는지 판단한 결과
const isUserDid2ChannelAuth = ref(false)
// 유저의 카카오 인증 상태
const userAuthKakao = ref(0)
const userAuthOTP = ref(0)

// 입금 계좌 은행, 번호, 예금주
const inAccountBankName = ref(null)
const inAccountBankNum = ref(null)
const inAccountBankOwner = ref(null)
const inAccountUserName = ref('')
// 출금 계좌 은행, 번호, 예금주
const outAccountBankName = ref(null)
const outAccountBankNum = ref(null)
const outAccountBankOwner = ref(null)
// 입금, 출금 가능 금액
const availableIn = ref(0)
const availableOut = ref(0)
const availableOutData = computed(() => {
    let result = 0
    if (Number(availableOut.value) && !Number.isNaN(availableOut.value)) {
        result = Number(availableOut.value).toLocaleString('ko-KR', {
            maximumFractionDigits: 8,
            minimumFractionDigits: 0
        })
    }
    return result
})

// 검색 영역용
const searchNft = ref('')
const isFocused = ref(false)
const onFocus = () => {
    isFocused.value = true
}
const onBlur = () => {
    isFocused.value = false
}

// 주소 select 박스용 테스트
const checkedState = ref([false, false])
// 출금 신청 버튼을 활성화하기 위한 속성
// 가상자산의 출금신청 버튼 활성화 관리하기 위한 속성
const isAssetCanWithdraw = computed(() => {
    let result = false
    try {
        if (
            Number(outMinLimitForItem.value) <= Number(cryptoAmount.value) &&
            Number(cryptoAmount.value) <= Number(availableOut.value)
        ) {
            result = true
        }
        if (
            validationMessageForOutAmountCrypto.value &&
            validationMessageForOutAmountCrypto.value !== ''
        ) {
            result = false
        }
        if (cryptoAmount.value === 0 || !cryptoAmount.value) {
            result = false
        }
    } catch (error) {
        result = false
        console.error(error)
    }
    return result
})

// 작품의 출금신청 버튼 활성화 관리하기 위한 속성
const isVirtualCanWithdraw = computed(() => {
    let result = false
    try {
        if (Number(outMinLimitForItem.value) <= Number(nftAmount.value)) {
            result = true
        }
        if (Number(nftAmount.value) <= Number(availableOut.value)) {
            result = true
        }
        if (
            validationMessageForOutAmountNFT.value &&
            validationMessageForOutAmountNFT.value !== ''
        ) {
            result = false
        }
        if (nftAmount.value === 0 || !nftAmount.value) {
            result = false
        }
    } catch (error) {
        result = false
        console.error(error)
    }
    return result
})

// 출금 전 동의 항목에 전부 체크했는지 확인하는 속성
const allAgree = computed(() => {
    let result = false
    if (checkedState.value[0] === true && checkedState.value[1] === true) {
        result = true
    }

    if (Number(nftAmount.value) > Number(activeItem.value.current_balance)) {
        result = false
    } else if (Number(nftAmount.value) > Number(outMaxLimitForItem.value)) {
        result = false
    }

    if (Number(cryptoAmount.value) > Number(activeItem.value.current_balance)) {
        result = false
    } else if (Number(cryptoAmount.value) > Number(outMaxLimitForItem.value)) {
        result = false
    }
    // 내부출금일 경우 출금주소에 아무것도 입력하지 않을 경우 버튼 비활성화
    if (selectedOption.value === '1') {
        if (!innerWithdrawAddress.value) {
            result = false
        }
    }

    return result
})

const reArangedNftList = computed(() => {
    // 여기에서 resultArr는 객체들의 배열인데
    let resultArr = [...nftList.value]

    if (onlyPossessedAsset.value) {
        // 보유량이 0 초과인 항목만 필터링
        resultArr = resultArr.filter((a) => a.current_balance > 0)
    }

    // searchNft에 입력된 검색어가 있을 경우 필터링
    if (searchNft.value.trim()) {
        const searchTerm = searchNft.value.trim().toUpperCase() // 입력된 검색어를 대문자로 변환
        resultArr = resultArr.filter(
            (item) =>
                item.name.toUpperCase().includes(searchTerm) ||
                item.symbol.toUpperCase().includes(searchTerm) ||
                toChosungString(item.name).includes(searchTerm)
        )
    }

    resultArr.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })
    return resultArr
})

function toChosungString(word) {
    const ga = 44032 // '가'의 유니코드 시작
    const chosung = [
        'ㄱ',
        'ㄲ',
        'ㄴ',
        'ㄷ',
        'ㄸ',
        'ㄹ',
        'ㅁ',
        'ㅂ',
        'ㅃ',
        'ㅅ',
        'ㅆ',
        'ㅇ',
        'ㅈ',
        'ㅉ',
        'ㅊ',
        'ㅋ',
        'ㅌ',
        'ㅍ',
        'ㅎ'
    ]
    let result = ''
    for (let i = 0; i < word.length; i++) {
        let charCode = word.charCodeAt(i)
        if (charCode >= ga) {
            result += chosung[Math.floor((charCode - ga) / 588)]
        } else {
            result += word[i]
        }
    }
    return result
}

onMounted(async () => {
    dataLoading.value = false
    try {
        if (sessionStorage.getItem('unsent_variable') === 'Y') {
            onlyPossessedAsset.value = true
        } else {
            onlyPossessedAsset.value = false
        }
    } catch (error) {
        console.error(error)
    }

    id.value = sessionStorage.getUserId()
    whatAssetCanDepositWithdraw.value = process.env.VUE_APP_WHAT_ASSET_CAN_DEPOSIT_WITHDRAW || ''

    watch(kakaoVerifyState, () => {
        if (kakaoVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0 && kakaoVerifyState.value === 'send') {
                        step.value = 'withdraw_error'
                        kakaoVerifyState.value = 'ready'
                        clearInterval(authVerifyIntervalId)
                        makeKakaoAuthFailAlert()
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(authVerifyTime.value / 60)}`.slice(
                        -2
                    )}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    watch(otpVerifyState, () => {
        if (otpVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (
                        (authVerifyTime.value < 0 && otpVerifyState.value === 'send') ||
                        (authVerifyTime.value < 0 && otpVerifyState.value === 'request_out')
                    ) {
                        step.value = 'withdraw_error'
                        otpVerifyState.value = 'ready'
                        clearInterval(authVerifyIntervalId)
                        makeKakaoAuthFailAlert()
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(authVerifyTime.value / 60)}`.slice(
                        -2
                    )}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    watch(cryptoAmount, (newValue) => {
        displayCryptoAmount.value = formatNumberString(newValue)
    })
    watch(nftAmount, (newValue) => {
        displayNftAmount.value = formatNumberString(newValue)
    })

    // 공통 요청 로직 모음
    // 디폴트 마켓
    try {
        // 기본 마켓 데이터에 대한 요청
        exchangerApi.defaultMarket().then((response) => {
            if (response) {
                defaultMarket.value = response.result.market2
            }
        })
    } catch (error) {
        console.error(error)
    }

    if (route.params.type === 'withdraw') {
        initOutAccount()
    }

    // 작품의 입금 / 출금시 실행될 로직
    if (route.name === 'BalanceTypeWithSymbol') {
        if (route.params.type === 'deposit') {
            try {
                const response = await hasBlockChainAddress(id.value, route.params.symbol)
                if (response.result.address && response.result.address.length > 1) {
                    isThisItemHaveAddress.value = true
                    makeQrCode(response.result.address)
                }
            } catch (error) {
                console.error(error)
            }
            try {
                outOnlyLimitData.value = await requestLimitMaxOut({
                    id: id.value,
                    symbol: route.params.symbol,
                    type: 'in'
                })
            } catch (error) {
                console.error(error)
            }
        } else if (route.params.type === 'withdraw') {
            if (route.params.symbol && route.params.symbol !== defaultMarket.value) {
                withdrawProcess.value = 'prepare'
            }
            try {
                outOnlyLimitData.value = await requestLimitMaxOut({
                    id: id.value,
                    symbol: route.params.symbol,
                    type: 'out'
                })
            } catch (error) {
                console.error(error)
            }
        }

        try {
            const dealType = route.params.type === 'withdraw' ? 'out' : 'in'
            requestAssetAvailable(route.params.symbol, dealType)
            initLimitData()
        } catch (error) {
            console.error(error)
        }

        try {
            // 컨퍼메이션 횟수 체크
            requestConfirmationAsset({ symbol: route.params.symbol }).then((response) => {
                if (response.data.status === 'success') {
                    confirmationCount.value = response.data.result.data
                }
            })
        } catch (error) {
            console.error(error)
        }

        try {
            // 네트워크 이름 체크
            exchangerApi.getMarketInfo({ market1: route.params.symbol }).then((response) => {
                if (response.data.status === 'success') {
                    n_network.value = changeToUpperCase(response.data.result.data.networkName)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    // 법정화폐
    try {
        selectFiatMarketFlag({ id: id.value }).then((response) => {
            if (response === 1) {
                isUseFiatCurrency.value = 1
                // 법정화폐, 실제 원화 마켓을 사용한다면 요청하는 로직 모음
                fetchDataWhenUseFiatCurrency()
            } else {
                // 그렇지 않을 경우(default값 0) 요청 로직 모음
                fetchDataWhenDontUseFiatCurrency()
            }
        })
    } catch (error) {
        console.error(error)
    }

    try {
        // 작품의 입출금일 경우 수수료를 추가 요청합니다.
        if (route.name === 'BalanceTypeWithSymbol' && route.params.symbol !== defaultMarket.value) {
            initGetOutFeeData()
        }
    } catch (error) {
        console.error(error)
    }
})

onBeforeUnmount(() => {
    clearInterval(authVerifyIntervalId)
    try {
        if (sessionStorage.getItem('unsent_variable') !== 'Y') {
            sessionStorage.removeStorage('unsent_variable')
        }
    } catch (error) {
        console.error(error)
    }
})

// functions ================================================================================================= //
// page render
/**
 * 카카오 인증 기준으로 모달 컴포넌트를 다시 랜더링합니다.
 */
function moveToKakaoAuth() {
    kakaoVerifyState.value = 'send'
    security_auth_process.value = 1
    startAuthBeforeWithdraw(1)
}
/**
 * 구글 OTP 인증 기준으로 컴포넌트를 다시 랜더링합니다.
 */
function moveToGoogleOTPAuth() {
    otpVerifyState.value = 'send'
    security_auth_process.value = 2
    startAuthBeforeWithdraw(2)
}

// 요청 api
/**
 * ! 현재 사용하지 않습니다.
 * 법정 화폐, 실제 원화 마켓을 사용할 때 요청하는 로직 모음
 */
async function fetchDataWhenUseFiatCurrency() {
    Promise.allSettled([
        initLimitData(),
        initInAccount(),
        initOutAccount(),
        initWalletList(),
        getAllMarketToken(id.value),
        getMemberAssetEval(id.value),
        exchangerApi.getDealMarketList()
    ])
        .then((result) => {
            tokenList.value = []
            nftList.value = []
            if (userStore?.memberAllMarketToken) {
                userStore?.memberAllMarketToken.forEach((item) => {
                    if (item.coin === true) {
                        tokenList.value.push(item)
                    } else {
                        nftList.value.push(item)
                    }
                })
            }

            if (tokenList.value[0]?.symbol === 'KRW') {
                pages.value = 0
            } else {
                pages.value = 1
            }

            userStore.setUserSecurityLevel(inoutLimitData.value.security_level)

            dealMarketList.value = exchanger.dealMarketList

            if (!inAccountUserName.value || inAccountUserName.value === '') {
                inAccountUserName.value = outAccountBankOwner.value
            }

            dataLoading.value = true

            if (!outAccountBankName.value && !outAccountBankNum.value) {
                confirm_btn.value = `계좌 등록`
                modal_title.value = `보안설정 안내`
                nested_msg.value = `원화계좌가 등록되지 않아 입출금에 제한이 있습니다.<br/><br/>
    [제한내용]<br/>
    <span class="text-market_rise">- 원화 입출금불가</span><br/>
    - 가상자산 & 작품: 1일 출금한도 1천만원<br/><br/>
    원화계좌 등록이 완료되면 입출금한도를 증가시킬 수 있습니다.<br/>
    계좌 등록 페이지로 이동하시겠습니까?`
                commandForModal.value = '계좌등록알림'
                changeConfirmDialogTrue()
            }
        })
        .catch((error) => console.error(error))
}

/**
 * 법정화폐를 사용하지 않을 때 요청하는 로직 모음
 */
async function fetchDataWhenDontUseFiatCurrency() {
    Promise.allSettled([
        // 유저의 정보를 요청합니다. (보안레벨, 인증 정보 확인용)
        initUserInformation(),
        // 지갑의 생성 유무를 판단하기 위한 데이터를 요청합니다.
        initWalletList(),
        // 모든 마켓 심볼 토큰들의 데이터를 가져옵니다.
        getAllMarketToken(id.value),
        // 총 보유자산을 얻기 위한 데이터를 요청합니다.
        getMemberAssetEval(id.value),
        // 현재 거래 시장의 목록을 요청합니다.
        exchangerApi.getDealMarketList()
    ])
        .then((result) => {
            tokenList.value = []
            nftList.value = []
            if (userStore?.memberAllMarketToken) {
                userStore?.memberAllMarketToken.forEach((item) => {
                    if (item.coin === true) {
                        if (item.symbol === defaultMarket.value) {
                            tokenList.value.push(item)
                        } else {
                            assetList.value.push(item)
                        }
                    } else {
                        nftList.value.push(item)
                    }
                })
            }

            dealMarketList.value = exchanger.dealMarketList

            if (!inAccountUserName.value || inAccountUserName.value === '') {
                inAccountUserName.value = outAccountBankOwner.value
            }

            let tempArr = null

            // 우상단 영역에 현재 어떤 아이템이 진행되는지에 대한 정보를 세팅합니다.
            if (route.params.symbol && Number(route.params.isAsset) === 0) {
                tempArr = JSON.parse(
                    JSON.stringify(
                        tokenList.value.find((item) => item.symbol === route.params.symbol)
                    )
                )
                setActiveDataCoin(tempArr)
            } else if (route.params.symbol && Number(route.params.isAsset) === 1) {
                tempArr = JSON.parse(
                    JSON.stringify(
                        nftList.value.find((item) => item.symbol === route.params.symbol)
                    )
                )
                setActiveDataNFT(tempArr)
            } else {
                activeItem.value = JSON.parse(JSON.stringify(tokenList.value[0]))
            }

            // 작품의 입출금 페이지일 경우 알림 모달
            if (route.name === 'BalanceTypeWithSymbol') {
                initRateForChange({ market1: route.params.symbol, market2: defaultMarket.value })
                setDepositOrWithdrawAlert(activeItem.value.name)
            }

            dataLoading.value = true
        })
        .catch((error) => console.error(error))
}

/**
 * 자산 형식의 activeItem 세팅
 * @param {*} newData
 */
function setActiveDataCoin(newData) {
    activeItem.value = {}
    activeItem.value.coin = newData?.coin
    activeItem.value.image = newData?.image
    activeItem.value.name = newData?.name
    activeItem.value.symbol = newData?.symbol
    activeItem.value.state = newData?.state
    activeItem.value.total_volume = newData?.total_volume
    activeItem.value.avg_unit_cost = newData?.avg_unit_cost
    activeItem.value.buy_amount = newData?.buy_amount
    activeItem.value.eval_amount = newData?.eval_amount
    activeItem.value.eval_profit = newData?.eval_profit
    activeItem.value.eval_rate = newData?.eval_rate
    activeItem.value.current_balance = newData?.current_balance
    activeItem.value.total_volume = newData?.total_volume
    activeItem.value.exchanged_current_balance = newData?.exchanged_current_balance
}

/**
 * 작품 형식의 activeItem 세팅
 * @param {*} newData
 */
function setActiveDataNFT(newData) {
    activeItem.value = {}
    activeItem.value.image = newData?.image
    activeItem.value.name = newData?.name
    activeItem.value.symbol = newData?.symbol
    activeItem.value.state = newData?.state
    activeItem.value.eval_amount = newData?.eval_amount
    activeItem.value.current_balance = newData?.current_balance
    activeItem.value.percent = newData?.percent
    activeItem.value.exchanged_current_balance = newData?.exchanged_current_balance
}

/**
 * 인풋 영역의 데이터들을 초기화하고 입금 자산 선택 창으로 되돌아갑니다.
 */
function prepareReSelectAsset() {
    krwAmount.value = 0
    cryptoAmount.value = 0
    nftAmount.value = 0
    depositProcess.value = 'ready'
    withdrawProcess.value = 'ready'
}

/**
 * 입출금 주소 생성 알림 모달창
 */
function setDepositOrWithdrawAlert(name) {
    modal_title.value = `${name} ${
        route.params.type === 'withdraw' ? '출금' : '입금'
    } 주의사항 안내`
    nested_msg.value = `해당 ${!route.params.isAsset ? '자산' : '작품'}은 ${
        n_network.value
    } 네트워크 ${route.params.type === 'withdraw' ? '출금' : '입금'}만 지원합니다.<br/>
${n_network.value} 네트워크가 아닌 ${!route.params.isAsset ? '자산' : '작품'}을 ${
        route.params.type === 'withdraw' ? '출금' : '입금'
    }하면 오${
        route.params.type === 'withdraw' ? '출금' : '입금'
    }으로 복구가 어려우니 주의해주시기 바랍니다.`
    changeNestedDialogTrue()
}

// 서버에 입금가능, 환급가능 자산 요청
/* eslint-disable default-param-last */
async function requestAssetAvailable(name = process.env.VUE_APP_DEFAULT_MARKET, type) {
    const response = await setUserAvailableInout(id.value, name, type)
    if (response) {
        availableOut.value = response.result.available
    }
    if (isUseFiatCurrency.value === 1) {
        selectedEvalAmount.value =
            response.result.exchange_member_hold.toLocaleString('ko-KR', {
                maximumFractionDigits: 8,
                minimumFractionDigits: 0
            }) || 0
    } else {
        selectedEvalAmount.value = response.result
    }
}

// onMount시 실행될 초기값 넣는 구문들
/**
 * 입금 계좌 관련 변수를 초기화
 */
async function initInAccount() {
    const responseTest1 = await selectInAccount(id.value, 'KRW')
    inAccountBankName.value = responseTest1?.data?.account?.bank ?? null
    inAccountBankNum.value = responseTest1?.data?.account?.account ?? null
    inAccountBankOwner.value = responseTest1?.data?.account?.name ?? null
}

/**
 * 출금 계좌 관련 변수를 초기화
 */
async function initOutAccount() {
    const responseTest2 = await selectOutAccount(id.value)
    // 출금 계좌 은행, 번호, 예금주
    outAccountBankName.value = responseTest2?.data?.account?.bank || null
    outAccountBankNum.value = responseTest2?.data?.account?.account || null
    outAccountBankOwner.value = responseTest2?.data?.account?.name || null
}

/**
 * 입출금 제한금액에 대해 초기화합니다.
 */
async function initLimitData() {
    inoutLimitData.value = await prepareMemberSecurityLevel(id.value)
}

/**
 * 지갑의 주소를 얻어와 변수를 초기화합니다.
 */
async function initWalletList() {
    const response = await getSelectedWalletList(id.value)
    walletList.value = response.find((items) => items.walletName === 'metamask') || []
    if (walletList.value.walletName) {
        isUserHaveWalletAddress.value = true
        userWalletAddress.value = walletList.value.address
    }
}

/**
 * 대표 마켓 가격에 대한 symbol의 가격을 계산해서 반환받습니다.
 */
async function initRateForChange({ market1, market2 }) {
    const requestData = {}
    if (market1) {
        requestData.market1 = market1
    } else {
        rateForChange.value = { price: 1 }
        return false
    }
    if (market2) {
        requestData.market2 = market2
    } else {
        rateForChange.value = { price: 1 }
        return false
    }
    try {
        const response = await requestSymbolTicker(requestData)
        if (response.data.result) {
            rateForChange.value = response.data.result
        }
    } catch (error) {
        console.error(error)
    }
    return true
}

// 출금전 인증 요청 발송
/**
 * type 1 - 카카오
 * type 2 - 구글 어센티케이터
 */
async function startAuthBeforeWithdraw(type) {
    try {
        const userId = sessionStorage.getUserId()
        authStartTime.value = Number(new Date().getTime())

        const response2 = await requestOutAuth({ id: userId, type: type })

        if (response2.success === false) {
            alert(response2.data.title)
        }
    } catch (error) {
        console.error(error)
    }
}

// 출금 신청, 인증 완료 버튼 클릭 시 시행될 함수, 출금 요청을 보냅니다.
async function prepareWithdrawOut({ authType, code }) {
    try {
        const requestData = {
            id: id.value,
            symbol: route.name === 'BalanceTypeWithSymbol' ? route.params.symbol : unitSymbol.value,
            toAddress:
                Number(selectedOption.value) === 1
                    ? innerWithdrawAddress.value
                    : userWalletAddress.value,
            amount: route.name === 'BalanceTypeWithSymbol' ? nftAmount.value : cryptoAmount.value,
            feeSymbol: defaultMarket.value,
            authType: authType,
            inexType: Number(selectedOption.value) + 1 || 1
        }
        if (Number(authType) === 2) {
            otpVerifyState.value = 'request_out'
            if (code) {
                requestData.code = code
            }
        } else {
            kakaoVerifyState.value = 'request_out'
        }
        const response = await requestCompleteAuthBlockChainOut(requestData)
        if (response?.data?.status === 'success') {
            if (Number(authType) === 2) {
                otpVerifyState.value = 'done'
            } else {
                kakaoVerifyState.value = 'done'
            }
            clearInterval(authVerifyIntervalId)
            step.value = 'withdraw_done'
            withdrawSuccessAlert()
        } else if (response?.data?.descript) {
            step.value = 'withdraw_error'
            kakaoVerifyState.value = null
            otpVerifyState.value = null
            clearInterval(authVerifyIntervalId)
            makeKakaoAuthFailAlert()
        } else {
            step.value = 'withdraw_error'
            kakaoVerifyState.value = null
            otpVerifyState.value = null
            clearInterval(authVerifyIntervalId)
            onErrorAlert()
        }
    } catch (error) {
        console.error(error)
        step.value = 'withdraw_error'
        kakaoVerifyState.value = null
        otpVerifyState.value = null
        clearInterval(authVerifyIntervalId)
        onErrorAlert()
    }
}

/**
 * 유저의 2채널 인증 정보 등의 데이터를 가져옵니다.
 */
async function initUserInformation() {
    try {
        const response = await initMemberInfomation(id.value)
        if (response) {
            if (Number(response.authOtp) === 1) {
                isUserDid2ChannelAuth.value = true
                userAuthOTP.value = 1
            }
            if (Number(response.authKakao) === 1) {
                isUserDid2ChannelAuth.value = true
                userAuthKakao.value = 1
            }
            userStore.setUserSecurityLevel(response.securityLevel)
        } else {
            logOut()
            router.go(0)
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * 특정 심볼의 출금 수수료 얻어와 세팅합니다.
 */
async function initGetOutFeeData(symbol) {
    exchangerApi
        .getOutFeeList()
        .then((response) => {
            feeData.value = response.outFee
            if (Number(route.params.isAsset) === 1) {
                // 해당하는 수수료 데이터를 세팅합니다.
                findFromFeeDataBySymbol(route.params.symbol, defaultMarket.value, 'nft')
            } else {
                findFromFeeDataBySymbol(unitSymbol.value, defaultMarket.value, 'coin')
            }
        })
        .catch((error) => {
            console.error('데이터를 가져오는 데 실패했습니다:', error)
        })
}

/**
 * 이미 가져온 feeData로부터 받아온 symbol, market, type 을 이용하여 원하는 조건의 수수료 조건별 데이터를 보여줍니다.
 */
function findFromFeeDataBySymbol(symbol, unit, type) {
    try {
        const temp = feeData.value
        const result = temp.find((item) => item.asset === symbol && item.unit === unit)
        fee.value = result?.fee ? result?.fee : 0
    } catch (error) {
        console.error(error)
    }
}

/**
 * 출금 탭에서 동의사항에 체크가 되었는지 아닌지를 토글 형식으로 바꿉니다.
 */
function toggleImgSelected(index) {
    checkedState.value[index] = !checkedState.value[index]
}

/**
 * 보유자산만 보기 토글 버튼 함수
 */
function changePossessedOnly() {
    onlyPossessedAsset.value = !onlyPossessedAsset.value
    if (onlyPossessedAsset.value === true) {
        sessionStorage.setItem('unsent_variable', 'Y')
    } else {
        sessionStorage.setItem('unsent_variable', 'N')
    }
}

/**
 * 엔에프티 혹은 마켓을 눌렀을 때 데이터를 가공
 * TODO 리팩토링 중인 함수
 */
async function selectThisSymbol(item, isAsset) {
    if (item.symbol === defaultMarket.value) {
        router.push({
            name: 'BalanceType',
            params: {
                type: route.params.type
            }
        })
    } else {
        router.push({
            name: 'BalanceTypeWithSymbol',
            params: {
                symbol: item.symbol,
                isAsset: isAsset,
                type: route.params.type
            }
        })
    }
}

/**
 * 블록체인 입금 주소가 있는지 체크합니다.
 */
async function checkBlockChainAddress(symbol) {
    try {
        isThisItemHaveAddress.value = false
        const response = await hasBlockChainAddress(id.value, symbol)
        if (response.result.address && response.result.address.length > 1) {
            isThisItemHaveAddress.value = true
            makeQrCode(response.result.address)
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * 입금 자산 선택 클릭시 실행될 함수
 * @param {*} symbol
 * @param {*} name
 */
async function moveToDepositItem(symbol, name) {
    try {
        if (isUseFiatCurrency.value === 0) {
            // 현재 자산의 공개 상태 데이터를 판단하기 위해 active 아이템에 assetList의 값을 넣습니다.
            const temp = assetList.value.find((item) => item.symbol === symbol)
            activeItem.value.state = temp.state
        }
        // 심볼의 수수료 데이터를 가져와 세팅합니다.
        initGetOutFeeData(symbol)
        // 입출금 가능 금액에 대한 요청을 보냅니다.
        requestAssetAvailable(symbol, 'in')
        // 입출금 제한금액에 대한 초기화
        initLimitData()
        outOnlyLimitData.value = await requestLimitMaxOut({ id: id.value, symbol, type: 'in' })
        // 입금 상태 설정
        depositProcess.value = 'prepare'
        // QR 코드 생성
        checkBlockChainAddress(symbol)

        // TODO api 변경 후 교체 예정
        if (symbol === 'BTS') {
            n_network.value = 'Bitstar'
        } else {
            n_network.value = 'Ethereum'
        }

        unitSymbol.value = symbol
        unitName.value = name
        // 현재 자산에 대한 수수료 데이터를 갱신합니다.
        findFromFeeDataBySymbol(unitSymbol.value, defaultMarket.value, 'coin')

        // 컨퍼메이션 횟수 체크
        requestConfirmationAsset({ symbol }).then((response) => {
            if (response.data.status === 'success') {
                confirmationCount.value = response.data.result.data
            }
        })

        initRateForChange({ market1: defaultMarket.value, market2: symbol })

        // 입출금 자산 선택 모달 OFF
        changeAssetDialogFalse()

        setDepositOrWithdrawAlert(unitName.value)
    } catch (error) {
        console.error(error)
    }
}

/**
 * 출금 자산 선택 클릭시 실행될 함수
 * @param {*} symbol
 * @param {*} name
 */
async function moveToWithdrawItem(symbol, name) {
    try {
        if (isUseFiatCurrency.value === 0) {
            // 현재 자산의 공개 상태 데이터를 판단하기 위해 active 아이템에 assetList의 값을 넣습니다.
            const temp = assetList.value.find((item) => item.symbol === symbol)
            activeItem.value.state = temp.state || 1
        }
        // 심볼의 수수료 데이터를 가져와 세팅합니다.
        initGetOutFeeData(symbol)
        // 입출금 제한금액에 대한 초기화
        initLimitData()
        outOnlyLimitData.value = await requestLimitMaxOut({ id: id.value, symbol, type: 'out' })
        // 출금 가능 자산에 대한 정보 가져오기
        requestAssetAvailable(symbol, 'out')

        // 출금 상태 설정
        withdrawProcess.value = 'prepare'
        // QR 코드 생성
        checkBlockChainAddress(symbol)

        // TODO api 변경 후 교체 예정
        if (symbol === 'BTS') {
            n_network.value = 'Bitstar'
        } else {
            n_network.value = 'Ethereum'
        }

        unitSymbol.value = symbol
        unitName.value = name

        // 현재 자산에 대한 수수료 데이터를 갱신합니다.
        findFromFeeDataBySymbol(unitSymbol.value, defaultMarket.value, 'coin')

        // 컨퍼메이션 횟수 체크
        requestConfirmationAsset({ symbol }).then((response) => {
            if (response.data.status === 'success') {
                confirmationCount.value = response.data.result.data
            }
        })

        initRateForChange({ market1: defaultMarket.value, market2: symbol })

        // 입출금 자산 선택 모달 OFF
        changeAssetDialogFalse()

        setDepositOrWithdrawAlert(unitName.value)
    } catch (error) {
        console.error(error)
    }
}

/**
 * 지갑 생성 버튼을 눌렀을 때 실행될 함수 - 가상자산
 */
async function prepareUserJigapAddressModalForVirtual() {
    modal_title.value = `주의사항 안내`
    confirm_btn.value = `확인`
    nested_msg.value = `명칭이 같더라도 전송 네트워크가 다른 경우 정상적으로 입금되지 않습니다. 출금할 때 사용하는 네트워크가 거래소에서 지원하는지 반드시 확인해주세요.<br/><br/>

Ethereum 기반 가상자산(작품)의 경우, 신규 발급 주소 이외의 입금 주소(기존 C.A. 기반 입금 주소)로 착오전송(오입금)이 발생할 경우, 해당 착오전송 건의 복구할 수 없습니다.<br/><br/>

<strong>${unitName.value}</strong>(${
        n_network.value ? changeToUpperCase(n_network.value) : 'Ethereum'
    })의 네트워크명과 주의사항을 충분히 읽고 확인하였습니다.`
    commandForModal.value = '가상자산지갑등록'
    changeConfirmDialogTrue()
}

/**
 * 지갑 생성 버튼을 눌렀을 때 실행될 함수 - 작품
 */
async function prepareUserJigapAddressModal() {
    modal_title.value = `주의사항 안내`
    confirm_btn.value = `확인`
    nested_msg.value = `명칭이 같더라도 전송 네트워크가 다른 경우 정상적으로 입금되지 않습니다. 출금할 때 사용하는 네트워크가 거래소에서 지원하는지 반드시 확인해주세요.<br/><br/>

Ethereum 기반 가상자산(작품)의 경우, 신규 발급 주소 이외의 입금 주소(기존 C.A. 기반 입금 주소)로 착오전송(오입금)이 발생할 경우, 해당 착오전송 건의 복구할 수 없습니다.<br/><br/>

<strong>${activeItem.value.name}</strong>(${
        n_network.value ? changeToUpperCase(n_network.value) : 'Ethereum'
    })의 네트워크명과 주의사항을 충분히 읽고 확인하였습니다.`
    commandForModal.value = '지갑등록'
    changeConfirmDialogTrue()
}

/**
 * 지갑 생성 버튼 클릭 후 생성된 모달창을 다 읽고 확인했을 때 실행될 함수
 */
async function prepareUserJigapAddress(item) {
    isThisItemHaveAddress.value = false
    const response = await makeBlockChainAddress(id.value, item)

    if (
        response.status === 'success' &&
        response.result.address &&
        response.result.address.length > 0
    ) {
        makeQrCode(response.result.address)
    }
    isThisItemHaveAddress.value = true
    changeConfirmDialogFalse()
}

/**
 * 입출금 > 출금 > 환급 신청(KRW의 출금) 버튼을 눌렀을 때 실행될 함수.
 */
async function prepareFiatOut() {
    // 출금에 대한 Validation을 진행합니다.
    modal_title.value = '환급가능 금액 초과 안내'
    nested_msg.value =
        '환급가능한 금액을 초과하였습니다.<br/>환급 금액을 변경하거나 마지막 환급일시 기준으로 24시간이 지난 후 다시 시도해주세요.'
    if (krwAmount.value === 0) {
        modal_title.value = '환급 안내'
        nested_msg.value = '환급 금액이 0입니다.<br/>환급 금액을 확인해주세요.'
    }

    changeNestedDialogTrue()
}

/**
 * 입출금 > 출금 > 출금 신청(가상자산의 출금) 버튼을 눌렀을 때 실행될 함수.
 */
async function prepareValidationFiatInfo() {
    // 출금에 대한 Validation을 진행합니다.
    try {
        if (cryptoAmount.value === 0 || !cryptoAmount.value) {
            modal_title.value = '출금 안내'
            nested_msg.value = '출금 금액이 0입니다.<br/>출금 금액을 확인해주세요.'
            changeNestedDialogTrue()
        } else if (Number(cryptoAmount.value) > Number(availableOut.value)) {
            modal_title.value = '출금가능 금액 초과 안내'
            nested_msg.value =
                '출금가능한 금액을 초과하였습니다.<br/>출금 금액을 변경하거나 마지막 출금일시 기준으로 24시간이 지난 후 다시 시도해주세요.'
            changeNestedDialogTrue()
        } else if (Number(cryptoAmount.value) > Number(outMaxLimitForItem.value)) {
            modal_title.value = '출금가능 금액 초과 안내'
            nested_msg.value =
                '출금가능한 금액을 초과하였습니다.<br/>출금 금액을 변경하거나 마지막 출금일시 기준으로 24시간이 지난 후 다시 시도해주세요.'
            changeNestedDialogTrue()
        } else if (fee.value > tokenList.value[0].current_balance) {
            modal_title.value = '출금 안내'
            nested_msg.value = '수수료가 부족합니다..'
            changeNestedDialogTrue()
        } else if (cryptoAmount.value > 0 && fee.value < tokenList.value[0].current_balance) {
            withdrawProcess.value = 'before2ChannelAuth'
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * 입출금 > 출금 > 2채널 인증(가상자산의 출금) 버튼을 눌렀을 때 실행될 함수.
 */
async function prepareFiatOutVirtual() {
    initSecurityModal()
    try {
        commandForModal.value = '가상자산출금신청'
        changeSecurityAuthDialogTrue()
    } catch (error) {
        console.error(error)
    }
}

/**
 * 입출금 > 출금 > 출금 신청(작품의 출금) 버튼을 눌렀을 때 실행될 함수.
 */
async function prepareValidationVirtualInfo() {
    initSecurityModal()
    // 출금에 대한 Validation을 진행합니다.
    try {
        if (nftAmount.value === 0 || !nftAmount.value) {
            modal_title.value = '출금 안내'
            nested_msg.value = '출금 금액이 0입니다.<br/>출금 금액을 확인해주세요.'
            changeNestedDialogTrue()
        } else if (Number(cryptoAmount.value) > Number(availableOut.value)) {
            modal_title.value = '출금가능 금액 초과 안내'
            nested_msg.value =
                '출금가능한 금액을 초과하였습니다.<br/>출금 금액을 변경하거나 마지막 출금일시 기준으로 24시간이 지난 후 다시 시도해주세요.'
            changeNestedDialogTrue()
        } else if (Number(cryptoAmount.value) > Number(outMaxLimitForItem.value)) {
            modal_title.value = '출금가능 금액 초과 안내'
            nested_msg.value =
                '출금가능한 금액을 초과하였습니다.<br/>출금 금액을 변경하거나 마지막 출금일시 기준으로 24시간이 지난 후 다시 시도해주세요.'
            changeNestedDialogTrue()
        } else if (fee.value > tokenList.value[0].current_balance) {
            modal_title.value = '출금 안내'
            nested_msg.value = '수수료가 부족합니다..'
            changeNestedDialogTrue()
        } else if (nftAmount.value > 0 && fee.value < tokenList.value[0].current_balance) {
            commandForModal.value = '가상자산출금신청'
            withdrawProcess.value = 'before2ChannelAuth'
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * 입출금 > 출금 > 2채널 인증 신청(작품의 출금) 버튼을 눌렀을 때 실행될 함수.
 */
async function prepareFiatOutNFT() {
    initSecurityModal()
    const requestData = {
        id: id.value,
        symbol: activeItem.value.symbol,
        toAddress: innerWithdrawAddress.value
    }

    let response
    let prt

    // 작품의 내부출금일 경우에만 실행됩니다.
    if (selectedOption.value === '1') {
        try {
            response = await checkInternalOutAddress(requestData)
            if (response?.data?.status === 'success') {
                // 데이터베이스에 존재하는 주소인지 확인합니다. 존재하지 않는 주소일 경우 프로세스를 진행하지 않습니다.
                if (response.data.result.isExist === false) {
                    onInnerWithdrawFailAlertA()
                    return
                }
                // 해당 심볼로 주소를 만들었는지 체크합니다. 존재하지 않을 경우 프로세스를 진행하지 않습니다.
                if (response.data.result.isExistSymbol === false) {
                    onInnerWithdrawFailAlertB()
                    return
                }
                // 본인 주소인 경우 프로세스를 진행하지 않습니다.
                if (response.data.result.notFromAddress === false) {
                    onInnerWithdrawFailAlertC()
                    return
                }
            } else if (response?.data?.error.includes('Not Found')) {
                // 오류가 생긴 경우 주소 형식에 대한 얼럿을 보여줍니다.
                onInnerWithdrawFailAlertB()
                return
            } else if (response?.data?.error) {
                // 어느쪽에도 해당하지 않을 경우 네트워크 에러 얼럿을 띄웁니다.
                onNetworkErrorAlert()
                return
            } else {
                // 어느쪽에도 해당하지 않을 경우 네트워크 에러 얼럿을 띄웁니다.
                onNetworkErrorAlert()
                return
            }
        } catch (error) {
            console.error(error)
            // 네트워크 에러를 반환합니다.
            onNetworkErrorAlert()
            return
        }
    }

    try {
        commandForModal.value = '가상자산출금신청'
        changeSecurityAuthDialogTrue()
    } catch (error) {
        console.error(error)
    }
}

/**
 * 출금 모달창(보안인증 모달창)에서 취소 버튼을 눌렀을 때 실행될 함수 입니다.
 */
function cancelWithdrawProcess() {
    // 인증 단계 초기화
    security_auth_process.value = 0
    // 카카오 인증 상태 초기화
    kakaoVerifyState.value = null
    // OTP 인증 상태 초기화
    otpVerifyState.value = null
    // timer interval 초기화
    clearInterval(authVerifyIntervalId)
    // 모달 닫기
    closeModalAndMakeCanceledAlert()
}

function changeView(where) {
    if (!where) {
        pages.value = 0
    } else {
        pages.value = where
    }
}

/**
 * 가상자산에서 수수료 select를 눌렀을 때 실행될 함수
 */
function changedFeeData(symbol, market, type) {
    findFromFeeDataBySymbol(symbol, market, type)
}

/**
 * 백업코드를 클립보드로 복사하기 위한 함수입니다.
 */
function copyBackUpCodeToClipboard(destination) {
    copy(n_address.value)
    modal_title.value = '주소 복사 완료'
    nested_msg.value = `${destination}(${changeToUpperCase(
        n_network.value
    )})의 입금주소가 복사되었습니다.<br/>
주소를 붙여넣기 한 후, 현재 화면과 비교하고 일치하는지 한 번 더 확인해주세요.`
    changeNestedDialogTrue()
}

/**
 * QrCode를 생성합니다.
 */
function makeQrCode(newAddress) {
    n_address.value = newAddress
    qrDataLoading.value = true
}

// 모달창 컨트롤용 함수들
// 인증 실패 안내 얼럿
function makeKakaoAuthFailAlert() {
    modal_title.value = '인증실패 안내'
    nested_msg.value = `인증이 완료되지 않았거나 일치하지 않는 정보입니다.등록한 인증 정보를 확인한 후 다시 시도해주세요.`
    changeNestedDialogTrue()
}

// 출금 신청 성공 얼럿
function withdrawSuccessAlert() {
    modal_title.value = '안내'
    step.value = 'withdraw_success'
    nested_msg.value = `성공적으로 출금 신청되었습니다.`
    changeNestedDialogTrue()
}

// 네트워크 오류 실패 얼럿
function onNetworkErrorAlert() {
    modal_title.value = `안내`
    step.value = 'error'
    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
    changeNestedDialogTrue()
}

// 출금 신청 오류 실패 얼럿
function onErrorAlert() {
    modal_title.value = `승인오류 안내`
    step.value = 'error'
    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
    changeNestedDialogTrue()
}

function onInnerWithdrawFailAlertA() {
    modal_title.value = '안내'
    step.value = 'error'
    nested_msg.value = `입력하신 네트워크 주소가 카키에서 발급되었는지 확인 후 다시 입력해주세요.`
    changeNestedDialogTrue()
}

function onInnerWithdrawFailAlertB() {
    modal_title.value = '안내'
    step.value = 'error'
    nested_msg.value = `입금주소가 올바르지 않습니다. 확인 후에 다시 출금을 시도해주세요.`
    changeNestedDialogTrue()
}

function onInnerWithdrawFailAlertC() {
    modal_title.value = '안내'
    step.value = 'error'
    nested_msg.value = `본인 계정의 카키 주소로는 출금할 수 없습니다. 주소를 확인해주세요.`
    changeNestedDialogTrue()
}
</script>

<style scoped>
.activeTitle {
    font-weight: 700px;
    color: var(--main_color) !important;
}
.active {
    background-color: rgba(10, 138, 29, 0.1);
}
#qrcode {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

.v-input__details {
    display: none;
    padding: 0;
}

.searchBox:focus {
    outline: none;
    border: none; /* 만약 border도 제거하고 싶다면 추가 */
}
.focused-style {
    border: 1.5px solid #222421; /* 원하는 border 색상을 적용하세요 */
    background-color: #f2f2f2;
}

.pb_override {
    padding-bottom: 88px !important;
}

.asset_item:hover {
    cursor: pointer;
    background-color: rgba(10, 138, 29, 0.1);
}

.asset_item:hover .asset_name {
    border-bottom: 1px solid black;
}

/* [type='radio'] {
    vertical-align: middle;
    appearance: none;
    border: max(2px, 0.1em) solid gray;
    border-radius: 50%;
    width: 1.25em;
    height: 1.25em;
    transition: all 0.2s ease;
}

input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--black);
}

[type='radio']:checked {
    background: #222431;
}

[type='radio']:focus-visible {
    outline: max(2px, 0.1em) dotted tomato;
    outline-offset: max(2px, 0.1em);
}

[type='radio']:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
}

[type='radio']:hover + span {
    cursor: pointer;
}

[type='radio']:disabled {
    background-color: lightgray;
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
}

[type='radio']:disabled + span {
    opacity: 0.7;
    cursor: not-allowed;
} */
</style>
