<!-- 2채널 인증용 모달창 -->
<template>
    <v-dialog v-model="this_dialog" persistent width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    2채널 인증
                </div>
                <br />
                <div v-if="process === 0" class="text-[15px] flex flex-col">
                    <div 
                        v-if="Number(this_authKakao) === 1"
                        class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                        @click.prevent="moveToKakaoAuth()"
                    >
                        카카오페이 인증
                    </div>
                    <div
                        v-else
                        class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#f2f2f2]"
                        @click.prevent
                    >
                        카카오페이 인증
                    </div>
                    <div
                        v-if="Number(this_authOtp) === 1"
                        class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                        @click.prevent="moveToGoogleOTPAuth()"
                    >
                        구글 어센티케이터 인증
                    </div>
                    <div
                        v-else
                        class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#f2f2f2]"
                        @click.prevent
                    >
                        구글 어센티케이터 인증
                    </div>
                </div>
                <!-- 카카오 페이 인증을 클릭했을 때 -->
                <div v-else-if="process === 1" class="text-[15px] flex flex-col">
                    <div 
                        class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#f2f2f2]"
                        @click.prevent
                    >
                        카카오페이 인증
                    </div>
                    <div 
                    class="w-[400px] h-[49px] flex justify-center items-center text-white bg-main_color clickable rounded mb-[11px]"
                        @click="prepareVerifyKakaoAuth"
                    >
                        인증완료
                    </div>
                    <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                        <p>등록된 휴대폰 번호로 카카오페이 인증 요청이 발송되었습니다.</p>
                        <p>카카오톡 안내에 따라 인증 절차를 완료해주세요.({{ authRemainTime || '0:00' }})</p>
                    </span>
                </div>

                <!-- 구글 어센티케이터를 클릭했을 때 -->
                <div v-else-if="process === 2" class="text-[15px] flex flex-col w-[400px]">
                    <div 
                        class="w-[400px] h-[49px] pa-3 border bg-[#f2f2f2]"
                        @click.prevent
                    >
                        구글 어센티케이터 인증
                    </div>
                    <div
                        class="flex items-center justify-center mt-[10px] mb-[11px]"
                    >
                        <input
                            @keydown="handleKeydown"
                            v-model="userOtpVerifyNumber"
                            class="w-[321px] h-[50px] sub_border1 pa-4"
                            type="text"
                            placeholder="인증번호를 입력해주세요"
                            :disabled="otpVerifyState === 'ready' || otpVerifyState === 'done'"
                            :class="isOtpAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                            maxlength="6"
                        />
                        <button
                            v-if="otpVerifyState === 'send'"
                            class="mypage_btn_type1"
                            :class="[!userOtpVerifyNumber || userOtpVerifyNumber.length < 6 ? 'bg-[#F2F2F2] text-gray sub_border1' : '']"
                            :disabled="!userOtpVerifyNumber ||userOtpVerifyNumber.length < 6"
                            @click.prevent="prepareVerifyOtpAuth"
                        >
                            인증하기
                        </button>
                        <button
                            v-else
                            class="mypage_btn_type1 text-gray sub_border1 bg-[#F4F4F4]"
                            @click.prevent
                        >
                            인증하기
                        </button>
                    </div>

                    <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                        <p>인증번호 6자리를 작성해주세요.({{ authRemainTime || '0:00' }})</p>
                    </span>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="w-[90px] h-[43px] border"
                    color="black"
                    @click="()=>{
                        cancelWithdrawProcess();
                    }"
                >
                    취소
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="alert_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn :class="`modal_confirm_btn w-[90px]`" color="white" @click="()=>{
                    afterAuthActionFunction();
                }">
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { computed, ref, onMounted, watch, toRefs } from 'vue';
import { handleKeydown } from '@/utils/utilsFactory.js'
import { sendSignKakao, sendVerifyKakao, initUpdatePasswordAuth, initUpdatePasswordAuthCheck } from '../../api/auth/kakao';
import UtilSessionStorage from '../../utils/session';
import { verifyOtpKey } from '../../api/auth/otp';

const sessionStorage = new UtilSessionStorage();

// props 선언
const props = defineProps({
    whatChangeFor : {
        type : String,
    },
    dialog : {
        type : Boolean,
        default : false,
    },
    changeDialog : {
        type : Function,
    },
    authKakao : {
        type : Number,
        default : 0,
    },
    authOtp : {
        type : Number,
        default : 0,
    },
    // 반환값을 받았을 때 부모가 어떤 함수를 실행할지 받아서 시행합니다.
    afterCompleteAction : {
        type : Function,
    },
    // 실패시에 어떤 액션을 취할지 받아서 시행합니다.
    afterFailAction : {
        type : Function,
    },
    // 0으로 받거나 존재하지 않을 경우 기본값으로 시작, 존재할 경우 앞의 단계 생략 후 인증
    startProcess : {
        type : Number,
        default : 0,
    },
    // startProcess를 0으로 초기화합니다.
    initStartProcess : {
        type : Function,
    }
})

// props convert to ref
const {
    startProcess
} = toRefs(props)
// props 값 내부 변수화
// 모달창의 on off 상태
const this_dialog = computed(()=>{
    let result = false;
    result = props.dialog;
    return result;
})
// 카카오 인증 상태
const this_authKakao = computed(()=>{
    let result = false;
    result = props.authKakao;
    return result;
})
// OTP 인증 상태
const this_authOtp = computed(()=>{
    let result = false;
    result = props.authOtp;
    return result;
})
// 내부 상태 관리용 변수
const process = ref(0) ;

// 추가 중첩 모달창 관리용 변수
const modal_title = ref('안내');
const alert_dialog = ref(false)    
const nested_msg = ref('') 
function changeInnerDialogTrue () { alert_dialog.value = true; }
function changeInnerDialogFalse () { alert_dialog.value = false; }
function afterAuthActionFunction(){
    changeInnerDialogFalse();
    if( authSuccessOrFail.value ){
        props.afterCompleteAction();
    } else {
        props.afterFailAction();
    }
    initSecurityModal();
}


// 인증
// 인증의 성공 실패 상태
const authSuccessOrFail = ref(false);
const authRemainTime = ref('0:00');
let authVerifyIntervalId = null;
// 인증 제한시간 (초 단위)
const authLimitTime = ref(180)
// 인증버튼 눌렀을 때 저장될 인증 시작 시간
const authStartTime = ref(null)
// 인증 잔여시간 (단위 : 밀리초)
const authVerifyTime = ref(0)

// 카카오페이 인증 관리용 변수
// 카카오페이 인증 진행 상태
const kakaoVerifyState = ref(null);

// OTP 인증 관리용 변수
// OTP 인증 번호
const userOtpVerifyNumber = ref(null);
// OTP 인증 진행 상태
const otpVerifyState = ref(null);
// OTP 인증 완료 여부
const isOtpAuthCompleted = ref(false);

onMounted(async ()=>{
    const id = sessionStorage.getUserId();

    watch(kakaoVerifyState, () => {
        if (kakaoVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0) {
                        clearInterval(authVerifyIntervalId)
                        kakaoVerifyState.value = 'ready'
                        makeKakaoAuthFailAlert();
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(
                        authVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    watch(otpVerifyState, () => {
        if (otpVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0) {
                        clearInterval(authVerifyIntervalId)
                        otpVerifyState.value = 'ready'
                        makeKakaoAuthFailAlert();
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(
                        authVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    watch( startProcess, (newValue)=>{
        if( newValue === 1 ){
            moveToKakaoAuth();
        } else if ( newValue === 2 ){
            moveToGoogleOTPAuth();
        }
    })

})




function closeModalAndMakeCanceledAlert(){
  props.changeDialog();
  modal_title.value = `안내`;
  nested_msg.value = `보안인증이 취소되었습니다.`
  changeInnerDialogTrue();
}


// functions
// page render
/**
 * 카카오 인증 기준으로 컴포넌트를 다시 랜더링합니다.
 */
function moveToKakaoAuth(){
    kakaoVerifyState.value = 'send';
    process.value = 1;
    startAuthBeforeWithdraw(1);
}
/**
 * 구글 OTP 인증 기준으로 컴포넌트를 다시 랜더링합니다.
 */
function moveToGoogleOTPAuth(){
    otpVerifyState.value = 'ready';
    process.value = 2;
    startAuthBeforeWithdraw(2);
}

// 인증 요청 발송
/**
* type 1 - 카카오 
* type 2 - 구글 어센티케이터
*/
async function startAuthBeforeWithdraw( type ){
    try {
        const requestData = {
            id : sessionStorage.getUserId(),
        }
        authStartTime.value = Number(new Date().getTime())
        // 카카오 인증일 경우의 처리 로직
        if( props.whatChangeFor === '비밀번호변경' ){
            if( type === 1 ){
                kakaoVerifyState.value = 'send';
                requestData.type = 1;
                
            } else if( type === 2 ){
                otpVerifyState.value = 'send';
                requestData.type = 2;
            }
            const response2 = await initUpdatePasswordAuth( requestData );
            if( response2.success === false ){
                alert(response2.data.title);
            } 
        } else {
            if( type === 1 ){
                kakaoVerifyState.value = 'send';
                requestData.type = 1;
            } else if( type === 2 ){
                otpVerifyState.value = 'send';
                requestData.type = 2;
                return;
            }
            const response2 = await sendSignKakao( requestData );
            if( response2.success === false ){
                alert(response2.data.title);
            } 
        }
        
    } catch (error) {
        console.error(error)
    }
}

// 카카오 인증 완료 버튼 클릭 시 시행될 함수
async function prepareVerifyKakaoAuth(){
    
    const requestData = {
        id : sessionStorage.getUserId(),
        type : 1,
    }
    try {
        if( props.whatChangeFor === '비밀번호변경' ){
            requestData.value = 1;
            const response = await initUpdatePasswordAuthCheck( requestData );
            if( response.status === 'success' ){
                kakaoVerifyState.value = 'done';
                authSuccessOrFail.value = true;
                modal_title.value = `안내`;
                nested_msg.value = `성공적으로 인증되었습니다.`
                changeInnerDialogTrue();
            } else {
                kakaoVerifyState.value = 'error';
                modal_title.value = `안내`;
                nested_msg.value = `인증이 완료되지 않았거나 일치하지 않는 정보입니다. 등록한 인증 정보를 확인한 후 다시 시도해주세요.`
                changeInnerDialogTrue();
            }
        } else {
            const response = await sendVerifyKakao( requestData );
            if( response.status === 'success' ){
                kakaoVerifyState.value = 'done';
                authSuccessOrFail.value = true;
                modal_title.value = `안내`;
                nested_msg.value = `성공적으로 인증되었습니다.`
                changeInnerDialogTrue();
            } else {
                kakaoVerifyState.value = 'error';
                modal_title.value = `안내`;
                nested_msg.value = `인증이 완료되지 않았거나 일치하지 않는 정보입니다. 등록한 인증 정보를 확인한 후 다시 시도해주세요.`
                changeInnerDialogTrue();
            }
        }
    } catch (error) {
        console.error(error)
    }
    clearInterval(authVerifyIntervalId);
}

// Otp 인증 완료 버튼 클릭시 시행될 함수
async function prepareVerifyOtpAuth(){
    const requestData = {
        id : sessionStorage.getUserId(),
        type : 2,
    }
    try {
        if( props.whatChangeFor === '비밀번호변경' ){
            requestData.type = 2;
            requestData.code = userOtpVerifyNumber.value;
            const response = await initUpdatePasswordAuthCheck( requestData );
            if( response.status === 'success' ){
                modal_title.value = `안내`;
                nested_msg.value = `성공적으로 인증되었습니다.`
                otpVerifyState.value = 'done';
                authSuccessOrFail.value = true;
                changeInnerDialogTrue();
            } else {
                otpVerifyState.value = 'error';
                modal_title.value = `안내`;
                nested_msg.value = `인증이 완료되지 않았거나 일치하지 않는 정보입니다. 등록한 인증 정보를 확인한 후 다시 시도해주세요.`
                changeInnerDialogTrue();
            }
        } else {
            requestData.content = userOtpVerifyNumber.value;
            const response = await verifyOtpKey( requestData );
            if( response.status === 'success' ){
                modal_title.value = `안내`;
                nested_msg.value = `성공적으로 인증되었습니다.`
                otpVerifyState.value = 'done';
                authSuccessOrFail.value = true;
                clearInterval(authVerifyIntervalId)
                changeInnerDialogTrue();
            } else {
                otpVerifyState.value = 'error';
                modal_title.value = `안내`;
                nested_msg.value = `인증이 완료되지 않았거나 일치하지 않는 정보입니다. 등록한 인증 정보를 확인한 후 다시 시도해주세요.`
                changeInnerDialogTrue();
            }
        }
    } catch (error) {
        console.error(error)
    }
    clearInterval(authVerifyIntervalId);
}


// 2채널 인증 모달에 들어가는 정보들을 전부 초기값으로 세팅합니다.
function initSecurityModal(){
    // 인증 단계 초기화
    process.value = 0;
    // 카카오 인증 상태 초기화
    kakaoVerifyState.value = null;
    // OTP 인증 상태 초기화
    otpVerifyState.value = null;
    // 진행한 2채널 인증 데이터 초기화
    try {
        if( props.initStartProcess() ){
            props.initStartProcess();
        }
    } catch (error) {
        console.error(error)
    }
    // user가 입력한 verify code 초기화
    userOtpVerifyNumber.value = null;
    // 인증 성공 실패 상태 초기화
    authSuccessOrFail.value = false;
    // interval 초기화
    clearInterval(authVerifyIntervalId);
}


/**
 * 2채널 인증 모달창에서 취소 버튼을 눌렀을 때 실행될 함수 입니다.
 */
 function cancelWithdrawProcess(){
    // 모달 상태 초기화
    initSecurityModal();
    // 모달 닫기
    closeModalAndMakeCanceledAlert();
}


// 인증 실패 안내 얼럿
function makeKakaoAuthFailAlert(){
    // modalAction.value = '인증실패';
    modal_title.value = '안내';
    // modal_btn_msg.value = '확인';
    nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`
    // changeAlertDialogTrue();
    changeInnerDialogTrue();
}

</script>