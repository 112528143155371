<template>
    <!-- 푸터 영역 -->
    <footer
        v-if="loading"
        :class="[
            'flex flex-col tracking-tight justify-between items-center bg-main_color its-green'
        ]"
    >
        <!-- 일반 페이지 푸터 -->
        <div class="footer-wrpper md:w-[1440px] pl-[20px] pr-[44px] md:h-[290px]">
            <!-- 푸터의 위쪽 부분, Copyright를 제외한 전체 영역의 내용이 들어갑니다. -->
            <div
                class="relative flex flex-col md:flex-row md:justify-between footer-upper md:h-[289px] pt-[40px] text-center"
            >
                <!-- 푸터 위쪽의 왼쪽 섹션 -->
                <section class="flex mb-[15px]">
                    <div
                        class="md:max-w-[143px] md:h-[60px] md:mr-[45px] pc_only px-[20px] flex items-center"
                    >
                        <img src="/asset/img/logo_secondary.svg" style="height: 26px" />
                    </div>
                    <div
                        class="flex-col contents_primary md:leading-4 footer-contents text-noto-400"
                    >
                        <ul class="flex flex-col md:flex-row md:mb-[7px] md:leading-4">
                            <li class="__lang">해피데이 주식회사</li>
                            <li class="hidden md:block">｜</li>
                            <li class="__lang">대표 김영전</li>
                            <li class="hidden md:block">｜</li>
                            <li class="__lang">사업자등록번호 675-88-02417</li>
                            <li class="hidden md:block">｜</li>
                            <li class="__lang">통신판매업 신고번호: 제 2023-전주덕진-0209</li>
                        </ul>
                        <ul class="flex flex-col md:flex-row md:mb-[7px] md:leading-4">
                            <li class="__lang">대표 이메일: happyday@bitstar.co.kr</li>
                            <li class="hidden md:block">｜</li>
                            <li class="__lang">대표 연락처: 010-2206-0028</li>
                        </ul>
                        <ul class="flex flex-col md:flex-row md:mb-[7px] md:leading-4">
                            <li class="__lang">
                                (54860) 전라북도 전주시 덕진구 만성서로 80, 402호(만성동)
                            </li>
                            <li class="hidden md:block">｜</li>
                            <li class="__lang">Hosting by (주)후이즈</li>
                        </ul>
                        <div class="__lang md:leading-4 md:text-left">
                            Copyright ⓒ {{ curruntYearString }} Happyday Inc. All rights reserved.
                        </div>
                    </div>
                </section>
                <!-- 푸터 위쪽의 오른쪽 섹션 -->
                <section class="flex md:text-[13px] pt-1">
                    <div class="md:mr-[105px] md:text-left pc_only">
                        <ul>
                            <li class="md:mb-[15px]">
                                <p class="__lang text-noto-700 text-[#FFFF8D]">회사 정보</p>
                            </li>
                            <li class="md:mb-[7px] footer-item text-noto-350">
                                <a
                                    href="https://bitstar.co.kr/"
                                    class="__lang hover:text-inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >회사 소개</a
                                >
                            </li>
                            <li class="md:mb-[7px] footer-item text-noto-350">
                                <a
                                    href="https://www.jobkorea.co.kr/Recruit/Co_Read/C/34910360"
                                    class="__lang hover:text-inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >채용</a
                                >
                            </li>
                            <li class="md:mb-[7px] footer-item text-noto-350">
                                <a
                                    href="https://bitstar.co.kr/"
                                    class="__lang hover:text-inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >홍보</a
                                >
                            </li>
                            <li class="footer-item text-noto-350">
                                <a
                                    href="https://bitstar.co.kr/"
                                    class="__lang hover:text-inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >거래지원/제휴</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="w-full md:w-auto">
                        <ul class="grid grid-cols-3 md:grid-cols-1 md:text-left w-full">
                            <li class="md:mb-[15px] pc_only">
                                <p class="__lang text-noto-700 text-[#FFFF8D]">고객지원</p>
                            </li>
                            <li class="mb-[7px] footer-item text-noto-350">
                                <RouterLink to="/support/notice" class="__lang hover:text-inherit"
                                    >공지사항
                                </RouterLink>
                            </li>
                            <li
                                class="md:mb-[7px] footer-item text-noto-350 col-span-2 md:col-span-1"
                            >
                                <RouterLink to="/support/faq" class="__lang hover:text-inherit"
                                    >자주하는 질문
                                </RouterLink>
                            </li>
                            <li class="mb-[7px] footer-item text-noto-350">
                                <RouterLink
                                    v-if="checkLoginStatus()"
                                    to="/support/help"
                                    class="__lang hover:text-inherit"
                                    >1:1 문의
                                </RouterLink>
                                <div v-else class="clickable" @click="changeNestedDialogTrue()">
                                    1:1 문의
                                </div>
                            </li>
                            <!-- 1차 개발, 데모에서 제외 -->
                            <!-- <li class="mb-[7px] footer-item">
                                <RouterLink to="#" class="__lang hover:text-inherit"
                                    >OPEN API
                                </RouterLink>
                            </li> -->
                            <li class="mb-[7px] footer-item text-noto-350">
                                <RouterLink to="/privacy_policy" class="__lang hover:text-inherit"
                                    >이용약관
                                </RouterLink>
                            </li>
                            <!-- 1차 개발, 데모에서 제외 -->
                            <!-- <li class="mb-[7px] footer-item">
                                <RouterLink to="#" class="__lang hover:text-inherit"
                                    >정보보안
                                </RouterLink>
                            </li> -->
                            <li
                                class="footer-item text-noto-700 col-span-2 md:col-span-1 text-[#FDD835]"
                            >
                                <RouterLink
                                    to="/terms"
                                    class="__lang text-[#FDD835] hover:text-inherit"
                                    >개인정보처리방침
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </footer>

    <!-- alert용 모달 -->
    <v-dialog v-model="alert_dialog" persistent width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="modal_cancel_btn border w-[77px] h-[43px]"
                    color="black"
                    @click="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
                <v-btn
                    :class="`modal_confirm_btn w-[${90}px]`"
                    color="white"
                    @click="
                        () => {
                            changeNestedDialogFalse()
                            $router.push({ path: '/login' })
                        }
                    "
                >
                    로그인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { checkLoginStatus } from '@/utils/member'

const curruntYearString = ref('2023')

const loading = ref(false);

// 모달창 컨트롤용 변수
const alert_dialog = ref(false)
const modal_title = ref('안내')
const nested_msg = ref(`로그인이 필요한 서비스입니다.<br/>로그인 후 이용해주세요.`)
const step = ref('')

// 모달창 컨트롤 함수
function changeNestedDialogTrue() {
    alert_dialog.value = true
}
function changeNestedDialogFalse() {
    alert_dialog.value = false
}

function getCurrentYearString() {
    const currentYear = new Date().getFullYear() // 현재 연도를 가져옵니다.
    return currentYear.toString() // 숫자 타입인 연도를 문자열로 변환하여 반환합니다.
}

onMounted(() => {
    curruntYearString.value = getCurrentYearString()

    nextTick(()=>{
        loading.value = true;
    })
})
</script>

<style scoped>
.its-green .footer-contents {
    /* color: var(--stroke); */
    color: #eee;
}
.its-green .footer-item {
    color: #ffffff;
}
.its-green .is-it-green path {
    fill: #ffffff;
}
</style>
