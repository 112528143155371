/* eslint-disable consistent-return */
/* 공지사항 관련 데이터를 서버에 요청하는 로직 모음 */
import axios from 'axios';
import { requestHeader } from '../config';

/**
 * 서버에 공지사항 목록 데이터 요청
 * @returns 
 */
export async function getAllNoticeList({ page, title }){
  try {
    const requestData = {}
    if( page !== undefined && page !== null ) requestData.page = page
    else requestData.page = 1
    if( title !== undefined && title !== null ) requestData.title = title
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/notice/list`, requestData, requestHeader());
    return response.data.result
  } catch (error) {
    console.error(error)
  }
}


/**
 * 공지사항 상세보기
 * @param {*} requestData 
 * @returns 
 */
export async function getNoticeInfo( requestData ){
  return axios.post(`${process.env.VUE_APP_BACKEND}/notice/info`, requestData, requestHeader());
}