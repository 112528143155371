<!-- 거래현황 페이지 -->
<template>
    <main class="trading-monitor">
        <div class="flex justify-center pt-4 pb-3 -translate-x-1">
            <section class="left-tab mr-4">
                <ul>
                    <li :class="route.path === '/trading/myAssets' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
                        <router-link to="/trading/myAssets"><div class="w-[190px] h-[60px]">나의자산</div></router-link>
                    </li>
                    <li :class="route.path === '/trading/tradingHistory' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
                        <router-link to="/trading/tradingHistory"><div class="w-[190px] h-[60px]">거래내역</div></router-link>
                    </li>
                    <li :class="route.path === '/trading/pendingTransactions' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
                        <router-link to="/trading/pendingTransactions"><div class="w-[190px] h-[60px]">미체결내역</div></router-link>
                    </li>
                    <li :class="route.path === '/trading/withdrawalHistory' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
                        <router-link to="/trading/withdrawalHistory"><div class="w-[190px] h-[60px]">입출금내역</div></router-link>
                    </li>
                </ul>
            </section>
            <section class="container w-[1030px] border">
                <!-- /trading 루트의 child component 들이 랜더링되는 공간입니다. -->
                <RouterView></RouterView>
            </section>
        </div>
        
    </main>
</template>

<script setup>
import { onBeforeRouteUpdate, useRoute } from 'vue-router';

const route = useRoute();

onBeforeRouteUpdate(async (to, from) => {
    try {
        // 이 부분에 라우트 업데이트 전에 수행할 로직을 작성합니다.
    } catch (error) {
        console.error(error)
    }
});


</script>