/* eslint-env node */
/**
 * @file `country-reverse-geocoding.cjs`
 * (A CommonJS module exporting a default and named entity.)
 */
var countryReverseGeocoding = require('country-reverse-geocoding').country_reverse_geocoding;

var crg = countryReverseGeocoding();

function reverseGeocoding(lat = 37.5108317, long = 127.0291833) {
  var country = crg.get_country(lat, long);
 
  return country.code;
}

module.exports = reverseGeocoding;

