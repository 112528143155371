import HelpID from '../../pages/HelpID.vue'
import HelpPwd from '../../pages/HelpPwd.vue'

export default [
    {
        path: '/help',
        name: 'help',
        children: [
            {
                path: 'findId',
                name: 'findId',
                component: HelpID
            },
            {
                path: 'findPwd',
                name: 'findPwd',
                component: HelpPwd
            },

        ]
    }
]
