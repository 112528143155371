<!-- 마이페이지 -> 본인인증 -->
<template>
  <!-- 엔드포인트는 하나에서 div hide and show 형식으로 구성해야할 것 입니다. -->
  <div class="certification01 w-[1030px] bg-[#fff] basicBoxShadow">
    <!-- title 영역 -->
    <div class="tm1 border-b px-4 py-2">
        <p class="__lang subTitle text-noto-700 flex">
            본인인증 
        </p>
    </div>

    <!-- progress tab, 모든 본인 인증이 완료되었을 경우 hide -->
    <section v-if="isitActice < 6" class="flex">
      <!-- 1번 엠블렘, 휴대폰 인증 -->
      <div 
        :class="isitActice === 1 ? 'active' : ''" 
        class="w-1/5 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
      >
        <!-- 여기까지 온 회원들은 휴대폰 인증이 완료된 상태이기 때문에 조건부 랜더링을 실행하지 않습니다. -->
        <div><CheckedIcon /></div>
        <p class="__lang">휴대폰 인증</p>
      </div>
      <!-- 1번 엠블렘 종료 -->

      <!-- 2번 엠블렘, 신분증 인증 -->
      <div 
        :class="isitActice === 2 ? 'active shadow-auth_tab' : ''" 
        class="w-1/5 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
      >
        <div v-if="isitChecked(2)"><CheckedIcon /></div>
        <div v-else>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z" stroke="black"/>
<path d="M12.9713 25V23.3C16.9913 19.7 19.3713 16.92 19.3713 14.64C19.3713 13.14 18.5713 12.24 17.1513 12.24C16.0713 12.24 15.1913 12.94 14.4313 13.76L12.8313 12.16C14.1913 10.7 15.5113 9.92 17.5313 9.92C20.3313 9.92 22.1913 11.72 22.1913 14.48C22.1913 17.16 19.9713 20.04 17.2713 22.68C18.0113 22.6 18.9913 22.52 19.6913 22.52H22.8913V25H12.9713Z" fill="black"/>
</svg>
        </div>
        <p class="__lang">신분증 인증</p>
      </div>
      <!-- 2번 엠블렘 종료 -->

      <!-- 3번 엠블렘, 계좌 1원 인증 -->
      <div 
        :class="isitActice === 3 ? 'active shadow-auth_tab' : ''" 
        class="w-1/5 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
      >
        <div v-if="isitChecked(3)"><CheckedIcon /></div>
        <div v-else>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z" stroke="#222421"/>
<path d="M17.5913 25.28C15.2513 25.28 13.6713 24.4 12.6313 23.22L14.0113 21.36C14.8913 22.22 15.9713 22.88 17.3113 22.88C18.8113 22.88 19.8313 22.14 19.8313 20.8C19.8313 19.36 18.9713 18.44 15.7513 18.44V16.34C18.4713 16.34 19.3513 15.4 19.3513 14.08C19.3513 12.92 18.6313 12.24 17.3713 12.24C16.3113 12.24 15.4513 12.72 14.5713 13.54L13.0713 11.74C14.3513 10.62 15.7713 9.92 17.5113 9.92C20.3713 9.92 22.3113 11.34 22.3113 13.86C22.3113 15.46 21.4113 16.62 19.8513 17.24V17.34C21.5113 17.8 22.7913 19.04 22.7913 20.98C22.7913 23.7 20.4113 25.28 17.5913 25.28Z" fill="#222421"/>
</svg>

        </div>
        <p class="__lang">계좌 1원 인증</p>
      </div>
      <!-- 3번 엠블렘 종료 -->

      <!-- 4번 엠블렘, 거주지 정보 작성 -->
      <div 
        :class="isitActice === 4 ? 'active shadow-auth_tab' : ''" 
        class="w-1/5 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
      >
        <div v-if="isitChecked(4)"><CheckedIcon /></div>
        <div v-else>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z" stroke="#222421"/>
<path d="M15.3913 18.92H18.8513V15.9C18.8513 15.04 18.9113 13.7 18.9513 12.82H18.8713C18.5113 13.62 18.1113 14.44 17.6913 15.24L15.3913 18.92ZM18.8513 25V21.16H12.5313V19.16L18.0513 10.18H21.5913V18.92H23.3313V21.16H21.5913V25H18.8513Z" fill="#222421"/>
</svg>
        </div>
        <p class="__lang">거주지 정보 작성</p>
      </div>
      <!-- 4번 엠블렘 종료 -->

      <!-- 5번 엠블렘, 직업 정보 작성 -->
      <div 
        :class="isitActice === 5 ? 'active shadow-auth_tab' : ''" 
        class="w-1/5 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
      >
        <div v-if="isitChecked(5)"><CheckedIcon /></div>
        <div v-else>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z" stroke="#222421"/>
<path d="M17.6313 25.28C15.2913 25.28 13.7313 24.36 12.6313 23.26L13.9913 21.38C14.8313 22.2 15.8713 22.88 17.2513 22.88C18.7913 22.88 19.8713 21.9 19.8713 20.16C19.8713 18.44 18.8713 17.48 17.3713 17.48C16.4913 17.48 16.0113 17.7 15.1513 18.26L13.8113 17.38L14.2113 10.18H22.1313V12.66H16.7513L16.4713 15.8C17.0713 15.52 17.5713 15.38 18.2513 15.38C20.7313 15.38 22.8113 16.86 22.8113 20.08C22.8113 23.38 20.3713 25.28 17.6313 25.28Z" fill="#222421"/>
</svg>
        </div>
        <p class="__lang">직업 정보 작성</p>
      </div>
      <!-- 5번 엠블렘 종료 -->
    </section>
    <!-- 인증 progressTab 종료 -->

    <!-- window content 영역 -->
    <!-- 신분증 인증 작성 body, 본인인증 페이지 왔을 때 main으로 display -->
    <section 
      v-if="isitActice === 2"
      class="certification02 w-[1030px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
      :class=" isitActice === 2 || !isitActice ? 'activeTab' : 'disable'"
    >
      <br />
      <!-- title -->
      <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">신분증 인증</h1>
      <!-- underbar -->
      <div class="w-[500px] border-b mb-5"></div>
      <br/>
      <div class="text-center py-2">
        <p class="__lang text-[14px] mb-2">신분증을 선택한 후 양식에 맞춰 작성해주세요.</p>
        <p class="__lang text-[14px]">위조나 도용 시에는 법적 처벌을 받을 수 있습니다.</p>
      </div>
      <div class="my-5">
          <ul class="flex items-center align-center h-10 contents_primary">
              <li
                  :class="certificationType === 0 || !certificationType ? 'bg-main_color text-white' : ''"
                  class="clickable __lang py-2 h-8 border text-stroke flex items-center justify-center h-[34px] w-[89px]"
                  @click.prevent="certificationTypeChange(0)"
              >
                  주민등록증
              </li>
              <li
                  :class="certificationType === 1 ? 'bg-main_color text-white' : ''"
                  class="clickable __lang py-2 h-8 border text-stroke flex items-center justify-center h-[34px] w-[89px]"
                  @click.prevent="certificationTypeChange(1)"
              >
                  운전면허증
              </li>
              <li
                  :class="certificationType === 2 ? 'bg-main_color text-white' : ''"
                  class="clickable __lang py-2 h-8 border text-stroke flex items-center justify-center h-[34px] w-[100px]"
                  @click.prevent="certificationTypeChange(2)"
              >
                  외국인등록증
              </li>
          </ul>
      </div>

      <!-- 주민등록증 인증 탭 -->
      <section v-if="certificationType === 0 || !certificationType" class="flex flex-col justify-center items-center">
        <!-- 주민등록증 샘플 svg component -->
        <SampleCirtification class="mt-2"/>

        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">이름</label>

          <!-- input ----------------------------------------------->
          <input type="text" @focus="user.name.isFocused = true"
          :class="userValidation.name.state === 'error' ? 'error' : ''"
          class="bg-[#fff] border mb-4 default_btn pa-4" 
          v-model="user.name.value" id="user_name" name="user_name" placeholder="실명 이름을 입력해주세요.">

          <span v-if="userValidation.name.state === 'error'" class="text-market_rise">
            이름을 입력해주세요
          </span>
        </div>

        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">주민등록번호</label>
          <div class="relative default_btn flex items-center justify-between">

            <!-- input ----------------------------------------------->
            <input type="text" @focus="user.id_num_1.isFocused = true"
            :class="userValidation.id_num_1.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="6"
            v-model="user.id_num_1.value" id="id_num_1" name="id_num_1" placeholder="생년월일(6자리)"/>
            <div class="flex items-center">-</div>

            <!-- input ----------------------------------------------->
            <input :type="passwordORText ? 'text' : 'password'" @focus="user.id_num_2.isFocused = true"
            :class="userValidation.id_num_2.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="7"
            v-model="user.id_num_2.value" id="id_num_2" name="id_num_2" placeholder="뒷자리(7자리)"/>
            <div class="absolute right-4 bottom-3 clickable" @click.prevent="togglePasswordORText">
                <img v-if="passwordORText" src="/asset/img/eye-gray.svg" />
                <img v-else src="/asset/img/eye-gray-hide.svg" />
            </div>
          </div>

          <span v-if="userValidation.id_num_1.state === 'error'" class="text-market_rise">
            주민등록번호를 확인해주세요.
          </span>
          <span v-else-if="userValidation.id_num_2.state === 'error'" class="text-market_rise">
            주민등록번호를 확인해주세요.
          </span>
        </div>

        <!-- 발급 일자 및 DatePicker Open -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">발급일자</label>
          <div class="w-[366px] relative">
            <!-- input ----------------------------------------------->
            <input type="text"
            class="bg-[#fff] border mb-4 default_btn pa-4" 
            v-model="resultDate" id="date" name="date" placeholder="발급일자를 선택해주세요." @click.prevent="pickDate" readonly/>
            <div class="absolute right-2 clickable" style="bottom: 28px;" @click.prevent="pickDate">
              <img src="/asset/img/callendar.svg" class="clickable" style="width: 24px; height: 24px;"/>
            </div>
          </div>
        </div>

        <!-- next button area -->
        <div class="nextBtnArea mt-44 mb-28">
          <button v-if="userValidationCompleted" :class="['default_btn', 'btn_main_green']" id="j_next_btn" @click.prevent="prepareCheckIDCertification">
            인증하기
          </button>
          <button v-else :class="['default_btn', 'j_next_btn']" id="j_next_btn" @click.prevent>
            인증하기
          </button>
        </div>
      </section>
      <!-- 주민등록증 인증 탭 끝 -->

      <!-- 운전면허증 인증 탭 -->
      <section v-if="certificationType === 1" class="flex flex-col justify-center items-center">
        <div class="flex">
          <div class="flex flex-col justify-center text-center mr-[22px]">
            <div class="w-full"><b>구형</b> 면허증</div>
            <DriverOldCirtification />
          </div>
          <div class="flex flex-col justify-center text-center">
            <p><b>신형</b> 면허증</p>
            <DriverNewCirtification />
          </div>
        </div>

        <!-- 운전면허번호 -->
        <div class="flex flex-col mt-[48px]">
          <label class="__lang mb-2">운전면허번호</label>
          <div class="default_btn flex items-center justify-between text-[14px]">
            <!-- select ----------------------------------------------->
            <select class="bg-[#fff] border w-[100px] h-[50px] border px-4" v-model="driver.driver_id_num_1.value">
              <option value="" class="__lang">선택</option>
              <option v-for="(item, index) in localNumber" :key="index" :value="item.num">
                {{ `${item.num}` }}
              </option>
            </select>
            <div class="flex items-center">-</div>
            <!-- input ----------------------------------------------->
            <input type="text" @focus="driver.driver_id_num_2.isFocused = true"
            :class="driverValidation.driver_id_num_2.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[52px] pa-4" @keydown="handleKeydown" maxlength="2"
            v-model="driver.driver_id_num_2.value" id="driver_id_num_2" name="driver_id_num_2" placeholder="00"/>
            <!-- input ----------------------------------------------->
            <input type="text" @focus="driver.driver_id_num_3.isFocused = true"
            :class="driverValidation.driver_id_num_3.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[84px] pa-4" @keydown="handleKeydown" maxlength="6"
            v-model="driver.driver_id_num_3.value" id="driver_id_num_3" name="driver_id_num_3" placeholder="000000"/>
            <!-- input ----------------------------------------------->
            <input type="text" @focus="driver.driver_id_num_4.isFocused = true"
            :class="driverValidation.driver_id_num_4.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[52px] pa-4" @keydown="handleKeydown" maxlength="2"
            v-model="driver.driver_id_num_4.value" id="driver_id_num_4" name="driver_id_num_4" placeholder="00"/>
          </div>
          <span v-if="driverValidation.driver_id_num_2.state === 'error'" class="text-market_rise">
            {{  driverValidation.driver_id_num_2.msg || ''  }}
          </span>
        </div>

        <!-- 운전면허증 > 이름 -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">이름</label>
          <!-- input ----------------------------------------------->
          <input type="text" @focus="driver.name.isFocused = true"
          :class="driverValidation.name.state === 'error' ? 'error' : ''"
          class="bg-[#fff] border mb-4 default_btn pa-4" 
          v-model="driver.name.value" id="user_name_driver" name="user_name_driver" placeholder="실명 이름을 입력해주세요.">
          <span v-if="driverValidation.name.state === 'error'" class="text-market_rise">
            {{  driverValidation.name.msg || ''  }}
          </span>
        </div>

        <!-- 운전면허증 > 주민등록번호 -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">주민등록번호</label>
          <div class="relative default_btn flex items-center justify-between">

            <!-- input ----------------------------------------------->
            <input type="text" @focus="driver.id_num_1.isFocused = true"
            :class="driverValidation.id_num_1.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="6"
            v-model="driver.id_num_1.value" id="id_num_1" name="id_num_1" placeholder="생년월일(6자리)"/>
            <div class="flex items-center">-</div>

            <!-- input ----------------------------------------------->
            <input :type="passwordOrTextDriver ? 'text' : 'password'" @focus="driver.id_num_2.isFocused = true"
            :class="driverValidation.id_num_2.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="7"
            v-model="driver.id_num_2.value" id="id2_num_2" name="id2_num_2" placeholder="뒷자리(7자리)"/>
            <div class="absolute right-4 bottom-3 clickable" @click.prevent="togglePasswordORTextDriver">
                <img v-if="passwordOrTextDriver" src="/asset/img/eye-gray.svg" />
                <img v-else src="/asset/img/eye-gray-hide.svg" />
            </div>
          </div>

          <span v-if="driverValidation.id_num_1.state === 'error'" class="text-market_rise">
            {{ driverValidation.id_num_1.msg || '' }}
          </span>
          <span v-else-if="driverValidation.id_num_2.state === 'error'" class="text-market_rise">
            {{ driverValidation.id_num_2.msg || '' }}
          </span>
        </div>

        <!-- 운전면허증 > 암호일련번호 -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">암호일련번호</label>
          <!-- input ----------------------------------------------->
          <input type="text" @focus="driver.driver_serial_number_code.isFocused = true"
          :class="driverValidation.driver_serial_number_code.state === 'error' ? 'error' : ''"
          class="bg-[#fff] border mb-4 default_btn pa-4" maxlength="6" @input="handleInput( $event )"
          :value="driver.driver_serial_number_code.value" id="driver_serial_number_code" name="driver_serial_number_code" placeholder="대문자와 숫자만 입력해주세요.">
          <span v-if="driverValidation.driver_serial_number_code.state === 'error'" class="text-market_rise">
            {{ driverValidation.driver_serial_number_code.msg || ''  }}
          </span> 
        </div>

        <!-- 인증 버튼 영역 -->
        <div class="nextBtnArea mt-12 mb-[100px] flex justify-center">
          <button v-if="driverValidationCompleted" :class="['default_btn', 'btn_main_green']" id="j_next_btn" @click.prevent="prepareCheckDriverIDCertification">
            인증하기
          </button>
          <button v-else :class="['default_btn', 'j_next_btn']" id="j_next_btn" @click.prevent>
            인증하기
          </button>
        </div>
      </section>
      <!-- 운전면허증 인증 탭 끝 -->

      <!-- 외국인등록증 인증 탭 -->
      <section v-if="certificationType === 2" class="flex flex-col justify-center items-center">
        <ForeignCirtification />

        <!-- 외국인등록증 > 이름 -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">이름(영어 이름은 대소문자를 정확하게 입력해주세요.)</label>
          <!-- input ----------------------------------------------->
          <input type="text" @focus="foreigner.name.isFocused = true"
          :class="foreignerValidation.name.state === 'error' ? 'error' : ''"
          class="bg-[#fff] border mb-4 default_btn pa-4" 
          v-model="foreigner.name.value" id="foreign_name" name="foreign_name" placeholder="실명 이름을 입력해주세요.">
          <span v-if="foreignerValidation.name.state === 'error'" class="text-market_rise">
            {{ foreignerValidation.name.msg || '' }}
          </span>
        </div>

        <!-- 외국인등록증 > 외국인 등록번호 -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">외국인등록번호</label>
          <div class="relative default_btn flex items-center justify-between">

            <!-- input ----------------------------------------------->
            <input type="text" @focus="foreigner.foreign_id_1.isFocused = true"
            :class="foreignerValidation.foreign_id_1.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="6"
            v-model="foreigner.foreign_id_1.value" id="foreign_id_1" name="foreign_id_1" placeholder="생년월일(6자리)"/>
            <div class="flex items-center">-</div>

            <!-- input ----------------------------------------------->
            <input :type="passwordORTextForeigner ? 'text' : 'password'" @focus="foreigner.foreign_id_2.isFocused = true"
            :class="foreignerValidation.foreign_id_2.state === 'error' ? 'error' : ''"
            class="bg-[#fff] border h-[50px] w-[170px] pa-4" @keydown="handleKeydown" maxlength="7"
            v-model="foreigner.foreign_id_2.value" id="foreign_id_2" name="foreign_id_2" placeholder="뒷자리(7자리)"/>
            <div class="absolute right-4 bottom-3 clickable" @click.prevent="togglePasswordORTextForeigner">
                <img v-if="passwordORTextForeigner" src="/asset/img/eye-gray.svg" />
                <img v-else src="/asset/img/eye-gray-hide.svg" />
            </div>
          </div>

          <span v-if="foreignerValidation.foreign_id_1.state === 'error'" class="text-market_rise">
            {{ foreignerValidation.foreign_id_1.msg || '' }}
          </span>
          <span v-else-if="foreignerValidation.foreign_id_2.state === 'error'" class="text-market_rise">
            {{ foreignerValidation.foreign_id_2.msg || '' }}
          </span>
        </div>

        <!-- 외국인등록증 > 발급 일자 및 DatePicker Open -->
        <div class="flex flex-col mt-[62px]">
          <label class="__lang mb-2">발급일자</label>
          <div class="w-[366px] relative">
            <!-- input ----------------------------------------------->
            <input type="text"
            class="bg-[#fff] border mb-4 default_btn pa-4" 
            v-model="resultDate" id="date" name="date" placeholder="발급일자를 선택해주세요." @click.prevent="pickDate" readonly/>
            <div class="absolute right-2 clickable" style="bottom: 28px;" @click.prevent="pickDate">
              <img src="/asset/img/callendar.svg" class="clickable" style="width: 24px; height: 24px;"/>
            </div>
          </div>
        </div>
        
        <div class="nextBtnArea my-5 mb-15">
          <button v-if="foreignerValidationCompleted" :class="['default_btn', 'btn_main_green']" id="j_next_btn" @click.prevent="prepareCheckForeignIDCertification">
            인증하기
          </button>
          <button v-else :class="['default_btn', 'j_next_btn']" id="j_next_btn" @click.prevent>
            인증하기
          </button>
        </div>
      </section>
      <!-- 외국인등록증 인증 탭 끝 -->
      <br/>
       
    </section>

    <!-- 계좌 1원 인증 body -->
    <section
      v-if="isitActice === 3"
      class="certification03 w-[1030px] bg-[#fff] basicBoxShadow mb-5"
      :class=" isitActice === 3 ? 'activeTab' : 'disable'"
    >
      <!-- 계좌 1원 인증 보내기 전에 나타날 영역 -->
      <div class="flex flex-col justify-center items-center">
        <br />
        <!-- title -->
        <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">계좌 1원 인증</h1>
        <!-- underbar -->
        <div class="w-[500px] border-b mb-5"></div>
        <div v-if="!isBankVerifyNumberSend" class="text-center mx-auto mb-[78px]">
          <p class="__lang text-[14px] mb-2">본인 확인을 위해 본인 명의의 은행 계좌번호를 입력해주세요.</p>
          <p class="__lang text-[14px]">해당 계좌로 1원이 입금됩니다.</p>
        </div>
        <div v-else class="text-center mx-auto mb-5">
          <p class="__lang text-[14px] mb-2">해당 계좌로 1원이 입금됩니다.</p>
          <p class="__lang text-[14px]">거래소에서 송급된 1원 내역을 입력해주세요.</p>
          <div class="mt-4"><img src="/asset/img/oneWonAuthSample.svg" /></div>
        </div>
        <form>
          <div class="flex flex-col justify-center items-center mb-[296px]">
            <select class="default_btn border pl-5 mb-3" :class="[ isBankVerifyNumberSend ? 'bg-[#f2f2f2]' : '']" v-model="bankData" :disabled="isBankVerifyNumberSend === true">
              <option class="__lang" disabled>은행을 선택해주세요.</option>
              <option 
                class="__lang" 
                v-for="bank in banksData" 
                :key="bank?.code" 
                :value="`(${bank?.code}) ${bank?.name}`"
              >
                {{ bank?.name }}
              </option>
            </select>
            <!-- input ----------------------------------------------->
            <input type="text" v-model="bankAccount" placeholder="은행 계좌번호" class="default_btn border pl-5 mb-3" :class="[ isBankVerifyNumberSend ? 'bg-[#f2f2f2]' : '']" :disabled="isBankVerifyNumberSend === true" />
            <input v-if="isBankVerifyNumberSend" type="text" v-model="bankAuthVerifyNumber" placeholder="인증번호 3자리를 입력해주세요" class="default_btn border pl-5 mb-3" />
          </div>
          <section class="btnArea mb-[100px]">
            <button v-if="validationForAccount && isBankVerifyNumberSend === false" class="__lang btn_main_green default_btn" @click.prevent="prepareCheckOneWonTransfer">송금 요청</button>
            <button v-else-if="isBankVerifyNumberSend" class="__lang btn_main_green default_btn" @click.prevent="prepareVerifyBankAuthNumber">인증 요청</button>
            <div v-else class="__lang default_btn bg-stroke text-white flex justify-center items-center">
              송금 요청
            </div>
          </section>
        </form>
        <br/>
      </div>
    </section>

    <!-- 거주지 정보 작성 body -->
    <section
      v-if="isitActice === 4"
      class="certification04 w-[1030px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
      :class=" isitActice === 4 ? 'activeTab' : 'disable'"
    >
      <form class="flex flex-col justify-center items-center">
        <br />
        <!-- title -->
        <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">거주지 정보 작성</h1>
        <!-- underbar -->
        <div class="w-[500px] border-b mb-5"></div>
        <br/><br/>
        <span class="flex">
          <input type="text" v-model="zip.code" placeholder="우편번호" class="w-[282px] h-[50px] border pl-5 mb-3 mr-2"/>  
          <button class="__lang mypage_btn_type1" @click.prevent=execDaumPostZipcode>주소 검색</button>
        </span>
        <span>
          <input type="text" v-model="zip.address1" placeholder="지번/도로명" class="default_btn border pl-5 mb-3"/>
        </span>
        <span>
          <input type="text" v-model="zip.address2" placeholder="상세 주소(층수·동·호수 등 모두 작성)" class="default_btn border pl-5 mb-3"/>
        </span>
        <small class="__lang w-[366px] text-gray">※ 상세주소가 일부 누락되거나 오정보 입력 시 거래소 이용이 제한될 수 있습니다.</small>
        
        <br/>

        <!-- subtitle -->
        <span class="flex flex-start w-[366px]">
          <h1 class="__lang">거래 목적 및 자금 출처</h1>
        </span>
        <select class="default_btn border pl-5 mb-3" v-model="tradePurpose">
          <option class="__lang" disabled>거래 목적을 선택해 주세요.</option>
          <option v-for="(item, index) in tradePurposeList" :key="index">{{ item.name }}</option>
        </select>
        <select class="default_btn border pl-5 mb-3" v-model="fundSource">
          <option class="__lang" disabled>자금의 출처를 선택해주세요.</option>
          <option v-for="(item, index) in fundSourceList" :key="index">{{ item.name }}</option>
        </select>

        <div class="my-24">
          <button
              class="__lang btn_main_green default_btn"
              @click.prevent="prepareUpdateAddressInfo"
          >
              다음
          </button>
        </div>
      </form>
    </section>

    <!-- 직업 정보 작성 body -->
    <section
      v-if="isitActice === 5"
      class="certification05 w-[1030px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
      :class=" isitActice === 5 ? 'activeTab' : 'disable'"
    >
    <form class="flex flex-col justify-center items-center">
        <br />
        <!-- title -->
        <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">직업 정보 작성</h1>
        <!-- underbar -->
        <div class="w-[500px] border-b mb-5"></div>
        <br/><br/>
        <!-- subtitle -->
        <span class="flex flex-start w-[366px] mb-3 text-noto-700">
          <h1 class="__lang">직업 정보</h1>
        </span>
        <select class="default_btn border pl-5 mb-3" v-model="jobName">
          <option class="__lang" disalbed>직업 구분을 선택해주세요.</option>
          <option v-for="(item, index) in jobList" :key="index">{{ item.name }}</option>
        </select>
        <select class="default_btn border pl-5 mb-3" v-model="earnMeterial">
          <option class="__lang" disalbed>소득 방식을 선택해주세요.</option>
          <option v-for="(item, index) in earnMeterialList" :key="index">{{ item.name }}</option>
        </select>
        <br/>

        <!-- subtitle -->
        <span class="flex flex-start w-[366px] mb-3 text-noto-700">
          <h1 class="__lang">직장/학교 정보</h1>
        </span>
        <span>
          <input type="text" v-model="job.name" placeholder="직장 또는 학교명을 입력해주세요." class="default_btn border pl-5 mb-3"/>
        </span>
        <span class="flex">
          <input type="text" v-model="job.zipcode" placeholder="우편번호" class="w-[287px] h-[50px] border pl-5 mb-3"/>  
          <button class="__lang mypage_btn_type1" @click.prevent="execDaumPostCompanycode">주소 검색</button>
        </span>
        <span>
          <input type="text" v-model="job.address1" placeholder="직장 또는 학교 주소" class="default_btn border pl-5 mb-3"/>
        </span>
        <span>
          <input type="text" v-model="job.address2" placeholder="상세 주소(층수·동·호수 등 모두 작성)" class="default_btn border pl-5 mb-3"/>
        </span>
        <small class="w-[366px] text-gray">※ 상세주소가 일부 누락되거나 오정보 입력 시 거래소 이용이<br/> 제한될 수 있습니다.</small>
        <br /><br />
        <button class="btn_main_green default_btn" @click.prevent="prepareUpdateJobInfo">작성 완료</button>
        <br /><br /><br /><br />
      </form>
    </section>

    <!-- 본인인증 완료 -->
    <section
      v-if="isitActice === 6"
      class="certification04 w-[1030px] h-[750px] bg-[#fff] basicBoxShadow flex flex-col items-center mb-5"
      :class=" isitActice >= 6 ? 'activeTab' : 'disable'"
    >
      <br />
      <!-- title -->
      <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">본인인증 완료</h1>
      <!-- underbar -->
      <div class="w-[500px] border-b mb-[40px]"></div>
      <div class="mb-[50px]">
        <p class="__lang text-[14px] mb-2">본인인증이 완료되었습니다.</p>
        <p class="__lang text-[14px]">(사용자에 따라 추가서류 요청 메일이 발송될 수 있습니다.)</p>
      </div>
      <RouterLink to="/exchanger">
        <button class="btn_main_green default_btn">거래하러 가기</button>
      </RouterLink>
    </section>

    <!-- DatePicker 모달창 -->
    <v-dialog v-model="dialog" width="406px" class="flex justify-center pa-0">
      <DatePicker 
        :changeNestedDialog="changeDialogFalse"
        :model="resultDate"
        :setDateValue="setDateValue"
      />
    </v-dialog>

    <!-- 로딩 스테이터스 모달창 -->
    <v-dialog v-model="dataLoadingStatus" width="406px" class="flex justify-center pa-0">
      <div class="flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- alert용 중첩 가능한 모달 -->
    <v-dialog v-model="nested_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="changeNestedDialogFalse">
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router';
// component import
import { 
  CheckedIcon, 
  SampleCirtification, 
  DriverNewCirtification, 
  DriverOldCirtification, 
  ForeignCirtification
} from '@/components/svg'
import DatePicker from '@/components/modal/DatePicker.vue'
// static data import
import banks from '@/data/banks.json';
// utils import
import { handleKeydown } from '@/utils/utilsFactory';
import UtilSessionStorage from '../../utils/session';
// api import
import { initMemberInfomation, authKYC, authBank, updateAddress, updateJobInfo, updateBankAccount, logOut } from '../../api/user/index.js';


// 세션 사용 기능
const sessionStorage = new UtilSessionStorage();

// 라우터 인스턴스 주입
const router = useRouter();

const banksData = banks;
const bankData = ref(null);   // 1원 인증 보낼 은행 데이터를 보여줄 select 와 바인딩될 변수

// 아이디 담을 변수
let id = '';
// 1원 인증용 은행 계좌번호 input 바인딩 데이터
const bankAccount = ref('');
const isBankVerifyNumberSend = ref(false);
const bankAuthVerifyNumber = ref(null);

// 주소 연동용 데이터
const zip = reactive({
    code: '',
    address1: '',
    address2: ''
})
const job = reactive({
    name: '',
    zipcode: '',
    address1: '',
    address2: '',
    jobCate1: '',
    jobCate2: '',
})
// 거래 목적 select
const tradePurpose = ref('');
// 자금 출처 select
const fundSource = ref('');
// 거래 목적 리스트
const tradePurposeList = ref([
  { name : '장·단기적 투자' },
  { name : '상품·서비스 결제' },
  { name : '사업관련 활동' },
  { name : '가상자산 기부' },
  { name : '가상자산 보관' },
  { name : '기타 서비스 이용' },
])
//  자금 출처 리스트
const fundSourceList = ref([
  { name : '근로 및 연금소득' },
  { name : '퇴직소득' },
  { name : '대출금' },
  { name : '용돈/생활비' },
  { name : '상속/증여' },
  { name : '일시재산양도로 인한 소득' },
  { name : '기타' },
])

// 직업 정보 바인딩 데이터
const jobName = ref('');
// 소득 방식 바인딩 데이터
const earnMeterial = ref('');

// 직업 정보 리스트
const jobList = ref([
  { name : '근로소득자' },
  { name : '자영업자' },
  { name : '무직' },
  { name : '무직' }, 
])

// 소득 방식 리스트
const earnMeterialList = ref([
  { name : '근로소득' },
  { name : '사업소득' },
  { name : '연금소득' },
])

// 비밀번호 - 텍스트 전환, password to text, pwd to text 관련
const passwordORText = ref(false);
const passwordOrTextDriver = ref(false);
const passwordORTextForeigner = ref(false);
/**
 * 비밀번호 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglePasswordORText(){
  passwordORText.value = !passwordORText.value
}
function togglePasswordORTextDriver(){
  passwordOrTextDriver.value = !passwordOrTextDriver.value
}function togglePasswordORTextForeigner(){
  passwordORTextForeigner.value = !passwordORTextForeigner.value
}

// TODO 계좌 1원 인증 위해서 잠시 코드 변경
const isitActice = ref(2);

/**
 * 0 : 주민등록증
 * 1 : 운전면허증
 * 2 : 외국인등록증
 */
const certificationType = ref(0);

/**
 * 주민등록증용 양방향 바인딩 변수
 */
const user = ref({
  name : { value: '', isFocused: false },
  id_num_1 : { value: '', isFocused: false },
  id_num_2 : { value: '', isFocused: false },
  date : { value: '', isFocused: false },
})

/**
 * 운전면허증용 양방향 바인딩 변수
 */
const driver = ref({
  id_num_1 : { value: '', isFocused: false },
  id_num_2 : { value: '', isFocused: false },
  name : { value: '', isFocused: false },
  driver_id_num_1 : { value: '', isFocused: false },
  driver_id_num_2 : { value: '', isFocused: false },
  driver_id_num_3 : { value: '', isFocused: false },
  driver_id_num_4 : { value: '', isFocused: false },
  driver_serial_number_code : { value: '', isFocused: false },
})

function handleInput(event) {
  driver.value.driver_serial_number_code.value = event.target.value.toUpperCase();
}

/**
 * 외국인등록증용 양방향 바인딩 변수
 */
const foreigner = ref({
  name : { value: '', isFocused: false },
  foreign_id_1 : { value: '', isFocused: false },
  foreign_id_2 : { value: '', isFocused: false },
  date : { value: '', isFocused: false },
})

const localNumber = ref([
  { "name": "서울", "num": 11 },
  { "name": "부산", "num": 12 },
  { "name": "경기", "num": 13 },
  { "name": "강원", "num": 14 },
  { "name": "충북", "num": 15 },
  { "name": "충남", "num": 16 },
  { "name": "전북", "num": 17 },
  { "name": "전남", "num": 18 },
  { "name": "경북", "num": 19 },
  { "name": "경남", "num": 20 },
  { "name": "제주", "num": 21 },
  { "name": "대구", "num": 22 },
  { "name": "인천", "num": 23 },
  { "name": "대전", "num": 25 },
  { "name": "울산", "num": 26 }
])

// validation 결과 변수화, computed 사용
/**
 * state = "(빈칸)" 변화 X
 * state = error
 * state = done
 */
/**
 * 주민등록증 validation
 */
const userValidation = computed(()=>{
  const result = {
    name: { msg: '', state: ''},
    id_num_1: { msg: '', state: ''},
    id_num_2: { msg: '', state: ''},
    date: { msg: '', state: ''},
    success: false
  }
  if ( user.value.name.isFocused && user.value.name.value === '' ) {
    result.name.msg = '이름을 입력해주세요.';
    result.name.state = 'error';
  } else {
    result.name.msg = '';
    result.name.state = 'success';
  }

  if ( user.value.id_num_1.isFocused && user.value.id_num_1.value === '' ) {
    result.id_num_1.msg = '주민등록번호를 입력해주세요.';
    result.id_num_1.state = 'error';
  } else if( user.value.id_num_1.isFocused && user.value.id_num_1.value.toString().length !== 6 ){
    result.id_num_1.msg = '주민등록번호를 확인해주세요.';
    result.id_num_1.state = 'error';
  } else {
    result.id_num_1.msg = '';
    result.id_num_1.state = 'success';
  }

  if ( user.value.id_num_2.isFocused && user.value.id_num_2.value === '' ) {
    result.id_num_2.msg = '주민등록번호를 입력해주세요.';
    result.id_num_2.state = 'error';
  } else if( user.value.id_num_2.isFocused && user.value.id_num_2.value.toString().length !== 7 ){
    result.id_num_2.msg = '주민등록번호를 확인해주세요.';
    result.id_num_2.state = 'error';
  } else {
    result.id_num_2.msg = '';
    result.id_num_2.state = 'success';
  }

  if( result.name.state === 'success' && result.id_num_1.state === 'success' && result.id_num_2.state === 'success' ){
    result.success = true;
  }
  return result;
})

/**
 * 본인인증 > 신분증 인증 > 주민등록증 > 인증하기 탭 활성화
 */
const userValidationCompleted = computed(()=>{
  let result = null;
  result = user.value.name.value && user.value.id_num_1.value && user.value.id_num_2.value && resultDate;
  if( user.value.name.value === '' || user.value.id_num_1.value === '' || user.value.id_num_2.value === '' || resultDate.value === ''){
    result = false;
  }
  if( userValidation.value.name.state === 'error' || userValidation.value.id_num_1.state === 'error' || 
  userValidation.value.id_num_2.state === 'error' || userValidation.value.date.state === 'error' || userValidation.value.success === false ){
    result = false;
  }
  return result;
})

/**
 * =========== 운전 면허증 validation ====================
 */
const driverValidation = computed(()=>{
  const result = {
    name: { msg: '', state: ''},
    id_num_1: { msg: '', state: ''},
    id_num_2: { msg: '', state: ''},
    driver_id_num_1: { msg: '', state: ''},
    driver_id_num_2: { msg: '', state: ''},
    driver_id_num_3: { msg: '', state: ''},
    driver_id_num_4: { msg: '', state: ''},
    driver_serial_number_code: { msg: '', state: ''},
    success: false
  }
  if ( driver.value.name.isFocused && driver.value.name.value === '' ) {
    result.name.msg = '이름을 입력해주세요.';
    result.name.state = 'error';
  }

  if ( driver.value.id_num_1.isFocused && driver.value.id_num_1.value === '' ) {
    result.id_num_1.msg = '주민등록번호를 입력해주세요.';
    result.id_num_1.state = 'error';
  } else if( driver.value.id_num_1.isFocused && driver.value.id_num_1.value.toString().length !== 6 ){
    result.id_num_1.msg = '주민등록번호를 확인해주세요.';
    result.id_num_1.state = 'error';
  } else {
    result.id_num_1.msg = '';
    result.id_num_1.state = 'success';
  }

  if ( driver.value.id_num_2.isFocused && driver.value.id_num_2.value === '' ) {
    result.id_num_2.msg = '주민등록번호를 입력해주세요.';
    result.id_num_2.state = 'error';
  } else if( driver.value.id_num_2.isFocused && driver.value.id_num_2.value.toString().length !== 7 ){
    result.id_num_2.msg = '주민등록번호를 확인해주세요.';
    result.id_num_2.state = 'error';
  } else {
    result.id_num_2.msg = '';
    result.id_num_2.state = 'success';
  }

  if ( driver.value.driver_id_num_2.isFocused && driver.value.driver_id_num_2.value === '' ) {
    result.driver_id_num_2.msg = '운전면허번호를 입력해주세요.';
    result.driver_id_num_2.state = 'error';
  } else if( driver.value.driver_id_num_2.isFocused && driver.value.driver_id_num_2.value.toString().length !== 2 ){
    result.driver_id_num_2.msg = '운전면허번호를 확인해주세요.';
    result.driver_id_num_2.state = 'error';
  } else {
    result.driver_id_num_2.msg = '';
    result.driver_id_num_2.state = 'success';
  }

  if ( driver.value.driver_id_num_3.isFocused && driver.value.driver_id_num_3.value === '' ) {
    result.driver_id_num_3.msg = '운전면허번호를 입력해주세요.';
    result.driver_id_num_3.state = 'error';
  } else if( driver.value.driver_id_num_3.isFocused && driver.value.driver_id_num_3.value.toString().length !== 6 ){
    result.driver_id_num_3.msg = '운전면허번호를 확인해주세요.';
    result.driver_id_num_3.state = 'error';
  } else {
    result.driver_id_num_3.msg = '';
    result.driver_id_num_3.state = 'success';
  }

  if ( driver.value.driver_id_num_4.isFocused && driver.value.driver_id_num_4.value === '' ) {
    result.driver_id_num_4.msg = '운전면허번호를 입력해주세요.';
    result.driver_id_num_4.state = 'error';
  } else if( driver.value.driver_id_num_4.isFocused && driver.value.driver_id_num_4.value.toString().length !== 2 ){
    result.driver_id_num_4.msg = '운전면허번호를 확인해주세요.';
    result.driver_id_num_4.state = 'error';
  } else {
    result.driver_id_num_4.msg = '';
    result.driver_id_num_4.state = 'success';
  }

  if ( driver.value.driver_serial_number_code.isFocused && driver.value.driver_serial_number_code.value === '' ) {
    result.driver_serial_number_code.msg = '암호일련번호를 입력해주세요.';
    result.driver_serial_number_code.state = 'error';
  } else if( driver.value.driver_serial_number_code.isFocused && driver.value.driver_serial_number_code.value.toString().length !== 6 ){
    result.driver_driver_serial_number_codeid_num_1.msg = '암호일련번호를 확인해주세요.';
    result.driver_serial_number_code.state = 'error';
  } else {
    result.driver_serial_number_code.msg = '';
    result.driver_serial_number_code.state = 'success';
  }
  return result;
})

/**
 * 본인인증 > 신분증 인증 > 운전면허증 > 인증하기 탭 활성화
 */
 const driverValidationCompleted = computed(()=>{
  let result = true;
  if( driver.value.name.value === '' || driver.value.id_num_1.value === '' || driver.value.id_num_2.value === '' || 
  driver.value.driver_serial_number_code.value === '' || driver.value.driver_id_num_1.value === '' || driver.value.driver_id_num_2.value === ''){
    result = false;
  }
  if( driverValidation.value.name.state === 'error' || driverValidation.value.id_num_1.state === 'error' || 
  driverValidation.value.id_num_2.state === 'error' || driverValidation.value.driver_serial_number_code.state === 'error' || 
  driverValidation.value.driver_id_num_1.state === 'error' || driverValidation.value.driver_id_num_2.state === 'error' ){
    result = false;
  }
  return result;
})

/**
 * ============외국인 validation=======================
 */
const foreignerValidation = computed(()=>{
  const result = {
    name: { msg: '', state: ''},
    foreign_id_1: { msg: '', state: ''},
    foreign_id_2: { msg: '', state: ''},
    date: { msg: '', state: ''},
    success: false
  }
  if ( foreigner.value.name.isFocused && foreigner.value.name.value === '' ) {
    result.name.msg = '이름을 입력해주세요.';
    result.name.state = 'error';
  } else {
    result.name.msg = '';
    result.name.state = '';
  }

  if ( foreigner.value.foreign_id_1.isFocused && foreigner.value.foreign_id_1.value === '' ) {
    result.foreign_id_1.msg = '외국인등록번호를 입력해주세요.';
    result.foreign_id_1.state = 'error';
  } else if( foreigner.value.foreign_id_1.isFocused && foreigner.value.foreign_id_1.value.toString().length !== 6 ){
    result.foreign_id_1.msg = '외국인등록번호를 확인해주세요.';
    result.foreign_id_1.state = 'error';
  } else {
    result.foreign_id_1.msg = '';
    result.foreign_id_1.state = 'success';
  }

  if ( foreigner.value.foreign_id_2.isFocused && foreigner.value.foreign_id_2.value === '' ) {
    result.foreign_id_2.msg = '외국인등록번호를 입력해주세요.';
    result.foreign_id_2.state = 'error';
  } else if( foreigner.value.foreign_id_2.isFocused && String(foreigner.value.foreign_id_2.value).toString().length !== 7 ){
    result.foreign_id_2.msg = '외국인등록번호를 확인해주세요.';
    result.foreign_id_2.state = 'error';
  } else {
    result.foreign_id_2.msg = '';
    result.foreign_id_2.state = 'success';
  }

  return result;
})

/**
 * 본인인증 > 신분증 인증 > 외국인등록증 > 인증하기 탭 활성화
 */
 const foreignerValidationCompleted = computed(()=>{
  let result = true;
  if( foreigner.value.name.value === '' || foreigner.value.foreign_id_1.value === '' || foreigner.value.foreign_id_2.value === '' || 
  resultDate.value === ''){
    result = false;
  }
  if( foreignerValidation.value.name.state === 'error' || foreignerValidation.value.foreign_id_1.state === 'error' || 
  foreignerValidation.value.foreign_id_2.state === 'error' ){
    result = false;
  }
  return result;
})

/**
 * ============ 1원 인증용 계좌 Validation ======================
 */
const validationForAccount = computed(()=>{
  let result = true;
  if( !bankData.value || bankData.value === '' ){
    result = false;
  }

  if( !bankAccount.value || bankAccount.value === '' ){
    result = false;
  }

  if( bankAccount.value.length > 15 || bankAccount.value.length < 10 ){
    result = false;
  }

  return result;
})


// 모달창 관련
const dialog = ref(false);
const resultDate = ref('');

function changeDialogTrue () { dialog.value = true; }
function changeDialogFalse () { dialog.value = false; }
function setDateValue( newData ){
  resultDate.value = newData;
}

// 모달창 컨트롤용 변수
const nested_dialog = ref(false)
const modal_title = ref('')
const nested_msg = ref('')
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }

const dataLoadingStatus = ref(false);
function changeDataLoadingStatusTrue () { dataLoadingStatus.value = true; }
function changeDataLoadingStatusFalse () { dataLoadingStatus.value = false; }

// 컴포넌트를 다시 랜더링하거나 새로고침 등을 했을 때 이미 인증이 진행된 단계가 초기화되니 store를 통해 중앙식으로 상태관리를 해야합니다.
const auth_status = ref({
  id_verification : false,
  one_won_certification : false,
  address_completed : false,
  job_confirm : false,
});

onMounted(()=>{
  id = sessionStorage.getUserId();
  checkMemberAuthStatuses( );

})


/**
 * 처음 init되었을 때 검사하는 함수
 */
async function checkMemberAuthStatuses() {
  try {
    const response = await initMemberInfomation(id);

    if (response) {
      if (Number(response?.authKyc) === 1) {
        isitActice.value = 3;
        auth_status.value.id_verification = true;

        if (Number(response?.authBank) === 1) {
          isitActice.value = 4;
          auth_status.value.one_won_certification = true;

          if (response.address1 && response.address2 && response.zipcode) {
            isitActice.value = 5;
            auth_status.value.address_completed = true;

            if (response.jobZipcode && response.jobAddress1 && response.jobAddress2) {
              isitActice.value = 6;
              auth_status.value.job_confirm = true;
            }
          }
        }
      }
    } else {
      logOut();
      router.go(0);
    }

    
  } catch (error) {
    console.error(error);
  }
}

/**
 * 각각의 진행단계를 반환해서 알맞는 페이지로 이동시키는 함수. tab과 section에서 씁니다.
 */
function isitChecked( tab ){
  switch (tab) {
    case 2: return checkIDVerification(tab);
    case 3: return oneWonCertification(tab);
    case 4: return isCompletedAddressConfirm(tab);
    case 5: return isCompletedJobConfirm(tab);
    default:
      break;
  }
  return false;
}


function checkIDVerification(tab){
  // 신분증 인증을 한 뒤에 true or false 값을 반환합니다.
  return auth_status.value.id_verification;
}
function oneWonCertification(tab){
  // 1원 인증을 한 후 true or false 값을 반환합니다.
  return auth_status.value.one_won_certification;
}
function isCompletedAddressConfirm(tab){
  // 거주지 정보 작성을 했는지 체크한 후 true or false 값을 반환합니다.
  return auth_status.value.address_completed;
}
function isCompletedJobConfirm(tab){
  // 직업 정보 작성을 했는지 체크한 후 true or false 값을 반환합니다.
  return auth_status.value.job_confirm;
}


/**
 * 신분증 인증을 완료하고 다음 스텝으로 넘어갑니다.
 */
function moveToOneWonCertification(){
  isitActice.value = 3;
  auth_status.value.id_verification = true;
}

/**
 * 신분증 인증(주민등록증)을 준비합니다.
 */
async function prepareCheckIDCertification(){
  changeDataLoadingStatusTrue();
  // 신분증 인증 요청 로직
  const requestItem = {
    id,
    type : 1,
    idName : user.value.name.value,
    idJuminNum : `${user.value.id_num_1.value}${user.value.id_num_2.value}`,
    idIssueDate : resultDate.value.replace(/\D/g, ''),
  }

  const response = await authKYC( requestItem );

  changeDataLoadingStatusFalse();

  if( response?.data?.success === true ){

    // 신분증 인증이 성공했다면 관련 상태값을 바꿉니다.
    moveToOneWonCertification();
  } 
  else {
    modal_title.value = '인증오류 안내'
    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
    일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
    잠시 후 다시 시도해주세요.<br/><br/>
    계속 발생하는 경우 1:1 문의를 통해 확인해주세요`;
    changeNestedDialogTrue();
  }
  
  
}

/**
 * 운전 면허증으로 신분증 인증을 준비합니다.
 */
async function prepareCheckDriverIDCertification(){
  // 운전 먼혀증 인증 요청 로직
  changeDataLoadingStatusTrue();
  // 신분증 인증 요청 로직
  const requestItem = {
    id,
    type : 2,
    dlName : driver.value.name.value,
    dlJuminNum : `${driver.value.id_num_1.value}${driver.value.id_num_2.value}`,
    dlLicenseAreaNum : driver.value.driver_id_num_1.value,
    dlLicenseYearNum : driver.value.driver_id_num_2.value,
    dlLicenseIdxNum : driver.value.driver_id_num_3.value,
    dlLicenseNum : driver.value.driver_id_num_4.value,
    dlEncryptIdxNum : driver.value.driver_serial_number_code.value,
  }

  try {
    const response = await authKYC( requestItem );
    changeDataLoadingStatusFalse();

    if( response?.data?.message.includes('success') ){

      // 신분증 인증이 성공했다면 관련 상태값을 바꿉니다.
      moveToOneWonCertification();
    } else if( response?.data?.message.includes('fail') ){
      // 정보가 불일치할 경우에 나오는 모달창입니다.
      modal_title.value = '안내';
      nested_msg.value = `회원님의 정보가 일치하지 않습니다. 다시 입력해주세요.<br/><br> 
      개명, 주민등록번호 변경, 신분증 재발급 등의 경우<br/>1:1문의를 통해 확인해주세요.`
      changeNestedDialogTrue();
    } else {
      modal_title.value = '인증오류 안내'
      nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
      일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
      잠시 후 다시 시도해주세요.<br/><br/>
      계속 발생하는 경우 1:1 문의를 통해 확인해주세요`;
      changeNestedDialogTrue();
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 외국인 등록증으로 신분증 인증을 준비합니다.
 */
async function prepareCheckForeignIDCertification(){
  // 외국인 등록증 인증 요청 로직
  
  // 신분증 인증이 성공했다면 관련 상태값을 바꿉니다.
  moveToOneWonCertification();
}


/**
 * 1원 인증을 준비합니다.
 */
async function prepareCheckOneWonTransfer(){

  const bankCode = bankData.value.split(' ')[0].replace('(', '').replace(')', '');
  const bankName = bankData.value.split(' ')[1];

  const requestData = {
    id : sessionStorage.getUserId(),
    bankCode,
    bankAccount : bankAccount.value,
    bankName,
  }
  
  // 1원 인증 요청 로직
  const response = await authBank( requestData );

  // 1원 인증이 성공했다면 관련 상태값을 바꿉니다.
  if( response.message.includes(`success`) ){
    moveToOneWonVerifyPage();
  } else {
    alert(`1원 인증 실패`)
  }
}

function moveToOneWonVerifyPage(){
  isBankVerifyNumberSend.value = true;
}


async function prepareVerifyBankAuthNumber(){
  const requestData = { id, code : bankAuthVerifyNumber.value };
  const response = await updateBankAccount( requestData );
  if( response.message.includes(`success`) ){
    isitActice.value = 4;
    auth_status.value.one_won_certification = true;
  } else {
    alert(`1원 인증 실패`)
  }
}


/**
 * 거주지 정보 작성에 들어온 form 값을 validation 하고 이상이 없을 시 서버에 요청합니다.
 */
async function prepareUpdateAddressInfo(){
  // form 으로 들어온 값 유효성 검사
  // 이상이 없을 시 거주지 정보 수정 요청
  const requestData = {
    id, 
    zipcode: zip.code, 
    address1: zip.address1, 
    address2: zip.address2, 
    purpose: tradePurpose.value, 
    fundSource: fundSource.value,
  }

  const response = await updateAddress( requestData );
  if( response.message.includes(`success`) ){
    alert('주소 등록에 성공했습니다.')
    // 요청이 성공했다면 관련 상태값을 바꿉니다.
    isitActice.value = 5;
    auth_status.value.address_completed = true;
  } else {
    alert('주소 등록에 실패했습니다.')
  }

  
}


/**
 * 직업 정보 작성에 들어온 form 값읋 validation 하고 이상이 없을 시 서버에 요청합니다.
 */
async function prepareUpdateJobInfo(){
  // form 으로 들어온 값 유효성 검사
  // 이상이 없을 시 직업 정보 수정 요청

  const requestData = {
    id, 
    job : job.name,
    jobZipcode: job.zipcode, 
    jobAddress1: job.address1, 
    jobAddress2: job.address2, 
    jobCate1: jobName.value, 
    jobCate2: earnMeterial.value,
  }

  const response = await updateJobInfo( requestData );
  if( response.message.includes(`success`) ){
    alert('주소 등록에 성공했습니다.')
    // 요청이 성공했다면 관련 상태값을 바꿉니다.
    isitActice.value = 6;
    auth_status.value.address_completed = true;
  } else {
    alert('주소 등록에 실패했습니다.')
  }

  
}

function certificationTypeChange( target ){
  certificationType.value = target
}

function pickDate(){
  changeDialogTrue();
}

/* eslint-disable */
/**
 * 집 주소 검색 및 결과 인풋에 반영
 */
function execDaumPostZipcode() {
  new daum.Postcode({
      oncomplete: function(data) {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let roadAddr = data.roadAddress; // 도로명 주소 변수
          let extraRoadAddr = ''; // 참고 항목 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
              extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if(extraRoadAddr !== ''){
              extraRoadAddr = ' (' + extraRoadAddr + ')';
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          // document.getElementById('sample4_postcode').value = data.zonecode;
          // document.getElementById("sample4_roadAddress").value = roadAddr;

          zip.code = data.zonecode;
          zip.address1 = roadAddr;
          zip.address2 = '';
      }
  }).open();
}

/**
 * 회사 주소 검색 및 결과 인풋에 반영
 */
 function execDaumPostCompanycode() {
    new daum.Postcode({
        oncomplete: function(data) {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let roadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 참고 항목 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
                extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            job.zipcode = data.zonecode;
            job.address1 = roadAddr;
            job.address2 = '';        
        }
    }).open();
}
</script>

<style scoped>
.active {
  background-color: var(--auth_done);
  color: white;
}
.active svg path {
  stroke: white;
  /* fill: white; */
}
.active svg path:nth-child(2) {
  fill: white;
}
.disable { 
  display: none;
}

.error {
  border: 1px solid var(--market_rise) !important;
}
input:focus {
  outline: none !important;
  border: 2px solid black !important;
}
.error:focus {
  border: 2px solid var(--market_rise) !important;
}
</style>
