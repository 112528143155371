import DepositView from '../../pages/DepositView.vue'

export default [
    {
        path: '/balance',
        name: 'Balance',
        component: DepositView,
    },
    {
        path: '/balance/:type',
        name: 'BalanceType',
        component: DepositView,
    },
    /**
     * :symbol = 심볼명
     * :isAsset = 자산인지 작품인지 여부, 0 = 자산, 1 = 작품
     * :type = 입금인지 출금인지 여부, deposit = 입금, withdraw = 출금
     */
    {
        path: '/balance/:symbol/:isAsset/:type',
        name: 'BalanceTypeWithSymbol',
        component: DepositView,
    },
]


