<template>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z" stroke="#222421"/>
  <rect x="10" y="9" width="16" height="16" fill="url(#pattern0)"/>
  <defs>
  <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
  <use xlink:href="#image0_690_18151" transform="scale(0.01)"/>
  </pattern>
  <image id="image0_690_18151" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVR4nO3aTWobQRSF0QfZQC7yPMGQlWYpWkHWYQ9qByH7CMgILMgsEcjqG/U50DMNSvrq9R+aAQAAAAAAAAAAtnWYmR8z83XjdTAzmZnXmTnNzK+ZefardMS4HKIUxRClMIYoG1zA119iXI6fM/Pl3gvck/zDZPx5nMM9bb3oRxUxeohRRIwiYhQRo4gYRcQoIkaReM7oETF6RIweEaNHxOgRMXpEjB4Ro0fE6BExekSMHhGjR8TokT3H+DQz39//r/S//W/q9P7ZlrXfJMaxaJdl75NxLPqCEaNn10WMnlNBxOg5P2fPMc4+F93BHIrWsqnzrnzZeFdm75PRFCVi9ESJGD1RIkZPlIjREyVi9ESJGD1RIkZPlIjREyVi9ET5JsbHuvZ90+8rAz7ku6m2STnd6al/124ZZYmxzenr5DTVPSnLZPRMynIB75mUZTJ6oiwxeqIsMXqiLDF6oiwxeu6+ju6mAAAAAAAAAJhtvQG+wT4PukmujwAAAABJRU5ErkJggg=="/>
  </defs>
  </svg>
</template>