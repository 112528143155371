export default [
    {
        path: '/child/OrderChildPopUp',
        name: 'OrderChildPopUp',
        component: () => import('../pages/PopUp/OrderChildPopUp.vue')
    },
    {
        path: '/chart/MainChart',
        name: 'ChartChildPopUp',
        component: () => import('../pages/PopUp/ChartChildPopUp.vue')
    },
]
