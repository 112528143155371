<!-- 메인화면 -->
<template>
    <main>
        <MainPage :noticeData="noticeData" />

        <RankingPage
            :defaultMarketData="defaultMarketData"
            :marketcap10="Top10.marketcap10"
            :volume10="Top10.volume10"
            :roc10="Top10.roc10"
        />

        <!-- <IntroduceService />

    <SecurityInfra />

    <OurCertification /> -->

        <div class="mainC">
            <div class="md:ml-[329px] md:pt-[158px]">
                <p class="__lang title text-noto-700 text-white md:mb-[14px]">
                    마켓플레이스에는 없는
                </p>
                <p class="__lang title text-noto-700 text-white md:mb-[14px]">
                    <span class="text-[#04C800]">거래를 위한</span> 서비스
                </p>
            </div>
        </div>

        <div class="relative mainD">
            <div class="absolute md:right-[296px] top-[160px]">
                <p class="md:mb-[40px] text-[18px] md:text-[32px] text-[#1C4025] text-noto-500">
                    거래소
                </p>
                <p class="text-[32px] md:text-[50px] text-[#212121] text-noto-700">시세 분석은</p>
                <p class="md:mb-[36px] text-[32px] md:text-[50px] text-[#212121] text-noto-700">
                    보고 싶은 만큼
                </p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">
                    팝업 기능으로 거래하는 작품의
                </p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">
                    차트·호가를 새창으로 볼 수 있습니다.
                </p>
            </div>
        </div>

        <div class="relative mainE">
            <div class="absolute md:left-[380px] top-[160px]">
                <p class="md:mb-[40px] text-[18px] md:text-[32px] text-[#1C4025] text-noto-500">
                    거래현황
                </p>
                <p class="text-[32px] md:text-[50px] text-[#212121] text-noto-700">작품과 수익을</p>
                <p class="md:mb-[36px] text-[32px] md:text-[50px] text-[#212121] text-noto-700">
                    한 눈에 관리
                </p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">구매한 작품의 수익률을</p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">조회할 수 있습니다.</p>
            </div>
        </div>

        <div class="relative mainF">
            <div class="absolute md:right-[345px] top-[160px]">
                <p class="md:mb-[40px] text-[18px] md:text-[32px] text-[#1C4025] text-noto-500">
                    입출금
                </p>
                <p class="text-[32px] md:text-[50px] text-[#212121] text-noto-700">입금도 출금도</p>
                <p class="md:mb-[36px] text-[32px] md:text-[50px] text-[#212121] text-noto-700">
                    안전하게
                </p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">
                    사전에 확인한 지갑만 승인하여
                </p>
                <p class="text-[16px] md:text-[26px] text-[#616161]">
                    간단하고 안전하게 입출금합니다.
                </p>
            </div>
        </div>
    </main>

    <v-dialog v-model="dialog" width="400px" height="502px">
        <v-card class="flex">
            <v-card-text class="flex flex-col w-[400px] pa-0">
                <div
                    class="clickable"
                    @click="$router.push({ path: '/support/notice', query: { id: noticeId } })"
                >
                    <img src="/asset/img/home_image/main_home_announce_231212.jpg" />
                </div>
            </v-card-text>
            <v-card-actions class="pa-0">
                <div
                    class="w-[240px] pa-0 flex justify-center items-center clickable h-full hover:bg-[#f4f4f4]"
                    @click.prevent="
                        () => {
                            localStorage.setItem(`AnnouncePopupTimestamp`, new Date().getTime())
                            dialog = false
                        }
                    "
                >
                    오늘 하루 그만보기
                </div>
                <div
                    :class="`w-[160px] pa-0 flex justify-center items-center clickable h-full hover:bg-[#f4f4f4]`"
                    @click.prevent="
                        () => {
                            dialog = false
                        }
                    "
                >
                    닫기
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
// vue 메인 기능 import
import { onMounted, ref } from 'vue'

// component import
import MainPage from '../components/main/MainPage.vue'
import RankingPage from '../components/main/RankingPage.vue'
import IntroduceService from '../components/main/IntroduceService.vue'
import SecurityInfra from '../components/main/SecurityInfra.vue'
import OurCertification from '../components/main/OurCertification.vue'

// pinia store import
import { useTop10Store } from '@/stores/main/index.js'

// api import
import { getAllNoticeList } from '../api/notice/index.js'
import { getTop10Data } from '../api/mainPage'
import ExchangerApi from '../api/exchanger'
import UtilLocalStorage from '@/utils/storage/localStorage'

const exchangerApi = new ExchangerApi()

const localStorage = new UtilLocalStorage()

// pinia 인스턴스 주입
const Top10 = useTop10Store()

const noticeData = ref([])
const noticeId = ref(1)

const defaultMarketData = ref({
    market: '',
    market1: '',
    market2: ''
})

// 공지사항 모달쪽 변수
const dialog = ref(false)

// 비교하는 시간대와 현재 시간을 비교해서 같은 날일 경우 true를 반환합니다.
function isItAvailableTimestamp(timestamp) {
    try {
        const today = new Date()
        const clickDate = new Date(timestamp)
        const isSameDate =
            today.getFullYear() === clickDate.getFullYear() &&
            today.getMonth() === clickDate.getMonth() &&
            today.getDate() === clickDate.getDate()
        if (isSameDate === false) {
            localStorage.delItem(`AnnouncePopupTimestamp`)
        }
        return isSameDate
    } catch (error) {
        console.error(error)
    }
    return false
}

onMounted(async () => {
    const temp = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev' ? 27 : 1;
    noticeId.value = temp;
    
    try {
        const isMainAnnounceNoticed = isItAvailableTimestamp(
            localStorage.getItem(`AnnouncePopupTimestamp`)
        )
        if (!isMainAnnounceNoticed) {
            dialog.value = true
        } else {
            dialog.value = false
        }
    } catch (error) {
        dialog.value = false
        console.error(error)
    }

    getAllNoticeList({ page: 1 }).then((response) => {
        noticeData.value = response.data.filter((item) => item.top === true)
    })
    exchangerApi.defaultMarket().then((response) => {
        if (response) {
            getTop10Data(response.result.market2)
            defaultMarketData.value.market = response.result.market
            defaultMarketData.value.market1 = response.result.market1
            defaultMarketData.value.market2 = response.result.market2
        }
    })
    window.scrollTo(0, 0)
})
</script>

<style scoped>
.mainC {
    height: 380px;
    background-image: url('../../public/asset/img/home_image/home_image1_1x.png');
    overflow: hidden;
}
.mainD {
    height: 860px;
    background-image: url('../../public/asset/img/home_image/home_image2_1x.png');
}
.mainE {
    height: 860px;
    background-image: url('../../public/asset/img/home_image/home_image3_1x.png');
    background-position: 95% 50%;
}
.mainF {
    height: 860px;
    background-image: url('../../public/asset/img/home_image/home_image4_1x.png');
}
@media (max-width: 768px) {
    .mainC {
        max-height: 285px;
    }
    .mainD {
        max-height: 400px;
        background-image: url('../../public/asset/img/app_home_image/home_image1_1x.png');
        background-position: center;
    }
    .mainE {
        max-height: 400px;
        background-size: 280px;
        background-position: 95% 20%;
    }
    .mainF {
        height: 400px;
        background-image: url('../../public/asset/img/app_home_image/home_image2_1x.png');
        background-position: center;
    }
}
</style>
