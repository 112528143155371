class UtilLocalStorage {
  getItem( key ){
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  setItem( key, value ){
    localStorage.setItem(key, JSON.stringify(value));
  }

  // 만료 시간과 함께 데이터를 저장
  setItemWithExpireTime( key, value, tts ){
    let ttsTime = 1800;
    if( tts ){
      ttsTime = tts;
    }
    const obj = {
      value : value,
      expire: Date.now() + ttsTime
    }

    const objString = JSON.stringify(obj);
    localStorage.setItem(key, objString);
  }

  // 만료 시간을 체크하며 데이터 읽기
  getItemWithExpireTime(KeyName){
    const objString = localStorage.getItem(KeyName);

    if( !objString ){
      return null;
    }
    const obj = JSON.parse(objString);

    if(Date.now() > obj.expire) {
      this.delItem(KeyName);
      return null;
    }
    return obj.value;
  }


  


  delItem( key ){
    localStorage.removeItem( key );
  }

  storageClear() {
    localStorage.clear();
  }
}

export default UtilLocalStorage