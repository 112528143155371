<!-- 고객지원 > 자주하는 질문 -->
<template>
    <div v-if="!loading" class="w-[1050px] h-[800px] bg-[#fff] flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
    </div>
    <div v-else class="pt-[48px] w-full">
        <div class="flex justify-between">
            <span class="">
                <h1 class="mb-3 pl-[19px] text-[24px] text-noto-500">자주하는 질문</h1>
            </span>
        </div>
        <section id="myAccordion" class="min-h-[670px]">
            <v-expansion-panels variant="accordion" v-model="panel">
                <v-expansion-panel
                    v-for="(item, index) in sortedItemList || []"
                    :key="index"
                    class="bg-[#fff]"
                >  
                    <v-expansion-panel-title class="bg-[#fff] text-[15px]">{{ item.title }}</v-expansion-panel-title>
                    <v-expansion-panel-text class="px-[40px] py-[30px] bg-[#f6f6f6] text-[14px]" v-html="item.content">
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </section>
    </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const loading = ref(false)

const page = ref(1)
const pageLength = ref(20)
const search = ref('')
const sortedItemList = computed(() => {
    let result = []
    try {
      if (route.query.search) {
          result = [...faqList.value].filter((item) => item.title.includes(route.query.search))
      } else {
          result = [...faqList.value]
      }
    } catch (error) {
      console.error(error)
    }
    return result
})

const whatTimeIsItNow = ref(null)

const faqList = ref(null)
const panel = ref(null);

onMounted(async () => {
    faqList.value = ITEM_LIST;
    whatTimeIsItNow.value = new Date().getTime()

    checkQueryString()

    loading.value = true
})


/**
 * 더미데이터
 */
const ITEM_LIST = [
    {
        id: '1',
        issueType: '이용문의',
        title: '휴대전화에 카카오톡이 설치되어 있지 않아요.',
        content: `카카오톡이 설치되어 있지 않아도 이메일 로그인을 통해 거래소 이용이 가능합니다.<br/>
* 다만, 카카오톡이 설치되어 있지 않을 경우 카카오페이 인증이 불가하여,<br/>
출금을 위해서는 구글 어센티케이터 인증을 활성화 후 사용해 주시기 바랍니다.<br/><br/>

피싱 등 금융사고를 예방하기 위해 2채널 인증을 활성화하시길 권장드립니다.<br/><br/>

카카오페이 인증과 구글 어센티케이터 인증이 불가하여 2채널 인증을 설정할 수 없는 경우에는<br/>
일부 기능 이용에 제한될 수 있습니다.`,
        regDate: whatTimeIsItNow.value
    },
    {
        id: '2',
        issueType: '나의지갑',
        title: '다른 지갑 주소로 등록하고 싶어요.',
        content: `개인지갑 주소 등록 시 메타마스크에서 활성되어 있는 주소로 등록됩니다.<br/>
활성되어 있는 주소가 카키에 등록하고자 하는 주소가 아닌 경우 아래와 같이 진행해주시기 바랍니다.<br/><br/>

[다른지갑 등록 방법]<br/><br/>

1. 메타마스크 아이콘을 클릭해주세요.<br/>
2. 지갑 상단 우측의 지구본 아이콘을 클릭해주세요.<br/>
3. 등록하고자 하는 지갑 주소 아래의 [이 계정으로 전환] 버튼을 클릭해주세요.<br/>
4. 마이페이지 > 나의지갑 화면의 [메타마스크 등록] 버튼을 다시 클릭해주세요.<br/>
5. 지갑주소가 변경되었는지 확인 후 2채널 인증을 완료하면 다른 지갑주소로 등록됩니다.`,
        regDate: whatTimeIsItNow.value
    },
    {
        id: '3',
        issueType: 'NFT',
        title: '작품 출금은 어떻게 하나요?',
        content: `거래소에서 입출금을 진행하기 위해서는 외부 지갑을 등록해야합니다.<br/>
현재는 등록 가능한 지갑 서비스로, 메타마스크를 지원하고 있습니다.<br/><br/>

거래소는 건전한 디지털 자산 투자 문화 정착을 위해,<br/>
공정하고 투명한 ‘거래지원 정책’을 통해 검증된 디지털 자산에 대해서만 거래될 수 있도록 운영하고 있습니다.<br/>
따라서, ‘거래지원’ 작품에 한해  서비스에서 거래 및 입출금이 가능합니다.<br/><br/>

[출금 방법]<br/><br/>

1. 입출금 > 출금 화면으로 이동하여, 출금할 작품을 선택해주세요.<br/>
2. 출금량을 입력하고 동의사항을 체크해주세요.<br/>
3. 출금 신청 버튼을 클릭해주세요.<br/>
4. 2채널 인증을 완료하면 출금 신청이 완료됩니다.<br/><br/>

출금 진행 상황은 거래현황 > 입출금내역 화면에서 확인할 수 있으며, 출금이 완료되면 메일로 알려드립니다.<br/>
정상적으로 출금되었다면 거래현황 > 입출금내역 화면에서 출금 내역을 확인하실 수 있습니다.<br/><br/>

이 외에 반복적으로 출금이 실패하는 경우, 1:1 문의 부탁드립니다.`,
        regDate: whatTimeIsItNow.value
    },
    {
        id: '4',
        issueType : '입금출금',
        title : '특정 토큰 ID만 출금할 수 없나요?',
        content : `작품은 동일한 작품명이라 하여도 토큰의 ID가 다를 수 있습니다.<br/>
현재 출금 시에는 수량만 지정할 수 있으며, 토큰 ID를 조회하거나 특정 토큰 ID만 선택하여 출금할 수 없습니다.<br/>
하나의 토큰 ID가 소진될 때까지 출금되며, 모두 소진되면 다음 토큰 ID가 소진될 때까지 출금됩니다.<br/>
이 점 유의하시어 입금하기 전 작품의 토큰 ID를 꼭 확인해주세요.<br/><br/>

예) 작품 A의 토큰 ID 1, 2, 3을 각각 10개씩 보유하고 있을 때<br/>
1. 작품 A를 10개 출금 신청했을 때: 토큰 ID 1이 10개 출금됨<br/>
2. 작품 A를 15개 출금 신청했을 때: 토큰 ID 1이 10개, 토큰 ID 2가 5개 출금됨<br/>
3. 작품 A를 25개 출금 신청했을 때: 토큰 ID 1이 10개, 토큰 ID 2가 10개, 토큰 ID 3이 5개 출금됨<br/>
4. 작품 A를 1개씩 11번 출금 신청했을 때: 토큰 ID 1이 1개씩 10번 출금된 후 토큰 ID 2가 1개 출금됨`,
    },
    {
        id: '5',
        issueType : '이름개명',
        title : '이름을 개명했어요.',
        content : `개명으로 인해 이름이 변경되었다면 1:1 문의로 개명을 신청해주시기 바랍니다.<br/><br/>

[신청 방법]<br/><br/>

1. 고객센터 > 1:1 문의 화면으로 이동해주세요.<br/>
2. 1:1 문의 내용에 개명 전 성명, 개명 후 성명을 포함하여 작성해주세요.<br/>
3. 카키에 등록된 회원님의 메일 주소로 추가 증빙 서류에 대한 메일을 발송드립니다.<br/>
4. 회신된 메일의 안내에 따라 증빙 서류를 첨부하여 발송해주세요.`,
    },
]

/**
 * 임시 더미데이터 생성용 함수 (데모용)
 */
async function getAllFaqList() {
    let usedIndices = []
    let count = 1
    for (let i = 0; i < 24; i++) {
        // 무작위 인덱스 생성
        let randomIndex
        do {
            randomIndex = Math.floor(Math.random() * ITEM_LIST.length)
        } while (usedIndices.includes(randomIndex))

        // 인덱스를 사용한 횟수 저장
        usedIndices.push(randomIndex)

        // 5번의 반복마다 사용한 인덱스 초기화
        if (usedIndices.length === 4) {
            usedIndices = []
        }

        // 무작위 항목 가져오기
        const randomItem = ITEM_LIST[randomIndex]

        // item 객체 생성과 값 할당
        const item = {
            idx: i,
            issueType: randomItem.issueType || '', // 무작위 항목의 issueType 값 할당
            title: randomItem.title || '', // 무작위 항목의 title 값 할당
            content: randomItem.content || '',
            regDate: whatTimeIsItNow.value - 86400000 * count
        }

        // faqList 배열에 item 객체 추가
        faqList.value.push(item)
        count += randomIndex
    }

    return true
}

// 쿼리스트링을 체크해서 변수들에 세팅합니다.
function checkQueryString() {
    if (route.query.page) {
        page.value = Number(route.query.page)
    }
    if (route.query.search) {
        search.value = route.query.search
    }
    if( Number(route.query.faqid) ){
        panel.value = Number(route.query.faqid) - 1;
    }
}

/**
 * 검색어를 가지고 쿼리스트링에 넣고 결과를 탐색합니다.
 */
function searchItem() {
    updateQueryString({ to_search: search.value, to_page: 1 })
}

/**
 * 새로운 쿼리스트링을 받아서 페이지를 업데이트 합니다.
 * @param {*} newPage
 */
function updateQueryString({ to_page, to_search }) {
    const queryString = {}
    if (to_page) queryString.page = to_page
    if (to_search) queryString.search = to_search
    router.push({
        path: '/support/faq',
        query: queryString
    })
}
</script>

<style>
#myAccordion .v-expansion-panel-title--active,
#myAccordion .v-expansion-panel-title--active
 {
    background-color: white !important;
}

.v-expansion-panel-title__overlay {
    background-color: white !important;
}

.v-expansion-panel--active > .v-expansion-panel-title {
    max-height: 42px !important;
}

.v-expansion-panel-title {
    max-height: 42px !important;
}

.v-expansion-panel-title__overlay {
    max-height: 42px !important;
}
</style>
