<!-- 나의 자산, 나의자산 -->
<template>
    <div class="w-[1030px] min-h-[800px] myAsset">
        <!-- <div> -->
        <div
            v-if="isPageLoaded === false"
            class="w-[1030px] min-h-[800px] bg-[#fff] flex justify-center items-center"
        >
            <v-progress-circular
                width="10"
                size="80"
                indeterminate
                color="#305C3A"
            ></v-progress-circular>
        </div>
        <!-- title 영역 -->
        <div v-if="isPageLoaded" class="tm1 border-b px-4 py-2">
            <p class="subTitle text-noto-700 flex">
                나의자산 
                <span @mouseover="isHovered = true" @mouseleave="isHovered = false" @click="makeMyAssetGuideAlert">
                    <QuestionRound class="ml-1 mt-2 clickable" id="question_mark" />
                </span>
                <span ></span>
            </p>
        </div>

        <div v-if="isPageLoaded" class="ms2 flex">
            <!-- 총 보유 자산 표시 영역 -->
            <div class="w-[567px] border-r">
                <!-- 총 구매금액, 총 평가수익 등 -->
                <div class="flex w-full min-h-[110px] px-5">
                    <div class="flex flex-col w-1/2">
                        <div class="flex items-center h-[42px] pt-5 mt-2 mb-5">
                            <span class="contents_primary text-gray pl-2 min-w-[78px]"
                                >총 보유금액</span
                            >
                            <span class="text-[17px] ml-2 mr-3 whitespace-normal max-w-[110px]">
                                {{
                                    Number(userStore?.memberAssetEval?.total_exchanged_current_balance) ? Math.floor(
                                        userStore?.memberAssetEval?.total_exchanged_current_balance
                                    )?.toLocaleString() : 0 || 0
                                }}
                            </span>
                            <small class="contents_secondary text-gray">{{ 'KRW' }}</small>
                        </div>
                        <div class="divider-1 w-[230px] ml-2">
                            <!-- 가운데 구분선 -->
                        </div>
                        <div class="flex items-center h-[42px] pt-4">
                            <span class="contents_primary text-gray pl-2 min-w-[78px]"
                                >총 구매금액</span
                            >
                            <span class="text-[14px] ml-2 mr-3 whitespace-normal max-w-[110px]">
                                {{
                                    Number(userStore?.memberAssetEval?.total_buy_amount) ? Math.floor(
                                        userStore?.memberAssetEval?.total_buy_amount
                                    )?.toLocaleString() : 0 || 0
                                }}
                            </span>
                            <small class="contents_secondary text-gray">{{ 'KRW' }}</small>
                        </div>
                        <div class="flex items-center h-[42px] pt-5 pb-5">
                            <span class="contents_primary text-gray pl-2 min-w-[78px]"
                                >총 평가금액</span
                            >
                            <span class="text-[14px] ml-2 mr-3 whitespace-normal max-w-[110px]">
                                {{
                                    Number(userStore?.memberAssetEval?.total_eval_amount) ? Math.floor(
                                        userStore?.memberAssetEval?.total_eval_amount
                                    )?.toLocaleString() : 0 || 0
                                }}
                            </span>
                            <small class="contents_secondary text-gray">{{ 'KRW' }}</small>
                        </div>
                    </div>
                    <div class="divider-2 h-[136px] my-auto">
                        <!-- 가운데 구분선 -->
                    </div>
                    <div class="flex flex-col w-1/2 pl-5">
                        <div class="flex items-center h-[42px] pt-5 mt-2 mb-5">
                            <span class="contents_primary text-gray pl-2 min-w-[78px]"
                                >보유 원화 포인트</span
                            >
                            <span class="text-[17px] ml-2 mr-3 whitespace-normal max-w-[110px]">
                                {{
                                    Number(userStore?.memberAssetEval?.total_coin_eval_amount) ? Math.floor(
                                        userStore?.memberAssetEval?.total_coin_eval_amount
                                    )?.toLocaleString() : 0 || 0
                                }}
                            </span>
                            <small class="contents_secondary text-gray">{{ 'KRW' }}</small>
                        </div>
                        <div class="divider-1 w-[230px] ml-2">
                            <!-- 가운데 구분선 -->
                        </div>
                        <div class="flex items-center justify-between h-[42px] pt-5 pl-2">
                            <span class="contents_primary text-gray min-w-[78px]">총 평가수익</span>
                            <div class="min-w-[60px] flex justify-end items-center">
                                <div
                                    class="text-[14px] ml-2 mr-3 whitespace-normal max-w-[110px]"
                                    :class="
                                        userStore?.memberAssetEval?.total_eval_profit > 0
                                            ? 'text-market_rise'
                                            : userStore?.memberAssetEval?.total_eval_profit < 0
                                            ? 'text-market_fall'
                                            : ''
                                    "
                                >
                                    {{
                                        Number(userStore?.memberAssetEval?.total_eval_amount) ? Math.floor(
                                            userStore?.memberAssetEval?.total_eval_profit
                                        )?.toLocaleString() : 0 || 0
                                    }}
                                </div>
                                <div class="w-[25px]">
                                    <small class="contents_secondary text-gray w-[25px]">{{
                                        'KRW'
                                    }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-between h-[42px] pt-5 pb-5 pl-2">
                            <span class="contents_primary text-gray min-w-[78px]">총 평가수익률</span>
                            <div class="min-w-[60px] flex justify-end items-center">
                                <div
                                    class="text-[14px] ml-2 mr-3 whitespace-normal max-w-[110px]"
                                    :class="
                                        userStore?.memberAssetEval?.total_eval_rate > 0
                                            ? 'text-market_rise'
                                            : userStore?.memberAssetEval?.total_eval_rate < 0
                                            ? 'text-market_fall'
                                            : ''
                                    "
                                >
                                    {{
                                        Number(userStore?.memberAssetEval?.total_eval_amount) ? (
                                            userStore?.memberAssetEval?.total_eval_rate
                                        )?.toLocaleString('ko-KR', {maximumFractionDigits: 2, minimumFractionDigits: 0}) : 0 || 0
                                    }}
                                </div>
                                <div class="w-[25px]">
                                    <small class="contents_secondary text-gray">{{ '%' }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 보유 심볼 및 코인 표시하는 영역 -->
                <div v-if="isUseFiatCurrency === 1" class="w-full text-center" :style="containerStyle">
                    <!-- 보유 심볼 -->
                    <div
                        v-for="(item, index) in dealMarketList"
                        :key="index"
                        :class="index !== dealMarketList.length - 1 ? 'border-r' : ''"
                    >
                        <div
                            class="bg-ex_RightA_label h-10 text-[18px] flex justify-center items-center"
                        >
                            {{ item || '' }}
                        </div>
                        <div class="h-14 flex justify-center items-center">
                            {{
                                assetList[index]?.current_balance !== undefined &&
                                assetList[index]?.current_balance !== null
                                    ? isExponential(String(assetList[index]?.current_balance))
                                        ? parseFloat(assetList[index]?.current_balance)
                                        : assetList[index]?.current_balance.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0})
                                    : '0'
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- 도넛 그래프 표시 및 자산별 총 금액 표시 영역 -->
            <div v-if="isPageLoaded" class="w-[463px]">
                <div class="min-h-[162px] flex">
                    <!-- 도넛 그래프 로직 추가 -->
                    <!-- 도넛 그래프 영역 -->
                    <div class="w-1/2 flex items-center justify-center">
                        <div id="d-chart-reload-btn"></div>
                        <div id="dougnut-chart-container" class="max-h-[150px]">
                            <canvas id="acquisitions"></canvas>
                        </div>
                    </div>
                    <!-- 도넛 그래프 지표 영역 -->
                    <div class="w-1/2 flex items-center">
                        <ul v-if="ChartDataList[0].label !== 'empty'">
                            <li v-for="(data, index) in ChartDataList || []" :key="index" class="flex">
                                <span v-if="data.label !== 'empty'" :style="{ color: cartColors[index] }" class="mr-5">●</span>
                                <span v-else :style="{ color: '#D9D9D9' }" class="mr-5">●</span>
                                <span v-if="data.label !== 'empty'" class="mr-5 w-[56px]">{{ data.label }}</span>
                                <span v-if="data.label !== 'empty'" class="ml-5 w-[45px]"
                                    >{{ Number(data.value) ? Number(data.value).toFixed(1) : '0.0' }}%</span
                                >
                                <span v-else class="ml-5 w-[45px]">
                                    0.0%
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isPageLoaded" class="tm1 border-t px-4 py-2">
            <p class="text-[20px] text-noto-700 flex">보유작품 목록</p>
        </div>
        <!-- 보유작품 표시 데이터 테이블 -->
        <div class="ms3 w-[1025px]" v-if="isPageLoaded">
            <table class="v-table">
                <colgroup>
                    <col style="width: 330px" />
                    <col style="width: 230px" />
                    <col style="width: 230px" />
                    <col style="width: 230px" />
                    <col style="width: 230px" />
                    <col style="width: 200px" />
                </colgroup>
                <tbody>
                    <tr class="h-[36px] bg-[#F2F2F2] contents_secondary text-[#848484]">
                        <td  
                        v-for="(item, index) in headers || []" :key="index" class="px-3"
                        :class="index !== 0 ? 'text-center' : ''"
                        >{{ item?.title }}</td>
                    </tr>
                </tbody>
            </table>
            <v-card class="w-full" style="padding: 0">
                <v-card-text class="w-full" style="padding: 0">
                    <v-window class="w-full h-full overflow-scroll min-h-[470px] max-h-[1036px]">
                        <v-window-item class="h-full">
                            <table class="v-table">
                                <colgroup>
                                    <col style="width: 330px" />
                                    <col style="width: 230px" />
                                    <col style="width: 230px" />
                                    <col style="width: 230px" />
                                    <col style="width: 230px" />
                                    <col style="width: 200px" />
                                </colgroup>
                                <tbody>
                                    <tr v-for="(item, index) in sortedTokenList || []" :key="index" class="h-[50px] contents_primary border-b">
                                        <!-- 보유작품 -->
                                        <td class="flex items-center justify-between h-[50px] px-3 pr-10">
                                            <div class="flex items-center">
                                                <img
                                                    :src="getImage(item?.name)"
                                                    style="width: 24px; height: 24px"
                                                    class="pr-2"
                                                />
                                                <div class="flex flex-col justify-center h-full">
                                                    <div class="h-[18px] w-[110px] flex items-center font-medium">{{ item?.name }}</div>
                                                    <div class="h-[18px] w-[110px] flex items-center font-light">{{ item?.symbol }}</div>
                                                </div>
                                                
                                            </div>
                                            <!-- 1차 개발에서 제외 -->
                                            <!-- <div class="relative">
                                                <div class="text-[#0A8A1D] flex items-center" @click="toggleDetails(index, item.symbol)"><span class="underline">거래하기</span>&nbsp;
                                                    <img v-if="activeIndex !== index" src="/asset/img/triangleDown.svg" style="width: 10px; height: 10px;" />
                                                    <img v-else src="/asset/img/triangleUp.svg" style="width: 10px; height: 10px;" />
                                                    <v-sheet v-if="activeIndex === index" class="hidden-details basicBoxShadow w-[155px] py-2 px-3 z-50 absolute translate-x-16 translate-y-10">
                                                        <div v-for="(item, index) in nftMarketListData || []" :key="index">
                                                            <RouterLink
                                                                :to="`/exchanger?code=${item?.market}`"
                                                                class="simple_link"
                                                            >
                                                            {{ `${item.name}` }}<small class="text-gray font-normal">{{ ` ${item.market1}/${item.market2}` }}</small>
                                                            </RouterLink>
                                                        </div>
                                                    </v-sheet>
                                                </div>
                                            </div> -->
                                            
                                        </td>
                                        <!-- 보유량 -->
                                        <td class="h-[50px] px-3 text-right">
                                            <span>{{
                                                (item?.current_balance)?.toLocaleString() ||
                                                ''
                                            }}</span>
                                            <span class="text-[10px] text-gray ml-1">{{ getSymbol(item?.name) }}</span>
                                        </td>
                                        <!-- 평균구매가 -->
                                        <td class="h-[50px] px-3 text-right">
                                            <span>{{
                                                (item?.avg_unit_cost)?.toLocaleString() || ''
                                            }}</span>
                                            <span class="text-[10px] text-gray ml-1">{{ item?.market || 'KRW' }}</span>
                                        </td>
                                        <!-- 구매금액 -->
                                        <td class="h-[50px] px-3 text-right">
                                            <span>{{ (item?.buy_amount)?.toLocaleString() || '' }}</span>
                                            <span class="text-[10px] text-gray ml-1">{{ item?.market || 'KRW' }}</span>
                                        </td>
                                        <!-- 평가금액 -->
                                        <td class="h-[50px] px-3 text-right">
                                            <span>{{
                                                (item?.exchanged_current_balance)?.toLocaleString() || ''
                                            }}</span>
                                            <span class="text-[10px] text-gray ml-1">{{ item?.market || 'KRW' }}</span>
                                        </td>
                                        <!-- 수익률 -->
                                        <td class="h-[50px] px-3 text-right">
                                            
                                            <div class="flex flex-col justify-center items-end h-full">
                                                <div 
                                                    class="h-[18px] w-[120px] flex justify-end items-center mr-3"
                                                    :class="[ 
                                                        sortedTokenList[index]?.eval_rate > 0 ? 'text-market_rise' : '',
                                                        sortedTokenList[index]?.eval_rate < 0 ? 'text-market_fall' : '',
                                                    ]"
                                                >
                                                    <span>{{
                                                        item?.eval_rate?.toLocaleString('ko-KR', {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        }) || ''
                                                    }}</span><span class="ml-[2px]">%</span>
                                                </div>
                                                <div 
                                                    class="h-[18px] w-[120px] flex justify-end items-center"
                                                    :class="[ 
                                                        sortedTokenList[index]?.eval_profit > 0 ? 'text-market_rise' : '',
                                                        sortedTokenList[index]?.eval_profit < 0 ? 'text-market_fall' : '',
                                                    ]"
                                                >   
                                                    <span>
                                                    {{ sortedTokenList[index]?.eval_profit > 0 ? '+' : '' }}{{ sortedTokenList[index]?.eval_profit?.toLocaleString('ko-KR', {
                                                            maximumFractionDigits: 2,
                                                        }) || '0' }}
                                                    </span>
                                                    <span class="text-[10px] ml-[2px]">{{ item?.market || 'KRW' }}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                
                            </table>
                            <div v-if="sortedTokenList.length < 1" class="w-full h-[444px] flex justify-center items-center">
                                <p>보유한 작품이 없습니다.</p>
                            </div>
                            
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </div>

        <!-- alert용 중첩 가능한 모달, 확인 전용 -->
        <v-dialog v-model="nested_dialog" width="486px">
            <v-card class="py-[12px] px-[27px]">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-[30px]">
                        {{ modal_title }}
                    </div>
                    <div>
                        <ul>
                            <li v-for="(item, index) in assetGuideItem" :key="index" class="flex text-[14px] mb-[8px]">
                                <div class="w-[132px] h-[21px] text-[#424242] text-noto-700">
                                    <label>
                                        {{ item.label }}
                                    </label>
                                </div>
                                <div>
                                    <span>
                                        {{ item.content }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 가운데 구분선 -->
                    <div class="custom-border mt-[12px] mb-[20px]"></div>
                    <!-- 유의사항 -->
                    <div class="w-[132px] h-[21px] text-[#424242] text-noto-700 mb-[4px]">
                        <label>
                            유의사항
                        </label>
                    </div>
                    <div class="text-[14px] text-noto-400 text-[#424242] pl-[20px]">
                        <ul class="list-disc">
                            <li v-for="(item, index) in assetNoticeItem" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-[50px]">
                    <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="changeNestedDialogFalse">
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
/* eslint-disable no-new, prefer-const */
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import Chart from 'chart.js/auto'

// pinia store import
import { useExchangerStore } from '@/stores/exchanger'
import { useUserStore } from '@/stores/user'
import { useOrderStore } from '@/stores/order'

// 요청 api import
import ExchangerApi from '@/api/exchanger'
import { getMemberAssetEval } from '../../api/user'
import { selectFiatMarketFlag } from '../../api/fiat/index.js'

// svg import
import { QuestionRound, QuestionRoundHovered } from '@/components/svg'

// utils import
import { isExponential } from '../../utils/utilsFactory'
import { colorRandomGenerator } from '@/utils/colorRandomGenerator'
import UtilSessionStorage from '@/utils/session'


const sessionStorage = new UtilSessionStorage();

const exchangerApi = new ExchangerApi();

// 실제 법정화폐(예 : 원화)를 사용하는지 판단하는 변수
const isUseFiatCurrency = ref(0);

// 나의 자산 옆 question icon hover 상태 저장
const isHovered = ref(false);

// pinia store 주입
const exchangerStore = useExchangerStore()
const userStore = useUserStore()

// 라우터 instance 주입
const router = useRouter()

// 컴포넌트에서 사용할 변수들 및 상태 관리
// 페이지네이션 용 ref화 페이지 변수
const page = ref(1)
const pageLength = ref(0)
const pageLimit = ref(10)
// 거래 가능한 마켓 목록
const dealMarketList = ref([])
// 해당하는 작품의 거래 마켓 목록
const nftMarketListData = ref([]);
// 보유 거래 코인 목록
const assetList = ref([{}])
// 보유 작품 목록
const tokenList = ref([])
const sortedTokenList = computed(() => {
    let result = []
    try {
        if (tokenList.value) {
            result = [...tokenList.value]
        }
    } catch (error) {
        console.error(error)
    }
    return result
})
// 거래하기 컴포넌트를 어디를 활성화할지 저장합니다.
const activeIndex = ref(null);
const toggleDetails = async (index, symbol) => {
  activeIndex.value = activeIndex.value === index ? null : index;
  nftMarketListData.value = await exchangerApi.getNftMarketList(symbol);
};
// 비동기함수들을 기다린 후에 조건부 랜더링이 되도록 하는 변수
const isPageLoaded = ref(false)
const dataTableLoading = ref(false)
// 동적으로 컴포넌트가 생성될 때 grid 레이아웃으로 1개, 2개, 3개, 4개 등으로 보유 코인의 종류가 달라질 때 다른 스타일을 적용하기 위한 로직
const containerStyle = computed(() => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${
        dealMarketList.value?.length >= 4 ? 2 : dealMarketList.value?.length
    }, 1fr)`,
    gridGap: '0'
}))

// utils에 정의해둔 30개의 colors를 가져옵니다.
const cartColors = ref(colorRandomGenerator())
const search = ref('')
const sortBy = ref([{ key: 'percent', order: 'desc' }])
const headers = ref([
    {
        title: '보유작품',
        align: 'start',
        key: 'name'
    },
    { title: '보유량', key: 'current_balance' },
    { title: '평균구매가', key: 'avg_unit_cost' },
    { title: '구매금액', key: 'buy_amount' },
    { title: '평가금액', key: 'eval_amount' },
    { title: '수익률', key: 'eval_rate' }
])

// 자산 안내 모달용 변수 설정
const assetGuideItem = ref([
    { label : `총 보유금액`, content : `보유 원화 포인트 + 작품의 총 평가금액`, },
    { label : `구매금액`, content : `보유량 x 구매평균가`, },
    { label : `평가금액`, content : `보유량 x 현재가`, },
    { label : `평가수익`, content : `평가금액 - 구매금액`, },
    { label : `평가수익률`, content : `평가수익 / 구매금액 x 100`, },
])
const assetNoticeItem = ref([
    `원화 포인트는 ${'ETH'}, ${'BTS'}를 입금 시점의 원화 시세로 반영합니다. 거래의 편의성을 위해 보여주는 지표이며 실제 원화를 입출금하지 않습니다.`,
    `보유작품의 평가금액, 평가수익, 구매평균가는 원화 포인트로 환산한 추정값으로 실제와 차이가 발생할 수 있습니다.`,
    `입금된 작품의 구매평균가는 입금 시점의 시세로 반영합니다.`,
    `본 내용은 참고용으로 당사는 이를 기반으로 한 투자 손실에 대해 책임을 지지 않습니다.`,
])

// 단순 확인용 모달창
const modal_title = ref('');
const nested_dialog = ref(false)    // 중첩용으로 나오는 작은 모달창
const nested_msg = ref('')          // alert용 모달창 메세지
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }

/**
 * 도넛 차트 데이터 임시 값
 */
const responseData = ref([]) // 서버로부터 받아온 자산 데이터를 가공해서 넣습니다.
const doughnutChartData = ref([]) // 도넛 차트 데이터에 들어갈 가공된 데이터가 들어갑니다.
const ChartDataList = computed(() => {  // 상위 9개만 노출시키고 나머지의 코인은 etc로 처리합니다.
    let result = [];
    result = doughnutChartData.value.slice(0, 9)
    if( !result.some(item => item.label === 'etc') && remainingItem.value && doughnutChartData.value.length > 9 ){
        result.push(remainingItem.value);
    }
    return result;
}) 
const remainingValue = ref(0);
const remainingItem = ref(null);

const calculateComputedRate = (index) => {
    let calculatedRateValue
    try {
        calculatedRateValue =
            (assetList.value[index].eval_amount /
                assetList.value.reduce((sum, item) => sum + item.eval_amount, 0)) *
            100
    } catch (error) {
        calculatedRateValue = 0
    }

    return calculatedRateValue
}

function getSymbol(name) {
    // userStore가 존재하고 memberAssetEval 프로퍼티가 존재하면
    // token_eval_list에서 name이 일치하는 항목을 찾습니다.
    try {
        if (userStore && userStore.memberAssetEval) {
            const temp = userStore.memberAssetEval.token_eval_list

            // token_eval_list가 배열이므로, 배열을 순회하면서 name이 일치하는 항목을 찾습니다.
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].name === name) {
                    return temp[i].symbol
                }
            }
        }
    } catch (error) {
        console.error(error)
    }
    return ''
}

/**
 * 마켓 혹은 심볼 이름을 통해 img 값을 얻어내는 함수
 * @param {*} name
 */
function getImage(name) {
    try {
        if (userStore && userStore.memberAssetEval) {
            const temp = [...userStore.memberAssetEval.token_eval_list]
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].name === name) {
                    return temp[i].image
                }
            }
        }
    } catch (error) {
        console.error(error)
    }
    return ''
}

/**
 * 페이지네이션에서 page가 바뀔때마다 실행될 함수, 쿼리스트링을 바꿉니다
 * @param {*} newPage
 */
function updateQueryString(newPage) {
    dataTableLoading.value = false
    router.push({ path: '/trading/myAssets', query: { page: newPage } })
}

function calculateAssetData(value) {}

onMounted(async () => {
    // isPageLoaded.value = false;
    try {
        const userId = sessionStorage.getUserId()

        const result = await selectFiatMarketFlag( { id : userId } );
        if( result === 1 ){
            isUseFiatCurrency.value = 1;    
        }

        await exchangerApi.getDealMarketList()
        dealMarketList.value = exchangerStore.dealMarketList

        // URL의 쿼리 스트링에서 페이지 값을 읽어옵니다.
        const currentPage = router.currentRoute.value.query.page
        // 페이지 값이 있으면 page에 설정합니다. 없으면 기본값은 1입니다.
        page.value = currentPage ? parseInt(currentPage, 10) : 1

        await getMemberAssetEval(userId)

        assetList.value = userStore?.memberAssetEval.coin_eval_list || []
        tokenList.value = userStore?.memberAssetEval.token_eval_list || []

    } catch (error) {
        console.error(error)
    }

    isPageLoaded.value = true

    try {
        const mergedList = [...assetList.value, ...tokenList.value]
        /**
         * eval_amount의 합계를 구합니다. 이미 total_eval_amount가 존재하므로 그것을 전체 양으로 삼습니다.
         * 그리고 그 합계에 대해서 각 객체가 가지는 eval_amount의 퍼센트를 calculateComputedRate의 로직으로 바꿉니다.
         */
        let total_exchanged_current_balance = 0
        for (let i = 0; i < mergedList.length; i++) {
            total_exchanged_current_balance += mergedList[i].exchanged_current_balance
        }

        const resultRateList = mergedList.map((item, index) => {
            const calculatedValue = (Number(item?.exchanged_current_balance) / Number(total_exchanged_current_balance)) * 100;
            const roundedValue = parseFloat(calculatedValue.toFixed(1));
            const result = {
                label: item?.symbol,
                value: roundedValue,
            }
            return result
        })

        responseData.value = [...resultRateList]

        // 들어온 차트 데이터를 value가 큰 순서대로 다시 sort 합니다.
        doughnutChartData.value = responseData.value
            .filter((item) => item.value !== null)
            .sort((a, b) => b.value - a.value)

        let sumOfValues = doughnutChartData.value.reduce(
            (accumulator, item) => accumulator + item.value, 0
        )
        remainingValue.value = 100 - sumOfValues

        remainingItem.value = {
            label: 'etc',
            value: remainingValue.value
        }
        if ( remainingValue.value > 0 && sumOfValues !== 0 ) {
            doughnutChartData.value.push(remainingItem.value)
        }
        if (dataTableLoading.value === false) {
            dataTableLoading.value = true
        }
    } catch (error) {
        console.error(error)
    }

    checkDoughnutChartEmpty();
    
    await nextTick()
    const ctx = document.getElementById('acquisitions')
    if (ctx) {
        new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: doughnutChartData.value.map((item) => item.value),
                        backgroundColor: doughnutChartData.value.map((item, index) => 
                        doughnutChartData.value.length === 1 && item.label === 'empty'
                            ? '#D9D9D9'
                            : cartColors.value[index]
                        ),
                        borderWidth: 1
                    }
                ]
            },
            options: {
                responsive: true
            }
        })
    }
})

function checkDoughnutChartEmpty(){
    if( doughnutChartData.value.length < 1 ){
        doughnutChartData.value.push(
            {
                label : 'empty',
                value : 100
            }
        )
    }
}

function makeMyAssetGuideAlert(){
    modal_title.value = '자산 안내';
    changeNestedDialogTrue();
}
</script>

<style scoped>
#question_mark {
    transform: translateY(1px);
}

table {
  border-collapse: collapse;
}

.custom-border{
    border-top: 1px solid var(--stroke);
}
</style>

<style>
.myAsset > .v-dialog > .v-overlay__content > .v-card > .v-card-text {
    /* padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important; */
    padding: 0 !important;
}
</style>
