// 랜덤하게 생성된 색들
/**
 * 30개까지의 random한 color를 생성합니다.
 */
export const colorRandomGenerator = () => {
  const colors = [
      'rgba(87, 161, 161, 1)',
      'rgba(188, 142, 96, 1)',
      'rgba(100, 142, 15, 1)',
      'rgba(234, 87, 115, 1)',
      'rgba(76, 124, 181, 1)',
      'rgba(98, 78, 31, 1)',
      'rgba(119, 92, 169, 1)',
      'rgba(88, 195, 224, 1)',
      'rgba(128, 224, 53, 1)',
      'rgba(217, 217, 217, 1)',
      'rgba(119, 177, 169, 0.2)',
      'rgba(251, 197, 49, 0.2)',
      'rgba(131, 111, 255, 0.2)',
      'rgba(175, 122, 197, 0.2)',
      'rgba(50, 255, 126, 0.2)',
      'rgba(40, 180, 99, 0.2)',
      'rgba(178, 235, 242, 0.2)',
      'rgba(220, 221, 225, 0.2)',
      'rgba(232, 67, 147, 0.2)',
      'rgba(191, 85, 236, 0.2)',
      'rgba(179, 57, 57, 0.2)',
      'rgba(186, 220, 88, 0.2)',
      'rgba(240, 255, 0, 0.2)',
      'rgba(255, 140, 0, 0.2)',
      'rgba(255, 20, 147, 0.2)',
      'rgba(147, 112, 219, 0.2)',
      'rgba(30, 144, 255, 0.2)',
      'rgba(102, 205, 170, 0.2)',
      'rgba(107, 142, 35, 0.2)',
      'rgba(255, 218, 185, 0.2)',
  ]

  return colors
}