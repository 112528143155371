<!-- 마이페이지 -->
<template>
  <main class="flex justify-center trading-monitor min-h-[100px] pt-4 mt-[60px]">
    <section class="left-tab mr-4 -translate-x-[10px]">
        <ul>
          <li :class="route.path.includes('/myinfo') ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
            <RouterLink :to="`/mypage/myinfo`" class="__lang">
              나의 정보
            </RouterLink>
          </li>
          <li
            v-if="fiatStore.isUseFiatCurrency === 1"
           :class="route.path.includes('/userAuth') ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
            <RouterLink :to="`/mypage/userAuth`" class="__lang">
              본인인증
            </RouterLink>
          </li>
          <li :class="route.path.includes('/securitySetting') ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
            <RouterLink :to="`/mypage/securitySetting`" class="__lang">
              <span v-if="fiatStore.isUseFiatCurrency !== 1">2채널 인증</span>
              <span v-else>보안인증</span>
            </RouterLink>
          </li>
          <li :class="route.path.includes('/wallet') ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'">
            <RouterLink :to="`/mypage/wallet`" class="__lang">
              나의지갑
            </RouterLink>
          </li>
        </ul>
    </section>
    <!-- 각각의 링크를 눌렀을 때 그 내용이 RouterView 안에 표시됩니다. 기본은 나의 정보입니다. -->
    <section class="-translate-x-[10px] mypage mb-[10px]">
      <RouterView></RouterView>
    </section>
  </main>

  <section 
    v-if="route.path === '/mypage/myinfo'" 
    class="bg-[#fff] min-h-[98px]"
  >
    <div class="w-[1040px] mx-auto contents_primary translate-x-[102px]">
      <!-- <h1 class="h-12 subTitle flex items-center pt-5 pl-5 mb-3 text-noto-700">알림 설정</h1> -->
      <!-- <ul>
        <li class="flex h-12 border-t border-b">
          <label class="flex items-center justify-center w-[180px] bg-ex_RightA_label">이벤트 알림받기</label>
          <div class="flex items-center justify-between w-[850px]">
            <div class="pl-5">공지사항, 입출금 안내 등 중요한 안내사항은 이벤트 안내 수신 동의 여부와 관계없이 이메일로 발송됩니다.</div>
            <div class="flex items-center">
              <input class="translate-y-[1px] mr-1" :checked="state.isEventAgreeChecked"
              type="radio" name="eventNotification" id="eventNotificationAgree" @change="watchImportantEventAlermAgree">
              <span :class="[ state.isEventAgreeChecked ? 'text-main_color text-noto-700' : '' ]">수신 동의</span>
              <input class="translate-y-[1px] mr-1 ml-3" :checked="state.isEventDisAgreeChecked"
              type="radio" name="eventNotification" id="eventNotificationDisagree" @change="watchImportantEventAlermDisAgree">
              <span :class="[ state.isEventDisAgreeChecked ? 'text-main_color text-noto-700' : '' ]">미동의</span>
            </div>
          </div>
        </li>
        <li class="flex h-12 border-b">
          <label class="flex items-center justify-center w-[180px] bg-ex_RightA_label">보안 알림받기</label>
          <div class="flex items-center justify-between w-[850px]">
            <div class="pl-5">로그인 IP가 기존과 다를 경우 이메일로 안내드립니다.</div>
            <div class="flex items-center">
              <input class="translate-y-[1px] mr-1" :checked="state.isSecurityAgreeChecked"
              type="radio" name="securityNotification" id="securityNotificationAgree" @change="watchImportantSecurityAlermAgree"/>
              <span :class="{ 'text-main_color text-noto-700': state.isSecurityAgreeChecked }">수신 동의</span>
              <input class="translate-y-[1px] mr-1 ml-3" :checked="state.isSecurityDisAgreeChecked"
              type="radio" name="securityNotification" id="securityNotificationDisagree" @change="watchImportantSecurityAlermDisAgree"/>
              <span :class="{ 'text-main_color text-noto-700': state.isSecurityDisAgreeChecked }">미동의</span>
            </div>
          </div>
        </li>
      </ul> -->
      <div class="text-noto-700 contents_title h-[50px] flex items-center">
        <!-- <div class="text-noto-700 contents_title h-[240px] flex items-center"></div> -->
        <p 
          class="border_b clickable underline text-gray"
          @click.prevent="$router.push({path : '/mypage/myinfo/withdraw'})"
        >
          회원탈퇴
        </p>
      </div>
    </div>

    <!-- alert용 중첩 가능한 모달 -->
    <v-dialog v-model="nested_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="changeNestedDialogFalse">
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </section>
</template>

<script setup>
// reactive import
import { reactive, onMounted, ref, watch } from 'vue';

// spa router 함수 이용을 위한 import
import { useRoute } from 'vue-router';

// pinia store import
import { useFiatStore } from '../stores/fiat/index.js';

// utils import
import UtilSessionStorage from '../utils/session';

// api import
import {
  updateAllowSetting,
  initMemberInfomation,
  requestWithdrawMember,
} from '../api/user/index.js'
import { selectFiatMarketFlag } from '../api/fiat';

const sessionStorage = new UtilSessionStorage();

// 라우트 인스턴스 생성
const route = useRoute();

// pinia 인스턴스 주입
const fiatStore = useFiatStore();

// 실제 법정화폐(예 : 원화)를 사용하는지 판단하는 변수
const isUseFiatCurrency = ref(0);

// 유저의 아이디
const id = ref('');

// 반응형 객체 생성
const state = reactive({
  isEventAgreeChecked: false,
  isEventDisAgreeChecked: true,
  isSecurityAgreeChecked: false,
  isSecurityDisAgreeChecked: true,
});

// 모달창 컨트롤용 변수
const nested_dialog = ref(false)
const modal_title = ref('안내')
const nested_msg = ref('')
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }

// functions ================================================================= //
/**
 * 탈퇴버튼을 눌렀을 때 검사 및 진행을 위한 준비단계 함수, 
 */
async function prepareMembershipWithdrawal(){

}

/**
 * 나의 정보 > 나의 정보 > 알림 설정 > 이벤트 알림받기 > 수신 동의 클릭시 실행될 함수
 */
async function watchImportantEventAlermAgree(){
  state.isEventAgreeChecked = true;
  state.isEventDisAgreeChecked = false;
  await updateAllowSetting({id: id.value, allowEventAlarm : 1});
  nested_msg.value = '이벤트 알림을 받을 수 있는 광고성 알림 수신에<br />동의하였습니다.'
  changeNestedDialogTrue();
}

/**
 * 나의 정보 > 나의 정보 > 알림 설정 > 이벤트 알림받기 > 미동의 클릭시 실행될 함수
 */
async function watchImportantEventAlermDisAgree(){
  state.isEventAgreeChecked = false;
  state.isEventDisAgreeChecked = true;
  await updateAllowSetting({id: id.value, allowEventAlarm : 1});
  nested_msg.value = '이벤트 알림을 받을 수 있는 광고성 알림 수신에<br />미동의하였습니다.'
  changeNestedDialogTrue();
}

/**
 * 나의 정보 > 나의 정보 > 알림 설정 > 보안 알림받기 > 수신 동의 클릭시 실행될 함수
 */
async function watchImportantSecurityAlermAgree(){
  state.isSecurityAgreeChecked = true;
  state.isSecurityDisAgreeChecked = false;
  await updateAllowSetting({id: id.value, allowSecurityAlarm : 1});
  nested_msg.value = '로그인 IP가 기존과 다를 경우 받을 수 있는<br />보안 알림 수신에 동의하였습니다.'
  changeNestedDialogTrue();
}

/**
 * 나의 정보 > 나의 정보 > 알림 설정 > 보안 알림받기 > 미동의 클릭시 실행될 함수
 */
async function watchImportantSecurityAlermDisAgree(){
  state.isSecurityAgreeChecked = false;
  state.isSecurityDisAgreeChecked = true;
  await updateAllowSetting({id: id.value, allowSecurityAlarm : 1});
  nested_msg.value = '로그인 IP가 기존과 다를 경우 받을 수 있는<br />보안 알림 수신에 미동의하였습니다.'
  changeNestedDialogTrue();
}


onMounted(async ()=>{
  id.value = sessionStorage.getUserId();

  selectFiatMarketFlag( { id : id.value } )
  .then(response => {
    if( response === 1 ){
        isUseFiatCurrency.value = 1;
    }
  })

  // 1차 개발에서 제외
  // initMemberInfomation( id.value )
  // .then( response => {
  //   const { allowEventAlarm, allowSecurityAlarm } = response;
  //   if( allowEventAlarm === 1 || allowEventAlarm === '1' ){
  //     state.isEventAgreeChecked = true;
  //   } else {
  //     state.isEventDisAgreeChecked = true;
  //   }
  //   if( allowSecurityAlarm === 1 || allowSecurityAlarm === '1' ){
  //     state.isSecurityAgreeChecked = true;
  //   } else {
  //     state.isSecurityDisAgreeChecked = true;
  //   }
  // })

})

</script>