<script setup>
import { ref, onMounted, watch } from 'vue'
import { RoundLeftIcon, RoundRightIcon } from '../../svg'

const props = defineProps({
    market2: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    items: {
        type: Array,
        default: () => []
    },
    index: {
        type: Number,
        default: 0
    }
})

const model = ref(null)

function makeRate(close, deal) {
    return ((Number(deal) - Number(close)) / Number(close)) * 100 || 0
}

onMounted(() => {})
</script>

<template>
    <div
        class="ranking-page md:pb-[20px] md:w-rank-calc md:mx-auto"
        :class="index === 0 ? 'mt-[40px] md:mt-[87px]' : 'mt-[24px] md:mt-[127px]'"
    >
        <div class="md:translate-x-[53px] md:mb-[16px]">
            <p>
                <span class="text-[26px] font-semibold">{{ title }}</span
                >&nbsp;<span class="text-[14px] text-noto-400 text-gray-darken-3">24시간 기준</span>
            </p>
        </div>
        <v-sheet class="mx-auto ranking_carousel">
            <v-slide-group v-model="model" class="pa-0" center-active show-arrows>
                <template v-slot:prev>
                    <v-icon
                        color="red"
                        large
                        class="pa-0 z-30 -translate-y-5 translate-x-4 md:translate-x-8 text-[50px] md:text-[100px]"
                        :style="{ background: '', borderRadius: '50%' }"
                    >
                        <RoundLeftIcon />
                    </v-icon>
                </template>
                <template v-slot:next>
                    <v-icon
                        color="red"
                        large
                        class="pa-0 z-40 -translate-y-5 -translate-x-4 md:-translate-x-8 text-[50px] md:text-[100px]"
                        :style="{ background: '', borderRadius: '50%' }"
                    >
                        <RoundRightIcon />
                    </v-icon>
                </template>

                <v-slide-group-item
                    v-for="(item, index) in items"
                    :key="index"
                    v-slot="{ isSelected, toggle }"
                >
                    <v-card
                        :color="isSelected ? 'primary' : 'white'"
                        class="main_card_wrapper md:ma-3 md:mr-[16px] rounded-lg overflow-visible"
                        style="border: none"
                        @click="toggle"
                    >
                        <div class="main_card overflow-hidden">
                            <RouterLink :to="`/exchanger?code=${item?.market}`">
                                <div class="mb-1 border-b">
                                    <img v-if="item.logo" :src="item.logo" />
                                    <img v-else src="/asset/img/image_blank.svg" />
                                </div>
                                <div class="w-full card-sub h-[60px] px-3 pt-[6px]">
                                    <span
                                        ><p class="text-noto-500">{{ item?.name || '' }}</p></span
                                    >
                                    <span
                                        class="flex justify-between text-[14px]"
                                        :class="[
                                            item?.roc > 0 ? 'plus' : item?.roc < 0 ? 'minus' : ''
                                        ]"
                                    >
                                        <span
                                            >{{ item?.last?.toLocaleString('ko-KR') || 0 }}
                                            <small class="text-[12px] text-gray-darken-3">{{
                                                props?.market2 || ''
                                            }}</small></span
                                        >
                                        <span>
                                            {{
                                                item?.roc > 0
                                                    ? `+${(item?.roc).toLocaleString('ko-KR', {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                      })}`
                                                    : item?.roc < 0
                                                    ? `${(item?.roc).toLocaleString('ko-KR', {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                      })}`
                                                    : `${(item?.roc).toLocaleString('ko-KR', {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                      })}` || ''
                                            }}
                                            <span class="text-gray-darken-3">%</span>
                                        </span>
                                    </span>
                                </div>
                            </RouterLink>
                        </div>
                    </v-card>
                </v-slide-group-item>
            </v-slide-group>
        </v-sheet>
    </div>
</template>
