<!-- 거래현황 > 입출금내역 -->
<template>
    <div v-if="loading===false" class="w-[1050px] h-[800px] bg-[#fff] flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
    </div>
    <div v-else-if="loading">
        <section class="tm1 px-4 py-2 mb-4 mr-5">
            <p class="__lang subTitle text-noto-700 flex">입출금내역</p>
        </section>
        <section class="flex items-center border-none mb-[6px]">
            <div class="pl-3">
                <ul class="flex items-center align-center h-10">
                    <li
                        :class="active_tab.type === 0 ? 'active' : 'clickable'"
                        class="__lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( $route.query.type ){
                                change_tab('type', 0)
                            } else {
                                return
                            }
                        }"
                        :disable="active_tab.type === 0"
                    >
                        전체
                    </li>
                    <li
                        :class="active_tab.type === 1 ? 'active' : 'clickable'"
                        class="__lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( $route.query.type !== 'in' ){
                                change_tab('type', 1)
                            } else {
                                return
                            }
                        }"
                        :disable="active_tab.type === 1"
                    >
                        입금
                    </li>
                    <li
                        :class="active_tab.type === 2 ? 'active' : 'clickable'"
                        class="__lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( $route.query.type !== 'out' ){
                                change_tab('type', 2)
                            } else {
                                return
                            }
                        }"
                        :disable="active_tab.type === 2"
                    >
                        출금
                    </li>
                </ul>
            </div>
            <!-- 거래내역 검색 탭 -->
            <div class="ml-5">
                <!-- 검색 상자 기능 -->
                <div class="pr-[10px] combobox-area relative overflow-visible">
                    <input 
                        class="combobox-input border w-[285px] h-[33px] text-[12px] pl-4" 
                        type="text" 
                        v-model="search"
                        :placeholder="'작품 전체'" 
                        @keyup.enter="searchItem"
                        @click:append="searchItem"
                        @focus="combobox = true" 
                        @blur="combobox = false"
                    />
                    <div v-if="combobox" class="absolute z-10 top-[33px] combobox-list w-[285px] max-h-[500px] bg-[#fff] overflow-scroll basicBoxShadow">
                        <div v-for="(item, index) in sortedMarketList || []" :key="index" 
                        class="combobox-item min-h-[30px] clickable px-5 py-2 text-[12px] text-noto-350 text-left" 
                        @mousedown="search = item.symbol">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div v-if="search.length > 0" class="absolute right-12 top-[10px] clickable" @click="search = ''">
                        <img src="/asset/img/cancelBtn.svg" />
                    </div>
                    <div class="absolute right-5 top-1 clickable" @click="searchItem">
                        <img src="/asset/img/search_primary.svg" />
                    </div>
                </div>
            </div>
        </section>

        <!-- 보유작품 표시 데이터 테이블 -->
        <section class="ms3">
            <v-card>
                <v-card-text style="padding: 0">
                    <v-window class="h-full">
                        <v-window-item class="h-full">
                            <!-- 데이터 테이블 기능 -->
                            <v-data-table
                                v-if="loading"
                                v-model:sort-by="sortBy"
                                :headers="headers"
                                :items="inoutList || []"
                                :items-per-page="-1"
                                sort-asc-icon="mdi-menu-up"
                                sort-desc-icon="mdi-menu-down"
                                class="elevation-1 overflow-visible"
                                style="width: 100%; font-size: 11px; min-height: 700px;"
                                no-data-text="입출금내역이 없습니다."
                                fixed-header
                                id="custom-scrollbar"
                            >
                                <template v-slot:item="{ item, index }">
                                    <tr>
                                        <!-- 시간 -->
                                        <td>
                                            <div class="leading-4 h-[14px] pa-0 ma-0 flex">
                                                {{
                                                    unix_to_yyyymmdd(
                                                        Number(item.columns?.reg_date / 1000)
                                                    )
                                                }}
                                            </div>
                                            <div
                                                class="leading-3 h-[14px] item-center pa-0 ma-0 flex text-gray"
                                            >
                                                {{
                                                    unix_to_timestamp(
                                                        Number(item.columns?.reg_date / 1000)
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <!-- 자산 / 작품 -->
                                        <td>
                                            {{ item?.columns?.symbol || '' }}
                                        </td>
                                        <!-- 구분 -->
                                        <td
                                            :class="`${
                                                item?.columns?.inout_type === 'in'
                                                    ? 'plus'
                                                    : item?.columns?.inout_type === 'out'
                                                    ? 'minus'
                                                    : ''
                                            }`"
                                        >
                                            {{
                                                item?.columns?.inout_type === 'in'
                                                    ? '입금'
                                                    : item?.columns?.inout_type === 'out'
                                                    ? '출금'
                                                    : '' || ''
                                            }}
                                        </td>
                                        <!-- 수량 -->
                                        <td>
                                            {{ item?.columns?.volume?.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0}) || '' }}
                                            {{ item?.columns?.symbol || '' }}
                                        </td>
                                        <!-- 환산금액 -->
                                        <td>
                                            {{
                                                item?.columns?.exchanged_volume?.toLocaleString() ||
                                                ''
                                            }}
                                            {{ exchangerStore.dealMarketList[0] || '' }}
                                        </td>
                                        <!-- 수수료 -->
                                        <td>
                                            {{
                                                Math.round(item?.columns?.exchanged_fee)?.toLocaleString() || ''
                                            }}
                                            {{ exchangerStore.dealMarketList[0] || '' }}
                                        </td>
                                        <!-- 총금액 -->
                                        <td>
                                            {{
                                                item?.columns?.exchanged_total?.toLocaleString() ||
                                                ''
                                            }}
                                            {{ exchangerStore.dealMarketList[0] || '' }}
                                        </td>
                                        <!-- 주소 -->
                                        <!-- 내부 입금일 때 -->
                                        <td v-if="item?.columns?.address.length < 1">
                                            <div
                                                v-if="item?.columns?.state === 1"
                                                @mouseover="handleMouseover(index)" 
                                                @mouseleave="handleMouseleave"
                                            >
                                                <span 
                                                    class="underline clickable" 
                                                    @click="handleClick( `${index}` )"
                                                >
                                                    {{ inoutList[index]?.inex_type === "internalWallet" ? '내부 지갑[확인]' : '외부 지갑[확인]' }}
                                                </span>
                                                <div class="relative">
                                                    <v-sheet v-if="(Number(activeIndex) === index && activeIndex !== null) || isHovered === index" 
                                                    class="hidden-details basicBoxShadow w-[330px] py-2 px-3 z-50 absolute -translate-x-[240px] -translate-y-1 text-left">
                                                        <div class="custom-line-height">
                                                            TXID: 
                                                            <span v-if="inoutList[index]?.inex_type === `externalWallet`">
                                                                <a :href="inoutList[index].exploreUrl" class="underline text-[#0065FF]" target="_blank" rel="noopener noreferrer">
                                                                    {{ inoutList[index]?.txnHash || '' }}
                                                                </a>
                                                            </span>
                                                            <span v-else>
                                                                {{ inoutList[index]?.txnHash || '' }}
                                                            </span>
                                                        </div>
                                                        <div class="custom-line-height">주소: {{ item?.columns?.to_address }}</div>
                                                        <div class="custom-line-height">금액: {{ inoutList[index]?.origin_volume 
                                                        ? inoutList[index]?.origin_volume?.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0}) 
                                                        : item?.columns?.volume?.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0}) || '' }} 
                                                        {{ inoutList[index]?.origin_symbol ? inoutList[index]?.origin_symbol : item?.columns?.symbol || '' }}</div>
                                                    </v-sheet>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- 내부 출금일 때 -->
                                        <td 
                                            v-else-if="item?.columns?.address.startsWith('0x')" class="relative "
                                        >
                                            <div
                                                v-if="item?.columns?.state === 1"
                                                @mouseover="handleMouseover(index)" 
                                                @mouseleave="handleMouseleave"
                                            >
                                                <span 
                                                    class="underline clickable" 
                                                    @click="handleClick( `${index}` )"
                                                >
                                                    {{ inoutList[index]?.inex_type === "internalWallet" ? '내부 지갑[확인]' : '외부 지갑[확인]' }}
                                                </span>
                                                <div class="relative">
                                                    <v-sheet v-if="(Number(activeIndex) === index && activeIndex !== null) || isHovered === index" 
                                                    class="hidden-details basicBoxShadow w-[330px] py-2 px-3 z-50 absolute -translate-x-[240px] -translate-y-1 text-left">
                                                        <div class="custom-line-height">
                                                            TXID: 
                                                            <span v-if="inoutList[index]?.inex_type === `externalWallet`">
                                                                <a :href="inoutList[index].exploreUrl" class="underline text-[#0065FF]" target="_blank" rel="noopener noreferrer">
                                                                    {{ inoutList[index]?.txnHash || '' }}
                                                                </a>
                                                            </span>
                                                            <span v-else>
                                                                {{ inoutList[index]?.txnHash || '' }}
                                                            </span>
                                                        </div>
                                                        <div class="custom-line-height">주소: {{ item?.columns?.address }}</div>
                                                        <div class="custom-line-height">금액: {{ inoutList[index]?.origin_volume 
                                                        ? inoutList[index]?.origin_volume?.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0}) 
                                                        : item?.columns?.volume?.toLocaleString('ko-KR', {maximumFractionDigits: 8, minimumFractionDigits: 0}) || '' }} 
                                                        {{ inoutList[index]?.origin_symbol ? inoutList[index]?.origin_symbol : item?.columns?.symbol || '' }}</div>
                                                    </v-sheet>
                                                </div>
                                            </div>
                                        </td>
                                        <td v-else>
                                            {{ item?.columns?.address.split(' ')[0].length < 6 && item?.columns?.address.split(' ')[0].length > 0 ? `${item?.columns?.address.split(' ')[0]}` : '한국은행' || '한국은행' }}
                                        </td>
                                        <!-- 상태 -->
                                        <td>
                                            {{
                                                item?.columns?.state === 9
                                                    ? '취소'
                                                    : item?.columns?.state === 1
                                                    ? '완료'
                                                    : item?.columns?.state === 0 && inoutList[index].inout_type === 'in'
                                                    ? '입금 대기'
                                                    : item?.columns?.state === 0 && inoutList[index].inout_type === 'out'
                                                    ? '출금 대기'
                                                    : item?.columns?.state === 0
                                                    ? '대기'
                                                    : ''
                                            }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <div>
                    <v-pagination 
                        v-model="page" 
                        :length="pageLength" 
                        rounded="circle"
                        @update:model-value="updateQueryString"
                        class="pb-5"
                        :total-visible="6"
                        color="#858585"
                        active-color="black"
                    ></v-pagination>
                </div>
            </v-card>
        </section>
    </div>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '../../stores/user'
import { useExchangerStore } from '../../stores/exchanger'
import { unix_to_timestamp, unix_to_yyyymmdd } from '@/utils/utilsFactory.js'
import UtilSessionStorage from '../../utils/session';
import { getUserInoutListData } from '../../api/user';
import ExchangerApi from '@/api/exchanger/index.js'
import { toChosungString } from '../../utils/utilsFactory'

const sessionStorage = new UtilSessionStorage();

const exchangerApi = new ExchangerApi();

// pinia 인스턴스
const userStore = useUserStore()
const exchangerStore = useExchangerStore()
// 라우터 instance 주입
const router = useRouter();
const route = useRoute();
// 페이지네이션 용 ref화 페이지 변수
const page = ref(1)
const pageLength = ref(1);
const inOutType = ref(null);
const startDate = ref(null);
const endDate = ref(null);

// click 및 hover 상태 저장용 변수
const activeIndex = ref(null);
const isHovered = ref(-1);

const search = ref('')
const combobox = ref(false);
const loading = ref(false)
const sortBy = ref([{ key: 'reg_date', order: 'desc' }])
const headers = ref([
    {
        title: '시간',
        align: 'start',
        sortable: false,
        key: 'reg_date'
    },
    { title: '자산/작품', sortable: false, key: 'symbol' },
    { title: '구분', sortable: false, key: 'inout_type' },
    { title: '수량', sortable: false, key: 'volume' },
    { title: '환산금액', sortable: false, key: 'exchanged_volume' },
    { title: '수수료', sortable: false, key: 'exchanged_fee' },
    { title: '총 금액', sortable: false, key: 'exchanged_total' },
    { title: '주소', sortable: false, key: 'address' },
    { title: '상태', sortable: false, key: 'state' }
])
// 입출금 리스트 데이터
const inoutList = ref([])
// 마켓 리스트 데이터
const marketListData = ref([]);
// 필터링 거친 리스트 데이터
const sortedMarketList = computed(() => {
    let result = [];
    try {
        result = marketListData.value

        // searchNft에 입력된 검색어가 있을 경우 필터링
        if (search.value.trim()) {
            const searchTerm = search.value.trim().toUpperCase();  // 입력된 검색어를 대문자로 변환
            result = result.filter(item => 
            item?.name.toUpperCase().includes(searchTerm) ||
            item?.symbol.toUpperCase().includes(searchTerm) ||
            toChosungString(item.name).includes(searchTerm) );
        }

        result.sort( ( a, b )=>{
            return a.symbol.localeCompare(b.symbol);
        })
    } catch (error) {
        console.error(error)
    }
    return result;
})
const active_tab = reactive({
    period: 0,
    type: 0
})

function handleClick( newVal ){
    if (activeIndex.value === null && newVal === '0') {
        activeIndex.value = '0';
    } else if (Number(activeIndex.value) === Number(newVal)) {
        activeIndex.value = null;
    } else if (newVal) {
        activeIndex.value = newVal;
    }
}

function handleMouseover(index) {
        isHovered.value = index;
    }

function handleMouseleave() {
        isHovered.value = -1;
    }


onMounted(async () => {
    
    checkQueryString();
    
    exchangerApi.getDealMarketList();
    
    // 쿼리스트링에 따라 ref 속성을 설정합니다.
    if( router.currentRoute.value.query.type ){
        inOutType.value = router.currentRoute.value.query.type
    }
    if( router.currentRoute.value.query.type === 'in' ) {
        active_tab.type = 1;
    } else if( router.currentRoute.value.query.type === 'out' ){
        active_tab.type = 2;
    } else {
        active_tab.type = 0;
    }
    if( route.query.symbol ){
        search.value = route.query.symbol;
    }
    
    startDate.value = router.currentRoute.value.query.start || null;
    endDate.value = router.currentRoute.value.query.end || null;

    const userId = sessionStorage.getUserId();
    const answer = await getUserInoutListData( userId, page.value, inOutType.value, startDate.value, endDate.value, search.value )
    pageLength.value = answer.page_num;

    paginateItems();

    // 마켓 리스트를 얻어와 세팅합니다.
    marketListData.value = await exchangerApi.allAssetListIncludeCoin({ coin : false });
    loading.value = true
})

/**
 * 페이지네이션이 일어날때마다 내부적으로 값들을 재계산하는 로직입니다.
 */
 function paginateItems () {
    inoutList.value = userStore.memberInoutList;
}

// 쿼리스트링을 체크해서 변수들에 세팅합니다.
function checkQueryString() {
    // // URL의 쿼리 스트링에서 페이지 값을 읽어옵니다.
    // const currentPage = router.currentRoute.value.query.page;
    // // 페이지 값이 있으면 page에 설정합니다. 없으면 기본값은 1입니다.
    // page.value = currentPage ? parseInt(currentPage, 10) : 1;

    try {
        if (route.query.page) {
            page.value = Number(route.query.page)
        }
        if (route.query.search) {
            search.value = route.query.search
        }
    } catch (error) {
        console.error(error)
    }
    
}

/**
 * 검색어를 가지고 쿼리스트링에 넣고 결과를 탐색합니다.
 */
function searchItem() {
    updateQueryStringWithType({ to_search: search.value, to_page: 1 })
}

/**
 * 페이지네이션에서 page가 바뀔때마다 실행될 함수, 쿼리스트링을 바꿉니다
 * @param {*} newPage 
 */
 function updateQueryString( newPage ){
    loading.value = false;
    const queryString = { page : newPage }
    try {
        if( search.value ){
            queryString.symbol = search.value;
        } else {
            queryString.symbol = null;
        }
        if( inOutType.value ){
            queryString.type = inOutType.value;
        } else {
            queryString.type = null;
        }
    } catch (error) {
        console.error(error)
    }

    router.push( { path:'/trading/withdrawalHistory',  query : queryString } )
}

function updateQueryStringWithType({ to_search, to_page, type }){
    loading.value = false;
    const queryString = {}
    if (to_page) queryString.page = to_page
    if (to_search) queryString.symbol = to_search
    if (type) queryString.type = type
    router.push( { path:'/trading/withdrawalHistory',  query : queryString } )
}

/**
 * 탭을 바꿀 수 있는 함수
 * @param {*} type
 * @param {*} tab
 */
function change_tab(type, tab) {
    if (type === 'period') {
        active_tab.period = tab
    } else if (type === 'type') {
        active_tab.type = tab
    }

    if( tab === 0 ){
        inOutType.value = null;
    } else if( tab === 1 ){
        inOutType.value = 'in';
    } else if( tab === 2 ){
        inOutType.value = 'out';
    }
    updateQueryString(1);
    // updateQueryStringWithType({ type : inOutType.value });
}
</script>

<style scoped>
.active {
    background-color: var(--main_color);
    color: white;
}

/* 입출금내역 데이터 테이블 중앙 정렬 용 */
.ms3 table tr td {
    text-align: center;
}



.custom-line-height {
    line-height: 1.3; 
    margin-bottom: 8px;
    word-wrap: break-word;
    word-break: break-all;
}
</style>

<style>
.ms3 div.v-table__wrapper {
    overflow: visible !important;
}
</style>
