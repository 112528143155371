/* eslint-disable no-param-reassign, no-restricted-syntax, no-plusplus */
import Crypto from '../crypto/index.js'

class UtilSessionStorage {

    ttsTime = 1800;

    secretKey

    initialize(){
        this.secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
    }

    getItem(key) {
        return window.sessionStorage.getItem(key)
    }

    getItemWithCrypto(key) {
        const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
        const cryptoItem = window.sessionStorage.getItem(key)
        try {
            return Crypto.decodeByAES256(secretKey, cryptoItem)
        } catch (error) {
            console.error(error)
        }
        return null;
    }

    getUserId() {
        const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
        const user = window.sessionStorage.getItem('user')
        if( user ){
            return Crypto.decodeByAES256(secretKey, user)
        }
        return '';
    }

    // 거래에 사용할 아이디 체크 함수, 윈도우 오프너 객체가 존재할 경우와 그렇지 않을 경우에 로직이 달라집니다.
    getUserIdforTransaction( isFromPopup ){
        try {
            if( isFromPopup ){
                const openerUser = window.opener.sessionStorage.getItem("user");
                if( openerUser ){
                    let secretKey;
                    if( !this.secretKey ){
                        secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
                    } else {
                        secretKey = this.secretKey
                    }
                    return Crypto.decodeByAES256( secretKey, openerUser )
                }
            } else {
                const openerUser = window.sessionStorage.getItem("user");
                if( openerUser ){
                    let secretKey;
                    if( !this.secretKey ){
                        secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
                    } else {
                        secretKey = this.secretKey
                    }
                    return Crypto.decodeByAES256( secretKey, openerUser )
                }
            }
        } catch (error) {
            console.error(error)
        }
        return null;
    }

    getToken() {
        const tk = window.sessionStorage.getItem('__dltk')
        if( tk ){
            return tk
        }
        return '';
    }

    /**
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    setItem(key, value) {
        if (key instanceof Object) {
            console.error('sessionStorage setItem key is Object!!')
            return false
        }
        if (value != null) {
            window.sessionStorage.setItem(key, value)
            return true
        }
        return false
    }

    /**
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    setItemWithCrypto(key, value) {
        if (key instanceof Object) {
            console.error('sessionStorage setItem key is Object!!')
            return false
        }
        if (value != null) {
            const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
            const result = Crypto.encodeByAES256(secretKey, value)
            window.sessionStorage.setItem(key, result)
            return true
        }
        return false
    }


    /**
     * 유효 시간과 암호화를 해서 특정 변수를 세션에 저장할 때
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    setItemWithCryptoWithExpireTime( key, value, tts ) {
        if( tts ){
            this.ttsTime = tts;
          }
        if (key instanceof Object) {
            return false
        }
        const obj = {
            value : value,
            expire: Date.now() + this.ttsTime
        }
        const objString = JSON.stringify(obj);
        if (objString != null) {
            const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
            const result = Crypto.encodeByAES256(secretKey, objString)
            window.sessionStorage.setItem(key, result)
            return true
        }
        return false
    }

    /**
     * 유효 시간이 있는 복호화된 아이템을 가져올 때
     * @param {*} KeyName 
     * @returns 
     */
    getItemWithCryptoWithExpireTime(KeyName){
         const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
        const cryptoItem = window.sessionStorage.getItem(KeyName)
        const objString = Crypto.decodeByAES256(secretKey, cryptoItem);
        if( !objString ){
            return null;
        }
        const obj = JSON.parse(objString);
        // if(Date.now() > obj.expire) {
        //     this.removeStorage(KeyName);
        //     return null;
        // }
        return obj.value;
      }


    setValueToStorageItem(key, element) {
        if (element && element.value != null) {
            const item = window.sessionStorage.getItem(key)
            if (item) {
                element.value = item
                return true
            }
            return false
        }
        return false
    }

    setCheckToStorageItem(key, element) {
        if (element && element.checked != null) {
            const item = window.sessionStorage.getItem(key)
            if (item) {
                element.checked = item === 'true'
                return true
            }
            return false
        }
        return false
    }

    setTextContentToStorageItem(key, element) {
        if (element && element.textContent != null) {
            const item = window.sessionStorage.getItem(key)
            if (item) {
                element.textContent = item
                return true
            }
            return false
        }
        return false
    }

    /**
     * @param {Array | Object} keys 1차 배열 또는 Depth 1의 오브젝트
     * @returns {boolean}
     */
    removeStorageKeys(keys) {
        if (keys instanceof Array) {
            for (let i = 0; i < keys.length; ++i) {
                window.sessionStorage.removeItem(keys[i])
            }
            return true
        }
        if (keys instanceof Object) {
            for (const key in keys) {
                if (Object.hasOwnProperty.call(keys, key)) {
                    const value = keys[key]
                    window.sessionStorage.removeItem(value)
                }
            }
            return true
        }
        return false
    }

    removeStorage(key) {
        if (key) {
            window.sessionStorage.removeItem(key)
            return true
        }
        return false
    }
}

export default UtilSessionStorage
