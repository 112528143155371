<!-- 거래현황 > 미체결내역 -->
<template>
    <div v-if="loading===false" class="w-[1050px] h-[800px] bg-[#fff] flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
    </div>
    <div v-else-if="loading">
        <section class="tm1 px-4 py-2 mb-4 mr-5">
            <p class="__lang subTitle text-noto-700 flex">미체결내역</p>
        </section>
        <section class="flex items-center border-none mb-[6px]">
            <div class="pl-3">
                <ul class="flex items-center align-center h-10">
                    <li
                        :class="route.query.stopLimit === 0 || !route.query.stopLimit ? 'active' : ''"
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( route.query.stopLimit === 0 || !route.query.stopLimit ){
                                return
                            } else {
                                change_tab('type', 0);
                            }
                        }"
                    >
                        전체
                    </li>
                    <li
                        :class="route.query.stopLimit === 1 || route.query.stopLimit === '1' ? 'active' : ''"
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( route.query.stopLimit === 1 || route.query.stopLimit === '1' ){
                                return
                            } else {
                                change_tab('type', 1);
                            }
                        }"
                    >
                        일반
                    </li>
                    <li
                        :class="route.query.stopLimit === 2 || route.query.stopLimit === '2' ? 'active' : ''"
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( route.query.stopLimit === 2 || route.query.stopLimit === '2' ){
                                return
                            } else {
                                change_tab('type', 2);
                            }
                        }"
                    >
                        예약
                    </li>
                </ul>
            </div>
            <!-- 거래내역 검색 탭 -->
            <div class="ml-5">
                <div class="pr-[10px] combobox-area relative overflow-visible">
                    <input 
                        class="combobox-input border w-[285px] h-[33px] text-[12px] pl-4" 
                        type="text" 
                        v-model="search"
                        :placeholder="'작품 전체'" 
                        @keyup.enter="searchItem"
                        @click:append="searchItem"
                        @focus="combobox = true" 
                        @blur="combobox = false"
                    />
                    <div v-if="combobox" class="absolute z-10 top-[33px] combobox-list w-[285px] max-h-[500px] bg-[#fff] overflow-scroll basicBoxShadow">
                        <div v-for="(item, index) in sortedMarketList || []" :key="index" 
                        class="combobox-item min-h-[30px] clickable px-5 py-2 text-[12px] text-noto-350 text-left" 
                        @mousedown="search = item.symbol">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div v-if="search.length > 0" class="absolute right-12 top-[10px] clickable" @click="search = ''">
                        <img src="/asset/img/cancelBtn.svg" />
                    </div>
                    <div class="absolute right-5 top-1 clickable" @click="searchItem">
                        <img src="/asset/img/search_primary.svg" />
                    </div>
                </div>
            </div>
        </section>

        <!-- 보유작품 표시 데이터 테이블 -->
        <section class="ms3">
            <v-card>
                <v-card-text style="padding: 0">
                    <v-window class="h-full">
                        <v-window-item class="h-full">
                            <!-- 데이터 테이블 기능 -->
                            <v-data-table
                                v-model:sort-by="sortBy"
                                :headers="headers"
                                :items="sortedPendingList || []"
                                :items-per-page="-1"
                                sort-asc-icon="mdi-menu-up"
                                sort-desc-icon="mdi-menu-down"
                                class="elevation-1"
                                style="width: 100%; font-size: 11px; min-height: 700px;"
                                no-data-text="미체결내역이 없습니다."
                                fixed-header
                                id="custom-scrollbar"
                            >
                                <template v-slot:item="{ item }">
                                    <tr class="text-center">
                                        <!-- 주문일시 -->
                                        <td>
                                            <div
                                                class="leading-4 h-[14px] text-[10px] pa-0 ma-0 flex translate-x-5"
                                            >
                                                {{ unix_to_yyyymmdd(item.columns.regDate / 1000) }}
                                            </div>
                                            <div
                                                class="leading-3 h-[14px] item-center pa-0 ma-0 flex translate-x-5 text-gray"
                                            >
                                                {{ unix_to_timestamp(item.columns.regDate / 1000) }}
                                            </div>
                                        </td>
                                        <!-- 작품 -->
                                        <td>
                                            {{ makeMarket1(item?.columns.market, 3) || '' }}
                                        </td>
                                        <!-- 마켓 -->
                                        <td>
                                            {{ makeMarket2(item?.columns.market, 3) || '' }}
                                        </td>
                                        <!-- 구분 -->
                                        <td
                                            :class="`${
                                                item?.columns?.ordType.includes('구매')
                                                    ? 'plus'
                                                    : item?.columns?.ordType.includes('판매')
                                                    ? 'minus'
                                                    : ''
                                            }`"
                                        >
                                            {{ item?.columns.ordType === "예약구매" ? '예약 구매' : item?.columns.ordType === "예약판매" ? '예약 판매' : item?.columns.ordType }}
                                        </td>
                                        <!-- 감시가 -->
                                        <td>
                                            {{
                                                item?.columns.ordType.includes('예약') ? 
                                                item?.columns.monitorPrice?.toLocaleString() +
                                                    ' ' +
                                                    makeMarket2(item?.columns.market, 3) || ''
                                                : '-'
                                            }}
                                        </td>
                                        <!-- 주문가 -->
                                        <td>
                                            {{ item?.columns.price?.toLocaleString() || '' }}
                                            {{ makeMarket2(item?.columns.market, 3) || '' }}
                                        </td>
                                        <!-- 주문량 -->
                                        <td>
                                            {{ item?.columns.volume?.toLocaleString() || '' }}
                                            {{ makeMarket1(item?.columns.market, 3) || '' }}
                                        </td>
                                        <!-- 미체결량 -->
                                        <td>
                                            {{
                                                item?.columns.remainVolume?.toLocaleString() || ''
                                            }}
                                            {{ makeMarket1(item?.columns.market, 3) || '' }}
                                        </td>
                                        <!-- 체결비율 -->
                                        <td>
                                            <!-- {{ getTradedRate(item?.columns?.idx) || '0.00%' }} -->
                                            {{ `${Number(item.columns.dealPercent).toLocaleString('ko-KR', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%` || '' }}
                                        </td>
                                        <!-- 상태 -->
                                        <td>
                                            <span
                                                class="underline clickable"
                                                @click="changeConfirmDialogTrue( item.columns.idx )"
                                                >주문취소
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <div>
                    <v-pagination 
                        v-model="page" 
                        :length="pageLength" 
                        rounded="circle"
                        @update:model-value="updateQueryString"
                        :total-visible="6"
                        color="#858585"
                        active-color="black"
                    ></v-pagination>
                </div>
            </v-card>

            <!-- alert용 중첩 가능한 모달, 주문 취소 확인용 -->
            <v-dialog v-model="confirm_dialog" persistent width="486px">
                <v-card class="pa-4">
                    <v-card-text class="flex flex-col">
                        <div class="text-noto-700 text-[18px] mb-3">
                            {{ modal_title }}
                        </div><br />
                        <div class="text-[15px]">
                            {{ nested_msg }}
                        </div>
                        <br />
                    </v-card-text>
                    <v-card-actions class="justify-end mt-12">
                        <v-btn class="modal_cancel_btn border w-[90px] h-[43px]" color="black" @click="changeConfirmDialogFalse">
                            취소
                        </v-btn>
                        <v-btn class="modal_confirm_btn w-[119px]" color="white" @click="prepareCancleOrder(orderIdx)">
                            취소확인
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- alert용 중첩 가능한 모달 -->
            <v-dialog v-model="nested_dialog" persistent width="486px">
                <v-card class="pa-4">
                    <v-card-text class="flex flex-col">
                        <div class="text-noto-700 text-[18px] mb-3">
                            {{ modal_title }}
                        </div><br />
                        <div class="text-[15px]" v-html="nested_msg">
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end mt-12">
                        <!-- 주문 취소 모달에서 확인 버튼 클릭시 시행될 함수 -->
                        <v-btn v-if="step === 'done'" class="modal_confirm_btn w-[90px]" color="white" @click="confirmAndRefreshPage">
                            확인
                        </v-btn>
                        <v-btn v-else class="modal_confirm_btn w-[90px]" color="white" @click="changeNestedDialogFalse">
                            확인
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </section>
    </div>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// pinia store import
import { useOrderStore } from '../../stores/order'
import { useExchangerStore } from '../../stores/exchanger'
// utill import
import {
    makeMarket1,
    makeMarket2,
    unix_to_timestamp,
    unix_to_yyyymmdd
} from '@/utils/utilsFactory'
import UtilSessionStorage from '../../utils/session';
// api import
import { makeCancelOrder, initOrderPendingList } from '../../api/order';
import ExchangerApi from '../../api/exchanger';
import { toChosungString } from '../../utils/utilsFactory';

const sessionStorage = new UtilSessionStorage();

// pinia instance 주입
const orderStore = useOrderStore();
const exchangerStore = useExchangerStore();

const exchangerApi = new ExchangerApi();

// 라우터 instance 주입
const router = useRouter();
const route = useRoute();
// 페이지네이션 용 ref화 페이지 변수
const page = ref(1)
const pageLength = ref(4);
const generalOrWatch = ref(0)

// 주문 취소용 모달을 위한 값
const modal_title = ref('')
const confirm_dialog = ref(false)   // 취소 혹은 주문취소를 할지 물어보는 모달창
const nested_dialog = ref(false)    // 중첩용으로 나오는 작은 모달창
const nested_msg = ref('')          // alert용 모달창 메세지
const orderIdx = ref(0);
const step = ref('');

const search = ref('')
const combobox = ref(false);
const loading = ref(false)
const sortBy = ref([{ key: 'regDate', order: 'desc' }])
const headers = ref([
    {
        title: '주문일시',
        align: 'start',
        sortable: false,
        key: 'regDate'
    },
    { title: '작품', sortable: false, key: 'market' },
    { title: '마켓', sortable: false, key: 'idx' },
    { title: '구분', sortable: false, key: 'ordType' },
    { title: '감시가', sortable: false, key: 'monitorPrice' },
    { title: '주문가', sortable: false, key: 'price' },
    { title: '주문량', sortable: false, key: 'volume' },
    { title: '미체결량', sortable: false, key: 'remainVolume' },
    { title: '체결비율', sortable: false, key: 'dealPercent' },
    { title: '상태', sortable: false, text: '상태', value: '상태', key: 'status' }
])
const sortedPendingList = ref([])
const active_tab = reactive({
    period: 0,
    type: 0
})
const marketListData = ref([]);
// 필터링 거친 리스트 데이터
const sortedMarketList = computed(() => {
let result = [];
  try {
    result = marketListData.value

    // searchNft에 입력된 검색어가 있을 경우 필터링
    if (search.value.trim()) {
        const searchTerm = search.value.trim().toUpperCase();  // 입력된 검색어를 대문자로 변환
        result = result.filter(item => 
        item?.name.toUpperCase().includes(searchTerm) ||
        item?.symbol.toUpperCase().includes(searchTerm) ||
        toChosungString(item.name).includes(searchTerm) );
    }

    result.sort( ( a, b )=>{
        return a.symbol.localeCompare(b.symbol);
    })

  } catch (error) {
    console.error(error)
  }
  return result;
})

onMounted(async ()=>{
    checkQueryString();

    // 쿼리스트링에 따라 ref 속성을 설정합니다.
    if( router.currentRoute.value.query.type ){
        generalOrWatch.value = router.currentRoute.value.query.stopLimit
    }

    const userId = sessionStorage.getUserId();
    const answer = await initOrderPendingList(
        { 
            id : userId, 
            page : page.value,
            type : route.query.type,
            stopLimit : route.query.stopLimit,
            start : Number(route.query.start),
            end : Number(route.query.end),
            symbol : route.query.search,
        } 
    )
    pageLength.value = answer;

    paginateItems();

    if( route.query.symbol ){
        search.value = route.query.symbol;
    }

    // 마켓 리스트를 얻어와 세팅합니다.
    marketListData.value = await exchangerApi.allAssetListIncludeCoin({ coin : false });
    loading.value = true;

    scrollToTop();
})

/**
 * 페이지네이션이 일어날때마다 내부적으로 값들을 재계산하는 로직입니다.
 */
function paginateItems () {
    sortedPendingList.value = orderStore.pendingList;
}

function sortByNormalOrReserved( how ){
    let temp = null;
    try {
        if( how === 2 ){
            temp =  [...orderStore.pendingList].filter( item => item.ordType.include( "예약" )  );
        } else if( how === 1 ){
            temp =  [...orderStore.pendingList].filter( item => !item.ordType.include( "예약" )  );
        }    
    } catch (error) {
        console.error(error)
    }
    
    return temp
}


/**
 * 페이지네이션에서 page가 바뀔때마다 실행될 함수, 쿼리스트링을 바꿉니다
 * @param {*} newPage 
 */
function updateQueryString( newPage ){
    loading.value = false;
    const queryString = {}
    try {
        if(newPage) queryString.page = newPage 
        if ( search.value ) queryString.search = search.value
        if( generalOrWatch.value ) queryString.stopLimit = generalOrWatch.value
    } catch (error) {
        console.error(error)
    }

    router.push({ 
        path:'/trading/pendingTransactions',  
        query : queryString 
    })
}


// 쿼리스트링을 체크해서 변수들에 세팅합니다.
function checkQueryString() {
    try {
        if (route.query.page) {
            page.value = Number(route.query.page)
        }
        if (route.query.search) {
            search.value = route.query.search
        }
    } catch (error) {
        console.error(error)
    }
}


/**
 * 검색어를 가지고 쿼리스트링에 넣고 결과를 탐색합니다.
 */
 function searchItem() {
    updateQueryString( page.value )
}


/**
 * 탭을 바꿀 수 있는 함수
 * @param {*} type
 * @param {*} tab
 */
function change_tab(type, tab) {
    if (type === 'period') {
        active_tab.period = tab
        // 탭이 바뀔 때 실행될 로직 추가할 것
    } else if (type === 'type') {
        page.value = 1;
        generalOrWatch.value = tab
        updateQueryString(page.value);
    }
}

/**
 * 받은 idx로 체결 비율을 구하는 함수, 임시용
 */
function getTradedRate( price, remain ) {
    let result;
    try {
        if( price !== 0 && remain !== 0 ){
            result = `${((Math.floor((remain / price * 100) * 100))/100).toLocaleString()}%`
        } else {
            result = '0.00%'
        }
    } catch (error) {
        result = '0.00%'
    }
    return result
}

/**
 * 모달창 조작용 함수
 */
function changeConfirmDialogFalse () { confirm_dialog.value = false; }
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { 
    nested_dialog.value = false; 
    router.go(0);
}

// 주문 취소 접수 확인 버튼 클릭시 실행될 함수
function confirmAndRefreshPage(){
    changeNestedDialogFalse();
    router.go(0);
}

// 주문을 취소를 하기 전에 확인용 모달창을 닫습니다.
async function prepareCancleOrder( order_idx ){
    changeConfirmDialogFalse();
    cancleOrder(order_idx);
}


/**
 * 주문 취소 버튼을 눌렀을 때 실행될 함수
 * @param {*} order_idx 
 */
function changeConfirmDialogTrue ( order_idx ) { 
    modal_title.value = `취소확인`
    nested_msg.value = '선택한 미체결 주문을 취소할까요?'
    orderIdx.value = order_idx;
    confirm_dialog.value = true; 
}


// 주문 취소를 서버에 요청합니다.
async function cancleOrder( order_idx ){
    const command = 'order_cancel';
    try {
        const id = sessionStorage.getUserId();
        const response = await makeCancelOrder({ command, id, idx : order_idx });
        modal_title.value = '주문 취소 접수'
        if( response === true ){
            step.value = 'done';
            nested_msg.value = '주문 취소 접수되었습니다.<br/>취소 완료 여부는 거래현황의 거래내역에서 확인할 수 있습니다.'
            changeNestedDialogTrue();
        } else {
            step.value = 'error';
            nested_msg.value = '일시적인 오류로 주문 취소가 정상적으로 접수되지 않았습니다. 다시 시도 부탁드립니다.'
            changeNestedDialogTrue();
        }
    } catch (error) {
        console.error(error)
        step.value = 'error';
        nested_msg.value = '일시적인 오류로 주문 취소가 정상적으로 접수되지 않았습니다. 다시 시도 부탁드립니다.'
        changeNestedDialogTrue();
    }
}

function scrollToTop() {
    window.scrollTo(0, 0)
}
</script>

<style scoped>
.active {
    background-color: var(--main_color);
    color: white;
}
</style>
