/* eslint-disable consistent-return, prefer-template */
/* 법정화폐 관련 비즈니스로직에 대한 내용을 서버에 요청하는 로직 모음 */
import axios from 'axios'
import { requestHeader } from '../config.js'
import { useFiatStore } from '../../stores/fiat/index.js';

/**
 * 실제 원화마켓을 사용하는지의 여부를 판단합니다.
 */
export async function selectFiatMarketFlag( requestData) {
    try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/inout/fiat_market_flag`, requestData, requestHeader() );
        if( response.data.status === "success" ){
            const fiatStore = useFiatStore();
            fiatStore.setFiatCurrency(response.data.result.realFiatMoneyMarket);
            return response.data.result.realFiatMoneyMarket
        }
        return response.data
    } catch (error) {
        console.error(error)
    }
    return null
}

/**
 * 법정화폐(한화) 입금 계좌를 조회합니다.
 * @returns
 */
export async function selectInAccount(id, tokenSymbol) {
    try {
        const requestData = {}
        if (id !== undefined && id !== null) requestData.id = id
        if (tokenSymbol !== undefined && tokenSymbol !== null) requestData.tokenSymbol = tokenSymbol
        const response = await axios.post(
            `${process.env.VUE_APP_BACKEND}/fiat_inout/in_account`,
            requestData,
            requestHeader()
        )
        return response.data
    } catch (error) {
        console.error(error)
    }
    return null
}

/**
 * 법정화폐(한화) 출금 계좌를 조회합니다.
 * @returns
 */
export async function selectOutAccount(id, tokenSymbol) {
    try {
        const requestData = {}
        if (id !== undefined && id !== null) requestData.id = id
        if (tokenSymbol !== undefined && tokenSymbol !== null) requestData.tokenSymbol = tokenSymbol
        const response = await axios.post(
            `${process.env.VUE_APP_BACKEND}/fiat_inout/out_account`,
            requestData,
            requestHeader()
        )
        return response.data
    } catch (error) {
        console.error(error)
    }
    return null
}
