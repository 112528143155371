// 주문에 사용되는 상태 값들, 혹은 Order init 함수들을 관리합니다.
import { ref } from 'vue'
import { defineStore } from 'pinia'

/**
 * OrderStore
 */
export const useOrderStore = defineStore('order', () => {
    const orderResultMessage = ref('')

    const localAmount = ref(1)
    const localPrice = ref(0)

    // 미체결 주문과 체결 주문에 대한 응답 데이터
    const pendingList = ref(null);  // 미체결 주문
    const dealList = ref(null);     // 체결 주문
    const totalAskBidCount = ref(null); // 판매, 구매 미체결 수량 데이터

    /**
     * OrderStore의 amount와 price를 업데이트 하는 함수
     * @param {*} newPrice
     * @void
     */
    function updateLocalPriceAmount(newPrice){
        localPrice.value = newPrice
        localAmount.value = 1
    }

    function setDealList( newData ){
        dealList.value = [];
        dealList.value = newData;
    }

    function setPendingList( newData ){
        pendingList.value = [];
        pendingList.value = newData;
    }

    function setTotalAskBidCount( newData ){
        totalAskBidCount.value = {};
        totalAskBidCount.value = newData;
    }

    function getOrderResultMessage(){
        return orderResultMessage.value?.descriptKor || '';
    }

    return {
        localAmount,
        localPrice,
        pendingList,
        dealList,
        orderResultMessage,
        totalAskBidCount,
        updateLocalPriceAmount,
        getOrderResultMessage,
        setDealList,
        setPendingList,
        setTotalAskBidCount,
    }
})
