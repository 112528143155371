export default [
    {
        path: '/coin_list',
        name: 'CoinList',
        component: () => import('@/pages/Mobile/CoinList.vue')
    },
    {
        path: '/m',
        name: 'Mobile',
        component: () => import('@/pages/Mobile/MobileViewer.vue'),
        redirect: '/m/home',
        children: [
            {
                path: 'home',
                name: 'MobileHome',
                component: () => import('@/pages/Mobile/MobileHome.vue')
            },
            {
                path: 'login',
                name: 'MobileLogin',
                component: () => import('@/pages/Mobile/MobileLogin.vue')
            },
            {
                path: 'join',
                name: 'MobileJoin',
                component: () => import('@/pages/Mobile/MobileJoin.vue')
            },
            {
                path: 'itemList',
                name: 'MobileExchangerList',
                component: () => import('@/pages/Mobile/MobileExchangerList.vue')
            },
            {
                path: 'exchanger',
                name: 'MobileExchanger',
                component: () => import('@/pages/Mobile/MobileExchangerItem.vue')
            },
            {
                path: 'deposit',
                name: 'MobileInout',
                component: () => import('@/pages/Mobile/MobileInout.vue')
            },
            {
                path: 'mypage',
                name: 'MobileMypage',
                component: () => import('@/pages/Mobile/MobileMypage.vue')
            },
            {
                path: 'trading',
                name: 'MobileTradingHistory',
                component: () => import('@/pages/Mobile/MobileTradingHistory.vue')
            },
            {
                path: 'help/findId',
                name: 'MobileFindId',
                component: () => import('@/pages/Mobile/MobileFindId.vue')
            },
            {
                path: 'help/findPwd',
                name: 'MobileFindPwd',
                component: () => import('@/pages/Mobile/MobileFindPwd.vue')
            },
            {
                path: '/support',
                name: 'support',
                component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
                children: [
                    {
                        path: 'notice',
                        name: 'notice',
                        component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
                    },
                    {
                        path: 'faq',
                        name: 'faq',
                        component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
                    },
                    {
                        path: 'help',
                        name: 'helpInquiry',
                        component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
                    }
                ]
            },
            {
                path: '/terms',
                name: 'terms',
                component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
            },
            {
                path: '/privacy_policy',
                name: 'privacyPolicy',
                component: () => import('@/pages/Mobile/MobilePreparignPage.vue'),
            }
        ]
    }
]
