/* eslint-disable consistent-return, prefer-template */
/* 카카오 관련 인증 등을 서버에 요청하는 로직 모음 */
import axios from 'axios';
import { requestHeader } from '../config'


// -------------------------- 마이 페이지(Member) 요청용 -------------------------------
/**
 * 카카오 인증 요청을 보냅니다. (마이페이지 용)
 * @returns
 */
export async function sendSignKakao( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/auth_kakao`, requestData, requestHeader() );
      return response.data
  } catch (error) {
      console.error(error)
  }
  return false
}


/**
 * 카카오 인증 확인 요청을 보냅니다. (마이페이지 용)
 * @param {*} requestData 
 * @returns 
 */
export async function sendVerifyKakao( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/verify_auth_kakao`, requestData, requestHeader() );
      return response.data
  } catch (error) {
      console.error(error)
  }
  return false
}


/**
 * 카카오 인증 초기화 요청을 보냅니다. (마이페이지 용)
 * @param {*} requestData 
 * @returns 
 */
export async function initKakaoAuth( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/init_auth_kakao`, requestData, requestHeader() );
      return response.data
  } catch (error) {
      console.error(error)
  }
  return false
}


/* ===============카카오, OTP 등 2채널 인증 요청================ */
// 서버에 비밀번호 변경을 위한 2채널 인증 요청
export async function initUpdatePasswordAuth( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_password_auth`, requestData, requestHeader() );
      return response.data
  } catch (error) {
      console.error(error)
  }
  return false
}

// 서버에 비밀번호 변경을 위한 2채널 인증 요청에 대한 확인
export async function initUpdatePasswordAuthCheck( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_password_check`, requestData, requestHeader() );
      return response.data
  } catch (error) {
      console.error(error)
  }
  return false
}