<!-- 고객지원 > 공지사항 -->
<template>
    <div v-if="!loading" class="w-[1050px] min-h-[800px] bg-[#fff] flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
    </div>
    <div v-else class="pt-[48px] w-full">
        <div class="flex justify-between">
            <span class="">
                <h1 class="mb-3 pl-5 text-[24px] text-noto-500">공지사항</h1>
            </span>
            <div v-if="!route.query.id" class="pr-[10px] combobox-area relative overflow-visible">
                <input 
                    class="combobox-input border w-[285px] h-[33px] text-[12px] pl-[13px]" 
                    type="text" 
                    v-model="search"
                    :placeholder="'검색어를 입력해주세요'" 
                    @keyup.enter="searchItem"
                    @click:append="searchItem"
                    @focus="combobox = true" 
                    @blur="combobox = false"
                />
                <div v-if="search.length > 0" class="absolute right-12 top-[10px] clickable" @click="search = ''">
                    <img src="/asset/img/cancelBtn.svg" />
                </div>
                <div class="absolute right-[24px] top-[5px] clickable" @click="searchItem">
                    <img src="/asset/img/search_primary.svg" />
                </div>
            </div>
        </div>

        <!-- 공지사항 list 섹션 -->
        <section v-if="!route.query.id">
            <v-table v-if="sortedItemList.length >= 1" class="w-full min-h-[690px] row-height-50">
                <thead style="height: 40px;">
                    <tr class="bg-[#f2f2f2] text-[15px]">
                        <td class="text-center w-[110px]">구분</td>
                        <td class="text-center w-[690px]">제목</td>
                        <td class="text-center w-[230px] pr-6">등록일</td>
                    </tr>
                </thead>
                <tbody >
                    <tr
                        v-for="(item, index) in importantNoticeList || []"
                        :key="index"
                        class="font-medium text-black text-[15px]"
                    >
                        <td class="text-center w-[110px]">중요</td>
                        <td class="w-[690px]">
                            <div class="clickable" @click="router.push({ path : `/support/notice`, query : { id : item.idx} })">{{ item.title }}</div>
                        </td>
                        <td class="text-center w-[230px] pr-6">
                            {{ unix_to_yyyymmdd(item.regDate / 1000) }}
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) in sortedItemList || []"
                        :key="index"
                        class="text-gray"
                    >
                        <td class="text-center w-[110px]">공지</td>
                        <td class="w-[690px]">
                            <div class="clickable" @click="router.push({ path : `/support/notice`, query : { id : item.idx} })">{{ item.title }}</div>
                        </td>
                        <td class="text-center w-[230px] pr-6">
                            {{ unix_to_yyyymmdd(item.regDate / 1000) }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <div v-if="sortedItemList.length < 1" class="w-full text-center flex justify-center items-center text-gray h-[543px] border-t border-b">
                검색 결과가 없습니다.
            </div>
            <div>
                <v-pagination
                    v-model="page"
                    :length="pageLength"
                    rounded="circle"
                    @update:model-value="updateQueryString"
                    class="pb-5"
                    :total-visible="6"
                    color="#858585"
                    active-color="black"
                ></v-pagination>
            </div>
        </section>

        <!-- 공지사항 상세 페이지 -->
        <section v-else-if="route.query.id">
            <div class="w-full min-h-[768px] pa-[19px]">
                <div class="page--top--border-t page--top--border-b h-[69px] mx-[19px] px-[20px] flex items-center justify-between">
                    <div class="text-[20px] text-noto-700">{{ noticeInfo.title }}</div>
                    <div class="text-[14px] text-noto-400 text-gray-darken-3">{{ noticeInfo.date }}</div>
                </div>
                <div 
                    class="min-h-[530px] page--top--border-b mx-[19px] pt-[40px] px-[30px] pb-[60px] text-gray-darken-3" 
                    v-html="noticeInfo.content"
                >
                </div>
                <div class="flex justify-end mb-[100px]">
                    <button class="white_btn w-[90px] h-[43px] mt-[30px] md:mr-[49px]" @click="router.push({ path : `/support/notice`, query : { page : 1} })">목록</button>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
// api import
import { getAllNoticeList, getNoticeInfo } from '@/api/notice/index.js'
// util import
import { unix_to_yyyymmdd } from '../../utils/utilsFactory'

// 라우터 인스턴스들 변수들에 할당
const route = useRoute();
const router = useRouter();

const loading = ref(false)

// 중요공지사항 리스트
const importantNoticeList = ref(null)
// 중요공지 중복 방지용 인덱스 리스트
const importantNoticeIdxList = ref([]);
// 공지사항 리스트
const noticeList = ref(null)
// 상세공지사항 정보
const noticeInfo = ref({
    title : '',
    date : '',
    content : '',
});

const page = ref(1)
const pageLength = ref(1)

const search = ref('')
const sortedItemList = computed(() => {
    let result = []
    try {
        for( let i = 0; i < noticeList.value.length; i++ ){
            if( !result.some(item => item.idx === noticeList.value[i].idx ) ){
                result.push( noticeList.value[i] );
            }
        }
        if (route.query.search) {
            result = result.filter((item) => item.title.includes(route.query.search))
        } 
    } catch (error) {
        console.error(error)
    }
    return result
})


onMounted(async () => {

    if( route.query.id ){
        try {
            const response = await getNoticeInfo({ idx : route.query.id})
            if( response.data.status === 'success' ){
                const item = response.data.result;
                noticeInfo.value.title = item.title;
                noticeInfo.value.date = unix_to_yyyymmdd( item.reg_date / 1000 ) || '1999.12.31';
                noticeInfo.value.content = item.content;
            } 
        } catch (error) {
            console.error(error)
        }
    } else {
        try {
            checkQueryString()
            const requestDataSet = {
                page : page.value ? page.value : 1,
                title : route.query.title,
            }
            const response = await getAllNoticeList(requestDataSet)
            noticeList.value = []
            importantNoticeList.value = []
            importantNoticeIdxList.value = []
            if( response?.page_num ){
                pageLength.value = response.page_num;
            }
            if( response?.data ){
                noticeList.value = response.data;
            }
            if( response?.top ){
                importantNoticeList.value = response.top;
            }

        } catch (error) {
            console.error(error)
        }
    }

    loading.value = true
})

// 쿼리스트링을 체크해서 변수들에 세팅합니다.
function checkQueryString() {
    if (route.query.page) {
        page.value = Number(route.query.page)
    }
    if (route.query.title) {
        search.value = route.query.title
    }
}

/**
 * 검색어를 가지고 쿼리스트링에 넣고 결과를 탐색합니다.
 */
function searchItem() {
    updateQueryString(1)
}


/**
 * 새로운 쿼리스트링을 받아서 페이지를 업데이트 합니다.
 * @param {*} newPage
 */
function updateQueryString(newPage) {
    const queryString = {}
    if (newPage) queryString.page = newPage
    if (search.value) queryString.title = search.value
    router.push({
        path: '/support/notice',
        query: queryString
    })
}
</script>

<style>
.v-table.row-height-50 td
{
  height: 40px !important;
  font-size: 14px !important;
}
.page--top--border-t {
    border-top: 1px solid #BDBDBD;
}
.page--top--border-b {
    border-bottom: 1px solid #E0E0E0;
}

</style>
