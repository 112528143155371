/* eslint-disable consistent-return, prefer-template */
/* 이메일 관련 인증 등을 서버에 요청하는 로직 모음 */
import axios from 'axios'
import { requestHeader } from '../config'

/**
 * 이메일 인증 요청을 보냅니다.
 * @returns
 */
export async function sendSignupEmail(emailId) {
    try {
        const requestData = { 
            type : '회원가입',
            email: emailId 
        }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/email/signup_email`, requestData, requestHeader() );
        return response
    } catch (error) {
        console.error(error)
    }
    return null
}

/**
 * 이메일 인증 코드를 확인합니다.
 * @returns
 */
export async function sendVerifyEmail(emailId, key) {
    try {
        const requestData = { email: emailId, content: key }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/email/verify_email`, requestData, requestHeader() );
        return response
    } catch (error) {
        console.error(error)
    }
}


/**
 * 이메일 변경을 위한 인증 요청을 보냅니다.
 * @param {*} emailId 
 * @param {*} key 
 * @returns 
 */
export async function sendChangeEmail( emailId ) {
    try {
        const requestData = { type: '이메일변경', email: emailId }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/email/changemail_email`, requestData, requestHeader() );
        return response
    } catch (error) {
        console.error(error)
    }
}