// 재사용 가능한 짧은 함수들 집합
import { useRoute } from 'vue-router';
import useClipboard from 'vue-clipboard3'

/**
 * 모바일 기기인지 아닌지 확인하는 함수
 * @returns { boolean }
 */
export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || window.opera;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

/**
 * 유닉스 타임을 00:00:00 형식으로 변환해주는 함수
 * @param {*} unix_time 
 * @returns { string }
 */
export const unix_to_timestamp = ( unix_time ) =>{
  const date = new Date( unix_time * 1000 );
  const hours = date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours();
  
  const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes();

  const seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : "" + date.getSeconds();

  return `${ hours }:${ minutes }:${ seconds }`;
}


/**
 * 입력받은 유닉스 타임을 23.06.02 이런 식의 날짜로 변환해주는 함수
 * @param {*} unix_time 
 */
export const unix_to_date = ( unix_time ) => {
  const date = new Date(unix_time * 1000); // 밀리초 단위이므로 변환 필요 없음

  const year = date.getFullYear().toString().slice(-2);  // 연도 마지막 2자리
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월
  const day = ("0" + date.getDate()).slice(-2);          // 일

  return `${year}.${month}.${day}`;
}


/**
 * 입력받은 유닉스 타임을 2023.11.11 이런 식의 날짜로 변환해주는 함수
 * @param {*} unix_time
 */
export const unix_to_yyyymmdd = ( unix_time ) => {
  const date = new Date(unix_time * 1000); // 밀리초 단위이므로 변환 필요 없음

  const year = date.getFullYear().toString();  // 연도 마지막 2자리
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월
  const day = ("0" + date.getDate()).slice(-2);          // 일

  return `${year}.${month}.${day}`;
}

/**
 * 입력받은 유닉스 타임을 2023.11 이런 식의 날짜로 변환해주는 함수
 * @param {*} unix_time
 */
export const unix_to_yyyymm = ( unix_time ) => {
  const date = new Date(unix_time * 1000); // 밀리초 단위이므로 변환 필요 없음

  const year = date.getFullYear().toString();  // 연도 마지막 2자리
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월

  return `${year}.${month}`;
}

/**
 * 입력받은 유닉스 타임을 2023.11 이런 식의 날짜로 변환해주는 함수
 * @param {*} unix_time
 */
export const unix_to_mmdd = ( unix_time ) => {
  const date = new Date(unix_time * 1000); // 밀리초 단위이므로 변환 필요 없음

  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월
  const day = ("0" + date.getDate()).slice(-2);          // 일

  return `${month}.${day}`;
}

/**
 * 입력받은 유닉스 타임을 2023-11-11 이런 식의 날짜로 변환해주는 함수
 * @param {*} unix_time
 */
export const unix_to_yyyymmdd_type2 = ( unix_time ) => {
  let date;

  if (unix_time) {
    // 밀리초 단위가 아닌 경우
    let adjustedUnixTime = unix_time < 10000000000 ? unix_time * 1000 : unix_time;
    date = new Date(adjustedUnixTime);
  } else {
    // unix_time이 주어지지 않은 경우
    date = new Date();
  }

  const year = date.getFullYear().toString();  // 연도 마지막 2자리
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월
  const day = ("0" + date.getDate()).slice(-2);          // 일

  return `${year}-${month}-${day}`;
}

/**
 * 입력받은 yyyy.mm.dd 형식의 문자열을 유닉스 타임으로 변환해주는 함수
 * @param {*} yyyymmdd 
 */
export function yyyymmdd_to_unixTimeStamp( yyyymmdd ){
  const parts = yyyymmdd.split('.');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript의 month는 0-11 범위입니다
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month, day);
  return date.getTime(); // 밀리초를 초로 변환
}


/**
 * OrderCommand를 반환해줍니다.
 * @param {*} selectedOption 
 * @returns {string}
 */
export const makeOrderCommnad = ( selectedOption ) => {
  return selectedOption === "1" ? 'order_put_market' : selectedOption === '2' ? 'order_put_watch' : selectedOption === '0' ? 'order_put_limit' : 'order_put_limit'
}


/**
 * 문자열을 받아서 가운데에 슬래시를 삽입해서 반환하는 함수입니다.
 * AAABBB가 들어오면 AAA/BBB 를
 * ASDGBQWER가 들어오면 ASDGBQ/WER 를 반환합니다.
 */
export function makeSlash(inputString, interval){
  if( !inputString || !interval ){
    return '';
  }
  if(inputString.length < interval) {
      return inputString;
  }

  let point = inputString.length - interval;
  let firstPart = inputString.substring(0, point);
  let secondPart = inputString.substring(point);
  return `${firstPart}/${secondPart}`;
}


/**
 * BTSKRW 와 같은 문자를 받았을 때 interval만큼 뒤에서 잘라내고 남은 문자열을 반환합니다.
 * @param {*} inputString 
 * @param {*} interval 
 * @returns 
 */
export function makeMarket1(inputString, interval){
  let result = "BAYC";
  if(inputString.length < interval) {
    return inputString;
  }
  let point = inputString.length - interval;
  result = inputString.substring(0, point);
  return result
}


/**
 * market2 형식, 예를 들어 BTSKRW 와 같은 문자를 받았을 때 interval을 입력한 만큼 뒤에서부터 자른 문자열을 반환합니다.
 * @param {*} inputString 
 * @param {*} interval 
 * @returns 
 */
export function makeMarket2(inputString, interval){
  let result = "KRW";
  let point = inputString.length - interval;
  result = inputString.substring(point);
  return result;
}


/**
 * 특정 문자열에서 endStr을 제거한 문자열을 반환하는 함수
 * @param {*} mainStr 
 * @param {*} endStr 
 * @returns 
 */
export function excludeEnd( mainStr, endStr ){
  if (mainStr.endsWith(endStr)) {
    return mainStr.slice(0, -endStr.length);
  }
  return mainStr;
}


/**
 * 한글 한글자 입력할 때 받아서 유니코드로 값을 반환해주는 함수
 */
export function toChosungString(word) {
  const ga = 44032; // '가'의 유니코드 시작
  const chosung = [
      "ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", 
      "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"
  ];
  let result = "";
  for (let i = 0; i < word.length; i++) {
      let charCode = word.charCodeAt(i);
      if (charCode >= ga) {
          result += chosung[Math.floor((charCode - ga) / 588)];
      } else {
          result += word[i];
      }
  }
  return result;
}

/**
 * 이메일 형식의 String을 받아서 @ 전까지 *** 으로 치환합니다.
 * @param {String} email 
 * @returns 
 */
export function maskEmail(email) {
  if(!email){
    return '';
  }
  let result = '';
  try {
    let splitEmail = email.split("@");
    let emailName = splitEmail[0];
    let emailDomain = splitEmail[1];
    
    if (emailName.length > 4) {
      result = emailName.substring(0, 3) + "*".repeat(emailName.length - 3) + "@" + emailDomain;
    } else {
      result = emailName + "@" + emailDomain;
    }
    if( !result ){
      result = 'b***@bitstart.co.kr'
    }
  } catch (error) {
    result = '';
    console.error(error)
  }
  
  return result;
}

/**
 * 쿼리스트링에 있는 code key의 value를 반환
 * @returns 
 */
export const initCode = () => {
  const route = useRoute();
  return route.query.code || ''; // 초기화할 때 route.query.code가 없는 경우 빈 문자열로 초기화
};


/**
 * 특정 문자열이 현재 라우터의 경로에 포함되어 있는지 알려주는 함수
 * @param {*} path 
 * @returns 
 */
export const isActive = ( path ) => {
  const route = useRoute();
  return route.path.includes(path)
}


/**
 * 받은 경로가 푸터의 스타일을 녹색으로 해야하는지,
 * @param {*} path 
 * @returns 
 */
export const isGreenTheme = (path) => {
  return path !== '/';
}


/**
 * 클립보드로 카피를 시켜주는 함수
 * @param {*} content 
 */
export const copy = async ( content ) => {
  try {
    const { toClipboard } = useClipboard()
    await toClipboard(content)
  } catch (e) {
    console.error(e)
  }
}


/**
 * input, 인풋 등의 요소에서 숫자와 관련된 키만 입력을 허용하는 기능
 * 현재 양의 정수 및 0 이상의 소수와 0까지만 구현 가능
 * 0으로 시작하는 요소 비허용
 * @param {*} event
 */
export function handleKeydown (event) {
  const basicAllowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Home', 'End'];
  let allowedKeys = [...basicAllowedKeys];

  if (event.ctrlKey || event.metaKey) {
    // Ctrl 또는 Cmd 키가 눌러진 경우 복사, 붙여넣기, 자르기, 전체 선택 허용
    if (event.key === 'v' || event.key === 'c' || event.key === 'x' || event.key === 'a') {
      return; // 기본 이벤트를 수행하도록 함
    }
  }
  
  if (!event.target.value.includes('.')) {
    allowedKeys.push('.');
  }
  
  if (event.keyCode !== 229 && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}


/**
 * input, 인풋 등의 요소에서 숫자와 관련된 키만 입력을 허용하는 기능
 * 현재 양의 정수 및 0 이상의 소수와 0까지만 구현 가능
 * 0으로 시작하는 요소 비허용
 * . 비허용 (양수, 자연수, 양의 정수만 허용)
 * @param {*} event 
 * @returns 
 */
export function handleRemovePeriodKeydown( event ){
  const basicAllowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
  if (event.ctrlKey || event.metaKey) {
    // Ctrl 또는 Cmd 키가 눌러진 경우 복사, 붙여넣기, 자르기, 전체 선택 허용
    if (event.key === 'v' || event.key === 'c' || event.key === 'x' || event.key === 'a') {
      return; // 기본 이벤트를 수행하도록 함
    }
  }
  // 주어진 keyCode가 기본 허용 목록에 없고, 229(일부 IME 관련 이벤트)가 아니라면 입력을 막는다
  if (event.keyCode !== 229 && !basicAllowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}


/**
 * input, 인풋 등의 요소에서 숫자와 관련된 키만 입력을 허용하는 기능
 * 현재 양의 정수 및 0 이상의 소수와 0까지만 구현 가능
 * 010 등을 허용해야하기 때문에 위의 함수와 분리
 * @param {*} event 
 */
export function handlePhoneNumberKeydown(event){
  const basicAllowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
  let allowedKeys = [...basicAllowedKeys];
  if (event.ctrlKey || event.metaKey) {
    // Ctrl 또는 Cmd 키가 눌러진 경우 복사, 붙여넣기, 자르기, 전체 선택 허용
    if (event.key === 'v' || event.key === 'c' || event.key === 'x' || event.key === 'a') {
      return; // 기본 이벤트를 수행하도록 함
    }
  }
  if (!event.target.value.includes('.')) {
    allowedKeys.push('.');
  }
  if (event.keyCode !== 229 && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}

/**
 * 숫자인 문자열을 받아서 역순으로 루프를 돌면서 3자리마다 쉼표를 추가
 * @param {*} str 
 * @returns 
 */
export function formatNumberString(str) {
  try {
    if( str === undefined || str === null ){
      return;
    }
    // 문자열을 소수점을 기준으로 나누기
    const parts = `${str}`.split('.');
    let integerPart = parts[0].replace(/,/g, ''); // 정수 부분에서 쉼표 제거

    if (integerPart.length > 3) {
      // 세 자리마다 쉼표 추가
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // 소수 부분이 있으면 다시 합치기
    const result = parts.length === 2 ? integerPart + '.' + parts[1] : integerPart;

    return result;
  } catch (error) {
    console.error(error)
    return null;
  }
}


/**
 * space 등의 입력을 회피하는 기능
 * @param {*} event
 */
export function preventSpace (event) {
  const notAllowedKeys = [' '];
  if (notAllowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}


/**
 * 받은 값이 지수인지 아닌지 테스트하는 함수
 * @param {*} value 
 * @returns 
 */
export function isExponential(value) {
  return /e/i.test(String(value));
}


/**
 * 첫번째 문자만 대문자로 변환해주는 함수
 * @param {*} value 
 */
export function changeToUpperCase( value ){
  let result = '';

  const firstChar = value.charAt(0);
  const others = value.slice(1);

  result = `${firstChar.toUpperCase()}${others}`

  return result;
}


export function useKeyBoard( item, index, e ){
  e.preventDefault();
  if( this.keyBoardValue === '0' && index < 9 ){
    this.keyBoardValue = `${item}`;
  } else if ( item && index < 9 ){
    this.keyBoardValue+=`${item}`
  } else if ( this.keyBoardValue !== '0' && index === 10 ){
    this.keyBoardValue+=`${item}`
  } else if ( index === 11 ){
    this.keyBoardValue = this.keyBoardValue.slice(0, -1);
  }
}