import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTop10Store = defineStore('top10', () => {
    const marketcap10 = ref([]) // NFT Top 10 데이터
    const volume10 = ref([]) // 변동률 Top 10 데이터
    const roc10 = ref([]) // 거래량 Top 10 데이터

    function setMarketTop10(newData) {
        marketcap10.value = newData
    }

    function setVolumeTop10(newData) {
        volume10.value = newData
    }

    function setRocTop10(newData) {
        roc10.value = newData
    }

    return {
        marketcap10,
        volume10,
        roc10,
        setMarketTop10,
        setVolumeTop10,
        setRocTop10
    }
})

/**
 * 모바일 기기인지 아닌지 판단해주는 역할을 하는 스토어. (매번 함수 실행을 할 수는 없으니 store화)
 */
export const useIsItMobileStore = defineStore('isitmobile', () => {
    const isMobile = ref(false)

    isMobile.value = checkIfMobile()

    const checkIfMobile = () => {
        const userAgent = navigator.userAgent || window.opera
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    }

    return {
        isMobile
    }
})
