import SupportView from '../pages/SupportView.vue'

import NoticeList from '../components/support/NoticeList.vue'
import FAQComp from '../components/support/FAQComp.vue'
import HelpCenter from '../components/support/HelpCenter.vue'
import PrivacyPolicy from '../components/support/PrivacyPolicy.vue'
import TermsOfService from '../components/support/TermsOfService.vue'

export default [
    {
        path: '/support',
        name: 'support',
        component: SupportView,
        children: [
            {
                path: 'notice',
                name: 'notice',
                component: NoticeList
            },
            {
                path: 'faq',
                name: 'faq',
                component: FAQComp
            },
            {
                path: 'help',
                name: 'helpInquiry',
                component: HelpCenter
            }
        ]
    },
    {
        path: '/terms',
        name: 'terms',
        component: TermsOfService
    },
    {
        path: '/privacy_policy',
        name: 'privacyPolicy',
        component: PrivacyPolicy
    }
]
