<template>
  <nav class="m-navbar">
        <ul class="grid grid-cols-5">
            <li>
                <router-link to="/m/home">
                    <div class="flex justify-center">
                        <img v-if="$route.path.includes('/m/home')" src="/asset/img/app_nav_btn/home_selected.png" />
                        <img v-else src="/asset/img/app_nav_btn/home.png" />
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/m/itemList">
                    <div class="flex justify-center">
                        <img v-if="$route.path.includes('/m/itemList')" src="/asset/img/app_nav_btn/bar_chart_selected.png" />
                        <img v-else src="/asset/img/app_nav_btn/bar_chart.png" />
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/m/trading">
                    <div class="flex justify-center">
                        <img v-if="$route.path.includes('/m/trading')" src="/asset/img/app_nav_btn/breif_selected.png" />
                        <img v-else src="/asset/img/app_nav_btn/breif.png" />
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/m/deposit">
                    <div class="flex justify-center">
                        <img v-if="$route.path.includes('/m/deposit')" src="/asset/img/app_nav_btn/inout_selected.png" />
                        <img v-else src="/asset/img/app_nav_btn/inout.png" />
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/m/mypage">
                    <div class="flex justify-center">
                        <img v-if="$route.path.includes('/m/mypage')" src="/asset/img/app_nav_btn/more.png" />
                        <img v-else src="/asset/img/app_nav_btn/more.png" />
                    </div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>