// SPA Router, 싱글 페이지 어플리케이션 라우터
import { createRouter, createWebHistory } from 'vue-router'

// import mobile routes
import mobilePagesRoutes from './mobilePagesRoutes'

// import another routes
import mainRoutes from './mainRoutes'
import announceRoutes from './announceRoutes'
import exchangerRoutes from './exchangerRoutes'
import popupRoutes from './popupRoutes'
import memberRoutes from './memberRoutes'
import mypageRoutes from './mypageRoutes'
import nftPlusRoutes from './nftPlusRoutes'
import niceRoutes from './niceRoutes'
import supportRoutes from './supportRoutes'
import tradingRoutes from './tradingRoutes'
import depositRoutes from './depositRoutes'
import testRoutes from './testRoutes'
import helpRoutes from './helpRoutes'

// import Error Page
import ErrorPage from '../pages/ErrorPage.vue'

// import pinia store
import { useExchangerStore } from '../stores/exchanger'

// import api
import ExchangerApi from '../api/exchanger/index.js'

let exchangerApi = null

const getRoutes = () => [
    ...mobilePagesRoutes,
    ...mainRoutes,
    ...exchangerRoutes,
    ...depositRoutes,
    ...tradingRoutes,
    ...announceRoutes,
    ...popupRoutes,
    ...memberRoutes,
    ...mypageRoutes,
    ...nftPlusRoutes,
    ...niceRoutes,
    ...supportRoutes,
    ...testRoutes,
    ...helpRoutes
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: getRoutes()
})

router.addRoute({
    ...tradingRoutes
})

// 라우터에서 리디렉션을 관리합니다.
router.beforeEach(async (to, from, next) => {
    try {
        const userDevice = window.navigator.userAgent;
        if( to.path.includes('/support/notice') ){
            next()
        } else if( userDevice.includes('Mobile') && !to.matched[0].path.includes('/m') && !to.path.includes('/m') ){
            const endPoint = { path : '/m/home' }
            if( to.path !== '/' ){
                endPoint.path = `/m${to.path}`
            }
            next(endPoint)
        } else if(
            to.path === '/m/exchanger' &&
            sessionStorage.getItem('locale') &&
            !to.query.code &&
            !to.query.where
        ){
            // 쿼리스트링 없이 exchanger로 진입할 때 default 마켓 정보를 통해 이동시킵니다.
            const item = JSON.parse(sessionStorage.getItem('locale'))
            next({ name: `MobileExchanger`, query: { code: item.code, where: item.where } })
        } else if (!to.query.code && !to.query.where && to.path === '/m/exchanger'){
            if (exchangerApi === null) {
                exchangerApi = new ExchangerApi()
            }
            const response = await exchangerApi.defaultMarket()
            const nextPath = { name: `MobileExchanger`, query: { code: `${response.result.market}` } }
            if (!to.query.where && to.path === '/m/exchanger') {
                nextPath.where = `${response.result.market2}`
            }
            next(nextPath)
        } else if ( !to.query.where && to.path === '/m/exchanger' && to.query.code ){
            if (exchangerApi === null) {
                exchangerApi = new ExchangerApi()
            }
            await exchangerApi.getDealMarketList()
            const store = useExchangerStore()
            let whereToGo = ''
            for (let i = 0; i < store.dealMarketList.length; i++) {
                if (to.query.code.includes(store.dealMarketList[i])) {
                    whereToGo = store.dealMarketList[i]
                }
            }
            next({ name: `MobileExchanger`, query: { code: `${to.query.code}`, where: `${whereToGo}` } })
        } else if (
            to.path === '/exchanger' &&
            sessionStorage.getItem('locale') &&
            !to.query.code &&
            !to.query.where
        ) {
            // 쿼리스트링 없이 exchanger로 진입할 때 default 마켓 정보를 통해 이동시킵니다.
            const item = JSON.parse(sessionStorage.getItem('locale'))
            next({ name: `exchanger`, query: { code: item.code, where: item.where } })
        } else if (!to.query.code && !to.query.where && to.path === '/exchanger') {
            if (exchangerApi === null) {
                exchangerApi = new ExchangerApi()
            }
            const response = await exchangerApi.defaultMarket()
            const nextPath = { name: `exchanger`, query: { code: `${response.result.market}` } }
            if (!to.query.where && to.path === '/exchanger') {
                nextPath.where = `${response.result.market2}`
            }
            next(nextPath)
        } else if (!to.query.where && to.path === '/exchanger' && to.query.code) {
            if (exchangerApi === null) {
                exchangerApi = new ExchangerApi()
            }
            await exchangerApi.getDealMarketList()
            const store = useExchangerStore()
            let whereToGo = ''
            for (let i = 0; i < store.dealMarketList.length; i++) {
                if (to.query.code.includes(store.dealMarketList[i])) {
                    whereToGo = store.dealMarketList[i]
                }
            }
            next({ name: `exchanger`, query: { code: `${to.query.code}`, where: `${whereToGo}` } })
        } else if (!sessionStorage.getItem('user') && to.path.includes('/mypage') && !to.path.includes('/m/')) {
            const routeInfo = { name: 'Login', query: { caller: '' } }
            if (to.path.includes('/myinfo')) {
                routeInfo.query.caller = 'FUInfo'
            } else if (to.path.includes('/securitySetting')) {
                routeInfo.query.caller = 'FSSetting'
            } else if (to.path.includes('/wallet')) {
                routeInfo.query.caller = 'FUWallet'
            }
            next(routeInfo)
        } else if (!sessionStorage.getItem('user') && to.path.includes('/support/help')) {
            const routeInfo = { name: 'Login', query: { caller: 'FHelp' } }
            next(routeInfo)
        } else if (
            (sessionStorage.getItem('user') && to.path.includes('/join')) ||
            (sessionStorage.getItem('user') && to.path.includes('help/findPwd')) ||
            (sessionStorage.getItem('user') && to.path.includes('/findId')) ||
            (sessionStorage.getItem('user') && to.path.includes('/login'))
        ) {
            next({ name: 'Main' })
        } else if (
            !sessionStorage.getItem('user') &&
            to.path.includes('/trading/pendingTransactions') && !to.path.includes('/m/')
        ) {
            next({ name: 'Login', query: { caller: 'FPendingList' } })
        } else if (!sessionStorage.getItem('user') && to.path.includes('/trading/tradingHistory') && !to.path.includes('/m/') ) {
            next({ name: 'Login', query: { caller: 'FTradingList' } })
        } else if (!sessionStorage.getItem('user') && to.path.includes('/trading') && !to.path.includes('/m/') ) {
            next({ name: 'Login', query: { caller: 'FTraSystem' } })
        } else if (!sessionStorage.getItem('user') && to.path.includes('/balance')) {
            next({ name: 'Login', query: { caller: 'FDeposit' } })
        } else if (!to.query.where && to.path === '/child/OrderChildPopUp') {
            if (exchangerApi === null) {
                exchangerApi = new ExchangerApi()
            }
            await exchangerApi.getDealMarketList()
            const store = useExchangerStore()
            let whereToGo = ''
            for (let i = 0; i < store.dealMarketList.length; i++) {
                if (to.query.code.includes(store.dealMarketList[i])) {
                    whereToGo = store.dealMarketList[i]
                }
            }
            next({
                path: `/child/OrderChildPopUp`,
                query: { code: `${to.query.code}`, where: `${whereToGo}` }
            })
        } else if (to.path === '/login' && from.path.includes('/help/findId') && !to.query.caller) {
            const routeInfo = { name: 'Login', query: { caller: 'FFindId' } }
            next(routeInfo)
        } else if (
            to.path === '/login' &&
            from.path.includes('/help/findPwd') &&
            !to.query.caller
        ) {
            const routeInfo = { name: 'Login', query: { caller: 'FFindPwd' } }
            next(routeInfo)
        } else if (to.path === '/login' && from.path.includes('/join') && !to.query.caller) {
            const routeInfo = { name: 'Login', query: { caller: 'Fjoin' } }
            next(routeInfo)
        } else if (
            to.path === '/login' &&
            from.path.includes('/support/notice') &&
            !to.query.caller
        ) {
            const routeInfo = { name: 'Login', query: { caller: 'FNotice' } }
            next(routeInfo)
        } else if (to.path === '/login' && from.path.includes('/support/faq') && !to.query.caller) {
            const routeInfo = { name: 'Login', query: { caller: 'FFaq' } }
            next(routeInfo)
        } else if (to.path === '/support') {
            next({ path: '/support/notice' })
        } else {
            next()
        }
    } catch (error) {
        return ErrorPage
    }
})

router.addRoute({
    path: '/:pathMatch(.*)*',
    component: ErrorPage
})

export default router
