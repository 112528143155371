import { createApp, createSSRApp } from 'vue'
// 상태관리 위한 피니아
import { createPinia } from 'pinia'

import PortalVue from 'portal-vue'

// import createError from 'http-errors';
// import connectRedis from 'connect-redis';
// import { createClient } from 'redis';

// ui library, framework. Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify'
import DayJsAdapter from '@date-io/dayjs'

import '@mdi/font/css/materialdesignicons.css'
// 추가 import
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import { VDataTable, VDataTableVirtual } from 'vuetify/labs/VDataTable'

import router from './router'

import App from './App.vue'

import { useExchangerStore } from './stores/exchanger'

import '../public/asset/css/main.scss'
import './style.css'

import lightTheme from '../theme_type1.json'
import darkTheme from '../theme_dark.json'

import UtilLocalStorage from './utils/storage/localStorage'

const localStorage = new UtilLocalStorage();

let theme = localStorage.getItem('theme');
if( !theme || theme.length < 1 ){
  theme = 'lightTheme'
}
if( theme !== 'lightTheme' && theme !== 'type2' && theme !== 'dark'){
  theme = 'lightTheme'
}

const vuetify = createVuetify({
  components : {
    VDataTable,
    VDataTableVirtual,
    VDatePicker,
    ...components
  },
  date: {
    // adapter: DayJsAdapter,
    adapter: VuetifyDateAdapter,
  },
  directives,
  theme: {
    defaultTheme: theme,
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 2,
    },
    themes: {
      lightTheme: lightTheme.type,
      type2: {
        dark: false,
        colors: {
          background: '#44FF22',
          surface: '#FFFFFF',
          primary: '#62EE00',
          'primary-darken-1': '#3700B3',
          secondary: '#DA03C6',
          'secondary-darken-1': '#018786',
          error: '#00B020',
          info: '#21F396',
          success: '#AF504C',
          warning: '#FB0000',
          "rise": "#CE271D",
          "fall": "#1261C4"
        },
      },
      dark: darkTheme.type
    },
  },
})

// 데이터 유효성 검사 모듈
// VeeValidate / vuelidate / vue-formulate 등의 후보가 존재하며, 추후에 구조가 좀 더 정해지면 그때 정해도 늦지 않음.



const app = createApp(App)

// const app = createSSRApp(App)

/** env Setup
if (process.env.NODE_ENV === 'test'){
  dotenv.config({ path : path.join(dirname, '.env.test') });
  app.set('env', 'test');
} else if (process.env.NODE_ENV === 'dev') {
  dotenv.config({ path: path.join(dirname, '.env.dev') });
  app.set('env', 'development');
} else {
  dotenv.config({ path: '.env' });
  app.set('env', 'production');
}
*/

// Redis Store

// catch 404 and forward to error handler
/**
app.use((req, res, next) => {
  next(createError(404, { message: `${req.method} ${req.url}` }));
});
 */

// error handler

const pinia = createPinia()

app.use(PortalVue)
app.use(pinia)

app.use(vuetify)
app.use(router)

app.mount('#app')
