<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @mouseenter="startAnimation"
    @mouseleave="resetAnimation"
  >
    <line
      y1="1.1"
      x2="15"
      y2="1.1"
      stroke="white"
      stroke-width="1.8"
      class="line-1"
    />
    <line
      y1="11.9"
      x2="15"
      y2="11.9"
      stroke="white"
      stroke-width="1.8"
      class="line-3"
    />
    <line
      y1="6.50002"
      x2="15"
      y2="6.50002"
      stroke="white"
      stroke-width="1.8"
      class="line-2"
    />
  </svg>
</template>


<script>
export default {
  methods: {
    startAnimation() {
      this.$el.querySelector(".line-1").classList.add("line-1-animate");
      this.$el.querySelector(".line-2").classList.add("line-2-animate");
      this.$el.querySelector(".line-3").classList.add("line-3-animate");
    },
    resetAnimation() {
      this.$el.querySelector(".line-1").classList.remove("line-1-animate");
      this.$el.querySelector(".line-2").classList.remove("line-2-animate");
      this.$el.querySelector(".line-3").classList.remove("line-3-animate");
    },
  },
};
</script>

<style scoped>
.line-1-animate {
  transform-origin: 0% 50%;
  animation: line1Animation 0.5s forwards;
}
.line-2-animate {
  opacity: 0;
  animation: line2Animation 0.5s forwards;
}
.line-3-animate {
  transform-origin: 0% 50%;
  animation: line3Animation 0.5s forwards;
}

@keyframes line1Animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes line2Animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes line3Animation {
  0% {
transform: rotate(0deg);
}
100% {
transform: rotate(-45deg);
}
}
</style>
