/* eslint-disable consistent-return */
/* user, member data를 서버에 요청하는 로직 모음 */
import axios from 'axios';
import { useExchangerStore } from '../../stores/exchanger/index'
import { useUserStore } from '../../stores/user/index'
import { requestHeader, requestRefreshHeader } from '../config.js'
import reverseGeocoding from '../utils/index.cjs';
import UtilSessionStorage from '../../utils/session';

const sessionStorage = new UtilSessionStorage();

/**
 * 로그인처리
 * @returns 
 */
export async function login ( id, pwd, platform, term, lat, long ) {
  try {
      const userStore = useUserStore();
      const requestData = makeLoginData( { id, pwd, platform, term, lat, long } );
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/sign_in`, requestData, requestHeader() );
      if( !response?.data?.result?.left ){
        userStore.setLoginFailedUserData( null );
      }
      if( response.data.status === 'success' ) {
          userStore.setLoginStatus({
              "status": "success",
          })
          userStore.setLoginedUser( response.data?.result );
      } else if( Number(response.data?.code) === 20003){
          userStore.setLoginStatus({
              "status": "locked",
          })
      } else if( Number(response.data?.result?.left) <= 0 ){
          userStore.setLoginStatus({
            "status": "locked",
          })
      } else if( response.data?.title ) {
          userStore.setLoginStatus({
              "status": "fail",
          })
          userStore.setLoginFailedUserData( response.data?.result );
      } else {
          userStore.setLoginStatus({
              "status": "fail",
          })
          userStore.setLoginFailedUserData( response.data?.result );
      }
      
  } catch (error) {
      console.error(error);
  }
  return null;
}


/**
 * 로그아웃
 */
export async function logOut(){
  const userStore = useUserStore();
  userStore.setLoginStatus({
      "status": "logout"
  })
  sessionStorage.removeStorage('user')
  sessionStorage.removeStorage('email')
  sessionStorage.removeStorage('loginStatus')
  sessionStorage.removeStorage('loginTime')
  sessionStorage.removeStorage('_x_access_token')
  sessionStorage.removeStorage('_x_refresh_token')
  sessionStorage.removeStorage('meta')
}


/**
 * 로그인 연장
 * @param {*} id 
 * @returns 
 */
export async function refreshLoginSession( id ){
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/refresh_token`, { id }, requestRefreshHeader() );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
}


/**
 * 서버에 회원 정보 조회 요청
 * @param {*} id
 * @returns
 */
export async function initMemberInfomation(id) {
  try {
      const requestData = { id }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/info`, requestData, requestHeader() );
      if (response) {
          return response.data.result.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}


/**
* 서버에 유저의 입출금 내역을 요청합니다.
*/
export async function getUserInoutListData( id, page, type, start, end, symbol ){
  try {
    const requestData = { id }
    if( page ) requestData.page = page;
    if( type ) requestData.type = type;
    if( start ) requestData.start = start;
    if( end ) requestData.end = end;
    if( symbol ) requestData.symbol = symbol;
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/inout/list`, requestData, requestHeader());
    if ( response ){
        const userStore = useUserStore();
        userStore.setMemberInoutList(response.data.result.data);
    }
    return response.data.result;
  } catch (error) {
    console.error(error)
  }
}


/**
 * 구매시에 보여주기 위한 거래가능 자산 가져오는 api
 * @param {*} id 유저의 아이디
 * @param {*} symbol 거래하려는 심볼
 */
export async function getAvailableWallet( id, market ){
  try {
    const requestData = { id, symbol : market }
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/available`, requestData, requestHeader() );
    if ( response.data.status === 'success' && response.data.result.available ){
      const userStore = useUserStore();
      userStore.setAvailableUserWallet( response.data.result.available );
      return response.data.result.available
    }
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 판매시에 보여주기 위한 거래가능 자산 가져오는 api
 * @param {*} id 유저의 아이디
 * @param {*} symbol 거래하려는 심볼
 */
export async function getAvailableAsset( id, symbol ){
  const userStore = useUserStore();
  try {
    const requestData = {
      id, symbol
    }
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/available`, requestData, requestHeader() );
    if ( response.data.status === 'success' && response.data.result.available ){
      userStore.setAvailableAsset( response.data.result.available );
      return response.data.result.available
    } 
    userStore.setAvailableAsset( 0 );  
    return response.data
  } catch (error) {
    userStore.setAvailableAsset( 0 );
    console.error(error)
  }
}


/**
 * 회원의 지갑 주소 목록을 서버에 요청
 * @param {*} id 
 */
export async function getSelectedWalletList( id ){
  try {
    const requestData = {
      id,
    }
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/select_wallet_list`, requestData, requestHeader() );
    if( response ){
      return response.data.result.data
    }
  } catch (error) {
    console.error(error)
  }
  return null;
}

/**
 * 보안등급 및 입출금 제한 금액
 * @param {*} id        회원의 id
 * @param {*} symbol    KRW, TOKEN, 혹은 개별 symbol을 넣을 수 있습니다.
 */
export async function prepareMemberSecurityLevel( id, symbol ){
  try {
    const requestData = {}
    if( id !== undefined && id !== null ) requestData.id = id
    if( symbol !== undefined && symbol !== null ) requestData.symbol = symbol
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/security_level`, requestData, requestHeader() );
    return response.data.result
  } catch (error) {
    console.error(error)
  }
}


/**
 * 서버에 지갑 등록을 요청
 * @param {*} id 
 * @param {*} networkName 
 * @param {*} walletName 
 * @param {*} address 
 * @param {*} nickName 
 * @param {*} allowToUse 
 * @returns 
 */
export async function registerWallet( id, networkName, walletName, address, nickName, checkNoticeRegWallet, allowUseInfo ){
  try {
    const requestData = {}
    if( id !== undefined && id !== null ) requestData.id = id
    if( networkName !== undefined && networkName !== null ) requestData.networkName = networkName  
    if( walletName !== undefined && walletName !== null ) requestData.walletName = walletName  
    if( address !== undefined && address !== null ) requestData.address = address  
    if( nickName !== undefined && nickName !== null ) requestData.nickName = nickName  
    if( checkNoticeRegWallet !== undefined && checkNoticeRegWallet !== null ) requestData.checkNoticeRegWallet = checkNoticeRegWallet
    if( allowUseInfo !== undefined && allowUseInfo !== null ) requestData.allowUseInfo = allowUseInfo
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/register_wallet`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)  
  }
}


/**
 * 서버에 지갑 주소 적합성 확인 요청 (동일 여부 등)
 */
export async function verifyWalletAddress( requestData ){
  return axios.post(`${process.env.VUE_APP_BACKEND}/member/verify_wallet_address`, requestData, requestHeader() );
}


/**
 * 지갑 주소 업데이트 요청
 * @param {*} id 
 * @param {*} networkName 
 * @param {*} walletName 
 * @param {*} address 
 * @param {*} nickName 
 * @param {*} allowToUse 
 * @returns 
 */
export async function getUpdateWallet( id, networkName, walletName, address, nickName, allowToUse ){
  try {
    const requestData = {}
    if( id !== undefined && id !== null ) requestData.id = id
    if( networkName !== undefined && networkName !== null ) requestData.networkName = networkName
    if( walletName !== undefined && walletName !== null ) requestData.walletName = walletName
    if( address !== undefined && address !== null ) requestData.address = address
    if( nickName !== undefined && nickName !== null ) requestData.nickName = nickName
    if( allowToUse !== undefined && allowToUse !== null ) requestData.allowToUse = allowToUse
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_wallet`, requestData, requestHeader() );
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 지갑 별명 업데이트 요청
 * @param {*} id 
 * @param {*} networkName 
 * @param {*} walletName 
 * @param {*} address 
 * @param {*} nickName 
 * @returns 
 */
export async function getUpdateWalletNickName( id, networkName, walletName, address, nickName ){
  try {
    const requestData = {}
    if( id !== undefined && id !== null ) requestData.id = id
    if( networkName !== undefined && networkName !== null ) requestData.networkName = networkName
    if( walletName !== undefined && walletName !== null ) requestData.walletName = walletName
    if( address !== undefined && address !== null ) requestData.address = address
    if( nickName !== undefined && nickName !== null ) requestData.nickName = nickName
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_wallet_nick_name`, requestData, requestHeader() );
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 지갑 삭제 요청
 * @param {*} id 
 * @param {*} networkName 
 * @param {*} walletName 
 * @param {*} address 
 * @returns 
 */
export async function getDeleteWallet( id, networkName, walletName, address ){
  try {
    const requestData = {}
    if( id !== undefined && id !== null ) requestData.id = id
    if( networkName !== undefined && networkName !== null ) requestData.networkName = networkName
    if( walletName !== undefined && walletName !== null ) requestData.walletName = walletName
    if( address !== undefined && address !== null ) requestData.address = address
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/delete_wallet`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 유저의 알림, 보안설정 등의 설정 변경을 위한 api
 * @param {*} id 
 * @param {*} networkName 
 * @param {*} walletName 
 * @param {*} address 
 * @returns 
 */
export async function updateAllowSetting( requestData ){
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_allow`, requestData, requestHeader() );
    return response.data.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 서버에 입출금 가능금액(심볼, 타입별)을 요청
 * @returns 
 */
export async function setUserAvailableInout( id, symbol, type ){
  const requestData = {id, symbol, type}
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/available_inout`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 서버에 출금 한도 금액 데이터를 요청
 * @param {*} requestData 
 * @returns 
 */
export async function requestLimitMaxOut(requestData){
  return axios.post(`${process.env.VUE_APP_BACKEND}/member/limit_max_out`, requestData, requestHeader() );
}


/**
 * 핸드폰 인증 완료 요청을 서버로 전송
 * @param {*} phone 
 * @param {*} birth 
 * @param {*} name 
 * @param {*} sex 
 * @returns 
 */
export async function saveMemberDoneAuthPhone( phone, birth, name, sex ){
  const requestData = { phone, birth, name, sex }
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/done_auth_phone`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 서버에 비밀번호 찾기 적합성 요청 (로그인 안했을 때도 사용 가능)
 * @param {*} requestData 
 * @returns 
 */
export async function verifyFindPassword( requestData ){
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/verify_find_password`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 서버에 비밀번호 바꾸기 적합성 요청 (로그인 안했을 때도 사용 가능)
 * @param {*} requestData 
 * @returns 
 */
export async function verifyUpdatePassword( requestData ){
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/verify_update_password`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 서버에 비밀번호 찾기 요청 (로그인 안했을 때도 사용 가능)
 * @param {*} requestData 
 * @returns 
 */
export async function findPassword( requestData ){
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/find_password`, requestData, requestHeader() );
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 이메일 인증이 완료되었다면 서버에 데이터를 저장
 * @param {*} email 
 * @param {*} phone 
 * @param {*} password 
 * @returns 
 */
export async function saveMemberDoneAuthEmail( email, phone, password ){
  const requestData = { email, phone, password }
  try {
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/done_auth_email`, requestData, requestHeader() );
    return response
  } catch (error) {
    console.error(error)
  }
}

/**
 * 계정 잠금을 요청
 * @param {*} id 
 * @returns 
 */
export async function lockAccount( id ){
  try {
    const requestData = {};
      if( id ){
        requestData.id = id
      }
    const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/lock_account`, requestData, requestHeader() )
    return response.data
  } catch (error) {
    console.error(error)
  }
}


/**
* 서버에 회원가입_약관동의로 요청
* @param {*} id
* @returns
*/
export async function registerCheckPolicy( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/check_policy`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_핸드폰변경으로 요청
* @param {*} phone
* @param {*} birth
* @param {*} name
* @param {*} sex
* @param {*} id
* @returns
*/
export async function updatePhone( phone, birth, name, sex, id ) {
  try {
      const requestData = { phone, birth, name, sex, id }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_phone`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_이메일변경으로 요청
* @returns
*/
export async function updateEmail( id, email ) {
  try {
      const requestData = { id, email }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_email`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_비밀번호변경으로 요청
* @param {*} id
* @param {*} password
* @returns
*/
export async function updatePwd({ id, password }) {
  try {
      const requestData = { id, password }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_password`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 비밀번호 유효성 검증 요청(마이페이지_비밀번호 적합성으로 요청)
* @param {*} id
* @param {*} password
* @returns
*/
export async function verifyPwd(id, password) {
  try {
      const requestData = { id, password }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/verify_password`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 계좌 정보 변경을 위한 1원 요청
* @returns
*/
export async function authBank( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/auth_bank`, requestData, requestHeader() );
      if (response) {
          return response.data.result
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_
* @returns
*/
export async function updateBankAccount( requestData ) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_bank_account`, requestData, requestHeader() );
      if (response) {
          return response.data.result
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_계좌정보초기화로 요청
* @returns
*/
export async function initBank() {
  try {
      const requestData = {}
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/init_bank_account`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_주소변경으로 요청
* @returns
*/
export async function updateAddress(requestData) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_address`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_직업정보변경으로 요청
* @returns
*/
export async function updateJobInfo(requestData) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/update_job_purpose`, requestData, requestHeader() );
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_아이디 이메일 찾기로 요청
* @returns
*/
export async function findIdPwd(phone, birth, name, sex) {
  try {
      const requestData = { phone, birth, name, sex }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/find_email`, requestData, requestHeader());
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 마이페이지_비밀번호 찾기로 요청
* @returns
*/
export async function findPwd(email) {
  try {
      const requestData = { 
        type : '비밀번호찾기',
        email 
      }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/email/findpw_email`, requestData, requestHeader());
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}

/**
* 서버에 신분증 인증 요청(주민등록증, 운전면허증, 외국인등록증, 여권 등의 요청)
* @param {*} requestData
* @returns
*/
export async function authKYC(requestData) {
  try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/auth_kyc`, requestData, requestHeader())
      if (response) {
          return response.data
      }
  } catch (error) {
      console.error(error)
  }
  return null
}


/**
 * 평가 손익 데이터 요청
 * @param {*} userid
 */
export async function getMemberAssetEval (userid ) {
  const userStore = useUserStore();
  try {
      const requestData = {};
      if( userid ){
        requestData.id = userid
      }
      if( !userStore.memberAssetEval || !userStore.memberAssetEval.length){
          const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/eval`, requestData, requestHeader() )
          if( response ){
            userStore.setMemberAssetEval( response.data.result );
          }
      }
  } catch (error) {
      console.error(error)
  }
  return null;
}


/**
 * 보유 토큰 목록 데이터 요청
 * @param {*} userid
 */
export async function getMemberTokenList ( userid ) {
  const userStore = useUserStore();
  try {
      const requestData = {};
      if( userid ){
        requestData.id = userid
      }
      
      if( !userStore.memberTokenList || !userStore.memberTokenList.length ){
          const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/token_list`, requestData, requestHeader() );
          userStore.setMemberTokenList( response.data.asset );
      }
  } catch (error) {
      console.error(error)
  }
}


/**
 * 보유 거래 코인 데이터 요청
 * @param {*} userid
 */
export async function getMemberCoinList ( userid  ) {
  const userStore = useUserStore();
  try {
      const requestData = {};
      if( userid ) requestData.id = userid;
      if( !userStore.memberCoinList || !userStore.memberCoinList.length ){
          const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/coin_list`, requestData, requestHeader() );
          userStore.setMemberCoinList( response.data.data );
      }
  } catch (error) {
      console.error(error)
  }
}


/**
 * 서버에 전체 토큰 대비 보유량 데이터를 요청
 */
export async function getAllMarketToken( userid  ){
  const userStore = useUserStore();
  try {
      const requestData = {};
      if( userid ){ requestData.id = userid; }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberasset/all_market_token`, requestData, requestHeader())
      userStore.setAllMarketToken( response.data.result.asset );
  } catch (error) {
      console.error(error)
  }
  return null;
}


/**
 * 즐겨찾기 / 관심 마켓 목록 가져오기 및 갱신
 * @param {String} id
 * @returns {Object} response
 */
export async function updateMemberFavoriteList (id) {

  if( !id ) return null;

  try {
      const requestData = { id };
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberfavorite/list`, requestData, requestHeader() );
      const exchangerStore = useExchangerStore();
      const userStore = useUserStore();
      if( response.data.status === 'success' && response.data.result.data ) {
          // 위의 요청으로부터 값을 받아오는 것이 성공적이었다면 즐겨찾기 리스트에 담습니다.
          userStore.setResponseFavoriteMarketList( response.data.result.data );
          const responseData = response.data.result.data.map((item)=>{
              // exchanger.marketList에서 item.market과 일치하는 객체를 찾습니다.
              try {
                  const marketInfo = exchangerStore.marketList.find(marketItem => marketItem.market === item.market);
                  const customed_item = {
                      name : marketInfo?.name,
                      market : item.market,
                      market1 : marketInfo?.market1,
                      market2 : marketInfo?.market2,
                      initPrice : marketInfo?.initPrice || 0,
                      idx : item.idx,
                      trade_price : marketInfo?.trade_price || 0,            // 추후에 계산식 수정 예정
                      change_rate : 0,            // 추후에 계산식 수정 예정
                      acc_trade_price_24h : 0,    // 추후에 계산식 수정 예정
                  };
                  return customed_item;
              } catch (error) {
                  console.error(error)
              }
          })
          userStore.setFavoriteMarketList( responseData );
      }
  } catch (error) {
      console.error(error)
  }
  return null;
}


/**
 * 즐겨찾기 / 관심 목록 추가
 * @param {String} id
 * @param {String} market
 * @returns {Object} response
 */
export async function initMemberFavoriteAdd (id, market) {
  try {
      const requestData = { id, market };
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberfavorite/add`, requestData, requestHeader() )
      
      await updateMemberFavoriteList( id );
      return response;
  } catch (error) {
      console.error(error)
  }
  return null;
}


/**
 * 즐겨찾기 / 관심 목록 삭제
 * @param {Number} id
 * @returns {Object} response
 */
export async function initMemberFavoriteDelete( market, idx ) {
  const userStore = useUserStore();
  try {
      const id = sessionStorage.getUserId();
      const requestData = {}
      if( id ) requestData.id = id;
      if( idx ) requestData.idx = idx;
      if( market ) requestData.market = market;
      if( idx === undefined || !idx ){
          // favoriteMarketList에서 market이 동일한 객체를 찾습니다.
          const targetItem = userStore.favoriteMarketList.find(item => item.market === market);
          // 찾은 객체에서 idx 값을 가져옵니다.
          if (targetItem) {
              requestData.idx = targetItem.idx;
          } 
      } 
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/memberfavorite/delete`, requestData, requestHeader() )
      return response
  } catch (error) {
      console.error(error)
  }
  return null;
}


/**
 * 유저의 아이디를 가지고 서버에 마지막 로그인 시간을 요청합니다.
 * @param {*} userEmailID 
 */
export async function getMemberLatestLoginLog( id ){
  const userStore = useUserStore();
  try {
      const requestData = { id : id }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/login_log_latest`, requestData, requestHeader() );
      if ( response.data?.result?.data ){
        userStore.setLatestUserLogin( response.data?.result?.data );
      }
  } catch (error) {
      console.error(error)
  }
}


/**
 * 유저의 아이디를 가지고 로그인 로그를 요청합니다.
 * @param {*} userEmailID 
 */
export async function getMemberLoginLog( userEmailID, page ){
  const userStore = useUserStore();
  try {
      const requestData = { id : userEmailID, page }
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/member/login_log`, requestData, requestHeader() );
      if ( response ){
          userStore.setUserLoginLog( response.data?.result );
      }
  } catch (error) {
      console.error(error)
  }
}

/**
 * 회원탈퇴 조건 체크 api
 */
export async function requestCheckWithdrawCondition( requestData ){
  return axios.post(`${process.env.VUE_APP_BACKEND}/member/check_withdraw_condition`, requestData, requestHeader() );
}

/**
 * 회원탈퇴 api
 * @param {*} requestData 
 * @returns 
 */
export async function requestWithdrawMember( requestData ){
  return axios.post(`${process.env.VUE_APP_BACKEND}/member/withdraw`, requestData, requestHeader() );
}


/**
 * 1:1 문의하기 api
 * @param {*} requestData 
 * @returns 
 */
export async function requestMemberInquiry( requestData ){
  return axios.post(`${process.env.VUE_APP_BACKEND}/member/inquiry`, requestData, requestHeader() );
}


/**
 * 로그인 데이터를 받아와서 서버에 보낼 데이터로 가공합니다.
 * @param {Object} data 
 * @returns 
 */
function makeLoginData( data ){
  let result = {};
  let location = '알 수 없음'
  let param1 = 37.5108317;
  let param2 = 127.0291833;
  const { lat, long } = data;
  if( lat ){
    param1 = lat
  }
  if( long ){
    param2 = long
  }
  const country = reverseGeocoding( param1, param2 )
  if( country ){
    location = country;
  }

  const { id, pwd, platform, term } = data

  result = {
    email : id, 
    password : pwd, 
    location, 
    platform, 
    ip : term,
  }

  return result;
}