<template>
    <svg width="1920" height="164" viewBox="0 0 1920 164" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g filter="url(#filter0_f_36_66)">
<line x1="970" y1="93.85" x2="1921" y2="93.85" stroke="black" stroke-width="0.3"/>
</g>
<g filter="url(#filter1_f_36_66)">
<path d="M-3 125.443L197.116 24.5569C268.565 -11.4632 354.704 -0.688649 415.079 51.8207L466.987 96.9656C501.32 126.826 549.941 133.856 591.326 114.943L669.667 79.1415C712.497 59.5685 761.069 56.5977 805.965 70.8051L920 106.892" stroke="black" stroke-opacity="0.6" stroke-width="0.5"/>
</g>
<g filter="url(#filter2_f_36_66)">
<path d="M-4 88.1614L78.5648 64.4909C194.899 31.1391 319.077 37.9514 431.065 83.8287V83.8287C494.844 109.957 565.187 115.533 632.287 99.779L680.373 88.4891C732.846 76.1693 787.497 76.5014 839.816 89.458L919 109.067" stroke="black" stroke-opacity="0.6" stroke-width="0.3"/>
</g>
<circle cx="1147.5" cy="94" r="7" fill="white"/>
<g opacity="0.4">
<circle cx="1147.5" cy="94" r="7" fill="#C3ECCC"/>
<circle cx="1147.5" cy="94" r="4" fill="#0A8A1D"/>
</g>
<circle cx="1274" cy="94" r="7" fill="white"/>
<g opacity="0.4">
<circle cx="1274" cy="94" r="7" fill="#C3ECCC"/>
<circle cx="1274" cy="94" r="4" fill="#0A8A1D"/>
</g>
<circle cx="1400.5" cy="94" r="7" fill="white"/>
<g opacity="0.4">
<circle cx="1400.5" cy="94" r="7" fill="#C3ECCC"/>
<circle cx="1400.5" cy="94" r="4" fill="#0A8A1D"/>
</g>
<circle cx="1527" cy="94" r="7" fill="white"/>
<g opacity="0.4">
<circle cx="1527" cy="94" r="7" fill="#C3ECCC"/>
<circle cx="1527" cy="94" r="4" fill="#0A8A1D"/>
</g>
<circle cx="1653.5" cy="94" r="7" fill="white"/>
<g opacity="0.4">
<circle cx="1653.5" cy="94" r="7" fill="#C3ECCC"/>
<circle cx="1653.5" cy="94" r="4" fill="#0A8A1D"/>
</g>
<g filter="url(#filter3_f_36_66)">
<path d="M-11 108L183.575 68.2776C236.433 57.4866 290.608 54.6626 344.302 59.8993L679.366 92.5779C732.939 97.8029 786.883 98.0216 840.497 93.2312L918 86.3063" stroke="black" stroke-opacity="0.6" stroke-width="0.3"/>
</g>
<g filter="url(#filter4_f_36_66)">
<path d="M952.706 160.36L947.706 157.24L942.506 154.44L944.026 151.84L949.026 154.96L954.226 157.76L952.706 160.36ZM944.026 160.36L942.506 157.76L947.706 154.96L952.706 151.84L954.226 154.44L949.026 157.24L944.026 160.36ZM946.866 162L947.066 156.08L946.866 150.2H949.866L949.666 156.08L949.866 162H946.866ZM978.348 160.36L973.348 157.24L968.148 154.44L969.668 151.84L974.668 154.96L979.868 157.76L978.348 160.36ZM969.668 160.36L968.148 157.76L973.348 154.96L978.348 151.84L979.868 154.44L974.668 157.24L969.668 160.36ZM972.508 162L972.708 156.08L972.508 150.2H975.508L975.308 156.08L975.508 162H972.508ZM1003.99 160.36L998.991 157.24L993.791 154.44L995.311 151.84L1000.31 154.96L1005.51 157.76L1003.99 160.36ZM995.311 160.36L993.791 157.76L998.991 154.96L1003.99 151.84L1005.51 154.44L1000.31 157.24L995.311 160.36ZM998.151 162L998.351 156.08L998.151 150.2H1001.15L1000.95 156.08L1001.15 162H998.151Z" fill="#0A8A1D"/>
</g>
<path d="M900.927 160.36L895.927 157.24L890.727 154.44L892.247 151.84L897.247 154.96L902.447 157.76L900.927 160.36ZM892.247 160.36L890.727 157.76L895.927 154.96L900.927 151.84L902.447 154.44L897.247 157.24L892.247 160.36ZM895.087 162L895.287 156.08L895.087 150.2H898.087L897.887 156.08L898.087 162H895.087ZM926.57 160.36L921.57 157.24L916.37 154.44L917.89 151.84L922.89 154.96L928.09 157.76L926.57 160.36ZM917.89 160.36L916.37 157.76L921.57 154.96L926.57 151.84L928.09 154.44L922.89 157.24L917.89 160.36ZM920.73 162L920.93 156.08L920.73 150.2H923.73L923.53 156.08L923.73 162H920.73Z" fill="#0A8A1D"/>
<g filter="url(#filter5_i_36_66)">
<rect x="897" y="26" width="100" height="100" fill="url(#pattern0)"/>
</g>
<defs>
<filter id="filter0_f_36_66" x="969" y="92.7" width="953" height="2.30005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_36_66"/>
</filter>
<filter id="filter1_f_36_66" x="-6.1123" y="0.214111" width="929.188" height="128.476" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_36_66"/>
</filter>
<filter id="filter2_f_36_66" x="-6.0415" y="41.4905" width="927.078" height="69.7224" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_36_66"/>
</filter>
<filter id="filter3_f_36_66" x="-13.0298" y="55.1946" width="933.043" height="54.9524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_36_66"/>
</filter>
<filter id="filter4_f_36_66" x="940.506" y="148.2" width="67.0044" height="15.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_36_66"/>
</filter>
<filter id="filter5_i_36_66" x="897" y="26" width="100" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_36_66"/>
</filter>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_36_66" transform="scale(0.01)"/>
</pattern>
<image id="image0_36_66" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABCNJREFUeNrsnU9O20AUxieUfVmyqZpeoIQtG+AEJXskkhPQnoBwAnoDqNQ94QQxm6q7Dl11h1E3XTo36DzxDG6UhozteX4ef580sqqG2Mxv3j/b89gwkCptYAp0abNNFzs43hvQwY2+G/srPmrdeKCj/fotadPv2GsBhJE7fHDjwI2tkl8zdeOGjg5QBiD+EGjiP7pxWgHCMmUM59yBSQFkPRiTACCW6dyNz9ospqcIBLmkS44PUiIrGWuKMxuKrGImDMPw+Wbu/BewkGcYZBUjBXNBVjJs2oX1GgRBMeKasyctonT5sEkoTbqsC2UwDNc4M14s3QHCPntkdGrAi6URvWoAxhGlmzW7mV9cY2zXdZnb79/M//z8/T3qGMKu4L5ijZFX3cmy4s6do8+ukKr7o4qXvOvOYWMGUiWjIhCffCpshnNW4ZwE/TBKIFz4zUre7hhWKd4qFp1UOF7FGNTPSsaH3aqVNP/8Ln+fxHXrthB2HfdN1wQcw2acSfnoUOr2ipSFnJZwU+O6CzT+viF/f8jrVw/EN9s5D5XdcFIwDnz9el0WP+X74fEjqZu0dwLXde8Z5CmxmMZgIb63R6ZCi/FLiQo+Cpe14/n5GyEgvkF6PxYgvrm/SGVcImvqxwLEK7sSvvVtuwikr806FtJrVdIGRHqCbBeB+OhO+HxzAFEWswBEl+Cyaq4NohPefgcQCEDqq9YBBBaCFQsgCjI7NfVIE1vaMs7/U/O47SzlYRuyyqfXfPhhGj13z49vzeOtn3wEl8QTwwlPdhbCJRUmMdW6K0oVkJon/cCN1+Z54+eyVZtbHFniXcEiM+m3EFsNxHPS67ojcLvwb9up/SHCk14ptLBlLca54G6xF2DSDwqBUTwoCicmi26xcuq+WXHyJ4VJz1d/F5RbOemozkVeNe09MxAKQwCBAARAIACBAARAIAABEAhAAESpEvO4X5C2PNM2ONrQedXmX2izpded79Jd3P6WujEdHO/RdjVq/bQFC5HReNUGTL4FPoTLkpFdZzcsQ7kCkPC6CfRZACmp1DPWAEhg9ZH26tKJR0/EEwCRsZAXeyJyz/gRgMhoxN3p/geD+sZfwmXJaqvk/wFIIK3aQp0AiLyymNLdGIDYFVW6BRB9BWIGIJLm8fJLzxZA9FjHup8BEEEgDwCiIKDDQprRHEDaZyGtDOptfaZ+PTjeMzEKrwEBCAQgHQJiMYW6gGSYQrgsAIHWFzdOABBYCAQgAAIBCIBAAAIBCIBA5VT1AVXa4blb7D+cmBo6n9bSc5H3axSbEMfUazHvrXhrCk2fQ3Uvlfxr0f0CtB3zbwdpLaudxpxXeyONmVX07S3ckMuP+wVgdSoxz51EU570RJM5qu9sXcIdPrkV9u3WtKgNeStaja/pDvHnLqD69VeAAQAGUl9vdjiqKAAAAABJRU5ErkJggg=="/>
</defs>
</svg>


</template>
