<!-- 메인 헤더 -->
<template>
    <header class="">
        <!-- 모바일용 헤더 -->
        <nav v-if="$route.path.includes('/m/')" class="bg-gray-100">
            <div class="max-w-6xl mx-auto px-4 h-[40px] flex justify-between items-center">
                <!-- 메뉴 -->
                <div class="flex space-x-4">
                    <div>
                        <a
                            v-if="$route.path.includes('/m/home')"
                            href="/m"
                            class="flex items-center px-1 text-gray-700"
                        >
                            <img
                                :src="`/asset/img/${'logo_primary_expand.svg'}`"
                                style="height: 26px"
                            />
                        </a>
                        <div
                            v-else-if="$route.path.includes('/m/itemList')"
                            class="flex items-center h-[40px] px-1 text-[18px] text-main_color"
                        >
                            거래소
                        </div>
                        <a v-else href="/m" class="flex items-center px-1 text-gray-700">
                            <img
                                :src="`/asset/img/${'logo_primary_expand.svg'}`"
                                style="height: 26px"
                            />
                        </a>
                    </div>
                </div>

                <!-- mobile menu -->
                <div
                    v-if="!checkLoginStatus()"
                    class="flex items-center"
                >
                    <RouterLink to="/m/login"> 로그인 </RouterLink>
                </div>
                <div
                    v-else-if="checkLoginStatus()"
                    @click.prevent="prepareLogOut()"
                    class="__lang clickable hover:text-inherit text-[13px] flex items-center"
                >
                    로그아웃
                </div>
            </div>
        </nav>

        <!-- 일반 헤더 -->
        <nav
            v-else
            :class="[
                'wrapper flex text-base fixed bg-[#fff] w-full z-50 top-0 text-noto-400',
                useRoute().path === '/exchanger'
                    ? 'bg-main_color text-white its-green text-noto-300'
                    : ''
            ]"
        >
            <section class="hd-cover flex justify-between min-w-[1440px]">
                <div class="flex">
                    <ul class="md:p-0 md:m-0 list-none flex">
                        <li class="header-logo pl-[20px]">
                            <div class="flex justify-center items-center h-full">
                                <RouterLink to="/" :class="[{ active: isActive('/') }, '']">
                                    <img
                                        :src="`/asset/img/${
                                            $route.path === '/exchanger'
                                                ? 'logo_secondary.svg'
                                                : 'logo_primary_expand.svg'
                                        }`"
                                        style="height: 26px"
                                    />
                                </RouterLink>
                            </div>
                        </li>
                        <li class="header-item">
                            <RouterLink
                                :to="`/exchanger`"
                                :class="[
                                    '__lang hover:text-inherit text-[15px]',
                                    isActive('/exchanger') ? 'active' : ''
                                ]"
                                >거래소
                            </RouterLink>
                        </li>
                        <li class="header-item">
                            <RouterLink
                                to="/trading/myAssets"
                                :class="[
                                    '__lang hover:text-inherit text-[15px]',
                                    isActive('/trading') ? 'active' : '',
                                    $route.path === '/exchanger' ? 'opacity-60' : ''
                                ]"
                                >거래현황
                            </RouterLink>
                        </li>
                        <li class="header-item">
                            <RouterLink
                                to="/balance/deposit"
                                :class="[
                                    '__lang hover:text-inherit text-[15px]',
                                    isActive('/balance') ? 'active' : '',
                                    $route.path === '/exchanger' ? 'opacity-60' : ''
                                ]"
                                >입출금
                            </RouterLink>
                        </li>
                        <!-- 1차 개발에서 제외 -->
                        <!-- <li class="pr-10">
                            <RouterLink
                                to="/announce"
                                :class="[
                                    '__lang hover:text-inherit',
                                    isActive('/announce') ? 'active' : ''
                                ]"
                                >N소식
                            </RouterLink>
                        </li> -->
                        <li class="header-item">
                            <RouterLink
                                to="/nft_plus"
                                :class="[
                                    '__lang hover:text-inherit text-[15px]',
                                    isActive('/nft_plus') ? 'active' : '',
                                    $route.path === '/exchanger' ? 'opacity-60' : ''
                                ]"
                                >NFT+
                            </RouterLink>
                        </li>
                    </ul>
                </div>
                <!-- 로그인 처리가 안되었을 때 헤더 -->
                <div v-if="!checkLoginStatus()">
                    <ul class="flex">
                        <li class="header-item">
                            <a
                                :href="`${loginPageUrl}`"
                                class="__lang text-[13px]"
                                :class="[
                                    $route.path === '/exchanger'
                                        ? 'text-[#FFFF8D] text-noto-700'
                                        : ''
                                ]"
                            >
                                로그인
                            </a>
                        </li>
                        <li class="header-item">
                            <RouterLink to="/join" class="__lang hover:text-inherit text-[13px]"
                                >회원가입</RouterLink
                            >
                        </li>
                        <li>|</li>
                        <li class="header-item">
                            <RouterLink
                                to="/support"
                                :class="[
                                    '__lang hover:text-inherit text-[13px]',
                                    isActive('/support') ? 'active' : ''
                                ]"
                                >고객지원
                            </RouterLink>
                        </li>
                    </ul>
                </div>
                <!-- 로그인 처리가 되었을 때 헤더 -->
                <div v-else>
                    <ul class="flex">
                        <li class="mr-[20px] flex align-center">
                            <div
                                :class="[
                                    'logged-in flex align-center px-[15px] text-[13px]',
                                    isActive('/exchanger') ? 'green-theme' : '',
                                    $route.path === '/exchanger' ? 'text-[#E1ECDB]' : ''
                                ]"
                            >
                                남은 시간 {{ restLoginTime || '??:??' }}
                                <RouterLink
                                    v-if="$route.path !== '/exchanger'"
                                    :to="makeUserMypagePath()"
                                >
                                    <UserBtn class="ml-[18px]" />
                                </RouterLink>
                                <RouterLink v-else :to="makeUserMypagePath()">
                                    <UserBtnSecondary class="ml-[18px]" />
                                </RouterLink>
                            </div>
                        </li>
                        <li v-if="timerCount < 1800 && timerCount !== null" class="pr-[20px]">
                            <span
                                @click.prevent="prepareRenewLoginDuration()"
                                class="__lang clickable hover:text-inherit text-[13px]"
                            >
                                연장
                            </span>
                        </li>
                        <li class="pr-[20px]">
                            <span
                                @click.prevent="prepareLogOut()"
                                class="__lang clickable hover:text-inherit text-[13px]"
                            >
                                로그아웃
                            </span>
                        </li>
                        <li>|</li>
                        <li class="header-item">
                            <RouterLink
                                to="/support"
                                :class="[
                                    '__lang hover:text-inherit text-[13px]',
                                    isActive('/support') ? 'active' : ''
                                ]"
                                >고객지원
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </section>
        </nav>
    </header>

    <!-- confirm용 모달 창 -->
    <v-dialog v-model="confirm_dialog" persistent width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div>
                <br />
                <div class="text-[15px]" v-html="confirm_dialog_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    v-if="step === 'autoLogout'"
                    class="modal_cancel_btn w-[90px]"
                    color="black"
                    @click="
                        () => {
                            changeConfirmDialogFalse()
                            $router.push({ path: '/' })
                        }
                    "
                >
                    확인
                </v-btn>
                <v-btn
                    v-else
                    class="modal_cancel_btn w-[90px]"
                    color="black"
                    @click="changeConfirmDialogFalse"
                >
                    취소
                </v-btn>
                <v-btn
                    v-if="step === 'autoLogout'"
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="
                        () => {
                            changeConfirmDialogFalse()
                            // $router.push({path : '/login'});
                            window.location.href = `${loginPageUrl}`
                        }
                    "
                >
                    {{ confirm_btn }}
                </v-btn>
                <v-btn
                    v-else
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="changeConfirmDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

// import pinia store
import { useExchangerStore } from '@/stores/exchanger'

// import utils function
import { isActive } from '@/utils/utilsFactory.js'
import { checkLoginStatus } from '@/utils/member'
import UtilSessionStorage from '@/utils/session'

// import svg
import { UserBtn, UserBtnSecondary } from '@/components/svg'
import { logOut, refreshLoginSession } from '../api/user'
import Crypto from '../utils/crypto'

const sessionStorage = new UtilSessionStorage()

// pinia store 주입
const exchanger = useExchangerStore()

// 지역변수 관리
const loginPageUrl = ref('/login')
const menuToggle = ref(false)
const timerCount = ref(null)
const restLoginTime = ref('0시간 00분')

// 모달 관리
const modal_title = ref('')
// confirm용 모달창 상태
const confirm_dialog = ref(false)
const confirm_btn = ref('확인')
const confirm_dialog_msg = ref('')
const step = ref('')
function changeConfirmDialogTrue() {
    confirm_dialog.value = true
}
function changeConfirmDialogFalse() {
    confirm_dialog.value = false
}

let intervalId

onMounted(() => {
    loginPageUrl.value = `${process.env.VUE_APP_BASE_URL}/login`
    menuToggle.value = false

    intervalId = setInterval(() => {
        if (checkLoginStatus()) {
            const threeHour = 10800
            // const threeHour = 30;
            const anHour = 3600
            const anMinute = 60
            let tempValue =
                Number(new Date().getTime()) -
                Number(JSON.parse(window.sessionStorage.getItem('loginTime')))
            tempValue = Math.floor(tempValue / 1000)
            timerCount.value = threeHour - tempValue
            if (timerCount.value < 1) {
                // 자동 로그아웃 처리 및 세션 정보 삭제 로직 추가
                makeAutoLogoutWarnning()
                logOut()
            }
            if (timerCount.value > 3600) {
                restLoginTime.value = `${`${Math.floor(timerCount.value / anHour)}`.slice(
                    -2
                )}시간 ${`0${Math.floor((timerCount.value % anHour) / 60)}`.slice(-2)}분`
            } else {
                restLoginTime.value = `${`${Math.floor(timerCount.value / anMinute)}`.slice(
                    -2
                )}분 ${`0${timerCount.value % anMinute}`.slice(-2)}초`
            }
        }
    }, 1000)
})

onUnmounted(() => {
    clearInterval(intervalId)
})

// 로그아웃 처리
const prepareLogOut = async () => {
    await logOut()

    // 페이지를 새로고침합니다.
    window.location.reload(true)
}

// 로그인 연장 함수
async function prepareRenewLoginDuration() {
    try {
        const id = sessionStorage.getUserId()
        if (id) {
            const response = await refreshLoginSession(id)
            if (response.status === 'success') {
                // 기존 액세스 토큰 제거
                sessionStorage.removeStorage('_x_access_token')
                sessionStorage.removeStorage('loginTime')
                const secretKey = process.env.VUE_APP_ENC_SECRET_KEY
                // 현재 시간 저장
                const now = new Date().getTime()
                // 현재 시간 세션 저장
                sessionStorage.setItem('loginTime', now)
                // 액세스 토큰 세션에 암호화하여 임시 저장
                const accessToken = Crypto.encodeByAES256(secretKey, response.result.accessToken)
                // 액세스 토큰 세션 저장
                sessionStorage.setItem('_x_access_token', accessToken)
            }
        }
    } catch (error) {
        console.error(error)
    }
}

// 자동 로그아웃 안내 모달 생성
function makeAutoLogoutWarnning() {
    modal_title.value = '자동 로그아웃 안내'
    confirm_dialog_msg.value = `안전한 서비스 이용을 위해 자동 로그아웃 되었습니다.<br/>다시 로그인해주세요.`
    confirm_btn.value = '로그인'
    step.value = 'autoLogout'
    changeConfirmDialogTrue()
}

function makeUserMypagePath() {
    return `/mypage/myinfo`
}
</script>

<style scoped>
.header-logo {
    padding-right: 40px;
}
.header-item {
    padding: 0 20px;
}
.active {
    font-family: 'Noto Sans KR Bold 700', sans-serif !important;
}

.its-green .is-it-green path {
    fill: #ffffff;
}

.logged-in {
    min-width: 186px;
    max-height: 40px;
    background: #f0f0f0;
}

.logged-in.green-theme {
    background: #244429;
    color: #e1ecdb;
}

@media screen and (max-width: 768px) {
    .wrapper {
        display: none;
    }
}
</style>
