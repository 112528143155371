/* eslint-disable no-plusplus */
// 거래소에 사용되는 상태 값들을 관리합니다.
// 이제 store에서는 통신을 하지 않습니다. store는 값을 저장하기만 합니다.
import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'

import { requestPriceTickData, requestPriceTickDataList } from '../../api/exchanger'


/**
 * 거래소, 마켓 관련해서 전역적으로 쓰이는 store
 */
export const useExchangerStore = defineStore('exchanger', () => {
    // market 및 전역적으로 사용되는 store
    // 현재 선택된 마켓들의 정보를 담습니다.
    const queryCodeData = ref('');
    const selectedData = ref({
        name: '',
        market: '',
        market1: '',
        market2: '',

    })
    // 페이지 상단의 정보를 나타내는데 쓰는 데이터
    const marketInfomation = ref({});           
    const marketTokenNftList = ref([]);
    const marketCoinQuote = ref([]);
    const tradedData = ref([]);
    // 수수료 상태 관리
    const feeData = ref([])
    // 호가, 호가주문에 쓰이는 가격 로직
    const changePriceView = ref([])
    const activeOrderPrice = ref(0)
    const devideUnitArray = ref([
        2000000, 1000000, 500000, 100000, 10000, 1000, 100, 10, 1, 0.1, 0
    ])
    // 거래 시장의 목록입니다. (KRW, ETH 등의 데이터가 해당합니다.)
    const dealMarketList = ref([]);
    const nftMarketList = ref([]);
    // http request 로 얻어올 market data, 여기에는 market 들의 List를 가공해서 담습니다.
    const marketList = ref([])   
    const otherMarketList = ref({})       
    // market item 을 가져오는 websocket이 성공했는지에 대한 여부를 저장
    const isitSocketSuccessed = ref(false)
    // 최소 주문 금액
    const MinOrderLimit = ref(5000)
    // 최대 주문 금액
    const MaxOrderLimit = ref(null);
    // 현재 해당하는 market의 nft의 잔량
    const itemRemainVolume = ref(1000000)

    // 매수:bid 매도:ask
    // 웹소켓 관련 변수
    const marketPriceDataAfterOpenSocket = ref({})       // 현재가 가격 정보들을 담습니다.
    const dailyMarketInfoAfterOpenSocket = ref({})      // 24시간 가격을 담습니다.
    const quoteGeneralBid = ref([]);                    // 호가(일반) 구매 탭 데이터
    const quoteGeneralAsk = ref([]);                  // 호가(일반) 판매 탭 데이터

    // 실시간 값을 바탕으로 한 호가 주문 가격 생성 로직
    const orderBookInterval = ref(10);
    const orderBookInstantDataBlue = ref([]);
    const orderBookInstantDataRed = ref([]);
    // 로직 정리되면 orderbookInstant 지우고 밑의 변수로 대체합니다.
    const quoteOrderBid = ref([]);
    const quoteOrderAsk = ref([]);
    const quoteOrderListLength = ref(15);
    const sumVolumeData = ref({});


    /**
     * /exchanger의 쿼리스트링이 바뀌었을 때 실행되는 모든 데이터 최신화 함수
     * @selectedData
     * @orderBookData
     * 들을 변경하고
     * @makePriceView 함수를 실행합니다.
     */
    async function dataAllChange( newCode ) {
        try {
            // 쿼리스트링의 코드를 잠시 저장합니다.
            queryCodeData.value = newCode;
            // Code가 바뀌었으니 이 값을 기반으로 기준이 될 데이터를 가공합니다.
            await updateSelectedData( newCode );
            // 모아보기 단위를 바꿉니다.
            makePriceView()
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 들어온 market의 정보를 가지고 정보를 찾아서 객체를 반환합니다.
     * @param {*} market 
     */
    function updateSelectedData( market ){
        // marketList 배열을 순회하면서 market 이름이 일치하는 객체를 찾는다.
        let newData = null;
        try {
            for (let i = 0; i < marketList.value.length; i++) {
                if (marketList.value[i].market === market) { newData = marketList.value[i]; break; }
            }
        } catch (error) {
            console.error(error)
        }

        try {
            if( newData === null || !newData ){
                for (let i = 0; i < otherMarketList.value.length; i++) {
                    if (marketList.value[i].market === market) { newData = marketList.value[i]; break; }
                }
            }
        } catch (error) {
            console.error(error)
        }

        // 위에서 생성한 newData가 Null이 아닌 경우에만 값을 다시 저장합니다.
        if( newData !== null ){
            selectedData.value = newData;
        }

        return newData;
    }


    /**
     * Order Container에 있는 가격을 update 합니다.
     */
    function updateActiveOrderPrice(){
        activeOrderPrice.value = selectedData.value?.trade_price || 0;
    }


    /**
     * orderPrice 의 값을 바꿉니다.
     * @param {*} newValue
     */
    function changeActiveOrderPrice(newValue) {
        try {
            activeOrderPrice.value = newValue
        } catch (error) {
            console.error(error)
        }
    }
    

    function setThisSocketSuccessed(boolean) {
        try {
            isitSocketSuccessed.value = boolean
        } catch (error) {
            console.error(error)
        }
    }



    /**
     * OrderBook에 사용할 모아보기 단위를 생성하는 함수
     */
    async function makePriceView() {
        // 초기화
        changePriceView.value = [];
        // 마켓으로부터 리스트를 가져옵니다.
        try {
            const response = await requestPriceTickDataList( { market2: selectedData.value.market2 } )    
            if (response.data.status === "success") {
                const tickArray = response.data.result.data;
                for(let i = 0; i < tickArray.length; i++){
                    const tick = tickArray[i].tick;
                    changePriceView.value.push(tick)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * ! Buy
     * 현재 선택되어 있는 가격 중심으로 데이터를 가공합니다.
     */
    function updateOrderBookInstantUnitBuy( market, newVal ){
        // 마켓 이름을 가져옵니다.
        const marketName = market;
        // 숫자로 변환하고, 실패시에 1을 반환합니다.
        const unit_price = Number(newVal) || 1;
        // 호가 단위를 구합니다.
        const devide_unit = makeDevideUnit( 1, unit_price )
        
        // 재할당될 변수라 let으로 선언
        let temp_price = unit_price;

        // 빈 배열로 초기화
        orderBookInstantDataBlue.value.length = 0;

        // 상단 영역의 데이터를 가공해서 넣습니다.
        for(let i = 0; i < 15; i+=1){
            orderBookInstantDataBlue.value.push( { market: marketName, price : temp_price, amount: 1, left: 1 } );
            temp_price += devide_unit;
        }
    }


    /**
     * ! Sell
     * 현재 선택되어 있는 가격 중심으로 데이터를 가공합니다.
     */
    function updateOrderBookInstantUnitSell( market, newVal ){
        // 마켓 이름을 가져옵니다.
        const marketName = market;
        // 숫자로 변환하고, 실패시에 1을 반환합니다.
        const unit_price = Number(newVal) || 1;
        // 호가 단위를 구합니다.
        const devide_unit = makeDevideUnit( 1, unit_price )
        
        // 재할당될 변수라 let으로 선언
        let temp_price = unit_price;

        // 빈 배열로 초기화
        orderBookInstantDataRed.value.length = 0;

        // 하단 영역의 데이터를 가공해서 넣습니다.
        for(let i = 0; i < 15; i+=1){
            temp_price -= devide_unit;
            orderBookInstantDataRed.value.push( { market: marketName, price : temp_price, amount: 1, left: 1 } );
        }
    }


    /**
     * 호가 단위를 숫자로 반환합니다.
     * 계산 로직이 완성되는대로 삭제될 함수입니다. (백엔드에서 받아오는 호가 단위로 변경할 예정)
     * @param {*} devide_unit 
     * @param {*} unit_price 
     * @returns 
     */
    function makeDevideUnit( devide_unit, unit_price ){
        let temp_unit = devide_unit;
        if( unit_price >= devideUnitArray.value[0] ) {
            temp_unit = 1000;
        } else if ( unit_price >= devideUnitArray.value[1] ) {
            temp_unit = 500;
        } else if ( unit_price >= devideUnitArray.value[2] ) {
            temp_unit = 100;
        } else if ( unit_price >= devideUnitArray.value[3] ) {
            temp_unit = 50;
        } else if ( unit_price >= devideUnitArray.value[4] ) {
            temp_unit = 10;
        } else if ( unit_price >= devideUnitArray.value[5] ) {
            temp_unit = 5;
        } else if ( unit_price >= devideUnitArray.value[6] ) {
            temp_unit = 1;
        } else if ( unit_price >= devideUnitArray.value[7] ) {
            temp_unit = 0.1;
        } else if ( unit_price >= devideUnitArray.value[8] ) {
            temp_unit = 0.01;
        } else if ( unit_price >= devideUnitArray.value[9] ) {
            temp_unit = 0.001;
        } else if ( unit_price >= devideUnitArray.value[10] ) {
            temp_unit = 0.0001;
        }
        return temp_unit;
    }


    function setFeeData( newValue ){
        feeData.value = newValue
    }


    /**
     * 마켓리스트를 초기화합니다.
     */
    function initMarketList(){
        let result = false;
        if( !marketList.value ){
            marketList.value = [];
            result = true;
        }
        if( !otherMarketList.value ){
            otherMarketList.value = [];
            result = true;
        }
        if( marketList.value.length < 1 ){
            marketList.value = [];
            otherMarketList.value = [];
            result = true;
        }
        return result;
    }


    /**
     * 마켓리스트로부터 받아온 데이터를 마켓리스트에 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setMarketListDataWithFormat( newValue ){
        try {
            if( Array.isArray(newValue) ){
                const tempArr = newValue.map((item) => {
                    const customed_item = {
                        name: item.name,
                        market: item.market,
                        market1: item.market1,
                        market2: item.market2,
                        initPrice: item.initPrice,
                        idx: item.idx,
                        amount: 0,
                        trade_price: 0,
                        change_rate: 0, 
                        acc_trade_price_24h: 0,
                        volume: 0,
                        highest: 0,
                        lowest: 0,
                        open: 0,
                        close: 0,
                    }
                    return customed_item
                })
                marketList.value = [...marketList.value, ...tempArr]
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 소켓으로 받아오기 전에 TestMarketListAfter를 바꿉니다.
     * @returns 
     */
    function initMarketPriceDataAfterOpenSocket(){
        return new Promise((resolve, reject) => {
            try {
                for( let i = 0; i < marketList.value.length; i++ ){
                    marketPriceDataAfterOpenSocket.value[marketList.value[i].market] = { trade_price : 0 }
                }
                resolve(true);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    }

    /**
     * 마켓리스트와 가격을 받아서 marketList 배열에 인수로 받은 market과 같은
     * 이름의 market을 가진 객체가 있다면 그 객체의 trade_price key에 새로 받은 price value를 세팅합니다.
     * @param {*} market 
     * @param {*} price 
     */
    function updateMarketListByNewPrice( market, price ){
        marketPriceDataAfterOpenSocket.value[market] = {}
        marketPriceDataAfterOpenSocket.value[market].trade_price = price;
        // marketList 배열을 순회하면서 market 이름이 일치하는 객체를 찾는다.
        for (let i = 0; i < marketList.value.length; i++) {
            if (marketList.value[i].market === market) {
                // 일치하는 객체가 있다면 해당 객체의 trade_price 값을 업데이트한다.
                marketList.value[i].trade_price = price;
                break;
            }
        }
        // 만일 가져온 마켓이 selectedData의 market과도 같다면 update를 실행합니다.
        if( market === selectedData.value.market ){
            selectedData.value.trade_price = price;
            if( activeOrderPrice.value === 0 || !activeOrderPrice.value ){
                updateActiveOrderPrice();
            }
        }
    }

    /**
     * 소켓으로 받아오기 전에 OpenSocket으로 받아올 데이터를 담을 객체 초기화
     */
    function initMarketListByTodayInfomation(){
        return new Promise((resolve, reject) => {
            try {
                for( let i = 0; i < marketList.value.length; i++ ){
                    dailyMarketInfoAfterOpenSocket.value[marketList.value[i].market] = {
                        volume:0, 
                        highest:0, 
                        lowest:0, 
                        open:0, 
                        close:0, 
                        amount:0
                    }
                }
                resolve(true);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
        
        
    }

    /**
     * 소켓으로 받아온 24시간 시장 지표를 저장합니다.
     * @param {*} market 
     * @param {*} price 
     */
    function updateMarketListByTodayInfomation( market, newData ){
        // marketList 배열을 순회하면서 market 이름이 일치하는 객체를 찾는다.
        // 해당 소켓 구독 결과가 price 결과일 수 있어서, data가 number 일 경우 에러 해결
        if (typeof newData !== 'object') {
            newData = { volume:0, highest:0, lowest:0, open:0, close:0, amount:0 };
        }

        dailyMarketInfoAfterOpenSocket.value[market] = {
            volume : newData.volume,
            highest : newData.highest,
            lowest : newData.lowest,
            open : newData.open,
            close : newData.close,
            amount : newData.amount,
        }

        // 이 값을 실시간으로 갱신할 필요는 없고 price 로직에서 랜더 데이터를 업데이트하기 때문에
        // 여기에서는 랜더 데이터 갱신 로직을 생략합니다.
        if( market === selectedData.value.market || !selectedData.value ){
            selectedData.value.volume = newData.volume;
            selectedData.value.highest = newData.highest;
            selectedData.value.lowest = newData.lowest;
            selectedData.value.open = newData.open;
            selectedData.value.close = newData.close;
            selectedData.value.amount = newData.amount;
        }
    }
    

    /**
     * 컴포넌트가 마운트 될때마다 기존에 있던 스토어에 접근해서 배열을 초기화합니다.
     */
    function initOrderBookMarketDepthData(){
        quoteGeneralBid.value.length = 0;
        quoteGeneralAsk.value.length = 0;
    }
    

    /**
     * orderbook, marketdepth 오더북, 마켓뎁스로부터 얻어온 웹소켓 데이터를 저장하는 함수
     */
    function updateOrderBookMarketDepthData( newData ){
        // console.log(`updateOrderBookMarketDepthData 데이터 change check : ${JSON.stringify(newData)}`);
        try {
            if( Array.isArray( newData.ask ) ){
                quoteGeneralAsk.value = newData.ask;
            }
            if( Array.isArray( newData.bid ) ){
                quoteGeneralBid.value = newData.bid;
            }
        } catch (error) {
            quoteGeneralAsk.value = [];
            quoteGeneralBid.value = [];
            console.error(error)
        }

        try {
            quoteOrderAsk.value = newData.ask;
            quoteOrderBid.value = newData.bid;
        } catch (error) {
            quoteOrderAsk.value = [];
            quoteOrderBid.value = [];
            console.error(error)
        }
        // console.log(`quoteGeneralAsk data check : ${JSON.stringify(quoteGeneralAsk.value)}`);
    }


    /**
     * 다른 곳에서 store에 접근해 OrderBook 데이터를 바꿀 수 있도록 해주는 함수
     * @param {*} newData 
     */
    function setOrderBookBuyList( newData ){
        try {
            if( newData ){
                quoteGeneralBid.value = newData;    
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 감지된 값이 없을 때 초기화해서 배열을 비우는 로직
     */
    function removeOrderBookBuyList(){
        try {
            quoteGeneralBid.value.length = 0;
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 다른 곳에서 store에 접근해 OrderBook Selled 데이터를 바꿀 수 있도록 해주는 함수
     * @param {*} newData 
     */
    function setOrderBookSellList( newData ){
        try {
            if( newData ){
                quoteGeneralAsk.value = newData;
            } else {
                quoteGeneralAsk.value = [];
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * selectedData의 trade_price를 바꿉니다.
     * @param {*} newData 
     */
    function setTradePrice( newData ){
        if( newData ){
            selectedData.value.trade_price = newData
        }
    }

    /**
     * 감지된 값이 없을 때 초기화해서 배열을 비우는 로직
     */
    function removeOrderBookSellList(){
        try {
            quoteGeneralAsk.value.length = 0;
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * TradedData(오더북에서 보는 마켓 별 체결 내역)
     * @param {*} newData 
     */
    function setTradedData( newData ){
        try {
            if( Array.isArray(newData) ){
                tradedData.value = newData;
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 새롭게 orderBookInterval을 만듭니다.
     * @param {*} newValue 
     */
    function setOrderBookInterval( newValue ){
        orderBookInterval.value = newValue;
    }


    /**
     * 새롭게 Market Coin Pirce를 만듭니다.
     * @param {*} newValue 
     */
    function setMarketCoinQuote( newValue ){
        marketCoinQuote.value = newValue;
    }


    /**
     * 거래 시장 목록을 세팅합니다.
     * @param {*} newValue 
     */
    function setDealMarketList( newValue ){
        try {
            if( Array.isArray(newValue) ){
                dealMarketList.value = newValue.map( item => item.symbol );
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * nft 마켓 리스트(작품, nft의 market list)
     * @param {*} newValue 
     */
    function setNftMarketList( newValue ){
        if( newValue ){
            nftMarketList.value = newValue;
        }
    }


    /**
     * 마켓의 이름으로 한글 이름을 반환하는 함수입니다.
     * @param {*} marketName 
     */
    function getMarketName( marketName ){
        // exchanger.marketList에서 marketName과 일치하는 객체를 찾습니다.
        const targetItem = marketList.value.find(item => item.market === marketName);

        // 찾은 객체에서 name 값을 반환합니다.
        if (targetItem) {
            return targetItem.name;
        } 
        return '&%!nsp';
    }


    /**
     * 최소 주문 금액을 새로 세팅합니다.
     */
    function setMinOrderLimitPrice( newValue ){
        if( newValue ){
            MinOrderLimit.value = newValue;
        }
    }


    /**
     * 최대 주문 금액을 새로 세팅합니다.
     */
    function setMaxOrderLimitPrice( newValue ){
        if( newValue ){
            MaxOrderLimit.value = newValue;
        }
    }


    /**
     * 해당 마켓의 정보를 세팅합니다.
     * @param {*} newValue 
     */
    function setMarketInfomation( newValue ){
        if( newValue ){
            marketInfomation.value = newValue;
        }
    }


    /**
     * logoUrl을 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setSelectedDataLogoUrl( newValue ){
        if( newValue ){
            selectedData.value.logoUrl = newValue;
        }
    }



    /**
     * 토큰 소속 NFT 리스트를 새로 세팅합니다.
     * @param {*} newValue 
     */
    function setNftTokenList( newValue ){
        if( newValue ){
            marketTokenNftList.value = newValue;
        }
    }


    /**
     * 남아 있는 nft 잔량을 알아오는 함수입니다.
     * @param {*} marketName
     */
    const getRemainVolumeForMarketItem = async (marketName) => {
        try {
            const response = {
                success: true,
                data: { result: { value: 10000000 } }
            }
            if (response) {
                itemRemainVolume.value = response?.data.result.value
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 마켓리스트의 값을 세팅합니다.
     * @param {*} newValue 
     */
    function setMarketList( newValue ){
        if( newValue ){
            marketList.value = newValue;
        }
    }


    /**
     * 체결강도의 값을 새롭게 세팅합니다.
     * @param {*} newValue 
     */
    function setSumVolumeData( newValue){
        if( Number( newValue ) || Number( newValue ) === 0 ){
            sumVolumeData.value = newValue;
        }
    }


    /**
     * 호가, 호가 주문 탭의 길이를 저장 (기본값 : 15)
     * @param {*} newValue 
     */
    function setQuoteOrderListLength( newValue ){
        if( Number( newValue ) ){
            quoteOrderListLength.value = newValue;
        }
    }


    return {
        activeOrderPrice,
        changePriceView,
        dailyMarketInfoAfterOpenSocket,
        dealMarketList,
        feeData,
        isitSocketSuccessed,
        itemRemainVolume,
        MinOrderLimit,
        MaxOrderLimit,
        marketCoinQuote,
        marketList,
        marketTokenNftList,
        marketInfomation,
        orderBookInstantDataBlue,
        orderBookInstantDataRed,
        orderBookInterval,
        otherMarketList,
        tradedData,
        marketPriceDataAfterOpenSocket,
        quoteGeneralBid,
        quoteGeneralAsk,
        quoteOrderBid,
        quoteOrderAsk,
        quoteOrderListLength,
        selectedData,
        sumVolumeData,
        changeActiveOrderPrice,
        dataAllChange,
        getMarketName,
        getRemainVolumeForMarketItem,
        initOrderBookMarketDepthData,
        initMarketList,
        initMarketListByTodayInfomation,
        initMarketPriceDataAfterOpenSocket,
        makePriceView,
        removeOrderBookBuyList,
        removeOrderBookSellList,
        setMarketListDataWithFormat,
        setDealMarketList,
        setFeeData,
        setTradedData,
        setTradePrice,
        setMarketCoinQuote,
        setMarketInfomation,
        setMarketList,
        setMaxOrderLimitPrice,
        setMinOrderLimitPrice,
        setNftMarketList,
        setNftTokenList,
        setSelectedDataLogoUrl,
        setSumVolumeData,
        setThisSocketSuccessed,
        setOrderBookBuyList,
        setOrderBookSellList,
        setOrderBookInterval,
        setQuoteOrderListLength,
        updateActiveOrderPrice,
        updateOrderBookInstantUnitBuy,
        updateOrderBookInstantUnitSell,
        updateOrderBookMarketDepthData,
        updateMarketListByNewPrice,
        updateMarketListByTodayInfomation,
    }
})
