<!-- 회원가입 -->
<template>
    <main class="bg-bg2 pa-4 md:mt-[60px]">
        <!-- Join의 Main Component -->
        <article
            v-if="activeJoinTab === 0"
            class="text-center bg-[#fff] md:container md:mx-auto md:w-[1240px] min-h-[650px] md:h-[772px] pt-[102px]"
            id="j-01"
        >
            <div class="__lang subTitle text-noto-500 md:loginbar">회원가입</div>
            <div></div>
            <section class="text-login_join_page_text pa-10 mb-3">
                <div class="__lang">{{ serviceName }}에 오신 것을 환영합니다.</div>
                <div class="__lang">회원가입을 하기 위해서는</div>
                <div class="__lang">본인 명의의 휴대폰 번호 인증이 필요합니다.</div>
            </section>
            <div>
                <form name="form_auth" target="auth_popup" :action="`${backEndUrl}/phone_auth/req`">
                    <!-- <button class="btn_main_green" @click.prevent="auth_type_check()">핸드폰 본인 인증하기</button> -->
                    <button class="__lang btn_main_green default_btn" @click="auth_type_check">
                        휴대폰 본인인증
                    </button>
                    <!-- 주문번호(ordr_idxx) -->
                    <input
                        type="hidden"
                        id="ordr_idxx"
                        name="ordr_idxx"
                        value=""
                        readonly="readonly"
                    />
                    <!-- 요청종류 -->
                    <input type="hidden" name="req_tx" value="cert" />
                    <!-- 요청구분 -->
                    <input type="hidden" name="cert_method" value="01" />
                    <!-- 웹사이트아이디 -->
                    <input type="hidden" name="web_siteid" value="" />
                    <!-- 노출 통신사 default 처리시 아래의 주석을 해제하고 사용하십시요
                SKT : SKT , KT : KTF , LGU+ : LGT
            <input type="hidden" name="fix_commid"      value="KTF"/>
            -->
                    <!-- 사이트코드 -->
                    <input type="hidden" name="site_cd" value="S6186" />
                    <!-- Ret_URL : 인증결과 리턴 페이지 ( 가맹점 URL 로 설정해 주셔야 합니다. ) -->
                    <input type="hidden" name="Ret_URL" v-model="retUrl" id="ret_url" />
                    <!-- cert_otp_use 필수 ( 메뉴얼 참고)
                Y : 실명 확인 + OTP 점유 확인 , N : 실명 확인 only
            -->
                    <input type="hidden" name="cert_otp_use" value="Y" />
                    <!-- cert_enc_use 필수 (고정값 : 메뉴얼 참고) -->
                    <input type="hidden" name="cert_enc_use" value="Y" />
                    <!-- cert_enc_use_ext 필수 (고정값 : 메뉴얼 참고) -->
                    <input type="hidden" name="cert_enc_use_ext" value="Y" />
                    <input type="hidden" name="res_cd" value="" />
                    <input type="hidden" name="res_msg" value="" />

                    <!-- up_hash 검증 을 위한 필드 -->
                    <input type="hidden" name="veri_up_hash" value="" />

                    <!-- 본인확인 input 비활성화 -->
                    <input type="hidden" name="cert_able_yn" value="Y" />

                    <!-- web_siteid 검증 을 위한 필드 -->
                    <input type="hidden" name="web_siteid_hashYN" value="" />

                    <!-- 가맹점 사용 필드 (인증완료시 리턴)-->
                    <input type="hidden" name="param_opt_1" value="opt1" />
                    <input type="hidden" name="param_opt_2" value="opt2" />
                    <input type="hidden" name="param_opt_3" value="opt3" />
                </form>
            </div>
        </article>
        <!-- Join의 Main Component 종료 -->

        <!-- 이용약관  -->
        <article
            v-if="activeJoinTab === 1"
            class="text-center bg-[#fff] md:container md:mx-auto md:w-[1240px] min-h-[630px] md:h-[772px] pt-[102px]"
            id="j-02"
        >
            <div class="__lang subTitle md:loginbar mb-10">이용약관</div>

            <div class="flex flex-col justify-center items-center text-[14px]">
                <span class="flex justify-start items-center j_bar_a w-[366px] pb-2">
                    <img
                        :src="all_agree ? `/asset/img/OkDoneType1.svg` : `/asset/img/OkType1.svg`"
                        style="width: 24px; height: 24px"
                        class="clickable mr-3"
                        @click.prevent="changeAllAgreeOrNot"
                    />
                    <span :class="all_agree ? 'text-main_color text-noto-700' : 'font-medium'"
                        >약관에 모두 동의합니다.</span
                    >
                </span>
            </div>
            <section
                class="flex flex-col justify-center items-center text-[14px] text-gray mt-5 pt-3"
            >
                <div
                    v-for="(item, index) in policyArray"
                    v-bind:key="item"
                    class="flex items-center mb-5"
                >
                    <span class="mr-3">
                        <img
                            :src="
                                checkedState[index]
                                    ? `/asset/img/CheckDoneType1.svg`
                                    : `/asset/img/CheckType1.svg`
                            "
                            style="width: 20px; height: 20px"
                            class="clickable"
                            @click="
                                () => {
                                    if (!checkedState[index]) {
                                        currentViewPolicyIndex = index
                                        if (index !== 0) {
                                            changeTermsDialogTrue()
                                        } else {
                                            toggleImgSelected(index)
                                        }
                                    } else {
                                        toggleImgSelected(index)
                                    }
                                }
                            "
                        />
                    </span>
                    <span
                        :class="checkedState[index] ? 'text-main_color font-medium' : `font-normal`"
                        class="w-[316px] flex justify-start clickable"
                        @click="
                            () => {
                                if (!checkedState[index]) {
                                    currentViewPolicyIndex = index
                                    if (index !== 0) {
                                        changeTermsDialogTrue()
                                    } else {
                                        toggleImgSelected(index)
                                    }
                                } else {
                                    toggleImgSelected(index)
                                }
                            }
                        "
                    >
                        {{ item.title }}
                    </span>
                    <span v-if="index === 0" class="w-[30px] h-[20px]">{{ ' ' }} </span>
                    <span
                        v-else
                        class="w-[30px] h-[20px] clickable"
                        @click="
                            () => {
                                if (!checkedState[index]) {
                                    currentViewPolicyIndex = index
                                    changeTermsDialogTrue()
                                } else {
                                    toggleImgSelected(index)
                                }
                            }
                        "
                    >
                        <img
                            :src="`/asset/img/simpleNextBtn.svg`"
                            style="width: 8px; height: 12px"
                            class="clickable"
                        />
                    </span>
                </div>
            </section>
            <br /><br />
            <div>
                <button
                    v-if="isRequiredItemChecked"
                    :class="['default_btn', 'btn_main_green']"
                    id="j_next_btn"
                    @click.prevent="agreeTermsAndGoNextPage()"
                >
                    다음
                </button>
                <button
                    v-else
                    :class="['default_btn', 'j_next_btn']"
                    id="j_next_btn"
                    @click.prevent
                >
                    다음
                </button>
            </div>
        </article>
        <!-- 이용약관 끝 -->

        <!-- 이메일로 회원가입 step1 -->
        <article
            v-if="activeJoinTab === 2"
            class="text-center bg-[#fff] md:container md:mx-auto md:w-[1240px] min-h-[630px] md:h-[772px] pt-[102px]"
            id="j-03"
        >
            <h1 class="__lang subTitle md:loginbar mb-10">이메일로 회원가입</h1>

            <!-- 이메일 입력 div -->
            <div class="flex items-center justify-center">
                <input
                    v-model="user_email"
                    class="w-[289px] h-[50px] sub_border1 pa-4"
                    type="text"
                    placeholder="이메일을 입력해주세요"
                    :disabled="emailFormCheck === true || isEmailAuthCompleted"
                    :class="emailFormCheck === true || isEmailAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                    @keydown="refreshEmailResponseMsg"
                />
                <button
                    v-if="emailVerifyState === 'ready' && emailValidation.status === true"
                    class="mypage_btn_type1"
                    @click.prevent="
                        () => {
                            emailResponseMsg = ''
                            checkForEmailAuth()
                        }
                    "
                >
                    전송하기
                </button>
                <div
                    v-else
                    class="mypage_btn_type1 text-gray sub_border1 bg-[#F2F2F2] flex justify-center items-center"
                >
                    전송하기
                </div>
            </div>

            <div
                class="w-[360px] mx-auto text-left text-[14px] mt-2"
                :class="emailValidation.status ? 'text-sub_color1' : 'text-red'"
            >
                {{ emailValidation.msg }}
            </div>
            <div
                class="w-[360px] mx-auto text-left text-[14px] mt-2"
                :class="emailAuthVerifyTime > 0 ? 'text-sub_color1' : 'text-red'"
                v-show="emailResponseMsg"
            >
                {{ emailResponseMsg }}
            </div>

            <!-- 이메일 주소가 없는 것을 확인했고 이메일로 인증 메일을 보냈을 때 나타날 영역 -->
            <div
                v-if="hasEmailVerifyDoneCheck"
                class="flex items-center justify-center mt-5 translate-y-[37px]"
            >
                <input
                    v-model="userVerifyNumber"
                    class="w-[289px] h-[50px] sub_border1 pa-4"
                    type="text"
                    placeholder="인증번호를 입력해주세요."
                    :disabled="emailVerifyState === 'ready' || isEmailAuthCompleted"
                    :class="isEmailAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                />
                <button
                    v-if="emailVerifyState === 'send'"
                    class="mypage_btn_type1"
                    @click.prevent="prepareVerifyEmailAuth"
                >
                    인증하기
                </button>
                <button
                    v-else
                    class="mypage_btn_type1 text-gray sub_border1 bg-[#F4F4F4]"
                    @click.prevent
                >
                    인증하기
                </button>
            </div>

            <!-- 이메일 인증까지 다 완료가 되었다면 isEmailAuthCompleted가 true로 바뀌면서 다음 영역으로 넘어갑니다. -->
            <div class="nextBtnArea mt-[340px]">
                <button
                    v-if="isEmailAuthCompleted"
                    :class="['default_btn', 'btn_main_green']"
                    id="j_next_btn"
                    @click.prevent="changeActiveJoinTab(3)"
                >
                    다음
                </button>
                <button
                    v-else
                    :class="['default_btn', 'j_next_btn']"
                    id="j_next_btn"
                    @click.prevent
                >
                    다음
                </button>
            </div>
        </article>
        <!-- 이메일로 회원가입 step1 끝 -->

        <!-- 이메일로 회원가입 step2. 비밀번호 입력 -->
        <article
            v-if="activeJoinTab === 3"
            class="text-center bg-[#fff] md:container md:mx-auto md:w-[1240px] min-h-[630px] md:h-[772px] pt-[102px]"
            id="j-04"
        >
            <h1 class="__lang subTitle md:loginbar mb-10">이메일로 회원가입</h1>
            <div class="w-[368px] flex flex-col mx-auto">
                <div class="relative">
                    <input
                        @focus="
                            () => {
                                isPassword1Focused = true
                                isPassword1FirstChecked = true
                            }
                        "
                        @keydown="preventSpace"
                        v-model="password1"
                        :class="
                            validationForPwd1.length > 0 && isPassword1Focused === true
                                ? 'error'
                                : ''
                        "
                        class="sub_border1 default_btn pa-4"
                        :type="passwordORText ? `text` : 'password'"
                        placeholder="비밀번호를 입력해주세요"
                    />
                    <div
                        class="absolute right-4 bottom-3 clickable"
                        @click.prevent="togglePasswordORText"
                    >
                        <img v-if="passwordORText" src="/asset/img/eye-gray.svg" />
                        <img v-else src="/asset/img/eye-gray-hide.svg" />
                    </div>
                </div>
                <span class="text-left text-[14px] text-market_rise mt-2">{{
                    validationForPwd1 || ''
                }}</span>
            </div>

            <div class="w-[368px] flex flex-col mx-auto mt-[50px] mb-[32px]">
                <div class="relative">
                    <input
                        @focus="
                            () => {
                                isPassword2Focused = true
                                isPassword2FirstChecked = true
                            }
                        "
                        @keydown="preventSpace"
                        v-model="password2"
                        :class="
                            validationForPwd2.length > 0 && isPassword2Focused === true
                                ? 'error'
                                : ''
                        "
                        class="sub_border1 default_btn pa-4"
                        :type="passwordORTextConfirm ? `text` : `password`"
                        placeholder="비밀번호를 한 번 더 입력해주세요"
                    />
                    <div
                        class="absolute right-4 bottom-3 clickable"
                        @click.prevent="togglePasswordORTextConfirm"
                    >
                        <img v-if="passwordORTextConfirm" src="/asset/img/eye-gray.svg" />
                        <img v-else src="/asset/img/eye-gray-hide.svg" />
                    </div>
                </div>
                <span class="text-left text-[14px] text-market_rise mt-2">{{
                    validationForPwd2 || ''
                }}</span>
            </div>

            <div class="w-[360px] text-[14px] text-gray text-left tracking-tighter mx-auto my-3">
                <div class="flex">
                    <img src="/asset/img/register_notice_dot.svg" />
                    <p>최소 8자리 이상이어야 합니다.</p>
                </div>
                <div class="flex translate-y-1">
                    <img src="/asset/img/register_notice_dot.svg" />
                    <p>영문, 숫자, 특수문자를 모두 조합해주세요.</p>
                </div>
                <div class="flex translate-y-2">
                    <img src="/asset/img/register_notice_dot.svg" />
                    <p>특수문자 ~!@#$^%&amp;*()_+={}|;:&lt;,&gt;.?`-[] 만 허용합니다.</p>
                </div>
            </div>

            <div class="nextBtnArea mt-[135px]">
                <button
                    v-if="
                        password1 === password2 &&
                        password1 !== '' &&
                        passwordPattern.test(password1) &&
                        password1.length >= 8
                    "
                    :class="['default_btn', 'btn_main_green']"
                    id="j_next_btn"
                    @click.prevent="prepareJoin()"
                >
                    회원가입
                </button>
                <button
                    v-else
                    :class="['default_btn', 'j_next_btn']"
                    id="j_next_btn"
                    @click.prevent
                >
                    회원가입
                </button>
            </div>
        </article>
        <!-- 이메일로 회원가입 step2. 비밀번호 입력 끝 -->

        <!-- 회원가입 완료 -->
        <article
            v-if="activeJoinTab === 4"
            class="text-center bg-[#fff] md:container md:mx-auto md:w-[1240px] min-h-[630px] md:h-[772px] pt-[102px] tracking-tighter"
            id="j-04"
        >
            <h1 class="__lang subTitle md:loginbar">회원가입 완료</h1>
            <div class="my-5 pt-5">
                <div class="mb-2">회원가입이 완료되었습니다!</div>
                <div>
                    작품 거래를 하기 위해서는 로그인 후
                    <span class="text-sub_color1">지갑 등록</span>이 필요합니다.
                </div>
            </div>
            <div class="translate-y-[100px]">
                <RouterLink to="/login">
                    <button class="btn_main_green default_btn">로그인</button>
                </RouterLink>
            </div>
        </article>
        <!-- 회원가입 끝 -->
    </main>

    <!-- alert용 중첩 가능한 모달, 핸드폰 인증 완료 -->
    <v-dialog v-model="nested_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ nested_title || '' }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    v-if="activeJoinTab < 2 && step === 'ready'"
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="
                        () => {
                            mobileCertificationSuccessed()
                        }
                    "
                >
                    확인
                </v-btn>
                <v-btn
                    v-else-if="activeJoinTab < 2 && step.includes('success')"
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="
                        () => {
                            mobileCertificationSuccessed()
                            changeActiveJoinTab(1)
                        }
                    "
                >
                    확인
                </v-btn>
                <v-btn
                    v-else-if="activeJoinTab === 2"
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
                <v-btn
                    v-else
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- alert용 중첩 가능한 모달, 이용약관 내용 전문 -->
    <v-dialog v-model="terms_dialog" width="650px">
        <v-card class="px-[19px] py-[11px]">
            <v-card-text class="flex flex-col">
                <!-- [필수] 서비스 이용약관 동의 에 대한 내용 -->
                <div v-if="currentViewPolicyIndex === 1" class="text-[15px]">
                    <h1 class="text-[24px] mb-[12px]">{{ serviceName }} 이용약관</h1>
                    <div class="w-[560px] max-h-[600px] overflow-y-scroll sub_border1">
                        <AgreeToTermsOfUse />
                    </div>
                </div>
                <!-- [필수] 개인정보 수집 및 이용 동의 -->
                <div v-else-if="currentViewPolicyIndex === 2" class="text-[15px]">
                    <h1 class="text-[24px] mb-[12px]">{{ serviceName }} 개인정보 처리방침</h1>
                    <div class="w-[560px] max-h-[600px] overflow-y-scroll sub_border1">
                        <PersonalInfoCollectionUsageAgreement />
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12 pr-[24px] mb-[16px]">
                <v-btn
                    class="modal_cancel_btn w-[90px]"
                    color="black"
                    @click="changeTermsDialogFalse()"
                >
                    미동의
                </v-btn>
                <v-btn
                    class="modal_confirm_btn w-[90px]"
                    color="white"
                    @click="
                        () => {
                            toggleImgSelected(currentViewPolicyIndex)
                            changeTermsDialogFalse()
                        }
                    "
                >
                    동의
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeUnmount, onUnmounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { saveMemberDoneAuthPhone, saveMemberDoneAuthEmail, registerCheckPolicy } from '../api/user'
import { sendSignupEmail, sendVerifyEmail } from '../api/auth/email'

// terms component import
import AdultOnly from './terms/AdultOnly.vue'
import EventMarketingAgreement from './terms/EventMarketingAgreement.vue'
import AgreeToReceiveNotificationsSuddenPrice from './terms/AgreeToReceiveNotificationsSuddenPrice.vue'
import AgreeToDesignateExpirationData from './terms/AgreeToDesignateExpirationData.vue'
import AgreeToTermsOfUse from './terms/AgreeToTermsOfUse.vue'
import PersonalInfoCollectionUsageAgreement from './terms/PersonalInfoCollectionUsageAgreement.vue'
import PersonalInfoUsageAgreement from './terms/PersonalInfoUsageAgreement.vue'

import UtilSessionStorage from '../utils/session/index.js'
import Crypto from '../utils/crypto/index.js'
import { preventSpace } from '../utils/utilsFactory'

const sessionStorage = new UtilSessionStorage()

const router = useRouter()

// ref 속성
const serviceName = ref(process.env.VUE_APP_SERVICE_NAME_KOR)
const backEndUrl = ref(process.env.VUE_APP_BACKEND)
const site_cd = ref('')
/**
 * 회원 가입 단계 지정
 * 0 : 휴대폰 인증 페이지
 * 1 : 필수 동의 페이지
 * 2 : 이메일 인증 페이지
 * 3 : 비밀번호 입력 페이지
 * 4 : 회원가입 완료 페이지
 */
const activeJoinTab = ref(0)
const policyArray = getPolicyArray()

const step = ref('ready')

const nested_dialog = ref(false) // 중첩용으로 나오는 작은 모달창
const nested_title = ref('안내')
const nested_msg = ref('') // alert용 모달창 메세지
const terms_dialog = ref(false)
const html_msg = ref('')
const checkedState = ref([]) // 각각의 policy 항목에 대응하는 ref 변수
const currentViewPolicyIndex = ref(0) // 현재 조회한 이용약관의 index를 저장할 변수
const howMuchNecessaryItems = ref(3) // 얼마나 많은 항목들이 필수 항목인지 설정하는 변수

// 모바일 인증 관련
const retUrl = ref('')
const BACKEND_URL = ref('')
const successUserDateset = ref({})

// 이메일 인증 관련
const user_email = ref('')
const emailFormCheck = ref(false) // 이메일 형식 체크에 성공했을 때 true로 변경
const hasEmailVerifyDoneCheck = ref(false)
const emailVerifyCheck = ref(false) // 이메일 인증이 성공적일 때 true로 변경
const emailVerifyState = ref('ready') // 이메일 인증이 ready일 때만 전송하기 활성화하기 위함.
const emailAuthLimitTime = ref(900)
const emailAuthStartTime = ref(null)
const emailAuthVerifyTime = ref(0)
let emailAuthVerifyIntervalId = null
const emailAuthRemainTime = ref('00:00')
const userVerifyNumber = ref('')
const emailResponseMsg = ref('')

// 회원가입 추가 정보
const password1 = ref('')
const password2 = ref('')
const isPassword1Focused = ref(false)
const isPassword2Focused = ref(false)
const isPassword1FirstChecked = ref(false)
const isPassword2FirstChecked = ref(false)
const validationForPwd1 = ref('')
const validationForPwd2 = ref('')
const allowedSpecialChars = '~!@#$^%&*()_+={}|;:<,>.?`[]-'
// const passwordPattern = new RegExp(`^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[${allowedSpecialChars}]).{8,}$`);
const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~!@#$^%&*()_+={}|;:<,>.?`\[\]-]).{1,}$/
const passwordPattern2 = new RegExp(`^[a-zA-Z0-9${allowedSpecialChars}]{8,}$`)
const isPasswordValidationCompleted = computed(() => {
    let result = false
    if (
        passwordPattern.test(password1.value) &&
        passwordPattern.test(password2.value) &&
        password1.value.length > 7 &&
        password2.value.length > 7
    ) {
        result = true
    }
    return result
})

// 비밀번호 - 텍스트 전환, password to text, pwd to text 관련
const passwordORText = ref(false)
const passwordORTextConfirm = ref(false)
/**
 * 비밀번호 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglePasswordORText() {
    passwordORText.value = !passwordORText.value
}
/**
 * 비밀번호 확인 입력 칸의 visible을 토글 기능으로 on off 합니다.
 */
function togglePasswordORTextConfirm() {
    passwordORTextConfirm.value = !passwordORTextConfirm.value
}

// computed 속성
/**
 * 모든 항목이 동의 되었는지 계산하는 computed 속성
 */
const all_agree = computed(() => {
    if (!checkedState.value || !checkedState.value.length || checkedState.value.length < 1) {
        return false
    }
    let result = true
    for (let i = 0; i < checkedState.value.length; i++) {
        if (checkedState.value[i] === false) {
            result = false
        }
    }
    return result
})

/**
 * 필수 항목에 체크가 되어 있는지 아닌지를 계산하는 computed 속성
 */
const isRequiredItemChecked = computed(() => {
    if (!checkedState.value || !checkedState.value.length || checkedState.value.length < 1) {
        return false
    }
    let result = true
    for (let i = 0; i < howMuchNecessaryItems.value; i++) {
        if (checkedState.value[i] === false) {
            result = false
        }
    }
    return result
})

/**
 * 이메일 validation 용 메세지
 */
const emailValidation = computed(() => {
    const result = {
        status: false,
        msg: ''
    }
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (user_email.value) {
        if (re.test(user_email.value)) {
            result.status = true
        } else {
            result.msg = '이메일 주소를 확인해주세요.(특수문자 -_.만 허용합니다.)'
        }
    } else {
        result.msg = ''
    }

    if (user_email.value === '') {
        result.msg = ''
    }
    return result
})

/**
 * 이메일 인증 응답 후 메세지
 */
const emailResponse = computed(() => {
    const result = {
        status: false,
        msg: ''
    }
    return result
})

/**
 * 2개의 input의 validation이 완료되면 다음 버튼을 활성화시킵니다.
 */
const isEmailAuthCompleted = computed(() => {
    let result = false
    if (emailFormCheck.value === true && emailVerifyCheck.value === true) {
        result = true
    }
    return result
})

function toggleImgSelected(index) {
    checkedState.value[index] = !checkedState.value[index]
}

function getPolicyArray() {
    return [
        {
            title: '[필수] 만 19세 이상입니다.',
            neccessary: true,
            command: 'checkAdult',
            components: AdultOnly
        },
        {
            title: '[필수] 서비스 이용약관 동의',
            neccessary: true,
            command: 'checkTermsToUse',
            components: AgreeToTermsOfUse
        },
        {
            title: '[필수] 개인정보 수집 및 이용 동의',
            neccessary: true,
            command: 'checkPrivacyPolicy',
            components: PersonalInfoCollectionUsageAgreement
        }
    ]
}

/**
 * 모달창의 상태 관리
 * 트루로 변하게 하거나 false로 변하게 하는 함수를 아예 분리했습니다.
 */
function changeTermsDialogTrue() {
    terms_dialog.value = true
}
function changeTermsDialogFalse() {
    terms_dialog.value = false
}
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}
function changeNestedMsg(newMsg) {
    nested_msg.value = newMsg
}

function changeAllAgreeOrNot() {
    if (all_agree.value === false) {
        checkedState.value = checkedState.value.map(() => true)
    } else {
        checkedState.value = checkedState.value.map(() => false)
    }
}

/**
 * user에게 display할 컴포넌트를 동적으로 변환하기 위해 사용하는 함수.
 * @param {*} where
 */
function changeActiveJoinTab(where) {
    if (where && where >= 0 && where < 5) {
        activeJoinTab.value = where
    }
}

/**
 * input에 새로운 값이 들어올 때 감지해서 이메일을 통해 받은 validation msg를 초기화합니다.
 */
function refreshEmailResponseMsg() {
    if (emailResponseMsg.value !== '' && emailResponseMsg.value) {
        emailResponseMsg.value = ''
    }
}

// 모바일 인증에 필요한 함수들
function receivePostMsg(event) {
    if (event.origin === `${BACKEND_URL.value}`) {
        const temp = {
            phone:
                event.data.phone && event.data.phone !== 'null' ? event.data.phone : '01012345678',
            birth: event.data.birth && event.data.birth !== 'null' ? event.data.birth : '19901225',
            name: event.data.name && event.data.name !== 'null' ? event.data.name : '홍길동',
            sex: event.data.sex && event.data.sex !== 'null' ? event.data.sex : 'female',
            local: event.data.local && event.data.local !== 'null' ? event.data.local : 'domestic'
        }

        sessionStorage.setItemWithCryptoWithExpireTime('successUserData', temp.phone, 1800)
        successUserDateset.value = temp
        mobileCertification()
    }
}

function auth_type_check() {
    retUrl.value = `${BACKEND_URL.value}/phone_auth/res`
    /* eslint-disable no-else-return */
    var auth_form = document.form_auth

    if (auth_form.ordr_idxx.value === '') {
        alert('주문번호는 필수 입니다.')
        return false
    } else {
        var AUTH_POP
        if (
            (navigator.userAgent.indexOf('Android') > -1 ||
                navigator.userAgent.indexOf('iPhone') > -1) == false
        ) {
            // 스마트폰이 아닌경우
            var return_gubun
            var width = 410
            var height = 500
            var leftpos = screen.width / 2 - width / 2
            var toppos = screen.height / 2 - height / 2
            var winopts =
                'width=' +
                width +
                ', height=' +
                height +
                ', toolbar=yes,status=no,statusbar=no,menubar=no,scrollbars=no,resizable=no'
            var position = ',left=' + leftpos + ', top=' + toppos
            AUTH_POP = window.open('', 'auth_popup', winopts + position)
        }

        auth_form.target = 'auth_popup' // !!주의 고정값 ( 리턴받을때 사용되는 타겟명입니다.)
        auth_form.action = `${process.env.VUE_APP_BACKEND}/phone_auth/req` // 인증창 호출 및 결과값 리턴 페이지 주소

        return true
    }
}

/**
 * 모바일 인증을 위한 함수입니다.
 */
async function mobileCertification() {
    // 0번인 join, main에서 다음 과정으로 넘어갑니다.
    try {
        const phone = sessionStorage.getItemWithCryptoWithExpireTime('successUserData')
        const {
            birth = successUserDateset.value.birth && successUserDateset.value.birth !== 'null'
                ? successUserDateset.value.birth
                : '19901225',
            name = successUserDateset.value.name && successUserDateset.value.name !== 'null'
                ? successUserDateset.value.name
                : '홍길동',
            sex = successUserDateset.value.sex && successUserDateset.value.sex !== 'null'
                ? successUserDateset.value.sex
                : 'female',
            local = successUserDateset.value.local && successUserDateset.value.local !== 'null'
                ? successUserDateset.value.local
                : 'domestic'
        } = successUserDateset.value
        const response = await saveMemberDoneAuthPhone(phone, birth, name, sex)
        if (response?.status === 'success') {
            nested_title.value = '안내'
            step.value = 'mobile_auth_success'
            nested_msg.value = `핸드폰 본인인증이 완료되었습니다.`
            sessionStorage.setItem('joinDepth', '1')
        } else if (
            response?.descript === `Existing member` &&
            response.title.includes('기존회원')
        ) {
            nested_title.value = '안내'
            nested_msg.value = `이미 등록된 휴대폰 번호입니다.<br/>
  같은 번호로 중복 가입을 할 수 없습니다.`
        } else {
            nested_title.value = '승인오류 안내'
            nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
  일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
  잠시 후 다시 시도해주세요.<br/><br/>
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
            sessionStorage.removeStorage('successUserData')
        }
        changeNestedDialogTrue()
    } catch (error) {
        console.error(error)
    }
}

/**
 * 모바일 인증이 완료되었을 때 실행될 함수 입니다.
 */
function mobileCertificationSuccessed() {
    changeNestedDialogFalse()
}

/**
 * 모든 사항에 동의를 하고 난 뒤에 다음 페이지로 넘어가는 함수
 */
async function agreeTermsAndGoNextPage() {
    const phone = sessionStorage.getItemWithCryptoWithExpireTime('successUserData')
    const requestData = {
        phone: phone || '01012345678',
        checkAdult: checkedState.value[0] === true ? 1 : 0,
        checkTermsToUse: checkedState.value[1] === true ? 1 : 0,
        checkPrivacyPolicy: checkedState.value[2] === true ? 1 : 0,
        allowUserInfo: checkedState.value[3] === true ? 1 : 0,
        allowBalanceAlarm: checkedState.value[5] === true ? 1 : 0,
        allowEventAlarm: checkedState.value[4] === true ? 1 : 0,
        allowInfoExpireDate: checkedState.value[6] === true ? 1 : 0
    }
    try {
        registerCheckPolicy(requestData).then((response) => {
            if (response.status === 'success') {
                changeActiveJoinTab(2)
            } else {
                nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
    일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
    잠시 후 다시 시도해주세요.<br/><br/>
    계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
                changeNestedDialogTrue()
            }
        })
    } catch (error) {
        console.error(error)
    }
}

// nice 본인인증 팝업 함수
function handleNiceAuthPopup() {
    const left =
        this.left !== 0 || window.document.body.offsetWidth / 2 - this.width / 2 + window.screenLeft
    const top = this.top !== 0 || window.screen.height / 2 - this.height / 2
    const option = `width=${this.width}, height=${this.height}, top=${top}, left=${left} fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no`
    window.open('/nice/checkplus_pop', 'popupChk', option)
}

/**
 * 이메일 인증을 하기 위해 input에 있는 값을 전송합니다.
 */
async function checkForEmailAuth() {
    if (!user_email.value) {
        return
    }
    try {
        emailAuthVerifyIntervalId = null
        if (emailValidation.value.status === true) {
            // 백엔드에 요청합니다.
            emailVerifyState.value = 'send'
            const response = await sendSignupEmail(user_email.value)
            hasEmailVerifyDoneCheck.value = true
            if (response.data?.status === 'success') {
                emailFormCheck.value = true
                emailAuthStartTime.value = Number(new Date().getTime())
                nested_title.value = '발송 안내'
                nested_msg.value = `인증번호가 정상적으로 발송되었습니다.<br/>
  메일이 오지 않는다면 스팸메일함을 확인하거나<br/>
  1:1 문의를 통해 확인해주세요.`
            } else {
                if (response.data?.title === '기존회원' && Number(response.data?.code) === 20004) {
                    nested_title.value = `안내`
                    emailResponseMsg.value = `이미 사용 중인 이메일 주소입니다.`
                    nested_msg.value = `동일한 이메일 주소를 보유한 회원이 존재하여 가입할 수 없습니다. 이메일 주소를 확인해주세요.`
                } else {
                    nested_title.value = `발송오류 안내`
                    emailResponseMsg.value = `예상하지 못한 오류가 발생했습니다.
  일시적인 현상이거나 네트워크 문제일 수 있으니,
  잠시 후 다시 시도해주세요.
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
                    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
  일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
  잠시 후 다시 시도해주세요.<br/><br/>
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
                }
                emailVerifyState.value = 'ready'
            }
        }
    } catch (error) {
        console.error(error)
        nested_title.value = `발송오류 안내`
        nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
  일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
  잠시 후 다시 시도해주세요.<br/><br/>
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
        emailVerifyState.value = 'ready'
    } finally {
        changeNestedDialogTrue()
    }
}

/**
 * 이메일로 받은 인증번호를 확인합니다.
 */
async function prepareVerifyEmailAuth() {
    const key = userVerifyNumber.value
    try {
        const response = await sendVerifyEmail(user_email.value, key)
        if (response.data.status === 'success') {
            nested_title.value = '안내'
            nested_msg.value = `정상적으로 인증되었습니다.`
            emailVerifyCheck.value = true
            emailResponseMsg.value = ''
        } else if (response.data.title.includes(`인증실패`)) {
            nested_title.value = '안내'
            nested_msg.value = `올바르지 않은 인증번호입니다.<br/>전송하기 버튼을 다시 눌러주세요.`
            // emailAuthVerifyTime.value = 0;
            emailAuthStartTime.value = null
            emailVerifyState.value = 'ready'
            emailFormCheck.value = false
            userVerifyNumber.value = null
            emailResponseMsg.value = `인증번호를 다시 발급받아주세요.(00:00)`
        } else {
            nested_title.value = `승인오류 안내`
            nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
    일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
    잠시 후 다시 시도해주세요.<br/><br/>
    계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
            // emailAuthVerifyTime.value = 0;
            emailAuthStartTime.value = null
            emailVerifyState.value = 'ready'
            emailFormCheck.value = false
            userVerifyNumber.value = null
        }
        clearInterval(emailAuthVerifyIntervalId)
        emailAuthVerifyIntervalId = null
    } catch (error) {
        console.error(error)
        nested_title.value = `승인오류 안내`
        nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
  일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
  잠시 후 다시 시도해주세요.<br/><br/>
  계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
    }

    changeNestedDialogTrue()
}

/**
 * 회원의 아이디와 비밀번호를 가지고 회원가입을 진행합니다.
 */
async function prepareJoin() {
    const email = user_email.value
    const phone = sessionStorage.getItemWithCryptoWithExpireTime('successUserData')

    // TODO 비밀번호 암호화해서 전송할 예정
    // const pw = Crypto.encodingStringByHash(password1.value);
    const pw = password1.value
    try {
        const response = await saveMemberDoneAuthEmail(email, phone, pw)
        if (response.data.status === 'success') {
            changeActiveJoinTab(4)
            sessionStorage.removeStorage('successUserData')
            sessionStorage.removeStorage('joinDepth')
        } else if (response.data.title === `부적합비밀번호`) {
            nested_title.value = '안내'
            nested_msg.value = `올바르지 않은 비밀번호 형식입니다.<br/>글자 수, 조합 형식, 입력할 수 있는 특수문자를 확인한 후 다시 입력해주세요.`
            changeNestedDialogTrue()
        } else {
            nested_title.value = response.data.title
            nested_msg.value = response.data.descriptKor
            changeNestedDialogTrue()
        }
    } catch (error) {
        console.error(error)
    }
}

onMounted(async () => {
    // serviceName.value = process.env.VUE_APP_SERVICE_NAME_KOR;
    step.value = 'ready'

    for (let i = 0; i < policyArray.length; i++) {
        checkedState.value.push(false)
    }

    let level = null
    try {
        level = sessionStorage.getItem('joinDepth')
    } catch (error) {
        console.error(error)
    }

    if (Number(level)) {
        changeActiveJoinTab(Number(level))
    }

    // 테스트용 단계 고정, 추후 삭제 필요
    // changeActiveJoinTab(4)

    watch(emailVerifyState, () => {
        if (emailVerifyState.value === 'send') {
            emailAuthVerifyIntervalId = setInterval(() => {
                if (emailAuthStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(emailAuthStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    emailAuthVerifyTime.value = emailAuthLimitTime.value - tempValue
                    if (emailAuthVerifyTime.value < 1) {
                        clearInterval(emailAuthVerifyIntervalId)
                        emailFormCheck.value = false
                        emailAuthStartTime.value = null
                        // emailAuthVerifyTime.value = 0;
                        emailResponseMsg.value = `인증번호를 다시 발급받아주세요.(00:00)`
                        emailVerifyState.value = 'ready'
                        return
                    }
                    emailAuthRemainTime.value = `${`0${Math.floor(
                        emailAuthVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(emailAuthVerifyTime.value % 60)}`.slice(-2)}`
                    emailResponseMsg.value = `인증번호를 작성해주세요.(${emailAuthRemainTime.value})`
                }
            }, 1000)
        }
    })

    watch(password1, () => {
        if (password1.value === '') {
            validationForPwd1.value = ''
        } else if (!passwordPattern.test(password1.value)) {
            validationForPwd1.value = '비밀번호는 영문, 숫자, 특수문자를 모두 포함해야 합니다.'
        } else {
            validationForPwd1.value = ''
        }
        if (password1.value.length < 8) {
            validationForPwd1.value = '비밀번호는 8자리 이상이어야 합니다.'
        }
    })

    watch(password2, () => {
        nextTick(() => {
            if (password2.value === '') {
                validationForPwd2.value = ''
            } else if (password1.value === password2.value) {
                validationForPwd2.value = ''
            } else {
                validationForPwd2.value = '비밀번호가 일치하지 않습니다.'
            }
        })
    })

    watch(isEmailAuthCompleted, (newVal) => {
        emailVerifyState.value = 'done'
    })

    /**
     * 핸드폰 본인 인증이 완료되었을 때 실행될 함수
     */
    BACKEND_URL.value = process.env.VUE_APP_BACKEND
    document.getElementById('ordr_idxx').value = Date.now()
    retUrl.value = `${BACKEND_URL.value}/phone_auth/res`
    window.addEventListener('message', receivePostMsg)
})

onUnmounted(() => {
    clearInterval(emailAuthVerifyIntervalId)
})
</script>

<style scoped>
.error {
    border: 1px solid var(--market_rise) !important;
}
input:focus {
    outline: none !important;
    border: 1px solid black !important;
}
.error:focus {
    border: 1px solid var(--market_rise) !important;
}
.j-hidden {
    display: none;
}

.j_bar_a {
    border-bottom: 0.4px solid rgba(190, 190, 190, 0.5);
}
</style>
