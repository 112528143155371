<template>
    <main>
        <div>Error Status: {{ errorStatus }}</div>
        <div>Error Message: {{ errorMessage }}</div>
    </main>
</template>

<script setup>
import { useErrorStore } from '@/stores/error/index.js'

const errorStore = useErrorStore()

const errorMessage = errorStore.message
const errorStatus = errorStore.status
</script>
