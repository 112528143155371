<!-- [필수] 개인정보 수집 및 이용 동의 -->
<template>
    <div class="overflow-y-scroll max-w-[552px]">
        <div>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >{{ companyName }}㈜(이하 “회사”라 합니다.)는 「개인정보 보호법」에 따라 정보주체의
                개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기
                위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br /><br />목차<br />개인정보
                처리방침은 다음과 같은 내용으로 구성되어 있습니다.<br /><br />제1조 개인정보 처리
                목적, 처리하는 개인정보 항목, 보유 및 이용 기간<br />제2조 개인정보 제3자 제공에
                관한 사항<br />제3조 개인정보의 위탁에 관한 사항<br />제4조 개인정보의 파기절차 및
                파기방법<br />제5조 정보주체의 권리·의무 및 행사방법<br />제6조 개인정보의 안전성
                확보조치에 관한 사항<br />제7조 자동으로 수집되는 장치의 설치·운영 및 거부방법<br />제8조
                개인정보 보호책임자에 관한 사항<br />제9조 정보주체의 권익침해에 대한 구제방법<br />제10조
                링크 사이트에 대한 책임<br />제11조 개인정보 처리방침의 변경에 관한 사항<br /><br /><br />제1조
                개인정보의 처리 목적, 개인정보 항목, 보유 및 이용 기간<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 정보주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                개인정보를 처리·보유합니다. 다만, 다음 사항에 해당하는 경우에는 해당 사유 종료시까지
                보존합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >다른 법령에서 별도 규정하고 있는 경우, 해당 보유·이용기간까지<br />관계 법령 위반에
                따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지<br />홈페이지
                이용에 따른 채권·채무관계 정산 시까지<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 다음의 목적 이외의 용도로는 사용하지 않으며 이용목적이 변경되는 경우에는
                「개인정보 보호법」 제 18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
                예정입니다.</span
            >
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0001.png"/>

        <div>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에
                동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br />각각의
                개인정보 처리 및 보유 기간은 다음과 같습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회원 가입 및 관리: 회원 탈퇴 시까지<br />재화 또는 서비스 제공: 회원 탈퇴 후 5년<br />이벤트
                정보 안내: 이벤트 및 광고성 정보 제공 종료 즉시 또는 동의 철회 시까지(이벤트 당첨 시
                경품 발송 또는 제공 시까지)<br />고충 및 민원 처리: 민원 처리 종료 후 6개월<br />자동
                생성 및 수집: 회원탈퇴 시까지<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 다음과
                같습니다.</span
            >
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0002.png"/>

        <div
            style="
                color: #222421;
                font-size: 16px;
                font-family: Noto Sans KR;
                font-weight: 300;
                line-height: 28.8px;
                word-wrap: break-word;
            "
        >
            <br />제2조 개인정보의 제3자 제공에 관한 사항<br />회사는 정보주체의 개인정보를
            개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한
            규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
            제공하고, 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.<br /><br />제3조
            개인정보처리의 위탁에 관한 사항<br />1. 회사는 원활한 개인정보 업무처리를 위하여 다음과
            같이 개인정보 처리업무를 위탁하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는
            지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br />2. 회사는 위탁계약
            체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 안전성
            확보조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
            문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고 있습니다.
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0003.png"/>

        <div>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제4조 개인정보의 파기 절차 및 파기방법<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성된
                경우에는 지체없이 해당 개인정보를 파기합니다. 다만, 제1조에서 명시한 바와 같이 다른
                법령에 따라 보존하여야 하는 경우에는 해당 개인정보를 분리하여 별도의 보관장소에
                보존합니다.<br />제1조에서 명시한 개인정보 보유 및 이용 기관 경과, 처리 목적 달성,
                해당 서비스 폐지, 사업 종료 등 개인정보의 처리가 불필요한 것으로 인정되는 날로부터
                지체없이 그 개인정보를 파기합니다. 전자문서는 재생할 수 없도록 파기하며 종이 문서는
                분쇄기로 분쇄하거나 소각하여 파기합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제5조 정보주체의 권리·의무 및 행사방법<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >정보주체(위임 받은 자 등 대리인 포함)는 언제든지 회사가 보유하고 있는 개인정보에
                대하여 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                이용자의 권리를 위임받은 자는 이를 증명할 수 있는 위임장을 제출하여야 합니다.<br />회원정보
                조회/변경, 동의철회/회원탙퇴 등 제1항에 따른 권리행사는
                {{ serviceName }} 서비스(https://khaki.co.kr) 로그인 후 나의정보 메뉴를 이용하시거나
                1:1 문의를 통해 하실 수 있으며, 이에 대해 지체 없이 조치하겠습니다.<br />개인정보
                열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여
                정보주체의 권리가 제한될 수 있습니다.<br />개인정보의 정정 및 삭제 요구는 다른
                법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                없습니다.<br />정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구
                시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인하며 이를 위해 최소한의
                개인정보를 수집할 수 있습니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의
                책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이
                상실될 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제6조 개인정보의 안전성 확보 조치에 관한 사항<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >내부관리계획의 수립 및 시행: 「개인정보 기술적·관리적 보호조치 기준」에 따른
                보호조치 이행을 위하여 세부적인 추진방안을 포함한 내부관리계획을 수립하여
                시행합니다.<br />개인정보 취급자 최소화 및 교육: 개인정보를 취급하는 담당자를
                최소화하여 지정하고 이들을 대상으로 교육을 정기적으로 실시하여 개인정보보호
                인식제고에 힘쓰고 있습니다.<br />개인정보에 대한 접근 제한:
                개인정보처리시스템(정보시스템, DB 포함)에 대한 접근권한의 부여, 변경, 말소를 통하여
                개인정보에 대한 접근통제에 필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                외부로부터의 무단 접근을 통제하고 있습니다.<br />접속기록의 보관 및 위·변조 방지:
                개인정보처리시스템에 접속한 기록(웹로그, DB쿼리 로그 등)을 최소 6개월 이상 보관,
                관리하고 있으며 접속 기록이 위·변조 및 도난, 분실되지 않도록 보안기능을 사용하고
                있습니다.<br />개인정보의 암호화: 이용자의 개인정보 및 인증정보는 전송 시 암호화하여
                보호하고 있으며 이용자 비밀번호 등 법적 암호화 대상 중요 정보를 저장 시 암호화하여
                보관하고 있습니다.<br />해킹 등에 대비한 기술적 대책: 해킹이나 바이러스 등에 의한
                개인정보 유출 및 훼손을 막기 위하여 클라우드 기반의 다양한 보안서비스를 이용하고
                있으며, 이를 통해서 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.<br />비인가자에
                대한 접근 통제: 개인정보가 처리되는 시스템 등은 접근차단시스템을 구축하고 인가된
                개인정보 취급자만 접근이 되도록 절차를 수립·운영하고 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제7조 개인정보를 자동으로 수집하는 장치의 설치·운영 및 거부방법<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’ 또는 토큰 세션
                정보를 사용합니다.<br />회사는 구글 애널리틱스(웹사이트 방문자의 데이터를
                수집·분석하는 웹분석 툴)를 사용할 수도 있습니다. 구글 애널리틱스는 쿠키를 통하여
                당사 웹사이트 이용자의 행태정보를 수집하게 되며, 이 경우 서비스의 이용약관에 따라
                개인을 식별할 수 없는 비식별정보만이 수집됩니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >구글 애널리틱스 수집 정보 저장: 웹사이트 사용과 관련하여 쿠키로 생성된 정보는
                미국에 위치한 서버를 통해 구글에 전달되어 구글에 저장<br />구글 애널리틱스 서비스
                이용약관: </span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >https://marketingplatform.google.com/about/analytics/terms/kr/<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >구글 애널리틱스 개인정보 처리방침: </span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >https://policies.google.com/privacy?hl=kr<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >구글 애널리틱스 차단 브라우저 부가기능의 설치: </span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >https://tools.google.com/dlpage/gaoptout<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 소량의
                정보이며 이용자 기기 내의 하드디스크에 저장되기도 합니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >쿠키의 사용목적: 이용자의 접속빈도나 방문시간 등을 파악하여 이용자에게 보다 편리한
                서비스를 제공하기 위해 사용됩니다.<br />쿠키의 설치·운영 및 거부: 브라우저 옵션
                설정을 통해 쿠키 허용, 쿠키 차단 등의 설정을 할 수 있습니다.<br />쿠키 저장을 거부
                또는 차단할 경우 서비스 이용에 어려움이 발생할 수 있습니다.<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >이용자의 토큰 세션 정보는 이용자 세션 관리 용도로만 사용되며 브라우저별 설정 기간에
                따라 보관 및 삭제 처리됩니다. 토큰 세션 정보 삭제를 원하는 경우 이용자가
                브라우저에서 직접 삭제하실 수 있습니다.</span
            >
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0004.png"/>

        <div>
            <span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                ><br />제8조 개인정보 보호책임자에 관한 사항<br /></span
            ><span
                style="
                    color: #222421;
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 300;
                    line-height: 28.8px;
                    word-wrap: break-word;
                "
                >회사는 개인정보를 보호하고 개인정보와 관련된 불만을 처리하기 위하여 아래와 같이
                개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.<br />정보주체는 개인정보
                보호법 제35조에 따른 개인정보의 열람 청구를 개인정보 열람청구 접수·처리 부서에 할 수
                있습니다. 정보주체는 {{ serviceName }} 1:1 문의를 통해서도 개인정보 열람·청구를 하실
                수 있습니다.</span
            >
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0005.png"/>

        <div
            style="
                color: #222421;
                font-size: 16px;
                font-family: Noto Sans KR;
                font-weight: 300;
                line-height: 28.8px;
                word-wrap: break-word;
            "
        >
            <br />제9조 정보주체의 권익침해에 대한 구제방법<br />정보주체는 아래의 기관에 대해
            개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는
            별개의 기관으로서 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나
            보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        </div>

        <img src="/asset/img/personalInfoCollectionUsageAgreementImgs/0006.png"/>

        <div
            style="
                color: #222421;
                font-size: 16px;
                font-family: Noto Sans KR;
                font-weight: 300;
                line-height: 28.8px;
                word-wrap: break-word;
            "
        >
            <br />제10조 링크 사이트에 대한 책임<br />{{ serviceName }} 웹사이트는 다른 외부
            사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트에 대한 통제권이
            없으므로 이용자가 외부사이트로부터 제공받는 서비스나 자료의 유효성, 진실성, 적법성에
            대해 책임 및 보증할 수 없으며, 링크된 외부 사이트의 개인정보 처리방침은 회사와
            무관하므로 해당 외부사이트의 정책을 확인하시기 바랍니다.<br /><br />제11조 개인정보
            처리방침의 변경에 관한 사항<br />본 방침은 2023년 11월 25일부터 시행됩니다.
        </div>
    </div>
</template>
