import JoinView from '../../pages/JoinView.vue'
import FindPwd from '../../pages/FindPwd.vue'

export default [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/LoginView.vue')
    },
    {
        path: '/join',
        name: 'join',
        component: JoinView
    },
    {
        path: '/member/findPwd',
        name: 'FindPwd',
        component: FindPwd
    }
]
