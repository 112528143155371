<!-- 간단한 메시지용 모달 -->
<script setup>
import { ref, onMounted, watch } from 'vue'

const modelValue = ref(new Date())

const props = defineProps({
    model: {
        type: String
    },
    changeNestedDialog: {
        type: Function
    },
    setDateValue: {
        type: Function
    },
    modalType: {
        type: String
    },
    goToNextStep: {
        type: Function
    }
})

onMounted(() => {

})

function makeDate(){
    let temp = null;
    try {
        const monthMapping = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        }

        if (modelValue.value[0]) {
            temp = String(modelValue.value[0]).split(' ')
            
        } else {
            temp = String(modelValue.value).split(' ')
        }
        const year = temp[3]
        const month = monthMapping[temp[1]]
        const day = temp[2]
        const date = temp[0]
        if (!year || !month || !day || !date) {
            props.changeNestedDialog()
            return
        }
        const result = `${year}.${month}.${day}`

        props.setDateValue(result)
    } catch (error) {
        console.error(error)
    }
}

function saveAndGoNext(){
    makeDate();
    props.goToNextStep();
}

function saveDate() {
    makeDate();

    props.changeNestedDialog()
}
</script>

<template>
    <v-card class="pa-0">
        <v-card-text>
            <v-locale-provider locale="ko">
                <v-date-picker
                    :click:cancel="[props.changeNestedDialog]"
                    v-model="modelValue"
                    input-mode="calendar"
                    reactive="true"
                    title="     "
                    hide-actions
                >
                </v-date-picker>
            </v-locale-provider>
        </v-card-text>
        <v-card-actions class="justify-end pa-7">
            <div>
                <v-btn
                    class="modal_cancel_btn border w-[90px] h-[43px]"
                    @click.prevent="props.changeNestedDialog"
                >
                    취소
                </v-btn>
                <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="saveDate"> 확인 </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<style scoped>
.v-date-picker-header__append {
    display: none !important;
}
</style>
