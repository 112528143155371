import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useFiatStore = defineStore('fiat', () => {
    const isUseFiatCurrency = ref(0);

    function setFiatCurrency( newData ){
        isUseFiatCurrency.value = newData
    }

    return {
        isUseFiatCurrency,
        setFiatCurrency,
    }
})