<!-- 입출금 페이지 -->
<template>
    <main class="flex justify-center trading-monitor pt-4">
        <section class="left-tab mr-4 -translate-x-12">
            <ul>
                <li
                    class="clickable __lang"
                    :class="
                        $route.params.type === 'deposit' || !$route.params.type
                            ? 'active bg-main_color shadow-green_tab text-white'
                            : 'bg-[#fff]'
                    "
                    @click.prevent="moveToDepositPage"
                >
                    입금
                </li>
                <li v-if="isUseFiatCurrency === 1"
                    class="clickable __lang"
                    :class="
                        $route.params.type === 'withdraw' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'
                    "
                    @click.prevent="moveToWithdrawPage"
                >
                    출금
                </li>
                <li v-else 
                class="clickable __lang"
                    :class="
                        $route.params.type === 'withdraw' ? 'active bg-main_color shadow-green_tab text-white' : 'bg-[#fff]'
                    "
                    @click.prevent="moveToWithdrawPage"
                >
                    출금
                </li>
            </ul>
        </section>
        <section class="-translate-x-12">
            <WalletChargeWithdraw />
        </section>

        <!-- alert용 중첩 가능한 모달, 취소 및 확인 버튼 -->
        <v-dialog v-model="confirm_dialog" width="470px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ modal_title }}
                    </div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        :class="`modal_cancel_btn`"
                        color="black"
                        @click="changeConfirmDialogFalse"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        :class="`modal_confirm_btn`"
                        :style="{ width: btn_width + 'px' }"
                        color="white"
                        @click="whatShouldModalDo('본인인증')"
                    >
                        {{ confirm_btn || '' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </main>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import WalletChargeWithdraw from '../components/deposit/WalletChargeWithdraw.vue'

// pinia store import
import { useUserStore } from '@/stores/user'

// api import
import { selectFiatMarketFlag } from '@/api/fiat/index.js'

// utils import
import UtilSessionStorage from '@/utils/session'

// 세션 유틸 사용을 위한 객체 생성
const sessionStorage = new UtilSessionStorage();

// 라우터 인스턴스 주입
const router = useRouter()

// 실제 법정화폐(예 : 원화)를 사용하는지 판단하는 변수
const isUseFiatCurrency = ref(0);

// pinia 인스턴스 주입
const userStore = useUserStore()

const level = ref(0)

// 모달 컨트롤을 위한 변수
const confirm_dialog = ref(false)
const modal_title = ref('')
const nested_msg = ref('')
const confirm_btn = ref('확인')
const btn_width = ref(123)
function changeConfirmDialogTrue() {
    confirm_dialog.value = true
}
function changeConfirmDialogFalse() {
    confirm_dialog.value = false
}

/**
 * 출금탭에 가기 전에 요청 받은 내용을 바탕으로 출금탭에 존재할지 말지 판단하는 함수
 */
function preventCheckToIntoWithdraw() {
    level.value = userStore.getUserSecurityLevel()
    if (level.value === '1' || level.value === 1) {
        confirm_btn.value = `본인인증하기`
        btn_width.value = 149
        modal_title.value = `보안설정 안내`
        nested_msg.value = `본인인증이 완료되지 않아 입출금에 제한이 있습니다.<br/><br/>
[제한내용]<br/>
<span class="text-market_rise">- 원화, 가상자산, 작품 입금불가</span><br/>
<span class="text-market_rise">- 원화, 가상자산, 작품 출금불가</span><br/><br/>
본인인증이 완료되면 입출금한도를 증가시킬 수 있습니다.<br/>
보안설정 페이지로 이동하시겠습니까?<br/><br/>
창을 닫으면 입금 페이지로 이동합니다.`
        changeConfirmDialogTrue()
    } else if (level.value === '2' || level.value === 2) {
        confirm_btn.value = `계좌 등록`
        btn_width.value = 123
        modal_title.value = `보안설정 안내`
        nested_msg.value = `원화계좌가 등록되지 않아 입출금에 제한이 있습니다.<br/><br/>
[제한내용]<br/>
<span class="text-market_rise">- 원화 입출금불가</span><br/>
- 가상자산 & 작품: 1일 출금한도 1천만원<br/><br/>
원화계좌 등록이 완료되면 입출금한도를 증가시킬 수 있습니다.<br/>
계좌 등록 페이지로 이동하시겠습니까?<br/><br/>
창을 닫으면 입금 페이지로 이동합니다.`
        changeConfirmDialogTrue()
    }
}

// 입금 페이지 진입 함수
function moveToDepositPage(){
    router.push({ name: 'BalanceType', params: { type : 'deposit' } })
}

// 출금 페이지 진입 함수
function moveToWithdrawPage(){
    router.push({ name: 'BalanceType', params: { type : 'withdraw' } })
}

function whatShouldModalDo(command) {
    if (command === '본인인증') {
        changeConfirmDialogFalse()
        router.push({ path: '/mypage/userAuth' })
    }
}

onMounted(async ()=>{
    const userId = sessionStorage.getUserId();
    const result = await selectFiatMarketFlag( { id : userId } );

    if( result === 1 ){
        isUseFiatCurrency.value = 1;
    }
})
</script>
