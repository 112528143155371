<!-- 마이페이지 -> 2채널 인증 -->
<template>
    <main>
        <div class="securifySetting01 w-[1030px] bg-[#fff] basicBoxShadow">
            <!-- title 영역 -->
            <div class="tm1 border-b px-4 py-2">
                <p class="__lang subTitle text-noto-700 flex">2채널 인증</p>
            </div>

            <!-- content 영역, 2채널 인증 main 화면 -->
            <section
                v-if="isEnteredGoogleAuth === false || !isEnteredGoogleAuth"
                class="securifySetting02 w-[1030px] min-h-[750px] bg-[#fff] basicBoxShadow flex flex-col items-center mb-5"
            >
                <br />
                <!-- title -->
                <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">2채널 인증</h1>
                <!-- underbar -->
                <div class="w-[500px] border-b mb-5"></div>
                <br />
                <p class="__lang text-[14px] mb-2">
                    2채널 인증(카카오페이 인증, 구글 어센티케이터 인증)을 통해
                </p>
                <p class="__lang text-[14px] mb-2">
                    로그인, 가상자산 출금, 작품 출금, 이메일 변경, 비밀번호
                </p>
                <p class="__lang text-[14px]">
                    변경 및 재설정 등의 서비스를 더욱 안전하게 이용할 수 있습니다.
                </p>
                <br /><br />
                <span class="flex mt-3">
                    <div
                        class="w-[282px] h-[50px] contents_primary text-noto-700 border pl-5 mb-3 flex items-center"
                    >
                        카카오페이 인증
                    </div>
                    <button
                        v-if="!hasKakaoAuth"
                        class="__lang mypage_btn_type2"
                        @click.prevent="$router.push({ path: 'securitySetting/kakao' })"
                    >
                        인증하기
                    </button>
                    <button
                        v-else
                        class="__lang mypage_btn_type1"
                        @click.prevent="prepareResetKakaoAuthPage"
                    >
                        초기화
                    </button>
                </span>
                <span class="flex">
                    <div
                        class="w-[282px] h-[50px] contents_primary text-noto-700 border pl-5 mb-3 flex items-center"
                    >
                        구글 어센티케이터 인증
                    </div>
                    <button
                        v-if="!hasOtpAuth"
                        class="__lang mypage_btn_type2"
                        @click.prevent="enterGoogleAuthPage"
                    >
                        인증하기
                    </button>
                    <button
                        v-else-if="hasOtpAuth"
                        class="__lang mypage_btn_type1"
                        @click.prevent="prepareResetGoogleAuthPage"
                    >
                        초기화
                    </button>
                </span>
            </section>
            <!-- content 영역, 보안설정 main 화면 끝 -->

            <!-- 구글 어센티케이터 인증 -->
            <article v-else>
                <!-- 아티클 작성 -->
                <section class="flex">
                    <!-- 1번 엠블렘, 모바일 앱 설치 -->
                    <div
                        :class="isitActice === 1 ? 'active shadow-auth_tab' : ''"
                        class="w-1/4 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
                    >
                        <div v-if="isitChecked(1)"><CheckedIcon /></div>
                        <div v-else>
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z"
                                    stroke="white"
                                />
                                <path
                                    d="M13.7513 25V22.62H16.9313V13.26H14.2513V11.44C15.7313 11.16 16.7513 10.78 17.6913 10.18H19.8713V22.62H22.6513V25H13.7513Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <p class="__lang">모바일 앱 설치</p>
                    </div>
                    <!-- 1번 엠블렘 종료 -->

                    <!-- 2번 엠블렘, 신분증 인증 -->
                    <div
                        :class="isitActice === 2 ? 'active shadow-auth_tab' : ''"
                        class="w-1/4 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
                    >
                        <div v-if="isitChecked(2)"><CheckedIcon /></div>
                        <div v-else>
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z"
                                    stroke="black"
                                />
                                <path
                                    d="M12.9713 25V23.3C16.9913 19.7 19.3713 16.92 19.3713 14.64C19.3713 13.14 18.5713 12.24 17.1513 12.24C16.0713 12.24 15.1913 12.94 14.4313 13.76L12.8313 12.16C14.1913 10.7 15.5113 9.92 17.5313 9.92C20.3313 9.92 22.1913 11.72 22.1913 14.48C22.1913 17.16 19.9713 20.04 17.2713 22.68C18.0113 22.6 18.9913 22.52 19.6913 22.52H22.8913V25H12.9713Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <p class="__lang">QR코드 스캔</p>
                    </div>
                    <!-- 2번 엠블렘 종료 -->

                    <!-- 3번 엠블렘, 계좌 1원 인증 -->
                    <div
                        :class="isitActice === 3 ? 'active shadow-auth_tab' : ''"
                        class="w-1/4 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
                    >
                        <div v-if="isitChecked(3)"><CheckedIcon /></div>
                        <div v-else>
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z"
                                    stroke="#222421"
                                />
                                <path
                                    d="M17.5913 25.28C15.2513 25.28 13.6713 24.4 12.6313 23.22L14.0113 21.36C14.8913 22.22 15.9713 22.88 17.3113 22.88C18.8113 22.88 19.8313 22.14 19.8313 20.8C19.8313 19.36 18.9713 18.44 15.7513 18.44V16.34C18.4713 16.34 19.3513 15.4 19.3513 14.08C19.3513 12.92 18.6313 12.24 17.3713 12.24C16.3113 12.24 15.4513 12.72 14.5713 13.54L13.0713 11.74C14.3513 10.62 15.7713 9.92 17.5113 9.92C20.3713 9.92 22.3113 11.34 22.3113 13.86C22.3113 15.46 21.4113 16.62 19.8513 17.24V17.34C21.5113 17.8 22.7913 19.04 22.7913 20.98C22.7913 23.7 20.4113 25.28 17.5913 25.28Z"
                                    fill="#222421"
                                />
                            </svg>
                        </div>
                        <p class="__lang">백업코드 저장</p>
                    </div>
                    <!-- 3번 엠블렘 종료 -->

                    <!-- 4번 엠블렘, 거주지 정보 작성 -->
                    <div
                        :class="isitActice === 4 ? 'active shadow-auth_tab' : ''"
                        class="w-1/4 bg-ex_RightA_label flex flex-col justify-center h-52 items-center text-noto-700"
                    >
                        <div v-if="isitChecked(4)"><CheckedIcon /></div>
                        <div v-else>
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.84456 9.03868L17.5 0.57735L32.1554 9.03868V25.9613L17.5 34.4226L2.84456 25.9613V9.03868Z"
                                    stroke="#222421"
                                />
                                <path
                                    d="M15.3913 18.92H18.8513V15.9C18.8513 15.04 18.9113 13.7 18.9513 12.82H18.8713C18.5113 13.62 18.1113 14.44 17.6913 15.24L15.3913 18.92ZM18.8513 25V21.16H12.5313V19.16L18.0513 10.18H21.5913V18.92H23.3313V21.16H21.5913V25H18.8513Z"
                                    fill="#222421"
                                />
                            </svg>
                        </div>
                        <p class="__lang">구글 어센티케이터 등록</p>
                    </div>
                    <!-- 4번 엠블렘 종료 -->
                </section>
                <!-- 엠블럼 영역 종료 -->

                <!-- 모바일 앱 설치 영역, -->
                <section
                    v-if="isitActice === 1"
                    class="w-[1030px] h-[646px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
                >
                    <br />
                    <!-- title -->
                    <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">
                        모바일 앱 설치
                    </h1>
                    <!-- underbar -->
                    <div class="w-[500px] border-b mb-5"></div>
                    <br />
                    <div class="text-center py-2 mb-[50px]">
                        <p class="__lang text-[14px] mb-2">
                            'Google Authenticator' 앱을 설치해주세요.
                        </p>
                        <p class="__lang text-[14px]">
                            구글 플레이 또는 앱스토어에서 설치할 수 있습니다.
                        </p>
                    </div>

                    <div class="flex justify-between w-[350px] mb-[80px]">
                        <div class="flex flex-col items-center">
                            <img
                                src="/asset/img/googleAuthentic.svg"
                                style="width: 135px; height: 135px"
                            />
                            <span>Google Authenticator</span>
                        </div>
                        <div>
                            <div>
                                <div class="clickable">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko-KR"
                                        target="_blank"
                                    >
                                        <img src="/asset/img/playstoreLinkBtn.svg" />
                                    </a>
                                </div>
                                <div class="clickable">
                                    <a
                                        href="https://apps.apple.com/kr/app/google-authenticator/id388497605"
                                        target="_blank"
                                    >
                                        <img src="/asset/img/appstoreLinkBtn.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="nextBtnArea my-5 mb-15">
                        <button
                            :class="['default_btn', 'btn_main_green']"
                            @click.prevent="moveToQrCodeScan"
                        >
                            다음
                        </button>
                    </div>
                </section>
                <!-- 모바일 앱 설치 영역, 끝 -->

                <!-- QR코드 스캔 영역 -->
                <section
                    v-if="isitActice === 2"
                    class="w-[1030px] h-[646px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
                >
                    <br />
                    <!-- title -->
                    <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">
                        QR코드 스캔
                    </h1>
                    <!-- underbar -->
                    <div class="w-[500px] border-b mb-5"></div>
                    <br />
                    <div class="text-center py-2 mb-[50px]">
                        <p class="__lang text-[14px] mb-2">
                            'Google Authenticator' 앱을 실행해주세요.
                        </p>
                        <p class="__lang text-[14px]">
                            아래의 QR코드를 스캔하거나 설정 키를 입력해주세요.
                        </p>
                    </div>

                    <div class="flex flex-col justify-between items-center w-[350px] mb-[50px]">
                        <div id="qrcode" v-if="qrcodeLoading" class="mb-3">
                            <qrcode-vue :value="qrcodeRef" :size="qrcodeSize" level="H" />
                        </div>
                        <div class="px-5 h-[50px] flex justify-center items-center bg-[#F2F2F2]">
                            {{ qrcodeSecretKey || '' }}
                        </div>
                    </div>

                    <div class="nextBtnArea my-5 mb-15">
                        <button
                            :class="['default_btn', 'btn_main_green', 'mb-2']"
                            @click.prevent="moveToSaveBackUpCode"
                        >
                            다음
                        </button>
                        <h6
                            class="underline contents_primary text-gray clickable"
                            @click="moveToMobileAppSetup"
                        >
                            이전 단계로
                        </h6>
                    </div>
                </section>
                <!-- QR코드 스캔 영역 끝 -->

                <!-- 백업코드 저장 영역 -->
                <section
                    v-if="isitActice === 3"
                    class="w-[1030px] h-[646px] bg-[#fff] basicBoxShadow flex flex-col justify-center items-center mb-5"
                >
                    <br />
                    <!-- title -->
                    <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">
                        백업코드 저장
                    </h1>
                    <!-- underbar -->
                    <div class="w-[500px] border-b mb-5"></div>
                    <br />
                    <div class="text-center py-2 mb-[50px]">
                        <p class="__lang text-[14px] mb-2">백업코드를 안전한 곳에 저장하세요.</p>
                        <p class="__lang text-[14px]">
                            휴대폰을 분실했을 때 이 코드로 구글 어센티케이터를 복구할 수 있습니다.
                        </p>
                    </div>

                    <div class="flex items-center w-[350px] mb-[200px]">
                        <input
                            class="w-[289px] h-[50px] pa-3 px-4 text-[14px] bg-[#F2F2F2]"
                            :value="backUpCode"
                            id="backUpCode"
                            disabled
                            readonly
                        />
                        <div
                            class="w-[77px] h-[50px] clickable flex justify-center items-center bg-[#fff] sub_border1 contents_primary text-main_color"
                            @click="copyBackUpCodeToClipboard"
                        >
                            복사하기
                        </div>
                    </div>

                    <div class="nextBtnArea my-5 mb-15">
                        <button
                            :class="['default_btn', 'btn_main_green', 'mb-2']"
                            @click.prevent="moveToGoogleAuthenticatorSignUp"
                        >
                            다음
                        </button>
                        <h6
                            class="underline contents_primary text-gray clickable"
                            @click="moveToQrCodeScan"
                        >
                            이전 단계로
                        </h6>
                    </div>
                </section>
                <!-- 백업코드 저장 영역 끝 -->

                <!-- 구글 어센티케이터 등록 영역 -->
                <section
                    v-if="isitActice === 4"
                    class="w-[1030px] h-[646px] bg-[#fff] basicBoxShadow flex flex-col items-center mb-5"
                >
                    <br />
                    <!-- title -->
                    <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">
                        구글 어센티케이터 등록
                    </h1>
                    <!-- underbar -->
                    <div class="w-[500px] border-b mb-5"></div>
                    <br />
                    <div class="text-center mb-[50px]">
                        <p class="__lang text-[14px] mb-2">전송하기 버튼을 눌러주세요.</p>
                        <p class="__lang text-[14px]">
                            가입하신 이메일 주소로 인증번호를 발송합니다.
                        </p>
                    </div>

                    <div class="flex flex-col items-center w-[500px] min-h-[200px] mb-[200px] mb-5">
                        <div class="mb-7">
                            <label class="text-[14px] mb-2">이메일 인증번호</label>
                            <div class="flex mt-2">
                                <input
                                    v-if="googleOTPStatus === 'ready' || googleOTPStatus === 'wait'"
                                    class="w-[289px] h-[50px] pa-3 px-4 text-[14px] bg-[#f2f2f2] sub_border1 text-gray"
                                    :value="userEmailId"
                                    id="userEmailId"
                                    disabled
                                    readonly
                                />
                                <input
                                    v-else-if="
                                        googleOTPStatus === 'send' || googleOTPStatus === 'done'
                                    "
                                    class="w-[289px] h-[50px] pa-3 px-4 text-[14px] bg-[#fff] sub_border1"
                                    v-model="emailVerifyCode"
                                    maxlength="8"
                                    placeholder="인증번호를 입력해주세요."
                                    :disabled="googleOTPStatus === 'done'"
                                />
                                <div
                                    v-if="googleOTPStatus === 'ready'"
                                    class="w-[77px] h-[50px] clickable flex justify-center items-center bg-main_color sub_border1 contents_primary text-white"
                                    @click="
                                        () => {
                                            emailVerifyCode = null
                                            prepareSendEmailAuth()
                                        }
                                    "
                                >
                                    전송하기
                                </div>
                                <div
                                    v-else-if="googleOTPStatus === 'send'"
                                    class="w-[77px] h-[50px] clickable flex justify-center items-center bg-main_color sub_border1 contents_primary text-white"
                                    @click.prevent="prepareVerifyEmailAuth"
                                >
                                    인증하기
                                </div>
                                <div
                                    v-else-if="googleOTPStatus === 'done'"
                                    class="w-[77px] h-[50px] flex justify-center items-center bg-[#f4f4f4] sub_border1 contents_primary text-gray"
                                >
                                    인증완료
                                </div>
                                <div
                                    v-else
                                    class="w-[77px] h-[50px] flex justify-center items-center bg-[#f4f4f4] sub_border1 contents_primary text-gray"
                                >
                                    전송하기
                                </div>
                            </div>
                            <div
                                v-if="googleOTPStatus !== 'ready' && Number(authVerifyTime) > 0"
                                class="mx-auto text-left text-[14px] mt-2 text-[#0A8A1D] mb-4 text-sub_color1"
                            >
                                인증번호를 작성해주세요.({{ authRemainTime }})
                            </div>
                            <div
                                v-else-if="
                                    googleOTPStatus !== 'ready' &&
                                    googleOTPStatus !== 'wait' &&
                                    Number(authVerifyTime) <= 0
                                "
                                class="mx-auto text-left text-[14px] mt-2 text-[#0A8A1D] mb-4 text-red"
                            >
                                인증번호를 다시 발급받아주세요.(00:00)
                            </div>
                        </div>

                        <div>
                            <label class="text-[14px] mb-2">구글 어센티케이터 인증코드</label>
                            <div class="mt-2 mb-5">
                                <input
                                    :value="googleAuthenticatorEmailAuthCode"
                                    @input="handleOnGoogleAuthenticatorEmailAuthCode"
                                    class="w-[366px] h-[50px] pa-3 px-4 text-[14px] bg-[#fff] sub_border1"
                                    id="googleAuthenticatorEmailAuthCode"
                                    placeholder="인증코드 6자리를 입력해주세요"
                                    maxlength="6"
                                    @keydown="handlePhoneNumberKeydown"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="nextBtnArea my-5 mb-15">
                        <div
                            v-if="isitGoogleAuthenticatorAuthCompleted === false"
                            :class="[
                                'default_btn',
                                'bg-stroke text-white',
                                'flex justify-center items-center mb-2'
                            ]"
                        >
                            등록하기
                        </div>
                        <button
                            v-else
                            :class="['default_btn', 'btn_main_green', 'mb-2']"
                            @click.prevent="completeGoogleAuthenticatorSignUp"
                        >
                            등록하기
                        </button>
                        <h6
                            class="underline contents_primary text-gray clickable"
                            @click="moveToSaveBackUpCode"
                        >
                            이전 단계로
                        </h6>
                    </div>
                </section>
                <!-- 구글 어센티케이터 등록 영역 끝 -->
            </article>
            <!-- 구글 어센티케이터 인증 끝 -->
        </div>
    </main>

    <!-- alert용 모달 -->
    <v-dialog v-model="nested_dialog" persistent width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    v-if="step !== 'finished' && step !== 'needRefresh'"
                    class="modal_cancel_btn border w-[77px] h-[43px]"
                    color="black"
                    @click="changeNestedDialogFalse"
                >
                    취소
                </v-btn>
                <v-btn
                    v-if="step === 'phoneAuth'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="prepareUpdatePhone"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else-if="step === 'googleOTP'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="enterGoogleAuthPage"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else-if="step === 'clearKakaoAuth'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="()=>{
                      changeNestedDialogFalse();
                      startProcess = 1;
                      whatItemChangeFor = 'kakao';
                      doubleChannelDialog = true;
                    }"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else-if="step === 'clearOtpAuth'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="()=>{
                      changeNestedDialogFalse();
                      startProcess = 2;
                      whatItemChangeFor = 'otp'
                      doubleChannelDialog = true;
                    }"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else-if="step === 'needRefresh'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="reloadPage"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else-if="step === 'finished'"
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click="async ()=>{
                        // TODO 유저의 지갑 주소가 있는지 판단이 된 상태라면 지갑 주소가 있다면 이 로직을 시행, 아니라면 닫고 새로고침
                        const response = await checkUserWalletAddress();
                        changeNestedDialogFalse();
                        if( response ){
                            page_change_dialog = true;
                        } else {
                            reloadPage();
                        }
                    }"
                >
                    {{ modal_btn_msg }}
                </v-btn>
                <v-btn
                    v-else
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click.prevent="changeNestedDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 입출금 페이지 이동 모달 -->
    <v-dialog v-model="page_change_dialog" width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ '안내' }}
                </div>
                <br />
                <div class="text-[15px]">
                    2채널 인증이 완료되어 입출금이 가능합니다. 입출금 페이지로 이동하시겠습니까?
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="modal_cancel_btn border w-[77px] h-[43px]"
                    color="black"
                    @click="()=>{
                        page_change_dialog = false;
                        reloadPage();
                    }"
                >
                    닫기
                </v-btn>
                <v-btn
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click.prevent="()=>{
                        page_change_dialog = false;
                        $router.push({ path : `/balance/deposit` });
                    }"
                >
                    이동하기
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- alert용 모달 -->
    <v-dialog v-model="alert_dialog" width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div>
                <br />
                <div class="text-[15px]" v-html="nested_msg"></div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click.prevent="changeAlertDialogFalse"
                >
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 2채널 인증 모달 -->
    <SecurityAuthModal 
        :whatChangeFor="whatItemChangeFor"
        :dialog="doubleChannelDialog"    
        :changeDialog="changeDoubleChannelDialog"
        :authKakao="hasKakaoAuth ? 1 : 0"
        :authOtp="hasOtpAuth ? 1 : 0"
        :startProcess="startProcess"
        :initStartProcess="initStartProcess"
        :afterCompleteAction="afterCompleteAction"
        :afterFailAction="afterFailAction"
    />

    <!-- 복사 완료시 표시하기 위한 토스트 알림 -->
    <div class="text-center ma-2">
        <v-snackbar v-model="toastBarStatus" :timeout="3000" color="#767676">
            {{ toastMessage }}
        </v-snackbar>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { useRouter } from 'vue-router'
// component import
import { CheckedIcon } from '@/components/svg'
import SecurityAuthModal from '@/components/modal/SecurityAuthModal.vue';
// api import
import { sendVerifyEmail } from '@/api/auth/email'
import { initKakaoAuth } from '@/api/auth/kakao'
import { initCreateOtpKey, registerOtpKey, requestDeleteOTPKey } from '@/api/auth/otp.js'
import { initMemberInfomation, findPwd, getSelectedWalletList } from '@/api/user/index.js'
// utils import
import { copy, maskEmail } from '@/utils/utilsFactory.js'
import UtilSessionStorage from '../../utils/session'
import { logOut } from '../../api/user'
import {
    formatNumberString,
    handleKeydown,
    handlePhoneNumberKeydown
} from '../../utils/utilsFactory'


const sessionStorage = new UtilSessionStorage()

const router = useRouter()

const items = ref(null)

const step = ref('')

// 페이지 이동용 변수 선언
const isitActice = ref(1)
const isEnteredGoogleAuth = ref(false)
const hasARSAuth = ref(true)
const hasKakaoAuth = ref(false)
const hasOtpAuth = ref(false)
// 구글 OTP 인증
const hasGoogleCertificated = ref( false );
// 유저의 본인인증이 완료되었는지에 대한 상태
const hasUserAuthCompleted = ref( false );
const googleOTPStatus = ref('ready')
const emailVerifyCode = ref('')
// 인증 관련 변수들
const whatItemChangeFor = ref('ready');
const doubleChannelDialog = ref(false);
function changeDoubleChannelDialog(){
    doubleChannelDialog.value = !doubleChannelDialog.value;
}
/**
 * 0 : 기본값
 * 1 : 카카오페이 인증
 * 2 : otp 인증
 */
const startProcess = ref(0);
const initStartProcess = () => { startProcess.value = 0 };
// 진행 상황 저장용 변수, 완료가 된 항목에 대해서 true로 전환
const kakaoAuthRequestData = ref({
    name: '',
    phone: '',
    birth: '',
    title: '',
    content: ''
})
const googleAuthStatus = ref({
    MobileAppSetup: false,
    QrCodeScan: false,
    SaveBackupCode: false,
    AuthenticatorSignUp: false
})

// 인증
const authRemainTime = ref('00:00')
let authVerifyIntervalId = null
// 인증 제한시간 (초 단위)
const authLimitTime = ref(900)
// 인증버튼 눌렀을 때 저장될 인증 시작 시간
const authStartTime = ref(null)
// 인증 잔여시간 (단위 : 밀리초)
const authVerifyTime = ref(0)

// form 용 바인딩 변수
const userId = ref('')
const userEmailId = ref('')
const backUpCode = ref('6ZG2DK951ZKWHHDV')
// 입력 받은 input area의 값들 (rawValue, 숫자형 데이터)
const googleAuthenticatorEmailAuthCode = ref('')
// xxx,xxx,xxx 형식으로 변환된 값이 임시 저장될 변수들
const formattedGoogleAuthenticatorEmailAuthCode = ref(0)
// 사용자에게 보여지게 될 input area의 값들
const displayGoogleAuthenticatorEmailAuthCode = ref(0)
// 각각의 input area에 대한 handleEvent
const handleOnGoogleAuthenticatorEmailAuthCode = (event) => {
    const numbersOnlyValue = event.target.value.replace(/\D/g, '')
    googleAuthenticatorEmailAuthCode.value = numbersOnlyValue
    formattedGoogleAuthenticatorEmailAuthCode.value = formatNumberString(numbersOnlyValue)
    event.target.value = formattedGoogleAuthenticatorEmailAuthCode.value
}

// 모달창 컨트롤용 변수
const nested_dialog = ref(false);
const alert_dialog = ref(false);
const page_change_dialog = ref(false);
const modal_title = ref('안내');
const nested_msg = ref('');
const modal_btn_msg = ref('변경');
const modal_btn_width = ref(77);
// 유저의 지갑 주소가 있는지 없는지 판단해주는 함수
async function checkUserWalletAddress(){
    try {
        const response = await getSelectedWalletList( userId.value )
        if( response[0]?.address ){
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}

// 토스트 메세지 컨트롤용 변수
const toastBarStatus = ref(false);
const toastMessage = ref('');

// 구글 어센티케이터 등록 페이지 validation
const isitGoogleAuthenticatorAuthCompleted = computed(() => {
    let result = false
    try {
        if (
            googleAuthenticatorEmailAuthCode.value?.length === 6 &&
            emailVerifyCode.value?.length > 7
        ) {
            result = true
        }
    } catch (error) {
        console.error(error)
    }
    return result
})

// qrcode 생성 및 관리용 변수
const qrcodeRef = ref(null)
const qrcodeSize = 141
const qrcodeLoading = ref(false)
const qrcodeSecretKey = ref('')

function afterCompleteAction(){
    doubleChannelDialog.value = false;
    if( whatItemChangeFor.value === 'kakao' ){
      cleanUpKakaoAuth();
    } else if( whatItemChangeFor.value === 'otp' ){
      cleanUpOtpAuth();
    } 
}

function afterFailAction(){
    doubleChannelDialog.value = false;
}

onMounted(async () => {
    watch(googleOTPStatus, () => {
        if (googleOTPStatus.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0) {
                        clearInterval(authVerifyIntervalId)
                        googleOTPStatus.value = 'ready'
                        makeAuthFailAlert()
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(authVerifyTime.value / 60)}`.slice(
                        -2
                    )}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    userId.value = sessionStorage.getUserId()
    try {
        initMemberInfomation(userId.value).then((response) => {
            if (response) {
                items.value = response
                userEmailId.value = maskEmail(response.email)
                if (Number(response.authOtp) === 1) {
                    hasOtpAuth.value = true
                }
                if (Number(response.authKakao) === 1) {
                    hasKakaoAuth.value = true
                }
                if (items.value) {
                    kakaoAuthRequestData.value.name = items.value.name
                    kakaoAuthRequestData.value.phone = items.value.phone
                    kakaoAuthRequestData.value.birth = items.value.birth
                    kakaoAuthRequestData.value.title = '카카오인증'
                    kakaoAuthRequestData.value.content = '카카오인증 테스트'
                }
            } else {
                logOut()
                router.go(0)
            }
        })
    } catch (error) {
        console.error(error)
    }
})

// functions ================================================================================ //
// 모달창 컨트롤 함수
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}
function changeAlertDialogTrue() {
    alert_dialog.value = true
}
function changeAlertDialogFalse() {
    alert_dialog.value = false
}

// 현재 진행상황을 확인하는 작업을 함수화
function isitChecked(tab) {
    switch (tab) {
        case 1:
            return checkMobileAppSetup(tab)
        case 2:
            return checkQrCodeScan(tab)
        case 3:
            return checkSaveBackupCode(tab)
        case 4:
            return checkAuthenticatorSignUp(tab)
        default:
            break
    }
    return false
}

/**
 * 모바일앱 설치 페이지에 대한 값 확인
 */
function checkMobileAppSetup(tab) {
    return googleAuthStatus.value.MobileAppSetup
}

/**
 * QR코드 스캔 페이지에 대한 값 확인
 */
function checkQrCodeScan(tab) {
    return googleAuthStatus.value.QrCodeScan
}

/**
 * 백업코드 저장 페이지에 대한 값 확인
 */
function checkSaveBackupCode(tab) {
    return googleAuthStatus.value.SaveBackupCode
}

/**
 * 구글 어센티케이터 등록 페이지에 대한 값 확인
 */
function checkAuthenticatorSignUp(tab) {
    return googleAuthStatus.value.AuthenticatorSignUp
}

/**
 * 구글 어센티케이터 등록을 위한 4단계의 절차를 시행하기 위해 최초로 실행되어야하는 함수.
 */
function enterGoogleAuthPage() {
    isEnteredGoogleAuth.value = true
    changeNestedDialogFalse()
}

/**
 * 구글 어센티케이터를 초기화하고 등록하기 위한 함수.
 */
function prepareResetGoogleAuthPage() {
    whatItemChangeFor.value = 'otp'
    modal_title.value = '안내'
    nested_msg.value = `구글 어센티케이터 인증을 초기화하시겠습니까?<br/>
초기화 버튼을 클릭하면 구글 어센티케이터 인증 후 해당 인증 연결이 해제됩니다.`
    modal_btn_msg.value = '초기화'
    step.value = 'clearOtpAuth'
    changeNestedDialogTrue()
}

/**
 * 카카오페이 인증 초기화
 */
function prepareResetKakaoAuthPage() {
    whatItemChangeFor.value = 'kakao'
    modal_title.value = '안내'
    nested_msg.value = `카카오페이 인증을 초기화하시겠습니까?<br/>
초기화 버튼을 클릭하면 카카오페이 인증 후 해당 인증 연결이 해제됩니다.`
    modal_btn_msg.value = '초기화'
    step.value = 'clearKakaoAuth'
    changeNestedDialogTrue()
}

/**
 * 모바일 앱 설치, (최초단계) 페이지로 이동하는 함수
 */
function moveToMobileAppSetup() {
    isitActice.value = 1
    googleAuthStatus.value.MobileAppSetup = false
    googleAuthStatus.value.QrCodeScan = false
    googleAuthStatus.value.SaveBackupCode = false
}
/**
 * QR코드 스캔 페이지로 이동하는 함수
 */
async function moveToQrCodeScan() {
    if (!qrcodeRef.value || qrcodeRef.value === '') {
        initCreateOtpKey(userId.value).then((response) => {
            qrcodeRef.value = response.result.qr
            const temp = response.result.qr.split('?')[1].split('&')[0].split('=')[1]
            qrcodeSecretKey.value = temp
            if (qrcodeSecretKey.value !== '' && qrcodeSecretKey.value) {
                backUpCode.value = qrcodeSecretKey.value
            }
            qrcodeLoading.value = true
        })
    }
    isitActice.value = 2
    googleAuthStatus.value.QrCodeScan = false
    googleAuthStatus.value.MobileAppSetup = true
}
/**
 * 백업코드 저장 페이지로 이동하는 함수
 */
function moveToSaveBackUpCode() {
    isitActice.value = 3
    googleAuthStatus.value.SaveBackupCode = false
    googleAuthStatus.value.QrCodeScan = true
}
/**
 * 구글 어센티케이터 등록 페이지로 이동하는 함수
 */
function moveToGoogleAuthenticatorSignUp() {
    isitActice.value = 4
    googleAuthStatus.value.SaveBackupCode = true
}
/**
 * 구글 어센티케이터 등록 절차가 전부 완료되었을 때 시행될 함수.
 */
async function completeGoogleAuthenticatorSignUp() {
    // 쿠키 등에 상태를 저장하거나 페이지 들어올 때 api 이용해서 현재 구글 어센티케이터의 상태를 반환하면 좋을 것 같습니다.
    let response2 = null
    try {
        response2 = await registerOtpKey(userId.value, googleAuthenticatorEmailAuthCode.value)
        if (response2?.status === 'success') {
            modal_title.value = '안내';
            nested_msg.value = `정상적으로 등록되었습니다.`;
            modal_btn_msg.value = `확인`;
            step.value = 'finished';
            changeNestedDialogTrue();
        } else if (response2?.descript?.includes('Fail')) {
            googleOTPStatus.value = 'ready';
            googleAuthenticatorEmailAuthCode.value = null;
            authStartTime.value = null;
            authVerifyTime.value = 0;
            onWrongAuthSuccess();
        } else {
            googleOTPStatus.value = 'ready';
            googleAuthenticatorEmailAuthCode.value = null;
            authStartTime.value = null;
            authVerifyTime.value = 0;
            onSubmitError(); 
        }
    } catch (error) {
        onSubmitError()
        googleOTPStatus.value = 'ready'
        console.error(error)
    }
}

// 페이지를 다시 로드합니다.
function reloadPage() {
    router.go(0)
}

/**
 * 백업코드를 클립보드로 복사하기 위한 함수입니다.
 */
function copyBackUpCodeToClipboard() {
    copy(backUpCode.value)
    toastMessage.value = '복사가 완료되었습니다!'
    toastBarStatus.value = true
}

/**
 * 보안설정 > 구글 어센티케이터 등록 > 전송하기 클릭시 실행될 함수
 */
async function prepareSendEmailAuth() {
    // hasOtpAuth.value = true;
    googleOTPStatus.value = 'wait'
    const userEmail = sessionStorage.getItemWithCrypto('email')
    if (!userEmail) {
        return
    }
    // EmailAuth.sendSignupEmail();
    try {
        const response = await findPwd(userEmail)
        if (response.status === `success`) {
            googleOTPStatus.value = 'send'
            authStartTime.value = Number(new Date().getTime())
            onSubmitSuccess()
        } else {
            googleOTPStatus.value = 'ready'
            onSubmitError()
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * 보안설정 > 구글 어센티케이터 등록 > 전송하기 클릭 후 이메일 인증 절차
 */
async function prepareVerifyEmailAuth() {
    let status = false
    const userEmail = sessionStorage.getItemWithCrypto('email')
    if (!userEmail) {
        return
    }
    try {
        const response = await sendVerifyEmail(userEmail, emailVerifyCode.value)
        if (response.data.status === `success`) {
            modal_title.value = '안내'
            nested_msg.value = `정상적으로 인증되었습니다.`
            // emailVerifyCheck.value = true
            googleOTPStatus.value = 'done'
            clearInterval(authVerifyIntervalId)
            status = true
        } else {
            modal_title.value = '안내'
            nested_msg.value = `올바르지 않은 인증번호입니다.<br/>
전송하기 버튼을 다시 눌러주세요.`
            googleOTPStatus.value = 'ready'
            status = false
        }
        changeAlertDialogTrue()
    } catch (error) {
        onSubmitError()
        console.error(error)
    }

    return status
}

/**
 * 마이페이지 > 보안설정 > 문자 SMS 인증 > 변경하기 클릭시 실행될 함수
 */
function prepareUpdatePhone() {
    // 실행될 때 먼저
    // 회원가입할 때 인증했던 휴대폰 번호로 문자 SMS 인증.
    changeNestedDialogFalse()

    // TODO NICE 인증으로 바꿀 예정
    const routeData = router.resolve({ name: 'MobileAuth', query: { data: 'abcd' } })
    const attr = `toolbar=yes, scrollbars=no, resizable=no, left=${0}, top=${0}, width=${440}, height=${843}`
    window.open(routeData.href, '', attr)
}

/**
 * 카카오페이 인증 초기화 버튼 클릭시 실행될 함수
 */
async function cleanUpKakaoAuth() {
    step.value = 'needRefresh'
    changeNestedDialogFalse()
    try {
        const response = await initKakaoAuth({ id: sessionStorage.getUserId() })
        if (response.status === `success`) {
            modal_title.value = '안내'
            nested_msg.value = `정상적으로 초기화되었습니다.`
            modal_btn_msg.value = `확인`
            changeNestedDialogTrue()
        } else {
            onSubmitError()
        }
    } catch (error) {
        onSubmitError()
        console.error(error)
    }
}

/**
 * 구글 어센티케이터 인증 초기화 버튼 클릭시 실행될 함수
 */
async function cleanUpOtpAuth() {
    step.value = 'needRefresh'
    changeNestedDialogFalse()
    try {
        const response = await requestDeleteOTPKey({ id: sessionStorage.getUserId() })
        if (response.data.status === `success`) {
            modal_title.value = '안내'
            nested_msg.value = `정상적으로 초기화되었습니다.`
            modal_btn_msg.value = `확인`
            changeNestedDialogTrue()
        } else {
            onSubmitError()
        }
    } catch (error) {
        onSubmitError()
        console.error(error)
    }
    changeNestedDialogTrue()
}

// 발송 완료 얼럿
function onSubmitSuccess() {
    modal_title.value = `발송 안내`
    nested_msg.value = `인증번호가 정상적으로 발송되었습니다.<br/>메일이 오지 않는다면 스팸메일함을 확인하거나<br/>1:1 문의를 통해 확인해주세요.`
    changeAlertDialogTrue()
}

// 발송 완료 얼럿
function onWrongAuthSuccess() {
    modal_title.value = '안내'
    nested_msg.value = `올바르지 않은 구글 어센티케이터 인증코드입니다.<br/>
전송하기 버튼을 다시 눌러주세요.`
    changeAlertDialogTrue()
}

// 인증 실패 안내 얼럿
function makeAuthFailAlert() {
    // modalAction.value = '인증실패';
    modal_title.value = '안내'
    // modal_btn_msg.value = '확인';
    nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`
    // changeAlertDialogTrue();
    changeAlertDialogTrue()
}

// 문의 발송 오류 안내 얼럿
function onSubmitError() {
    modal_title.value = `승인오류 안내`
    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
잠시 후 다시 시도해주세요.<br/>
<br/>
계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
    changeAlertDialogTrue()
}
</script>

<style scoped>
.active {
    background-color: var(--auth_done);
    color: white;
}
.active svg path {
    stroke: white;
    /* fill: white; */
}
.active svg path:nth-child(2) {
    fill: white;
}
</style>
