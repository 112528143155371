<!-- 마이페이지 -->
<template>
  <main class="flex justify-center trading-monitor min-h-[100px] pt-4">
    <section class="support left-tab mr-[20px] translate-x-[10px]">
        <ul>
          <li class="bg-gray h-[95px]">
            <div class="text-white h-[95px] pt-[5px]">
              고객지원
            </div>
          </li>
          <li :class="route.path.includes('/notice') ? 'active bg-main_color shadow-green_tab text-white text-[19px]' : 'bg-[#fff] text-[19px]'">
            <RouterLink :to="`/support/notice`" class="__lang">
              공지사항
            </RouterLink>
          </li>
          <li :class="route.path.includes('/faq') ? 'active bg-main_color shadow-green_tab text-white text-[19px]' : 'bg-[#fff] text-[19px]'">
            <RouterLink :to="`/support/faq`" class="__lang">
              자주하는 질문
            </RouterLink>
          </li>
          <li :class="route.path.includes('/help') ? 'active bg-main_color shadow-green_tab text-white text-[19px]' : 'bg-[#fff] text-[19px]'">
            <RouterLink v-if="checkLoginStatus()" to="/support/help" class="__lang"
                >1:1 문의
            </RouterLink>
            <div v-else class="clickable" @click="changeNestedDialogTrue()">
                1:1 문의
            </div>
          </li>
        </ul>
    </section>
    <!-- 각각의 링크를 눌렀을 때 그 내용이 RouterView 안에 표시됩니다. 기본은 나의 정보입니다. -->
    <section class="translate-x-[10px] basicBoxShadow w-[1030px] min-h-[500px] bg-[#fff] mb-[10px]">
      <RouterView></RouterView>
    </section>

    <!-- alert용 모달 -->
    <v-dialog v-model="alert_dialog" persistent width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn class="modal_cancel_btn border w-[77px] h-[43px]" color="black" @click="changeNestedDialogFalse">
                    확인
                </v-btn>
                <v-btn :class="`modal_confirm_btn w-[${90}px]`" color="white" @click="()=>{
                    changeNestedDialogFalse();
                    $router.push({path : '/login'});
                }">
                    로그인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </main>

</template>

<script setup>
// reactive import
import { reactive, onMounted, ref, watch } from 'vue';

// spa router 함수 이용을 위한 import
import { useRoute } from 'vue-router';

// utils import
import { checkLoginStatus } from '@/utils/member';
import UtilSessionStorage from '../utils/session';

const sessionStorage = new UtilSessionStorage();

// 라우트 인스턴스 생성
const route = useRoute();

const id = ref('');

// 모달창 컨트롤용 변수
const alert_dialog = ref(false);
const modal_title = ref('안내');
const nested_msg = ref(`로그인이 필요한 서비스입니다.<br/>로그인 후 이용해주세요.`);
const step = ref('');

// 모달창 컨트롤 함수
function changeNestedDialogTrue () { alert_dialog.value = true; }
function changeNestedDialogFalse () { alert_dialog.value = false; }


// functions ================================================================= //


onMounted(()=>{
  // id.value = sessionStorage.getUserId();
  window.scrollTo( 0, 0 )
})

</script>

<style scoped>
.left-tab ul li:first-child{
  height: 95px;
  font-size: 24px;
  font-weight: 700;
}
</style>