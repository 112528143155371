<!-- 거래내역 -->
<template>
    <div v-if="loading===false" class="w-[1050px] h-[620px] bg-[#fff] flex justify-center items-center">
        <v-progress-circular
            width="10"
            size="80"
            indeterminate
            color="#305C3A"
        ></v-progress-circular>
    </div>
    <div v-else-if="loading">
        <!-- 맨 위의 제목 영역 -->
        <section class="tm1 px-4 py-2 mb-4">
            <p class="__lang subTitle text-noto-700 flex">
            거래내역
            </p>
        </section>

        <!-- 가운데의 네비게이션 영역 -->
        <section class="flex justify-between items-center border-none mb-[6px]">
            <!-- 날짜별 정렬 아이콘들 -->
            <div class="pl-3">
                <ul class="flex items-center align-center h-10">
                    <li :class="route.query.period === '0' ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title" 
                        id="trade_history_week"
                        @click.prevent="()=>{
                            if( route.query.period !== '0' ){
                                change_tab('period', 0, 7)
                            } else {
                                return;
                            }
                        }"
                    >
                        1주일
                    </li>
                    <li :class="route.query.period === '1' || !route.query.period ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title" 
                        id="trade_history_a_month"
                        @click.prevent="()=>{
                            if( route.query.period !== '1' ){
                                change_tab('period', 1, 30)
                            } else {
                                return;
                            }
                        }"
                    >
                        1개월
                    </li>
                    <li :class="route.query.period === '2' ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title" 
                        id="trade_history_three_month"
                        @click.prevent="()=>{
                            if( route.query.period !== '2' ){
                                change_tab('period', 2, 90)
                            } else {
                                return;
                            }
                        }"
                    >
                        3개월
                    </li>
                    <li :class="route.query.period === '3' ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title" 
                        id="trade_history_six_month"
                        @click.prevent="()=>{
                            if( route.query.period !== '3' ){
                                change_tab('period', 3, 180)
                            } else {
                                return;
                            }
                        }"
                    >
                        6개월
                    </li>
                    <!-- <li :class="route.query.period === '4' ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title" 
                        id="trade_history_input"
                        @click.prevent="customRange"
                    >
                        직접입력
                    </li> -->
                </ul>
            </div>
            <!-- 전체 / 구매 / 판매 별 데이터 정렬 탭 -->
            <div>
                <ul class="flex items-center align-center h-10">
                    <li 
                        :class="!active_tab.type ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( active_tab.type === 1 || active_tab.type === 2 ){
                                change_tab('type', 0, 0)
                            } else {
                                return;
                            }
                        }"
                    >
                        전체
                    </li>
                    <li 
                        :class="active_tab.type === 2 ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( active_tab.type !== 2 ){
                                change_tab('type', 1, 1)
                            } else {
                                return;
                            }
                        }"
                    >
                        구매
                    </li>
                    <li 
                        :class="active_tab.type === 1 ? 'active' : ''" 
                        class="clickable __lang px-4 py-2 h-8 border text-stroke flex items-center contents_title"
                        @click.prevent="()=>{
                            if( active_tab.type !== 1 ){
                                change_tab('type', 2, 2)
                            } else {
                                return;
                            }
                        }"
                    >
                        판매
                    </li>
                </ul>
            </div>
            <!-- 거래내역 검색 탭 -->
            <div class="ml-5">
                <div class="pr-[10px] combobox-area relative overflow-visible">
                    <input 
                        class="combobox-input border w-[285px] h-[33px] text-[12px] pl-4" 
                        type="text" 
                        v-model="search"
                        :placeholder="'작품 전체'" 
                        @keyup.enter="searchItem"
                        @click:append="searchItem"
                        @focus="combobox = true" 
                        @blur="combobox = false"
                    />
                    <div v-if="combobox" class="absolute z-10 top-[33px] combobox-list w-[285px] max-h-[500px] bg-[#fff] overflow-scroll basicBoxShadow">
                        <div v-for="(item, index) in sortedMarketList || []" :key="index" 
                        class="combobox-item min-h-[30px] clickable px-5 py-2 text-[12px] text-noto-350 text-left" 
                        @mousedown="search = item.symbol">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                    <div v-if="search.length > 0" class="absolute right-12 top-[10px] clickable" @click="search = ''">
                        <img src="/asset/img/cancelBtn.svg" />
                    </div>
                    <div class="absolute right-5 top-1 clickable" @click="searchItem">
                        <img src="/asset/img/search_primary.svg" />
                    </div>
                </div>
            </div>
        </section>

        <!-- 보유작품 표시 데이터 테이블 -->
        <section class="ms3">
            <v-card>
                <v-card-text style="padding: 0">
                    <v-window class="h-full">
                        <v-window-item class="h-full">
                            <!-- 데이터 테이블 기능 -->
                            <v-data-table
                                v-model:sort-by="sortBy"
                                :headers="headers"
                                :items="orderStore.dealList || []"
                                :items-per-page="-1"
                                sort-asc-icon="mdi-menu-up"
                                sort-desc-icon="mdi-menu-down"
                                class="elevation-1"
                                style="width: 100%; font-size: 11px; min-height: 700px;"
                                no-data-text="거래내역이 없습니다."
                                fixed-header
                                id="custom-scrollbar"
                            >
                                <template v-slot:item="{ item, index }">
                                    <tr>
                                        <!-- 주문일시 -->
                                        <td>
                                            <div class="leading-4 h-[14px] text-[10px] pa-0 ma-0 flex">
                                                {{ unix_to_yyyymmdd( item.columns?.ordDate / 1000 ) }}
                                            </div>
                                            <div class="leading-3 h-[14px] item-center pa-0 ma-0 flex text-gray">
                                                {{ unix_to_timestamp( item.columns?.ordDate / 1000 ) }}
                                            </div>
                                        </td>
                                        <!-- 체결일시 -->
                                        <td>
                                            <div class="leading-4 h-[14px] text-[10px] pa-0 ma-0 flex">
                                                {{ unix_to_yyyymmdd( item.columns?.regDate / 1000 ) }}
                                            </div>
                                            <div class="leading-3 h-[14px] item-center pa-0 ma-0 flex text-gray">
                                                {{ unix_to_timestamp( item.columns?.regDate / 1000 ) }}
                                            </div>
                                        </td>
                                        <!-- 작품 -->
                                        <td>
                                            {{ item?.columns?.market1 || '' }}
                                        </td>
                                        <!-- 마켓 -->
                                        <td>
                                            {{ item?.columns?.market2 || '' }}
                                        </td>
                                        <!-- 구분 -->
                                        <td
                                            :class="`${ item?.columns?.ordType === '구매' ? 'plus' : item?.columns?.ordType === '판매' ? 'minus' : '' }`">
                                            {{ item?.columns?.ordType || '' }}
                                        </td>
                                        <!-- 거래량 -->
                                        <td>
                                            {{ (item?.columns?.volume)?.toLocaleString() || '' }} {{ item?.columns?.market1 || '' }}
                                        </td>
                                        <!-- 체결가 -->
                                        <td>
                                            {{ (item?.columns?.price)?.toLocaleString() || '' }} {{ item?.columns?.market2 || '' }}
                                        </td>
                                        <!-- 거래금액 -->
                                        <td>
                                            {{ (item?.columns?.amount)?.toLocaleString() || '' }} {{ item?.columns?.market2 || '' }}
                                        </td>
                                        <!-- 수수료 -->
                                        <td>
                                            {{ (item?.columns?.fee)?.toLocaleString('ko-KR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) || '' }} {{ item?.columns?.market2 || '' }}
                                        </td>
                                        <!-- 정산금액 -->
                                        <td>
                                            {{ (item?.columns?.finalAmountFixScale)?.toLocaleString() || '' }} {{ item?.columns?.market2 || '' }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <div>
                    <v-pagination
                        v-if="pageLength > 1"
                        v-model="page"
                        :length="pageLength"
                        @update:model-value="updateQueryString"
                        class="text-[14px] pt-5 pb-[60px]"
                        :total-visible="6"
                        color="#858585"
                        active-color="black"
                    ></v-pagination>
                </div>
            </v-card>
        </section>

        <!-- DatePicker 모달창 -->
        <v-dialog v-model="dialog" width="406px" class="flex justify-center pa-0">
            <DatePickerMultidate 
                :changeNestedDialog="changeNestedDialogFalse"
                :model="resultDate"
                :setDateValue="setDateValue"
            />
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, reactive,onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useOrderStore } from '@/stores/order';
import DatePickerMultidate from '@/components/modal/DatePickerMultidate.vue'

import { unix_to_timestamp, unix_to_yyyymmdd, yyyymmdd_to_unixTimeStamp} from '@/utils/utilsFactory'
import UtilSessionStorage from '@/utils/session'

import { initOrderDealList } from '../../api/order/index.js'
import ExchangerApi from '../../api/exchanger';
import { useExchangerStore } from '../../stores/exchanger';
import { toChosungString } from '../../utils/utilsFactory';

const sessionStorage = new UtilSessionStorage();

const exchangerApi = new ExchangerApi();

// pinia instance 주입
const exchangerStore = useExchangerStore();
const orderStore = useOrderStore();
// 라우터 instance 주입
const router = useRouter();
const route = useRoute();
// 페이지네이션 용 ref화 페이지 변수
const page = ref(1);
const pageLength = ref(4);
const askOrBidType = ref(null);
const startDate = ref(null);
const endDate = ref(null);
const period = ref(0);

// 모달창 관련
const dialog = ref(false);
const resultDate = ref('');
function changeNestedDialogTrue () { dialog.value = true; }
function changeNestedDialogFalse () { dialog.value = false; }
const setDateValue = ( newData ) => {
    resultDate.value = newData;
    let resultSplitData = resultDate.value.split(' ~ ');

    startDate.value = yyyymmdd_to_unixTimeStamp( resultSplitData[0] );

    endDate.value = yyyymmdd_to_unixTimeStamp( resultSplitData[1] );

    period.value = 4;

    updateQueryString( Number(page.value) );
}

const search = ref('')
const combobox = ref(false);
const loading = ref(false);
const sortBy = ref([{ key: 'time', order: 'desc' }])
const sortedDealList = ref([])
const marketListData = ref([]);
// 필터링 거친 리스트 데이터
const sortedMarketList = computed(() => {
let result = [];
  try {
    result = marketListData.value

    // searchNft에 입력된 검색어가 있을 경우 필터링
    if (search.value.trim()) {
        const searchTerm = search.value.trim().toUpperCase();  // 입력된 검색어를 대문자로 변환
        result = result.filter(item => 
        item?.name.toUpperCase().includes(searchTerm) ||
        item?.symbol.toUpperCase().includes(searchTerm) ||
        toChosungString(item.name).includes(searchTerm) );
    }

    result.sort( ( a, b )=>{
        return a.symbol.localeCompare(b.symbol);
    })

  } catch (error) {
    console.error(error)
  }
  return result;
})

const headers = ref([
    {
        title: '주문일시',
        align: 'start',
        sortable: false,
        key: 'ordDate'
    },
    {
        title: '체결일시',
        sortable: false,
        key: 'regDate'
    },
    { title: '작품', sortable: false, key: 'market1' },
    { title: '마켓', sortable: false, key: 'market2' },
    { title: '구분', sortable: false, key: 'ordType' },
    { title: '거래량', sortable: false, key: 'volume' },
    { title: '체결가', sortable: false, key: 'price' },
    { title: '거래금액', sortable: false, key: 'amount' },
    { title: '수수료', sortable: false, key: 'fee' },
    { title: '정산금액', sortable: false, key: 'finalAmountFixScale' }
])
const active_tab = reactive(
    {
        period : 0,
        type : 0,
    }
)


onMounted( async () => {
    try {
        checkQueryString();
        // URL의 쿼리 스트링에서 페이지 값을 읽어옵니다.
        page.value = Number(route.query.page) || 1;

        // 쿼리스트링에 따라 ref 속성을 설정합니다.
        if( router.currentRoute.value.query.type ){
            askOrBidType.value = router.currentRoute.value.query.type
        }

        if( router.currentRoute.value.query.type === '1' ) {
            askOrBidType.value = '1';
            active_tab.type = 1;
        } else if( router.currentRoute.value.query.type === '2' ){
            askOrBidType.value = '2';
            active_tab.type = 2;
        } else {
            askOrBidType.value = null;
            active_tab.type = null;
        }

        if( router.currentRoute.value.query.period ){
            active_tab.period = period.value;
        }

        if( route.query.symbol ){
            search.value = route.query.symbol;
        }

        // 마켓 리스트를 얻어와 세팅합니다.
        marketListData.value = await exchangerApi.allAssetListIncludeCoin({ coin : false });

    } catch (error) {
        console.error(error)
    }

    const userId = sessionStorage.getUserId();
    const answer = await initOrderDealList({
        id : userId, 
        page : page.value,
        type : askOrBidType.value,
        start : Number(route.query.start) ? Number(route.query.start) : new Date(Date.now() - 30 * 86400000).getTime(),
        end : Number(route.query.end) ? Number(route.query.end) : new Date().getTime(),
        symbol : route.query.symbol,
    })
    pageLength.value = answer;

    sortedDealList.value = orderStore.dealList;

    loading.value = true;

    scrollToTop();
})

/**
 * 페이지네이션에서 page가 바뀔때마다 실행될 함수, 쿼리스트링을 바꿉니다
 * @param {*} newPage 
 */
 function updateQueryString( newPage ){
    loading.value = false;

    const queryString = { page : newPage }
    try {
        if( askOrBidType.value ){
            queryString.type = askOrBidType.value;
        } else {
            queryString.type = null;
        }
        if(startDate.value){
            queryString.start = startDate.value;
        } else {
            queryString.start = route.query.start ? route.query.start : '';
        }
        if( endDate.value ){
            queryString.end = endDate.value;
        } else {
            queryString.end = route.query.end ? route.query.end : '';
        }
        if( period.value ){
            queryString.period = `${period.value}`;
        } else {
            queryString.period = route.query.period ? route.query.period : ``;
        }
        if( search.value ){
            queryString.symbol = search.value;
        } else {
            queryString.symbol = route.query.search ? route.query.search: '';
        }
    } catch (error) {
        console.error(error)
    }

    router.push({ 
        path:'/trading/tradingHistory',  
        query : queryString
    })
}


// 쿼리스트링을 체크해서 변수들에 세팅합니다.
function checkQueryString() {
    if (route.query.page) {
        page.value = Number(route.query.page)
    }
    if (route.query.search) {
        search.value = route.query.search
    }
}


/**
 * 검색어를 가지고 쿼리스트링에 넣고 결과를 탐색합니다.
 */
 function searchItem() {
    updateQueryString( page.value )
}


/**
 * 탭을 바꿀 수 있는 함수
 * @param {*} type 
 * @param {*} tab 
 */
async function change_tab( type, tab, state ){
    if( type === 'period' ){
        active_tab.period = tab;
    } else if( type === 'type' ){
        active_tab.type = tab;
    }

    if (state === 0) {
        // 전체 탭을 눌렀습니다.
        page.value = 1;
        askOrBidType.value = null;
    } else if (state === 1) {
        // 구매 탭을 눌렀습니다.
        page.value = 1;
        askOrBidType.value = '2';
    } else if (state === 2) {
        // 판매 탭을 눌렀습니다.
        page.value = 1;
        askOrBidType.value = '1';
    } else if (state > 6) {
        // 기간별로 보기를 눌렀습니다.
        page.value = 1;
        period.value = `${tab}`;
        startDate.value = new Date(Date.now() - (state + 1) * 86400000).getTime();
        endDate.value = new Date().getTime();
    }
    updateQueryString( page.value );
}

/**
 * 직접 입력을 눌렀을 때 실행될 함수입니다.
 */
async function customRange(){
    changeNestedDialogTrue();
    
    // page.value = 1;
    // period.value = 4;
    // 임시로 7일 전의 데이터로 설정했습니다.
    // startDate.value = new Date(Date.now() - 7 * 86400000).getTime();
    // endDate.value = new Date().getTime();
    // updateQueryString( page.value );
}

/**
 * 화면의 가장 위로 이동하는 함수
 */
function scrollToTop() {
    window.scrollTo(0, 0)
}
</script>

<style scoped>
.active{
    background-color: var(--main_color);
    color: white;
}

.ms3 table tr td { 
    text-align: center;
}
</style>