/* eslint-disable consistent-return, prefer-template */
/* otp 관련 인증 등을 서버에 요청하는 로직 모음 */
import axios from 'axios'
import { requestHeader } from '../config'

/**
 * otp qrcode 생성을 위한 최초 인증을 서버로 요청합니다.
 * @returns
 */
export async function initCreateOtpKey(id) {
    try {
        const requestData = { id }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/otp/create_key`, requestData, requestHeader() );
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * 최초 인증에 대한 인증 번호를 확인합니다.
 * @returns
 */
export async function registerOtpKey(id, content) {
    try {
        const requestData = { id, content }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/otp/register_key`, requestData, requestHeader());
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * 마지막 인증 코드를 확인합니다.
 * @returns
 */
export async function verifyOtpKey( requestData ) {
    try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/otp/verify`, requestData, requestHeader() );
        return response.data
    } catch (error) {
        console.error(error)
    }
}


/**
 * 백엔드에 OTP 상태 초기화 요청
 * @param {*} requestData.id
 * @returns 
 */
export async function requestDeleteOTPKey( requestData ){
    return axios.post(`${process.env.VUE_APP_BACKEND}/otp/delete`, requestData, requestHeader() );
  }
