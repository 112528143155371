/* eslint-disable consistent-return */
/* user, member data를 서버에 요청하는 로직 모음 */
import axios from 'axios'
import { requestHeader } from '../config.js'


/**
 * 서버에 아이디와 심볼을 가지고 입금 주소를 생성했는지 가지고 있는지 확인합니다.
 * @param {*} id
 * @param {*} tokenSymbol
 */
export async function hasBlockChainAddress(id, tokenSymbol) {
    try {
        const requestData = { id, tokenSymbol }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/blockchain_inout/select_address`, requestData, requestHeader() )
        if (response) {
            return response.data
        }
    } catch (error) {
        console.error(error)
    }
    return false
}

/**
 * 서버에 아이디와 심볼을 가지고 입금주소 생성을 요청합니다.
 * @param {*} id
 * @param {*} tokenSymbol
 */
export async function makeBlockChainAddress(id, tokenSymbol) {
    try {
        const requestData = { id, tokenSymbol }
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/blockchain_inout/create_address`, requestData, requestHeader())
        if (response) {
            return response.data
        }
    } catch (error) {
        console.error(error)
    }
    return false
}

/**
 * 서버에 내부 출금 보내는 주소 확인 요청을 보냅니다.
 * @param {*} requestData 
 */
export async function checkInternalOutAddress( requestData ){
    return axios.post(`${process.env.VUE_APP_BACKEND}/blockchain_inout/check_internal_out_address`, requestData, requestHeader())
}


// -------------------------- 입출금 페이지 보안 인증용 요청 -------------------------------
/**
 * 출금 신청을 위한 인증 요청을 보냅니다. (입출금 페이지 용)
 * @returns 
 */
export async function requestOutAuth( requestData ){
    try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/blockchain_inout/request_out_auth`, requestData, requestHeader() );
      return response.data
    } catch (error) {
      console.error(error)
    }
    return false
  }


/**
 * 블록체인 입출금 > 출금 신청으로 요청
 * @param {*} requestData.id
 * @param {*} requestData.symbol
 * @param {*} requestData.toAddress
 * @param {*} requestData.amount
 * @param {*} requestData.feeSymbol
 * @param {*} requestData.authType      1-kakao / 2-otp
 * @param {*} requestData.code
 * @returns
 */
export async function requestCompleteAuthBlockChainOut( requestData ){
    try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND}/blockchain_inout/request_out`, requestData, requestHeader() )
        if (response) {
            return response
        }
    } catch (error) {
        console.error(error)
    }
    return false
}


/**
 * 특정 심볼의 컨퍼메이션 횟수를 요청합니다.
 * @param {String} requestData.symbol
 * @returns 
 */
export async function requestConfirmationAsset( requestData ){
    return axios.post(`${process.env.VUE_APP_BACKEND}/asset/confirmation`, requestData, requestHeader() )
}