<!-- 간단한 메시지용 모달 -->
<script setup>
import { computed, ref, onMounted, watch } from 'vue'

const resultData = ref('')

const modelValue = ref(new Date())

const modelValue2 = ref(new Date())

const step = ref(0)

const result1 = ref('');
const result2 = ref('');

const resultDateSentence = computed(()=>{
    let result = `YYYY.MM.DD ~ YYYY.MM.DD`
    if( result1.value && result1.value !== '' ){
        result = `${result1.value} ~ YYYY.MM.DD`
    }
    if( result2.value && result2.value !== '' ){
        result = `${result1.value} ~ ${result2.value}`
    }
    return result;
})

const props = defineProps({
    model: {
        type: String
    },
    changeNestedDialog: {
        type: Function
    },
    setDateValue: {
        type: Function
    },
    modalType: {
        type: String
    },
    goToNextStep: {
        type: Function
    }
})

onMounted(() => {

})

function makeDate(){
    let temp = null;
    try {
        const monthMapping = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        }

        if (modelValue.value[0]) {
            temp = String(modelValue.value[0]).split(' ')
            
        } else {
            temp = String(modelValue.value).split(' ')
        }
        const year = temp[3]
        const month = monthMapping[temp[1]]
        const day = temp[2]
        const date = temp[0]
        if (!year || !month || !day || !date) {
            props.changeNestedDialog()
            return
        }
        const result = `${year}.${month}.${day}`

        props.setDateValue(result)
    } catch (error) {
        console.error(error)
    }
}

function saveAndGoNext(){
    result1.value = splitAndMakeResultDateYYYYMMDD( modelValue.value );
    step.value = 1;
}

function saveDate() {
    result2.value = splitAndMakeResultDateYYYYMMDD( modelValue2.value );
    // props.changeNestedDialog()
    props.setDateValue( resultDateSentence.value );
    props.changeNestedDialog()
}

function splitAndMakeResultDateYYYYMMDD( origin ){
    let temp = '';
    const monthMapping = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        }

        if (origin[0]) {
            temp = String(origin[0]).split(' ')
            
        } else {
            temp = String(origin).split(' ')
        }
        const year = temp[3]
        const month = monthMapping[temp[1]]
        const day = temp[2]
        const date = temp[0]

        const result = `${year}.${month}.${day}`

        return result;
}
</script>

<template>
    <div class="bg-[#fff]">
        <div class="text-center py-5">{{ resultDateSentence }}</div>
        <v-card class="pa-0">
            <v-card-text>
                <div v-if="step===0">
                    <v-locale-provider locale="ko">
                        <v-date-picker
                            :click:cancel="[props.changeNestedDialog]"
                            v-model="modelValue"
                            input-mode="calendar"
                            reactive="true"
                            title="     "
                            hide-actions
                        >
                        </v-date-picker>
                    </v-locale-provider>
                </div>
                <div v-else-if="step===1">
                    <v-locale-provider locale="ko">
                        <v-date-picker
                            :click:cancel="[props.changeNestedDialog]"
                            v-model="modelValue2"
                            input-mode="calendar"
                            reactive="true"
                            title="     "
                            hide-actions
                        >
                        </v-date-picker>
                    </v-locale-provider>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center pa-7">
                <div v-if="step === 0">
                    <v-btn class="modal_confirm_btn w-[200px]" color="white" @click="saveAndGoNext"> 다음 날짜 선택하기 </v-btn>
                </div>
                <div v-else-if="step === 1">
                    <v-btn class="modal_confirm_btn w-[90px]" color="white" @click="saveDate"> 조회 </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<style scoped>
.v-date-picker-header__append {
    display: none !important;
}
</style>
