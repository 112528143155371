<template>
    <main>
        <div class="securifySetting01 w-[1030px] bg-[#fff] basicBoxShadow">
            <!-- title 영역 -->
            <div class="tm1 border-b px-4 py-2">
                <p class="__lang subTitle text-noto-700 flex">2채널 인증</p>
            </div>

            <!-- content 영역, 2채널 인증 main 화면 -->
            <section
                v-if="process === 0 || !process"
                class="securifySetting02 w-[1030px] h-[750px] bg-[#fff] basicBoxShadow flex flex-col items-center mb-5"
            >
                <br />
                <!-- title -->
                <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">카카오페이 인증</h1>
                <!-- underbar -->
                <div class="w-[500px] border-b mb-5"></div>
                <br />
                <p class="__lang text-[14px] mb-2">
                    카카오톡에서 카카오페이 인증을 등록한 후
                </p>
                <p class="__lang text-[14px] mb-2">
                    등록하기 버튼을 눌러주세요.
                </p>
                <br />
                <div class="nextBtnArea my-5 mb-15">
                    <button :class="['default_btn', 'btn_main_green', 'mb-2']" @click.prevent="startKakaoAuth">
                        등록하기
                    </button>
                </div>
            </section>

            <!-- content 영역, 보안설정 main 화면 -->
            <section
                v-else-if="process === 1"
                class="securifySetting02 w-[1030px] h-[750px] bg-[#fff] basicBoxShadow flex flex-col items-center mb-5"
            >
                <br />
                <!-- title -->
                <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">카카오페이 인증</h1>
                <!-- underbar -->
                <div class="w-[500px] border-b mb-5"></div>
                <br />
                <p class="__lang text-[14px] mb-2">
                    인증 절차를 완료한 후 인증 완료 버튼을 눌러주세요
                </p>
                <div class="remain_time_area mt-[50px] w-[330px] h-[55px] sub_border1 text-noto-700 text-main_color flex justify-center items-center">
                    {{ computedRemainTime }}
                </div>
                <div>
                    <ul class="list-disc pa-4 text-[14px] text-gray">
                        <li class="__lang">
                            등록된 휴대폰 번호로 카카오페이 인증이 발송되었습니다.
                        </li>
                        <li class="__lang">
                            메시지 안내에 따라 인증 절차를 완료해주세요.
                        </li>
                    </ul>
                </div>
                <br />
                <div class="nextBtnArea my-5 mb-15">
                    <button :class="['default_btn', 'btn_main_green', 'mb-2']" @click.prevent="prepareVerifyKakaoAuth">
                        인증완료
                    </button>
                </div>
            </section>
        </div>
    </main>

    <!-- 입출금 페이지 이동 모달 -->
    <v-dialog v-model="page_change_dialog" width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ '안내' }}
                </div>
                <br />
                <div class="text-[15px]">
                    2채널 인증이 완료되어 입출금이 가능합니다. 입출금 페이지로 이동하시겠습니까?
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn
                    class="modal_cancel_btn border w-[77px] h-[43px]"
                    color="black"
                    @click="()=>{
                        page_change_dialog = false;
                        $router.push({ path : `/mypage/securitySetting` });
                    }"
                >
                    닫기
                </v-btn>
                <v-btn
                    :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                    color="white"
                    @click.prevent="()=>{
                        page_change_dialog = false;
                        $router.push({ path : `/balance/deposit` });
                    }"
                >
                    이동하기
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 단순 얼럿용 모달 -->
    <v-dialog v-model="alert_dialog" persistent width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="excuteModalAction">
                    {{ modal_btn_msg }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import UtilSessionStorage from '../../utils/session';
import { sendSignKakao, sendVerifyKakao } from '../../api/auth/kakao.js'
import { getSelectedWalletList } from '../../api/user';

const sessionStorage = new UtilSessionStorage();

const router = useRouter();

const process = ref(0);
const computedRemainTime = ref('00:00')
const kakaoAuthRequestData = ref({
  name : '',
  phone : '',
  birth : '',
  title : '',
  content : '',
})
let kakaoAuthVerifyIntervalId = null;
const kakaoVerifyState = ref(false);
const kakaoAuthLimitTime = ref(180)
const kakaoAuthStartTime = ref(null)
const kakaoAuthVerifyTime = ref(0)

// 유저의 지갑 주소가 있는지 없는지 판단해주는 함수
async function checkUserWalletAddress(){
    try {
        const userId = sessionStorage.getUserId();
        const response = await getSelectedWalletList( userId );
        if( response[0]?.address ){
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}

// 모달창 컨트롤용 변수
const modalAction = ref(null);
const alert_dialog = ref(false);
const page_change_dialog = ref(false);
const modal_title = ref('안내');
const nested_msg = ref(null);
const modal_btn_msg = ref('확인');
const modal_btn_width = ref(117);
function changeAlertDialogTrue () { alert_dialog.value = true; }
function changeAlertDialogFalse () { alert_dialog.value = false; }
async function excuteModalAction(){
    if( modalAction.value === '인증실패' ){
        changeAlertDialogFalse();
        router.push({path : '/mypage/securitySetting'});
    } else if( modalAction.value === '인증성공' ){
        const response = await checkUserWalletAddress();
        changeAlertDialogFalse();
        if( response ){
            page_change_dialog.value = true;
        } else {
            router.push({path : '/mypage/securitySetting'});
        }
    } else {
        changeAlertDialogFalse();
    }
}

onMounted(()=>{
    watch(kakaoVerifyState, () => {
        if (kakaoVerifyState.value === 'send') {
            kakaoAuthVerifyIntervalId = setInterval(() => {
                if (kakaoAuthStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(kakaoAuthStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    kakaoAuthVerifyTime.value = kakaoAuthLimitTime.value - tempValue
                    if (kakaoAuthVerifyTime.value < 0) {
                        clearInterval(kakaoAuthVerifyIntervalId)
                        kakaoVerifyState.value = 'ready'
                        makeKakaoAuthFailAlert();
                        return
                    }
                    computedRemainTime.value = `${`${Math.floor(
                        kakaoAuthVerifyTime.value / 60
                    )}`.slice(-2)}:${`0${Math.floor(kakaoAuthVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })
})

async function startKakaoAuth(){
    // 카카오 인증 발송 및 다음 페이지 넘어가기

    try {
        const userId = sessionStorage.getUserId();
        kakaoVerifyState.value = 'send';
        kakaoAuthStartTime.value = Number(new Date().getTime())

        const response2 = await sendSignKakao( { id : userId } );

        if( response2.success === false ){
            makeKakaoAuthFailAlert();
        } else {
            process.value = 1;
        }
        

  } catch (error) {
    console.error(error)
  }
}

async function prepareVerifyKakaoAuth(){
    // 인증 완료 버튼 클릭 시 시행될 함수
    const requestData = {
        id : sessionStorage.getUserId(),
    }
    try {
        const response = await sendVerifyKakao( requestData );
        if( response.status === `success` ){
            makeKakaoAuthSuccessAlert();
            clearInterval(kakaoAuthVerifyIntervalId)
        } else {
            makeKakaoAuthFailAlert();
        }
    } catch (error) {
        console.error(error)
        makeKakaoAuthErrorAlert();
    }
}

// 등록 완료 얼럿
function makeKakaoAuthSuccessAlert(){
    modalAction.value = '인증성공';
    modal_title.value = '안내';
    modal_btn_msg.value = '확인';
    nested_msg.value = `정상적으로 등록되었습니다.`
    changeAlertDialogTrue();
}


// 인증 실패 안내 얼럿
function makeKakaoAuthFailAlert(){
    modalAction.value = '인증실패';
    modal_title.value = '안내';
    modal_btn_msg.value = '확인';
    nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요.<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`
    changeAlertDialogTrue();
}

// 승인오류 안내 얼럿
function makeKakaoAuthErrorAlert(){
    modal_title.value = '승인오류 안내';
    modal_btn_msg.value = '확인';
    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
    일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
    잠시 후 다시 시도해주세요.<br/><br/>
    계속 발생하는 경우 1:1 문의를 통해 확인해주세요.`
    changeAlertDialogTrue();
}
</script>

<style scoped></style>
