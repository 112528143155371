<template>
    <HeaderMain
        v-if="
            route.name !== 'OrderChildPopUp' &&
            route.name !== 'ChartChildPopUp' &&
            route.name !== 'MobileAuth'
        "
    />

    <RouterView
        :key="$route.fullPath"
        :class="
            route.name !== 'OrderChildPopUp' &&
            route.name !== 'ChartChildPopUp' &&
            route.name !== 'MobileAuth' &&
            !route.path.includes('/m/')
                ? 'mt-[60px]'
                : ''
        "
    />

    <FooterMain
        v-if="
            route.name !== 'OrderChildPopUp' &&
            route.name !== 'ChartChildPopUp' &&
            route.name !== 'MobileAuth' &&
            !route.path.includes('/m/')
        "
    />

    <MobileNavigator
        v-if="route.path.includes('/m/')"
    />
</template>

<script>
import { RouterView, useRoute } from 'vue-router'
import HeaderMain from './components/HeaderMain.vue'
import FooterMain from './components/FooterMain.vue'
import MobileNavigator from './components/MobileNavigator.vue'
import { getColors } from './utils/loadColors'

import('../public/asset/css/layout/mobile.scss')

export default {
    data() {
        return {
            colors: getColors(),
            route: useRoute()
        }
    },
    components: {
        HeaderMain,
        FooterMain,
        MobileNavigator,
        RouterView
    },
    mounted() {
        this.setCssVariables()
    },
    methods: {
        setCssVariables() {
            for (const colorName in this.colors) {
                document.documentElement.style.setProperty(`--${colorName}`, this.colors[colorName])
            }
        }
    }
}
</script>
