<template>
    <section
        :class="['main_section_A']"
        :style="`background-image:url(/asset/img/home_image/home_banner1_2.png);`"
    >
        <article class="main_article_small relative h-full min-w-[1440px] max-w-[1440px] mx-auto">
            <div class="h-full flex flex-col justify-between">
                <div class="translate-y-[40px] md:translate-y-[248px]">
                    <p
                        class="__lang title text-noto-700 text-white md:mb-[4px] h-[52px] md:h-[72px]"
                    >
                        쉽고 간편한 거래,
                    </p>
                    <p
                        class="__lang title text-noto-700 text-white md:mb-[4px] h-[52px] md:h-[72px]"
                    >
                        <span class="text-[#04C800]">카키</span>에서
                    </p>
                </div>

                <div
                    :class="[
                        'login_btn_area -translate-y-[28px] md:-translate-y-[58px] text-noto-500 text-left'
                    ]"
                >
                    <button
                        v-if="!checkLoginStatus()"
                        class="btn_main_green bg-[#0A8A1D] md:mr-[12px]"
                        @click.prevent="router.push({ path: `/login`, query: { caller: 'Fmain' } })"
                    >
                        로그인
                    </button>
                    <button
                        v-else
                        class="btn_main_green bg-[#0A8A1D] md:mr-[12px]"
                        @click.prevent="router.push({ path: `/exchanger` })"
                    >
                        거래소 바로가기
                    </button>
                    <RouterLink
                        v-if="!checkLoginStatus()"
                        :to="'/join'"
                        class="__lang hover:text-inherit"
                    >
                        <button class="btn_white">회원가입</button>
                    </RouterLink>
                    <RouterLink v-else :to="'/trading/myAssets'" class="__lang hover:text-inherit">
                        <button class="btn_white">나의자산 보기</button>
                    </RouterLink>
                </div>
            </div>
            <!-- 공지사항 -->
            <div :class="['absolute bottom-[58px] right-[20px] main_announce']">
                <div class="flex justify-between">
                    <span class="__lang relative text-announce_title text-noto-700">공지사항</span>
                    <span class="clickable" @click="router.push({ path: '/support/notice' })">
                        <ThreeLine class="mt-1" />
                    </span>
                </div>
                <div class="flex w-[300px] h-[73px]">
                    <ul class="text-white">
                        <li v-for="(item, index) in props.noticeData.slice(0, 3)" :key="index">
                            <RouterLink :to="`/support/notice?id=${item.idx}`" class="__lang">
                                <span
                                    class="truncate max-w-[320px] hover:text-inherit hover:border-b hover:border-stroke"
                                    >{{ item?.title || '' }}</span
                                >
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </div>
        </article>
    </section>
</template>

<script setup>
import { RouterLink, useRouter } from 'vue-router'
import { ThreeLine } from '../svg'
import { checkLoginStatus } from '../../utils/member'

const router = useRouter()

const props = defineProps({
    noticeData: {
        type: Array
    }
})
</script>