import MyPageView from '../../pages/MyPageView.vue'

import MyInfo from '../../components/mypage/MyInfo.vue'
import WithdrawMember from '../../components/mypage/WithdrawMember.vue'
import UserAuth from '../../components/mypage/UserAuth.vue'
import SecuritySetting from '../../components/mypage/SecuritySetting.vue'
import KakaoAuth from '../../components/mypage/KakaoAuth.vue'
import UserWallet from '../../components/mypage/UserWallet.vue'
import UserWalletRegister from '../../components/mypage/UserWalletRegister.vue'

export default [
    {
        path: '/mypage',
        name: 'mypage',
        component: MyPageView,
        children: [
            {
                path: 'myinfo',
                name: `myinfo`,
                component: MyInfo
            },
            {
                path: 'myinfo/withdraw',
                name: `myinfoWithdraw`,
                component: WithdrawMember
            },
            {
                path: 'userAuth',
                name: `userAuth`,
                component: UserAuth
            },
            {
                path: 'securitySetting',
                name: `securitySetting`,
                component: SecuritySetting
            },
            {
                path: 'securitySetting/kakao',
                name: `securitySettingKakao`,
                component: KakaoAuth
            },
            {
                path: 'wallet',
                name: `wallet`,
                component: UserWallet
            },
            {
                path: 'wallet/register',
                name: `walletRegister`,
                component: UserWalletRegister
            }
        ]
    }
]
