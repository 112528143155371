<template>
    <div>
        <div class="relative w-[366px] mx-auto">
            <!-- input ----------------------------------------------->
            <input
                @focus="
                    () => {
                        form.id.isFocused = true
                        form.id.firstChecked = true
                    }
                "
                @keydown="preventSpace"
                :class="form.id.isFocused === true ? 'error' : ''"
                class="border px-[17px] w-[366px] mb-2 h-[50px]"
                type="text"
                placeholder="이메일 계정"
                id="id"
                name="id"
                v-model="form.id.value"
                autofocus
            />
        </div>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
        <p>ggg111</p>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { preventSpace } from '../utils/utilsFactory'

const form = ref({
    id: {
        value: '',
        isFocused: false,
        firstChecked: false
    }
})

onMounted(() => {})
</script>

<style scoped></style>
