/* eslint-disable consistent-return */
/* 메인 페이지 관련 데이터를 서버에 요청하는 로직 모음 */
import axios from 'axios';
import { useTop10Store } from '../../stores/main'
import { requestHeader } from '../config';

/**
 * Top 10 데이터 요청하는 함수
 * @returns success: response.data, fail null
 */
export async function getTop10Data( market2 ){
  try {
      const mainPage = useTop10Store();
      const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/top10`, { market2 }, requestHeader() );
      mainPage.setMarketTop10( response.data.result.marketcap10 )
      mainPage.setVolumeTop10( response.data.result.volume10 )
      mainPage.setRocTop10( response.data.result.roc10 )
  } catch (error) {
      console.error(error)
  }
}