<template>
    <div class="securifySetting01 w-[1030px] bg-[#fff] basicBoxShadow">
        <div
            v-if="!dataLoading"
            class="w-[1050px] h-[800px] bg-[#fff] flex justify-center items-center"
        >
            <v-progress-circular
                width="10"
                size="80"
                indeterminate
                color="#305C3A"
            ></v-progress-circular>
        </div>
        <!-- title 영역 -->
        <div v-else-if="dataLoading" class="tm1 border-b px-4 py-2">
            <p class="__lang subTitle text-noto-700 flex">지갑 주소</p>
        </div>
        <!-- content 영역 -->
        <section
            class="securifySetting02 w-[1030px] bg-[#fff] basicBoxShadow flex flex-col items-center"
        >
            <br />
            <!-- title -->
            <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">지갑 주소 등록</h1>
            <!-- underbar -->
            <div class="w-[500px] border-b mb-5"></div>
            <br />
            <p class="__lang text-noto-300 text-[14px] mb-2">본인 소유의 개인지갑을 등록해주세요</p>
            <p class="__lang text-noto-300 text-[14px]">개인지갑 등록 유의사항을 반드시 확인해주세요.</p>
            <br /><br />
            <div>
                <ul class="mt-5">
                    <li class="flex flex-col justify-between px-3 mb-5">
                        <label class="__lang text-[14px]">개인지갑</label>
                        <input
                            class="__lang text-[14px] default_btn bg-[#f2f2f2] pa-4 font-medium"
                            :value="metaMaskData.name"
                            readonly
                        />
                    </li>
                    <li class="flex flex-col justify-between px-3 mb-5 mt-2">
                        <label class="__lang text-[14px]">지갑주소</label>
                        <input
                            class="__lang text-[14px] default_btn bg-[#f2f2f2] pa-4 font-medium"
                            :value="metaMaskData.address"
                            readonly
                        />
                    </li>
                    <li class="flex flex-col justify-between px-3 mb-5 mt-2">
                        <label class="__lang text-[14px]">네트워크</label>
                        <input
                            class="__lang text-[14px] default_btn bg-[#f2f2f2] pa-4 font-medium"
                            :value="metaMaskData.network"
                            readonly
                        />
                    </li>
                </ul>
            </div>
            <div class="flex flex-col justify-center items-center text-[14px] mt-5">
                <span class="flex justify-start items-center j_bar_a w-[366px] mb-[12px]">
                    <img
                        :src="all_agree ? `/asset/img/OkDoneType1.svg` : `/asset/img/OkType1.svg`"
                        style="width: 24px; height: 24px"
                        class="clickable mr-3"
                        @click.prevent="changeAllAgreeOrNot"
                    />
                    <span :class="all_agree ? 'text-main_color text-noto-700' : 'font-medium'"
                        >모두 동의합니다.</span
                    >
                </span>
            </div>
            <div class="w-[366px] sub_border1--bottom"></div>
            <section
                class="flex flex-col justify-center items-center text-[14px] text-gray mt-[12px] mb-2"
            >
                <div
                    v-for="(item, index) in policyArray || []"
                    v-bind:key="item"
                    class="flex items-center mb-5"
                >
                    <span class="mr-3">
                        <img
                            :src="
                                checkedState[index]
                                    ? `/asset/img/CheckDoneType1.svg`
                                    : `/asset/img/CheckType1.svg`
                            "
                            style="width: 20px; height: 20px"
                            class="clickable"
                            @click="()=>{
                                if( index === 0 ){
                                    if( !personal_wallet_resister_notice ){
                                        personal_wallet_resister_notice = true;
                                    } else {
                                        personal_wallet_resister_notice = false;
                                    }
                                    
                                } else if( index === 1 ){
                                    personal_info_collection_usage_agreement_notice = true;
                                }
                                return;
                            }"
                        />
                    </span>
                    <span
                        :class="checkedState[index] ? 'text-main_color font-medium' : `font-normal`"
                        class="w-[316px] flex justify-start clickable"
                        @click="()=>{
                            if( index === 0 ){
                                if( !personal_wallet_resister_notice ){
                                    personal_wallet_resister_notice = true;
                                } else {
                                    personal_wallet_resister_notice = false;
                                }
                            } else if( index === 1 ){
                                personal_info_collection_usage_agreement_notice = true;
                            }
                            return;
                        }"
                    >
                        {{ item.title }}
                    </span>
                    <span>
                        <img
                            :src="`/asset/img/simpleNextBtn.svg`"
                            style="width: 8px; height: 12px"
                            class="clickable"
                            @click="()=>{
                                if( index === 0 ){
                                    if( !personal_wallet_resister_notice ){
                                        personal_wallet_resister_notice = true;
                                    } else {
                                        personal_wallet_resister_notice = false;
                                    }
                                } else if( index === 1 ){
                                    personal_info_collection_usage_agreement_notice = true;
                                }
                                return;
                            }"
                        />
                    </span>
                </div>
            </section>
            <div v-if="all_agree">
                <button
                    class="btn_main_green default_btn"
                    @click.prevent="
                        () => {
                            if (Number(userAuthKakao) === 1 || Number(userAuthOTP) === 1) {
                                securityAuthForMetaMaskWallet()
                            } else {
                                makePleaseLink2ChannelAuthAlert()
                            }
                        }
                    "
                >
                    {{ isUseFiatCurrency === 0 ? `2채널 인증` : '보안 인증' }}
                </button>
            </div>
            <div v-else>
                <div
                    class="bg-stroke default_btn text-white flex justify-center items-center"
                    @click.prevent
                >
                    {{ isUseFiatCurrency === 0 ? `2채널 인증` : '보안 인증' }}
                </div>
            </div>
            <div class="flex w-[366px] mt-3">
                <p class="__lang underline clickable" @click="newWindow">지갑 주소가 다른가요?</p>
            </div>

            <br /><br />
        </section>

        <!-- 주소 등록 버튼 -->
        <v-dialog v-model="double_channel_warning_alert" persistent width="470px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">안내</div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        class="modal_cancel_btn border w-[77px] h-[43px]"
                        color="black"
                        @click="changeDoubleChannelWarningAlertFalse"
                    >
                        닫기
                    </v-btn>
                    <v-btn
                        :class="`modal_confirm_btn w-[90px]`"
                        color="white"
                        @click="$router.push({ path: '/mypage/securitySetting' })"
                    >
                        이동하기
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- alert용 중첩 가능한 모달 -->
        <v-dialog v-model="nested_dialog" persistent width="470px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ modal_title }}
                    </div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        v-if="security_auth_process === 'done'"
                        class="modal_cancel_btn border w-[77px] h-[43px]"
                        color="black"
                        @click="()=>{
                            $router.push({ path : `/mypage/wallet` })
                        }"
                    >
                        닫기
                    </v-btn>
                    <v-btn
                        v-else-if="step !== 'auth_done' && step !== 'auth_check'"
                        class="modal_cancel_btn border w-[77px] h-[43px]"
                        color="black"
                        @click="cancelAuth"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        :class="`modal_confirm_btn w-[90px]`"
                        color="white"
                        @click="()=>{
                            if( step === 'auth_done' ){
                                excuteModalAction();
                            } else if( security_auth_process === 'done' ){
                                nested_dialog = false;
                                changeSecurityAuthDialogFalse();
                                $router.push({ path : `/balance/deposit` });
                            } else {
                                nested_dialog = false;
                                changeSecurityAuthDialogFalse();
                            }
                        }"
                    >
                        {{ security_auth_process === 'done' ? '이동하기' : '확인' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 단순 확인용 얼럿 -->
        <v-dialog v-model="alert_dialog" width="470px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        {{ modal_title }}
                    </div>
                    <br />
                    <div class="text-[15px]" v-html="nested_msg"></div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        :class="`modal_confirm_btn w-[${modal_btn_width}px]`"
                        color="white"
                        @click="completeWalletUpdate"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="security_auth_dialog" persistent width="486px">
            <v-card class="pa-4">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">2채널 인증</div>
                    <br />
                    <div v-if="security_auth_process === 0" class="text-[15px] flex flex-col">
                        <div
                            v-if="Number(userAuthKakao) === 1"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToKakaoAuth()"
                        >
                            카카오페이 인증
                        </div>
                        <div
                            v-else
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#BEBEBE]"
                            @click.prevent
                        >
                            카카오페이 인증
                        </div>
                        <div
                            v-if="Number(userAuthOTP) === 1"
                            class="w-[400px] h-[49px] pa-3 border mb-3 clickable"
                            @click.prevent="moveToGoogleOTPAuth()"
                        >
                            구글 어센티케이터 인증
                        </div>
                        <div
                            v-else
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#BEBEBE]"
                            @click.prevent
                        >
                            구글 어센티케이터 인증
                        </div>
                    </div>
                    <!-- 카카오 페이 인증을 클릭했을 때 -->
                    <div v-else-if="security_auth_process === 1" class="text-[15px] flex flex-col">
                        <div
                            class="w-[400px] h-[49px] pa-3 border mb-3 bg-[#f2f2f2]"
                            @click.prevent
                        >
                            카카오페이 인증
                        </div>
                        <div
                            class="w-[400px] h-[49px] flex justify-center items-center text-white bg-main_color clickable rounded mb-[11px]"
                            @click="prepareVerifyKakao()"
                        >
                            인증완료
                        </div>
                        <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                            <p>등록된 휴대폰 번호로 카카오페이 인증 요청이 발송되었습니다.</p>
                            <p>
                                카카오톡 안내에 따라 인증 절차를 완료해주세요. ({{
                                    authRemainTime || '0:00'
                                }})
                            </p>
                        </span>
                    </div>

                    <!-- 구글 어센티케이터를 클릭했을 때 -->
                    <div
                        v-else-if="security_auth_process === 2"
                        class="text-[15px] flex flex-col w-[400px]"
                    >
                        <div class="w-[400px] h-[49px] pa-3 border bg-[#f2f2f2]" @click.prevent>
                            구글 어센티케이터 인증
                        </div>
                        <div class="flex items-center justify-center mt-[10px] mb-[11px]">
                            <input
                                @keydown="handleKeydown"
                                v-model="userOtpVerifyNumber"
                                class="w-[321px] h-[50px] sub_border1 pa-4"
                                type="text"
                                placeholder="인증번호를 입력해주세요"
                                :disabled="otpVerifyState === 'ready' || otpVerifyState === 'done'"
                                :class="isOtpAuthCompleted ? 'bg-[#F2F2F2]' : ''"
                                maxlength="6"
                            />
                            <button
                                v-if="otpVerifyState === 'send'"
                                class="mypage_btn_type1"
                                :class="[ !userOtpVerifyNumber || userOtpVerifyNumber.length < 5 ? 'bg-[#F2F2F2] text-gray sub_border1' : '']"
                                @click.prevent="prepareVerifyOtp"
                                :disabled="!userOtpVerifyNumber || userOtpVerifyNumber.length < 5"
                            >
                                인증하기
                            </button>
                            <button
                                v-else
                                class="mypage_btn_type1 text-gray sub_border1 bg-[#F4F4F4]"
                                @click.prevent
                            >
                                인증하기
                            </button>
                        </div>

                        <span class="text-[14px] text-[#0A8A1D] text-noto-350">
                            <p>인증번호 6자리를 작성해주세요. ({{ authRemainTime || '0:00' }})</p>
                        </span>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12">
                    <v-btn
                        class="w-[90px] h-[43px] border"
                        color="black"
                        @click="cancelWithdrawProcess"
                    >
                        취소
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 개인지갑 등록 유의사항 -->
        <v-dialog v-model="personal_wallet_resister_notice" persistent width="486px">
            <v-card class="px-[19px] py-[11px]">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        개인지갑 등록 유의사항
                    </div>
                    <br />
                    <div class="text-[15px]">
                        <ul class="list-disc pl-[20px]">
                            <li>본인 소유임이 확인 완료된 '개인지갑 주소'로 입출금이 가능합니다.</li>
                            <li>개인지갑은 메타마스크만 등록하실 수 있으며, 입출금 시 카키에 등록된 개인지갑 주소 및 네트워크(전송 방식)를 반드시 확인 후 진행해 주세요.</li>
                            <li>타인 소유의 개인지갑 등록을 요청받은 경우 금융사기, 보이스 피싱 등의 금융 범죄에 연루되실 수 있으며, 타인의 지갑주소를 등록하여 발생하는 문제에 대한 책임은 회원님께 있습니다.</li>
                            <li>타인에게 개인지갑 프라이빗키(니모닉 코드,시드키 등)를 공유하실 경우 회원님의 디지털 자산을 탈취당할 수 있으니 반드시 안전하게 보관해 주세요.</li>
                        </ul>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12 mr-[19px] mb-[12px]">
                    <v-btn
                        class="modal_cancel_btn border w-[77px] h-[43px]"
                        color="black"
                        @click="()=>{
                            checkedState[0] = false;
                            personal_wallet_resister_notice = false;
                        }"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        :class="`modal_confirm_btn w-[90px]`"
                        color="white"
                        @click="()=>{
                            checkedState[0] = true;
                            personal_wallet_resister_notice = false;
                        }
                            "
                    >
                        동의
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 개인정보 수집 및 이용 동의 -->
        <v-dialog v-model="personal_info_collection_usage_agreement_notice" persistent width="486px">
            <v-card class="px-[19px] py-[11px]">
                <v-card-text class="flex flex-col">
                    <div class="text-noto-700 text-[18px] mb-3">
                        개인정보 수집 및 이용동의
                    </div>
                    <br />
                    <div class="text-[15px]">
                        <div>
                            카키는 회원님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. 회원님의 동의를 통해 취득한 개인정보는 다음의 목적으로만 이용됩니다.
                        </div>
                        <br/><br/>
                        <ul class="list-disc pl-[20px]">
                            <li>수집 및 이용 항목: 본인 디지털 자산 주소</li>
                            <li>수집 및 이용 목적: <span class="text-noto-700 text-[17px]">개인 지갑주소로 입출금 지원</span></li>
                            <li>보유 및 이용 기간: <span class="text-noto-700 text-[17px]">회원 탈퇴 후 5년 보관</span></li>
                        </ul>
                        <br/><br/>
                        <div>
                            본 동의를 거부할 수 있으며, 동의를 거부할 시 요청하신 지갑주소 등록이 불가능합니다.
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mt-12 mr-[19px] mb-[12px]">
                    <v-btn
                        class="modal_cancel_btn border w-[77px] h-[43px]"
                        color="black"
                        @click="()=>{
                            checkedState[1] = false;
                            personal_info_collection_usage_agreement_notice = false
                        }"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        :class="`modal_confirm_btn w-[90px]`"
                        color="white"
                        @click="()=>{
                            checkedState[1] = true;
                            personal_info_collection_usage_agreement_notice = false;
                        }"
                    >
                        동의
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { registerWallet } from '@/api/user/index.js'
import { useUserStore } from '../../stores/user'
import UtilSessionStorage from '../../utils/session'
import MetaMaskWallet from '../../utils/blockChain/metaMaskWallet.js'
import { selectFiatMarketFlag } from '../../api/fiat'
import { initMemberInfomation, logOut } from '../../api/user'
import { requestOutAuth } from '../../api/blockChain'
import { sendSignKakao, sendVerifyKakao } from '../../api/auth/kakao'
import { verifyOtpKey } from '../../api/auth/otp'
import UtilLocalStorage from '../../utils/storage/localStorage'

const sessionStorage = new UtilSessionStorage()
const UseLocalStorage = new UtilLocalStorage()

const userStore = useUserStore()

const router = useRouter()

const walletList = ref([])
const dataLoading = ref(false)
let id = ''

const isNeedCancelBtn = ref(false)

const faqEndpoint = `${process.env.VUE_APP_BASE_URL}/support/faq?faqid=2`;

function newWindow(){
    window.open(`${faqEndpoint}`, `_blank`);
}

// 원화마켓 사용 여부
const isUseFiatCurrency = ref(0)
// 유저가 2채널 인증을 했는지 판단한 결과
const isUserDid2ChannelAuth = ref(false)
// 유저의 카카오, 구글 인증 정보
const userAuthKakao = ref(0)
const userAuthOTP = ref(0)

// 인증 컨트롤용 변수
// 확인까지 남은 인증시간 interval 저장용 변수
let authVerifyIntervalId = null
// 유저에게 보여줄 남은 시간
const authRemainTime = ref('0:00')
// 인증 제한시간 (초 단위)
const authLimitTime = ref(180)
// 인증버튼 눌렀을 때 저장될 인증 시작 시간
const authStartTime = ref(null)
// 인증 잔여시간 (단위 : 밀리초)
const authVerifyTime = ref(0)

// 카카오페이 인증 관리용 변수
// 카카오페이 인증 진행 상태
const kakaoVerifyState = ref(null)

// 핸드폰 인증
const retUrl = ref('')
const ordridxx = ref('')
const BACKEND_URL = ref('')

// OTP 인증 관리용 변수
// OTP 인증 번호
const userOtpVerifyNumber = ref(null)
// OTP 인증 진행 상태
const otpVerifyState = ref(null)
// OTP 인증 완료 여부
const isOtpAuthCompleted = ref(false)

const policyArray = getPolicyArray()

const metamask = new MetaMaskWallet()

const metaMaskData = ref({
    name: '메타마스크',
    address: '',
    network: 'Ethereum'
})

// 단순 확인용 얼럿 상태 관리
const alert_dialog = ref(false)
// 개인지갑 등록 유의사항 모달 상태 관리
const personal_wallet_resister_notice = ref(false)
// 개인정보 수집 및 이용동의 모달 상태 관리
const personal_info_collection_usage_agreement_notice = ref(false)

const modal_title = ref('')
const modal_btn_width = ref(90);
const step = ref(null)
const security_auth_dialog = ref(false) // 보안 인증에 사용되는 모달창
const security_auth_process = ref(0)
const nested_dialog = ref(false) //
const nested_title = ref('안내')
const nested_msg = ref('') // alert용 모달창 메세지
const terms_dialog = ref(false)
const checkedState = ref([])
const double_channel_warning_alert = ref(false)
/**
 * 모두 동의 혹은 비동의를 한번에 수행하기 위한 함수
 */
function changeAllAgreeOrNot() {
    if (all_agree.value === false) {
        checkedState.value = checkedState.value.map(() => true)
    } else {
        checkedState.value = checkedState.value.map(() => false)
    }
}

function changeAlertDialogTrue() {
    alert_dialog.value = true
}
function changeAlertDialogFalse() {
    alert_dialog.value = false
}
function changeNestedDialogTrue() {
    nested_dialog.value = true
}
function changeNestedDialogFalse() {
    nested_dialog.value = false
}
function changeSecurityAuthDialogTrue() {
    security_auth_dialog.value = true
}
function changeSecurityAuthDialogFalse() {
    security_auth_dialog.value = false
}
function changeDoubleChannelWarningAlertTrue() {
    double_channel_warning_alert.value = true
}
function changeDoubleChannelWarningAlertFalse() {
    double_channel_warning_alert.value = false
}
function closeModalAndMakeCanceledAlert() {
    changeSecurityAuthDialogFalse()
    modal_title.value = `안내`
    nested_msg.value = `보안인증이 취소되었습니다.`
    changeAlertDialogTrue()
}
function makePleaseLink2ChannelAuthAlert() {
    nested_msg.value = `2채널 인증을 등록한 후 이용할 수 있습니다. 2채널 인증 페이지로 이동하시겠습니까?`
    changeDoubleChannelWarningAlertTrue()
}

// computed 속성
/**
 * 모든 항목이 동의 되었는지 계산하는 computed 속성
 */
const all_agree = computed(() => {
    if (!checkedState.value || !checkedState.value.length || checkedState.value.length < 1) {
        return false
    }
    let result = true
    for (let i = 0; i < checkedState.value.length; i++) {
        if (checkedState.value[i] === false) {
            result = false
        }
    }
    return result
})

onMounted(async () => {
    id = sessionStorage.getUserId()

    watch(kakaoVerifyState, () => {
        if (kakaoVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0) {
                        clearInterval(authVerifyIntervalId)
                        kakaoVerifyState.value = 'ready'
                        modal_title.value = '안내'
                        nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`
                        changeAlertDialogTrue()
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(authVerifyTime.value / 60)}`.slice(
                        -2
                    )}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    watch(otpVerifyState, () => {
        if (otpVerifyState.value === 'send') {
            authVerifyIntervalId = setInterval(() => {
                if (authStartTime.value) {
                    let tempValue = Number(new Date().getTime()) - Number(authStartTime.value)
                    tempValue = Math.floor(tempValue / 1000)
                    authVerifyTime.value = authLimitTime.value - tempValue
                    if (authVerifyTime.value < 0) {
                        clearInterval(authVerifyIntervalId)
                        otpVerifyState.value = 'ready'
                        makeKakaoAuthFailAlert()
                        return
                    }
                    authRemainTime.value = `${`${Math.floor(authVerifyTime.value / 60)}`.slice(
                        -2
                    )}:${`0${Math.floor(authVerifyTime.value % 60)}`.slice(-2)}`
                }
            }, 1000)
        }
    })

    selectFiatMarketFlag({ id }).then((response) => {
        if (response === 1) {
            isUseFiatCurrency.value = 1
        }
    })

    initMemberInfomation(id).then((response) => {
        if (response) {
            userAuthKakao.value = response.authKakao === 1
            userAuthOTP.value = response.authOtp === 1
        } else {
            logOut()
            router.go(0)
        }
    })

    // watch(alert_dialog, (newValue, oldValue) => {
    //     if (oldValue === true && newValue === false && security_auth_process.value === 'done') {
    //         router.push({ path: '/mypage/wallet' })
    //     }
    // })

    for (let i = 0; i < policyArray.length; i++) {
        checkedState.value.push(false)
    }

    try {
        metaMaskData.value.address = sessionStorage.getItem('meta')
    } catch (error) {
        console.error(error)
    }

    dataLoading.value = true
})

// 2채널 인증 모달에 들어가는 정보들을 전부 초기값으로 세팅합니다.
function initSecurityModal() {
    // 인증 단계 초기화
    security_auth_process.value = 0
    // 카카오 인증 상태 초기화
    kakaoVerifyState.value = null
    // OTP 인증 상태 초기화
    otpVerifyState.value = null
    // user가 입력한 verify code 초기화
    userOtpVerifyNumber.value = null
    // 인증 잔여시간 초기화
    authVerifyTime.value = 0;
    // interval 초기화
    clearInterval(authVerifyIntervalId);
}

function toggleImgSelected(index) {
    checkedState.value[index] = !checkedState.value[index]
}

function getPolicyArray() {
    return [
        {
            title: '[필수] 개인지갑 등록 유의사항',
            neccessary: true
        },
        {
            title: '[필수] 개인정보 수집 및 이용동의',
            neccessary: true
        }
    ]
}

// 2채널 인증 모달 띄우는 함수
async function securityAuthForMetaMaskWallet() {
    modal_title.value = '2채널 인증'
    initSecurityModal()
    changeSecurityAuthDialogTrue()
}

// 지갑 주소 업데이트가 끝났을 때 호출될 함수
function completeWalletUpdate() {
    changeSecurityAuthDialogFalse();
    changeAlertDialogFalse();
    if( security_auth_process.value === 'done' ){
        afterRegisterWalletSuccess();
    }
}

/**
 * 모바일 인증을 하기 전에 실행될 함수.
 */
function prepareMobileCertification() {
    changeSecurityAuthDialogFalse()
    const routeData = router.resolve({ name: 'MobileAuth', query: { data: 'abcd' } })
    const attr = `toolbar=yes, scrollbars=no, resizable=no, left=${0}, top=${0}, width=${440}, height=${843}`
    window.open(routeData.href, '', attr)
}

/**
 * 모바일 인증을 위한 함수입니다.
 */
function mobileCertification() {
    const response = true
    // 0번인 join, main에서 다음 과정으로 넘어갑니다.
    if (response) {
        nested_msg.value = `핸드폰 본인 인증이 완료되었습니다.`
        changeNestedDialogTrue()
    } else {
        cantFindUserInfoAlert()
    }
}

/**
 * 회원 정보 부재 알림 얼럿
 */
function cantFindUserInfoAlert() {
    modal_title.value = `안내`
    nested_msg.value = `일치하는 회원정보를 찾을 수 없습니다. 휴대폰 번호를 다시 확인해주세요.`
    changeNestedDialogTrue()
}

/**
 * 보안인증 완료 후 메타마스크 서명을 준비합니다.
 */
async function excuteModalAction() {
    let result = null
    // 메타마스크 설명 요청 모달창을 띄웁니다.
    changeNestedDialogFalse();
    changeSecurityAuthDialogFalse();
    try {
        
    
        if (step.value === 'auth_done') {
            const message = `${process.env.VUE_APP_SITE_NAME}에서 요청한 서명입니다.

메타마스크 지갑의 본인 소유 확인을 진행합니다.
본인 소유 확인을 위한 서명이 완료되면
등록된 지갑주소로의 입출금이 가능합니다.

입출금시 등록된 지갑주소를 확인 후 진행해주세요.`

            step.value = 'metamask_sign'
            result = await metamask.sign(message)
            if (result) {
                const response = await registerWallet(
                    id,
                    'ethereum',
                    'metamask',
                    metaMaskData.value.address,
                    '나의 지갑',
                    1,
                    1
                );
                if (response?.result?.message?.includes(`success`)) {
                    modal_title.value = `주소 등록 완료`
                    nested_msg.value = `지갑 주소가 정상적으로 등록되었습니다.`
                    security_auth_process.value = 'done'
                } else if( response.descript.includes('same address') ){
                    modal_title.value = `안내`;
                    nested_msg.value = `이미 등록된 메타마스크 지갑 주소입니다.<br/>같은 주소로 중복 등록을 할 수 없습니다.`;
                } else {
                    modal_title.value = `승인오류 안내`
                    nested_msg.value = `예상하지 못한 오류가 발생했습니다.<br/>
        일시적인 현상이거나 네트워크 문제일 수 있으니,<br/>
        잠시 후 다시 시도해주세요.<br/><br/>
        계속 발생하는 경우 1:1 문의를 통해 확인해주세요`
                }

                changeAlertDialogTrue()
            }
        }
    } catch (error) {
        console.error(error)    
    }

    if (!result) {
        // 메타마스크 서명 팝업에서 닫기 버튼을 누르거나 취소한 경우의 처리
        modal_title.value = '안내';
        nested_msg.value = `서명이 취소되었습니다.<br/>메타마스크 서명 후 지갑 주소를 등록할 수 있습니다.`;
        changeSecurityAuthDialogFalse()
        changeNestedDialogFalse()
        changeAlertDialogTrue()
        return
    }
}

/**
 * 카카오 인증 기준으로 모달 컴포넌트를 다시 랜더링합니다.
 */
function moveToKakaoAuth() {
    kakaoVerifyState.value = 'send'
    security_auth_process.value = 1
    startAuthBeforeWithdraw(1)
}
/**
 * 구글 OTP 인증 기준으로 컴포넌트를 다시 랜더링합니다.
 */
function moveToGoogleOTPAuth() {
    otpVerifyState.value = 'send'
    security_auth_process.value = 2
    startAuthBeforeWithdraw(2)
}

// 인증 요청 발송
/**
 * type 1 - 카카오
 * type 2 - 구글 어센티케이터
 */
async function startAuthBeforeWithdraw(type) {
    try {
        authStartTime.value = Number(new Date().getTime())
        // 카카오 인증일 경우의 처리 로직
        if (type === 1) {
            const response2 = await sendSignKakao({ id: id })
            if (response2?.status !== 'success') {
                alert(response2.data.title)
            }
        }
    } catch (error) {
        console.error(error)
    }
}

// 카카오 인증 완료 버튼 클릭 시 시행될 함수
async function prepareVerifyKakao() {
    try {
        step.value = 'auth_check';
        const response = await sendVerifyKakao({ id: id })
        if (response?.status === 'success') {
            step.value = 'auth_done'
            modal_title.value = `안내`
            nested_msg.value = `성공적으로 인증되었습니다.`;
        } else {
            modal_title.value = `안내`
            nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`;
        }
        // changeAlertDialogTrue();
        changeNestedDialogTrue();
        kakaoVerifyState.value = null
        clearInterval(authVerifyIntervalId);
    } catch (error) {
        console.error(error)
    }
}

// Otp, 구글 어센티케이터 인증 완료 버튼 클릭 시 시행될 함수
async function prepareVerifyOtp() {
    try {
        step.value = 'auth_check';
        const requestData = {
            id: sessionStorage.getUserId(),
            content: userOtpVerifyNumber.value
        }
        const response = await verifyOtpKey(requestData)
        if (response?.status === 'success') {
            step.value = 'auth_done'
            modal_title.value = `안내`
            nested_msg.value = `성공적으로 인증되었습니다.`;
        } else {
            modal_title.value = `안내`
            nested_msg.value = `올바르지 않은 구글 어센티케이터 인증코드입니다.<br/>전송하기 버튼을 다시 눌러주세요. `;
        }
        // changeAlertDialogTrue();
        changeNestedDialogTrue();
        clearInterval(authVerifyIntervalId);
    } catch (error) {
        console.error(error)
    }
}

/**
 * 유저의 2채널 인증 정보 등의 데이터를 가져옵니다.
 */
async function initUserInformation() {
    try {
        const response = await initMemberInfomation(id.value)
        if (Number(response.authOtp) === 1) {
            isUserDid2ChannelAuth.value = true
            userAuthOTP.value = 1
        }
        if (Number(response.authKakao) === 1) {
            isUserDid2ChannelAuth.value = true
            userAuthKakao.value = 1
        }
        userStore.setUserSecurityLevel(response.securityLevel)
    } catch (error) {
        console.error(error)
    }
}

/**
 * 2채널 인증, 혹은 보안설정 모달에서 취소 버튼 클릭할 때 실행될 함수
 */
function cancelAuth() {
    changeSecurityAuthDialogFalse()
    changeNestedDialogFalse()
    changeAlertDialogFalse()
    modal_title.value = `안내`
    nested_msg.value = `서명이 취소되었습니다.<br/>메타마스크 서명 후 지갑 주소를 등록할 수 있습니다.`
    changeAlertDialogTrue()
}

/**
 * 2채널 인증 모달창에서 취소 버튼을 눌렀을 때 실행될 함수 입니다.
 */
function cancelWithdrawProcess() {
    // 모달 상태 초기화
    initSecurityModal()
    // 모달 닫기
    closeModalAndMakeCanceledAlert()
}

// 인증 실패 안내 얼럿
function makeKakaoAuthFailAlert() {
    // modalAction.value = '인증실패';
    modal_title.value = '안내'
    // modal_btn_msg.value = '확인';
    nested_msg.value = `인증이 완료되지 않았거나 회원정보가 일치하지 않습니다. 다시 시도해주세요<br/><br/>
개명, 휴대폰 번호 변경, 기기 변경 등의 경우 1:1 문의를 통해 확인해주세요.`
    changeAlertDialogTrue();
}

function afterRegisterWalletSuccess(){
    modal_title.value = '안내';
    nested_msg.value = `지갑 주소 등록이 완료되어 입출금이 가능합니다. 입출금 페이지로 이동하시겠습니까?`;
    changeNestedDialogTrue();
}
</script>

<style>
.securifySetting02 input {
    outline: none;
    border: none;
    box-shadow: none;
}
</style>
