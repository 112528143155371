<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<rect width="16" height="16" fill="url(#pattern0)"/>
<defs>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_631_132046" transform="scale(0.01)"/>
</pattern>
<image id="image0_631_132046" width="100" height="100" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC20lEQVR4nO3cy4oTQRTG8Rb3iuhecaXMXl0cqgwJJFUZYTbjg7hxKygmVEXBhS/hzvdw4RuIiD6CG4WRyhAvYXLpdHWfr+zvDwdmM3S6fpm+0UxVMcYYY4wxxhhjjDHWk+zi+I6J06fbRvsz9iobpqc2+rNto/0ZexVBwCIIWAQBiyBgEQQsgoBFELAIAhZB/jR8eXLdBvfezsa3Kq0Icp7MptdMcB/On064z/J6ervSiCDVGsZqlFD6DiIXYiii9BlEtmIooSCCDNPJdTGRtrdhgvu4a9/TmOg/DaK/WfURRFbf2uC+2zAdtbqNPTCWIAlucXyj6huIrC9UCyjQGEggsmmhMqLAY6CAyK6FyoBSBAYCiOy7UA1QisHQBpGaC3UISlEYmiBSF+MAlOIwtEDkUIwaKEViaIBIU4w9UIrF6BpEcmFsQSkao0sQyY1xAUrxGF2BDGs8N/q9UMG9rYNion9cextoGF2ASINvrYlulv0vChmjbRDJcAjJjQKN0SaIZDye50KBx2gLRFo4uTZFKQKjDRBp8UrnUJRiMHKDSAeXnXVRisLICdLlPYDZE6U4jFwgGjdkZgdKkRg5QDTvjs0GlGIxmoIgPKowayhFYzQBQcBYRykeowmIiZNHJrif2hirTHBPisdoeshKv2ui/6GN8V/V9KS+DcUQo3uQTSiGGLo3hn+jGGJgPDqxCSVdefGcgQGSOn13enn5AyvzzUW2FkHAIghYBAGLIGARBCyCgEUQsAgCFkHAIghYBAGLIGARBCyCgEUQsAgClg1+su/Lbv0b96xzkMF8fKS/4x50FECqs+qSif6L/s57wNEASYet6F7o77wHHCWQ0Xx01Qb/TX8BPNgogaTsq8mD5X9FUF8EDzSKIKmH0d+30X3VXwgPMsogqXtvJldMcM95ovcYIP9cfc393eV9SnqZroczmI+PtBkYY4wxxhhjjDHGGGOMMcYqsH4B8C33u68qkbsAAAAASUVORK5CYII="/>
</defs>
</svg>

</template>