<!-- 마이페이지 -> 나의지갑 -->
<template>
  <div class="securifySetting01 w-[1030px] bg-[#fff] basicBoxShadow">
    <!-- title 영역 -->
    <div v-if="dataLoading" class="tm1 border-b px-4 py-2">
        <p class="__lang subTitle text-noto-700 flex">
          나의 지갑
        </p>
    </div>
    <!-- content 영역 -->
    <section
      v-if="dataLoading"
      class="securifySetting02 w-[1030px] bg-[#fff] basicBoxShadow flex flex-col items-center"
    >
      <br />
      <!-- title -->
      <h1 class="__lang subTitle mt-5 mb-3 text-[#222421] text-noto-700">지갑 등록</h1>
      <!-- underbar -->
      <div class="w-[500px] border-b mb-5"></div>
      <br/>
      <p class="__lang text-[14px] mb-2">'본인 소유 확인'이 완료된 개인지갑 주소로만 입출금이 가능하며</p>
      <p class="__lang text-[14px]"><strong class="text-green">메타마스크 1개만 등록</strong> 할 수 있습니다.</p>
      <br/><br/>
      <button v-if="walletList.length < 1 || !walletList" class="btn_main_green w-[366px] h-[50px]" @click.prevent="prepareMakeNewAddress">
        메타마스크 등록
      </button>
      <br /><br />
    </section>
    <section class="securifySetting03 dataTable min-h-[400px] mb-5">
      <table>
        <colgroup>
          <col style="width:150px;"/>
          <col style="width:360px;"/>
          <col style="width:460px;"/>
          <col style="width:60px;"/> <!-- 삭제 버튼들 -->
        </colgroup>
        <thead>
          <tr class="v-custom-table contents_secondary text-noto-500">
            <th class="__lang text-left pl-3">지갑종류</th>
            <th class="__lang text-left">네트워크</th>
            <th colspan="3" class="__lang text-left">주소</th>
          </tr>
        </thead>
        <tbody class="text-[13px]">
          <tr v-for="(item, index) in walletList || []" :key="index" class="h-[50px] sub_border1--bottom">
            <td class="pl-3 flex items-center h-[50px]">
              <div class="pr-2"><img :src="item.image" style="width: 25px; height: 25px;" /></div>
              <div>{{ item.name === 'metamask' ? '메타마스크' : item.name || '' }}</div>
            </td>
            <td>
              {{ changeToUpperCase( item.network ) || '' }}
            </td>
            <td>
              {{ item.address || '' }}
            </td>
            <td class="__lang text-gray underline clickable pr-3" @click.prevent="prepareDeleteWallet( {index, name : item.name, network : item.network, address : item.address } )">
              삭제
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="walletList.length < 1 || !walletList" class="w-full h-[360px] flex justify-center items-center bg-[#F8F8F8] text-[11px] text-noto-500 text-[#959595]">
        등록된 지갑 주소가 없습니다.
      </div>
    </section>
    

    <!-- 주소 등록 버튼 -->
    <!-- alert용 중첩 가능한 모달 -->
    <v-dialog v-model="nested_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn class="modal_cancel_btn border w-[77px] h-[43px]" color="black" @click="changeNestedDialogFalse">
                    취소
                </v-btn>
                <v-btn :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="excuteModalAction">
                    {{ modal_btn_msg }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 단순 알림용 모달 -->
    <v-dialog v-model="alert_dialog" width="470px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn :class="`modal_confirm_btn w-[${modal_btn_width}px]`" color="white" @click="changeAlertDialogFalse">
                    {{ modal_btn_msg }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 확인 선택용 모달 -->
    <v-dialog v-model="confirm_dialog" persistent width="486px">
        <v-card class="pa-4">
            <v-card-text class="flex flex-col">
                <div class="text-noto-700 text-[18px] mb-3">
                    {{ modal_title }}
                </div><br />
                <div class="text-[15px]" v-html="nested_msg">
                </div>
            </v-card-text>
            <v-card-actions class="justify-end mt-12">
                <v-btn 
                  class="modal_cancel_btn" color="black" 
                  @click="changeConfirmDialogFalse"
                >
                  닫기
                </v-btn>
                <v-btn v-if="step === `auth_complete`" class="modal_confirm_btn w-[90px]" color="white" @click="()=>{
                    $router.push({path:'/mypage/securitySetting'})
                }">
                    이동하기
                </v-btn>
                <v-btn v-else-if="step === 'needRefresh'" class="modal_confirm_btn w-[90px]" color="white" @click="()=>{
                    $router.go(0)
                }">
                    확인
                </v-btn>
                <v-btn v-else class="modal_confirm_btn w-[90px]" color="white" @click="changeConfirmDialogFalse">
                    확인
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>  
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router';
import {
  getSelectedWalletList,
  getDeleteWallet,
} from '@/api/user/index.js'
import UtilSessionStorage from '../../utils/session';
import MetaMaskWallet from '../../utils/blockChain/metaMaskWallet.js'
import { changeToUpperCase } from '../../utils/utilsFactory';
import { initMemberInfomation, verifyWalletAddress } from '../../api/user';

const sessionStorage = new UtilSessionStorage();

const router = useRouter();

const walletList = ref([])
const dataLoading = ref(false)
let id = '';

const userAuthKakao = ref(0);
const userAuthOTP = ref(0);

const metamask = new MetaMaskWallet();

// 모달창 컨트롤용 변수
const step = ref('');
const modalType = ref(0);
const nested_dialog = ref(false);
const alert_dialog = ref(false);
const confirm_dialog = ref(false)
const modal_title = ref('지갑 등록');
const nested_msg = ref('지갑을 등록합니다. 주소와 다른 정보를 확인해주세요');
const modal_btn_msg = ref('확인');
const modal_btn_width = ref(117);
const modal_inherit_data = ref({});
function changeAlertDialogTrue () { alert_dialog.value = true; }
function changeAlertDialogFalse () { 
  alert_dialog.value = false; 
  if( nested_msg.value.includes('지갑 주소가 삭제') ){
    router.go(0);
  }
}
function changeConfirmDialogTrue () { confirm_dialog.value = true; }
function changeConfirmDialogFalse () { confirm_dialog.value = false; }
function changeNestedDialogTrue () { nested_dialog.value = true; }
function changeNestedDialogFalse () { nested_dialog.value = false; }
function makePleaseLink2ChannelAuthAlert(){
    step.value = `auth_complete`;
    modal_title.value = '2채널 인증 안내';
    nested_msg.value = `2채널 인증을 등록한 후 이용할 수 있습니다. 2채널 인증 페이지로 이동하시겠습니까?`
    changeConfirmDialogTrue();
}

onMounted(async ()=>{
  id = sessionStorage.getUserId();
  const response = await getSelectedWalletList( id );
  let count = 0;
  let result = [];
  
  if ( response ) {
    response.forEach(item => {
      item.networkList.forEach(network => {
          result.push({
            "name": item.walletName,
            "network": network,
            "address": item.address,
            "image" : item.walletImage
        });
      });
    });
  }
  if( result ) walletList.value = result

  initMemberInfomation(id).then((response2) => {
        if (response2) {
            userAuthKakao.value = response2.authKakao === 1
            userAuthOTP.value = response2.authOtp === 1
        } 
    })

  await nextTick();
  dataLoading.value = true;
})

/**
 * 모달 타입 변수에 따라서 다른 함수를 실행합니다.
 */
async function excuteModalAction(){
  const { name, network, address } = modal_inherit_data.value;
  let response = null;
  if( modalType.value === 2 ){
    response = await getDeleteWallet( id, network, name, address )
  }
  changeNestedDialogFalse();
  if( response.status.includes(`success`) ){
      modal_title.value = '안내'
      nested_msg.value = '지갑 주소가 삭제되었습니다.'
      modal_btn_msg.value = '확인'
      modal_btn_width.value = 87
  } else {
      modal_title.value = '안내'
      nested_msg.value = '지갑 주소가 삭제에 실패하였습니다.'
      modal_btn_msg.value = '확인'
      modal_btn_width.value = 87
  }
  changeAlertDialogTrue();
}

async function prepareMakeNewAddress(){
  modalType.value = 0;
  modal_title.value = '지갑 등록'
  nested_msg.value = '지갑을 등록합니다. 주소와 다른 정보를 확인해주세요'
  modal_btn_msg.value = '지갑 등록'
  modal_btn_width.value = 117

  // TODO 2채널 인증을 했는지 체크합니다.
  let it2ChannelCompleted;
  it2ChannelCompleted = userAuthKakao.value === true || userAuthOTP.value === true;
  if( !it2ChannelCompleted ){
    makePleaseLink2ChannelAuthAlert();
    return;
  }

  try {
    const result = await metamask.connect();
      if (result.state === MetaMaskWallet.state.notInstallMetaMask) {
          // 메타마스크 설치가 안된 경우
      } else if (result.state === MetaMaskWallet.state.connectFail) {
          // 연결을 취소한 경우
          walletNoticeModalShow();
      } else if (result.state === MetaMaskWallet.state.address) {
          // account 주소를 정상적으로 받아온 경우
          const userId = sessionStorage.getUserId();
          const response = await verifyWalletAddress({ 
            id : userId,
            networkName : 'ethereum',
            address : result.value,
          });
          if( response?.data?.status === 'success' && response?.data?.result?.isExist === false){
            sessionStorage.setItem('meta', result.value)
            router.push({ path: `/mypage/wallet/register` })
          } else {
            modal_title.value = '안내';
            nested_msg.value = `이미 등록된 메타마스크 지갑 주소입니다.<br/>같은 주소로 중복 등록을 할 수 없습니다.`;
            modal_btn_msg.value = '확인';
            modal_btn_width.value = 87;
            changeAlertDialogTrue();
          }
      } else {
        console.log(`please Install MetaMask`);
      }
    } catch (error) {
        console.error(error);
    }

}

/**
 * 메타마스크 지갑 관련 alert 창 관리 함수
 */
async function walletNoticeModalShow(){
  modalType.value = 1;
  modal_title.value = '안내'
  nested_msg.value = '메타마스크 연결 후 지갑 주소를 등록할 수 있습니다.'
  modal_btn_msg.value = '확인'
  modal_btn_width.value = 87
  changeAlertDialogTrue()
}

/**
 * 메타마스크 지갑 주소 삭제 준비 함수
 * @param {*} wallet 
 */
async function prepareDeleteWallet( wallet ){
  modalType.value = 2;
  modal_inherit_data.value = wallet;
  modal_title.value = '개인지갑 주소 삭제'
  nested_msg.value = '개인지갑 주소를 삭제하면 입출금이 불가능합니다.<br/>정말 삭제하시겠습니까?'
  modal_btn_msg.value = '삭제'
  modal_btn_width.value = 87
  changeNestedDialogTrue()
}
</script>