/* eslint-disable consistent-return */
/* user, member data를 서버에 요청하는 로직 모음 */
import axios from 'axios'

import { useExchangerStore } from '../../stores/exchanger/index'
import { useOrderStore } from '../../stores/order/index'
import { useUserStore } from '../../stores/user/index'

import { requestHeader } from '../config.js'

import { excludeEnd } from '@/utils/utilsFactory'

class ExchangerApi {

    store

    response

    default_market

    default_market_list

    constructor(props) {
        const store = useExchangerStore()
        this.store = store;
    }
  
    /**
     * exchanger에서 쿼리스트링이 바뀌었을 때 실행되는 함수, 거래소에 진입할 때 가장 먼저 시행됩니다.
     * @param {*} newCode
     */
    async updateCode(newCode, newMarket) {
        const market = excludeEnd(newCode, newMarket);
        try {
            this.store.dataAllChange(newCode)
            Promise.allSettled([
              this.getAndSetMarketInfo(market), 
              this.getMarketNftTokenList(market)
            ])
        } catch (error) {
            console.error(error)
        }
    }
    

    /**
     * 디폴트마켓을 가져온 후에 그 디폴트마켓 정보의 marketlist를 세팅합니다.
     */
    async getDefaultMarketAndMarketList(){
        try {
            await this.defaultMarket();
            if( this.default_market ){
                const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/list`, { market2 : this.default_market.market2}, requestHeader() )
                if( response.data.result.data ){
                    this.default_market_list = response.data?.result?.data || [];
                    return this.default_market_list;
                }
            } 
        } catch (error){
            console.error(error);
        }
        return [];
    }

    /**
     * 여러 마켓 리스트가 필요한 경우 객체에 담습니다.
     * @param {*} askMarket
     */
    async getMarketList(askMarket) {
        try {
            // 마켓으로부터 리스트를 가져옵니다.
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/list`, { market2 : askMarket}, requestHeader() )

            // 마켓리스트에 가져온 값들을 담습니다.
            this.store.setMarketListDataWithFormat(response?.data.result.data)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 거래 가능한 마켓의 목록을 가져옵니다.
     */
    async getDealMarketList() {
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/deal_market`, requestHeader());
            this.store.setDealMarketList(response.data.result.data)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 우측 영역의 coin들의 시세를 가져옵니다.
     * @returns
     */
    async updateMarketCoinPrice() {
        try {
            const response = await axios.get(`${process.env.VUE_APP_BACKEND}/market/coin_ticker`, requestHeader());
            if (response) {
                this.store.setMarketCoinQuote(response.data.result.data)
                return response.data
            }
            return response.data
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 디폴트 마켓 정보 요청
     * @returns
     */
    async defaultMarket() {
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/default_market`, requestHeader() );
            this.default_market = response.data.result;
            return response.data
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 출금 수수료를 가져옵니다.
     * @returns
     */
    async getOutFeeList(symbol) {
        try {
            const requestData = {}
            if( symbol ){
                requestData.symbol = symbol;
            }
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/fee/list_out`, requestData, requestHeader() );
            if (response) {
                return response.data.result
            }
            return response.data
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * NFT 거래 시장 목록
     * @param {*} market1
     * @returns
     */
    async getNftMarketList(market1) {
        try {
            const requestData = { market1 }
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/nft_market_list`, requestData, requestHeader() );
            if (response) {
                this.store.setNftMarketList(response.data.result.data)
                return response.data.result.data
            }
        } catch (error) {
            console.error(error)
        }
        return []
    }

    /**
     * 서버로부터 최소 주문 금액 가져오기
     * @param {*} market2
     */
    async getMinOrderLimitPrice(market2) {
        try {
            const requestData = {
                market2,
            }
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/min_max_ord`, requestData, requestHeader());
            if (response) {
                this.store.setMinOrderLimitPrice(response?.data.result.data.min_ord_limit)
                this.store.setMaxOrderLimitPrice(response?.data.result.data.max_ord_limit)
            }
        } catch (error) {
            console.error(error)
        }
    }


    async getAndSetMarketInfo( market ){
        try {
            const response = await this.getMarketInfo({ market1 : market });
            if (response) {
                // 선택된 마켓의 정보를 담습니다.
                this.store.setMarketInfomation(response.data.result.data)
                this.store.setSelectedDataLogoUrl(response.data.result.data.logoUrl)
            }
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 서버로 해당 마켓의 정보를 요청합니다.
     * @param {*} market1
     */
    async getMarketInfo(requestData) {
        try {
            this.response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/info`, requestData, requestHeader() );
            return this.response;
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * 서버로부터 토큰 소속 NFT 리스트를 가져옵니다.
     * @param {*} market1
     */
    async getMarketNftTokenList(market1) {
        try {
            const requestData = {
                market1
            }
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/token_nft_list`, requestData, requestHeader())
            if (response) {
                this.store.setNftTokenList(response.data.result.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 서버로부터 거래 수수료 데이터 가져오기
     */
    async getFeeData() {
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/fee/list_deal`, requestHeader())
            if (response.data.status === 'success') {
                this.store.setFeeData(response.data.result)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 서버에 코인/작품 목록 가져오기 요청
     */
    async allAssetListIncludeCoin( requestData ){
        try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND}/market/asset_list`, requestData, requestHeader());
            return response.data.result.data
        } catch (error) {
            console.error(error)
        }
    }
}

/**
 * 백엔드에 특정 심볼 가격을 요청합니다.
 * @returns 
 */
export async function requestSymbolTicker( requestData ){
    return axios.post(`${process.env.VUE_APP_BACKEND}/market/symbol_ticker`, requestData, requestHeader())
}


/**
 * 백엔드에 호가단위 데이터 요청
 * @param {*} market        마켓 이름 (예시 : ETHKRW)
 * @param {*} market2
 */
export async function requestPriceTickData ( requestData ) {
    return axios.post(`${process.env.VUE_APP_BACKEND}/market/price_tick`, requestData, requestHeader());
}

/**
 * 백엔드에 모아보기용 호가단위 데이터 요청
 * @param {*} market        마켓 이름 (예시 : ETHKRW)
 * @param {*} market2
 */
export async function requestPriceTickDataList ( requestData ) {
    return axios.post(`${process.env.VUE_APP_BACKEND}/market/price_tick_list`, requestData, requestHeader());
}

export default ExchangerApi
