<script setup>
import RankingItem from './item/RankingItem.vue'

// const rankings = ['작품 Top10', '변동률 Top10', '거래량 Top10']
const rankings = ['변동률 Top 10', '거래량 Top 10']
const props = defineProps({
    defaultMarketData: {
        type: Object
    },
    marketcap10: {
        type: Array
    },
    volume10: {
        type: Array
    },
    roc10: {
        type: Array
    }
})
</script>

<template>
    <section class="main_section_B">
        <article
            v-if="rankings"
            class="main_article hd-cover ranking_article h-full slider md:mb-[127px]"
            id="slider"
        >
            <RankingItem
                v-for="(item, index) in rankings"
                :market2="defaultMarketData?.market2 || ''"
                :key="index"
                :title="item"
                :index="index"
                :items="index === 0 ? props.roc10 : index === 1 ? props.volume10 : []"
            />
        </article>
        <article
            v-else
            class="main_article hd-cover ranking_article h-full slider"
            id="slider"
        ></article>
    </section>
</template>
